import { enAdmin } from "./admin";
import { enCommon } from "./common";
import { enRouter } from "./router";
import { enHome } from "./home";
import { enResetPassword } from "./resetPassword";
import { enSurvey } from "./survey";
import { enUser } from "./user";
import { enDropdown } from "./dropdown";
import { enPurchase } from "./purchase";
import { enError } from "./error";

export default {
    common: enCommon,
    home: enHome,
    user: enUser,
    survey: enSurvey,
    resetPassword: enResetPassword,
    admin: enAdmin,
    router: enRouter,
    dropdown: enDropdown,
    purchase: enPurchase,
    error: enError
};
