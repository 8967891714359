import { useState } from "react";
import HeaderHome from "./HeaderHome";
import Login from "./Login";
import SignIn from "./Signin";

export default function Home() {
    const [Shape, setShape] = useState<String>("login");
    console.log("TEST migration - by Ambroise"); // FIXME : remove after testing repo migration

    return (
        <div className="Home">
            <div className="bubbles" />
            <HeaderHome />

            {Shape === "login" && <Login setShape={setShape} />}
            {Shape === "signup" && <SignIn setShape={setShape} />}
        </div>
    );
}
