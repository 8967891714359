import { frAdmin } from "./admin";
import { frCommon } from "./common";
import { frRouter } from "./router";
import { frHome } from "./home";
import { frResetPassword } from "./resetPassword";
import { frSurvey } from "./survey";
import { frUser } from "./user";
import { frDropdown } from "./dropdown";
import { frPurchase } from "./purchase";
import { frError } from "./error";

export default {
    common: frCommon,
    home: frHome,
    user: frUser,
    survey: frSurvey,
    resetPassword: frResetPassword,
    admin: frAdmin,
    router: frRouter,
    dropdown: frDropdown,
    purchase: frPurchase,
    error: frError
};
