export default function PadlockValid() {
    return (
        <svg
            width="46"
            height="38"
            viewBox="0 0 46 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M28.431 12.5H26.8793V9.375C26.8793 5.0625 23.4035 1.5625 19.1207 1.5625C14.8379 1.5625 11.3621 5.0625 11.3621 9.375V12.5H9.81036C8.10346 12.5 6.70691 13.9062 6.70691 15.625V31.25C6.70691 32.9688 8.10346 34.375 9.81036 34.375H28.431C30.1379 34.375 31.5345 32.9688 31.5345 31.25V15.625C31.5345 13.9062 30.1379 12.5 28.431 12.5ZM14.4655 9.375C14.4655 6.78125 16.5448 4.6875 19.1207 4.6875C21.6966 4.6875 23.7759 6.78125 23.7759 9.375V12.5H14.4655V9.375ZM28.431 31.25H9.81036V15.625H28.431V31.25ZM19.1207 26.5625C20.8276 26.5625 22.2241 25.1562 22.2241 23.4375C22.2241 21.7188 20.8276 20.3125 19.1207 20.3125C17.4138 20.3125 16.0173 21.7188 16.0173 23.4375C16.0173 25.1562 17.4138 26.5625 19.1207 26.5625Z"
                fill="#00ACE7"
            />
            <path
                d="M32.1552 8.4375L35.1034 11.4062L43.3276 3.125L45.5 5.3125L35.1034 15.7813L29.9828 10.625L32.1552 8.4375Z"
                fill="#00ACE7"
            />
        </svg>
    );
}
