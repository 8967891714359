import axios from "axios";

interface DataGetHomePageByLang {
    lang: string;
}

export const GetHomePageByLang = async (
    data: DataGetHomePageByLang,
    callback: Function
) => {
    const { lang } = data;
    axios
        .get(`/api/homePage/${lang}`)
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};

interface DataPutHomePage {
    token: string;
    id: number;
    title: string;
    subTitle: string;
    catchLine: string;
    trad: {
        id: number;
        title: string;
        subTitle: string;
        catchLine: string;
    };
}

export const PutHomePage = async (data: DataPutHomePage, callback: Function) => {
    const { token, id, title, subTitle, catchLine, trad } = data;
    axios
        .put(`/api/homePage`, {
            id,
            token,
            title,
            subTitle,
            catchLine,
            trad
        })
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};
