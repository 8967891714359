import { PropsSVG } from "./SVG.interface";

export default function Key({
    className,
    onClick,
    size = 24,
    color = "#00ACE7",
    background = false
}: PropsSVG) {
    return (
        <svg
            className={className}
            width={size}
            height={size}
            onClick={onClick}
            viewBox={background ? `0 0 ${size} ${size}` : `0 0 24 24`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            {background && <rect width="40" height="40" rx="20" fill={"#EB864B"} />}
            <path
                d="M22 19H16V15H13.32C12.18 17.42 9.72 19 7 19C3.14 19 0 15.86 0 12C0 8.14 3.14 5 7 5C9.72 5 12.17 6.58 13.32 9H24V15H22V19ZM18 17H20V13H22V11H11.94L11.71 10.33C11.01 8.34 9.11 7 7 7C4.24 7 2 9.24 2 12C2 14.76 4.24 17 7 17C9.11 17 11.01 15.66 11.71 13.67L11.94 13H18V17ZM7 15C5.35 15 4 13.65 4 12C4 10.35 5.35 9 7 9C8.65 9 10 10.35 10 12C10 13.65 8.65 15 7 15ZM7 11C6.45 11 6 11.45 6 12C6 12.55 6.45 13 7 13C7.55 13 8 12.55 8 12C8 11.45 7.55 11 7 11Z"
                fill={color}
                transform={background ? "translate(8,8)" : ""}
            />
        </svg>
    );
}
