import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { GetResults } from "../../../services/survey.service";
import { Question } from "../../Admin/models";
import List from "../../Templates/Dashboard/List";
import { RowHeader } from "../../Templates/Dashboard/Row";

const rowOptions = {
    "id": true,
    "answers-value": true,
    "capacity-name": true,
    "psychology-name": true,
    "answers-result": true
};

interface ResponseDetailProps {
    survey:
        | {
              id: number;
              userId: number;
              results: ResultType[];
          }
        | boolean;
}

interface ResultObject {
    id: number;
    value: string;
    label: Object[];
    capacity: Object;
    psychology: Object;
}

interface ResultType {
    userId: number;
    question: Question;
    value: string;
}

export default function ResponseDetail({ survey }: ResponseDetailProps) {
    const [tSurvey] = useTranslation("survey");
    const [tAdmin] = useTranslation("admin");
    const [cookies] = useCookies(["access_token"]);

    const [results, setResults] = useState<Object[]>([]);

    const FormatValue = {
        "0": "0: " + tSurvey("notAgree"),
        "1": "1: " + tSurvey("quiteAgree"),
        "2": "2: " + tSurvey("somewhatAgree"),
        "3": "3: " + tSurvey("totalyAgree")
    };

    function formatResult(surveyId: number, results: ResultType[]): ResultObject[] {
        let format: ResultObject[] = [];
        for (const result of results) {
            if (surveyId === result.userId)
                format.push({
                    id: result.question.id,
                    value: FormatValue[result.value as keyof typeof FormatValue],
                    label: result.question.label,
                    capacity: result.question.capacity,
                    psychology: result.question.psychology
                });
        }
        const unicityOfQuestions: any = {};
        format = format.filter((result) => {
            return (unicityOfQuestions[result.id] = !unicityOfQuestions.hasOwnProperty(
                result.id
            ));
        });
        return format;
    }
    useEffect(() => {
        if (typeof survey === "object") {
            const formattedResults = formatResult(survey.userId, survey.results);
            setResults(formattedResults);
        }
    }, []);

    const resolveGet = (response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                setResults(response.data.results);
                break;
            default:
                console.log("error", response);
                break;
        }
    };

    return (
        <div className="table" id="AnswerTable">
            <RowHeader
                {...{
                    cells: {
                        "id": { text: tAdmin("ID"), active: false },
                        "answers-value": { text: tAdmin("Questions"), active: false },
                        "capacity-name": { text: tAdmin("Capacities"), active: false },
                        "psychology-name": { text: tAdmin("Psychology"), active: false },
                        "answer-result": { text: tAdmin("Result value"), active: false },
                        "reset": false
                    },
                    callback: () => {},
                    filters: false
                }}
            />

            {typeof survey === "object" && (
                <List
                    datas={results}
                    rowOptions={rowOptions}
                    getDatas={() => {
                        GetResults(
                            { token: cookies.access_token, id: survey.id },
                            resolveGet
                        );
                    }}
                />
            )}
        </div>
    );
}
