export const enResetPassword = {
    /**
     * Reset password page
     */
    "Change my password": "Change my password",
    "Confirm my new password": "Confirm my new password",

    "Your password has been successfully changed !":
        "Your password has been successfully changed!",
    "We were unable to update your password.": "We were unable to update your password.",
    "Your token may have expired, try again.": "Your token may have expired, try again.",
    "If the situation persists, do not hesitate to contact our support.":
        "If the situation persists, do not hesitate to contact our support."
};
