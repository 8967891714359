import { PropsSVG } from "./SVG.interface";

export default function StartSurvey({
    className,
    onClick,
    size = 24,
    color = "#00ACE7"
}: PropsSVG) {
    return (
        <svg
            className={className}
            width={size}
            height={size}
            onClick={onClick}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H6C4.9 3 4 3.9 4 5V20C4 21.1 4.9 22 6 22H14V20H6V5H8V8H16V5H18V13H20V5C20 3.9 19.1 3 18 3ZM12 5C11.45 5 11 4.55 11 4C11 3.45 11.45 3 12 3C12.55 3 13 3.45 13 4C13 4.55 12.55 5 12 5Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.41 15.42L19.99 14L16.82 17.17L15.41 15.75L14 17.16L16.82 20L21.41 15.42Z"
                fill={color}
            />
            <path d="M13 10H8V12H13V10Z" fill={color}/>
            <path d="M13 13H8V15H13V13Z" fill={color} />
            <path d="M13 16H8V18H13V16Z" fill={color} />
        </svg>
    );
}
