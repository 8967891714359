export const enPurchase = {
    BuyAction: "Buy",
    WichTestTypeQuestion: "Which TEST 4i do you want to buy?",
    HowManyQuestion: "How many TEST 4i do you want to buy?",
    Individual: "Individual",
    Crosseye: "Cross test",
    withoutTax: "without tax",
    totalPriceWithTax: "Total price with tax",
    totalPriceWithoutTax: "Total price without tax",
    subTotalPriceWithTax: "Subtotal price with tax",
};
