import { AxiosResponse } from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import Download from "../../../assets/Download";
import { GetPDF, GetResults } from "../../../services/survey.service";
import { Colors } from "../../../utils";
import RoleRestrictZone from "../../Templates/RoleRestrictZone";
import Waiting from "../../Templates/Waiting";
import CapacityDetail from "./CapacityDetail";
import CartoPsychology from "./CartoPsychology";
import DetailPsychology from "./DetailPsychology";
import ResponseDetail from "./ResponseDetail";

interface PropsTabs {
    id: number;
    activeDL?: boolean;
}
interface PropsResults {
    capacity: Array<{
        capacity: { id: number; name: string; description: string };
        psychology: { id: number; name: string; description: string };
        max: number;
        purcent: number;
        score: number;
    }>;
    psychology: { id: number; name: string; description: string };
    max: number;
    purcent: number;
    score: number;
}

const clearPsychology = (psychologyName: string) => {
    switch (psychologyName) {
        default:
        case "Monde de Soi":
            return { img: "self", color: Colors.Flamenco };
        case "Monde Inattendu":
            return { img: "situational", color: Colors.EyePoppingCherry };
        case "Monde de l'Autre":
            return { img: "relational", color: Colors.TranquilBay };
        case "Nouveau Monde":
            return { img: "creative", color: Colors.PassionFlower };
    }
};
export default function SurveyTabs({ id, activeDL = true }: PropsTabs) {
    const [t] = useTranslation("survey");
    const [cookies] = useCookies(["firstName", "access_token", "id"]);
    const [results, setResult] = useState<Array<PropsResults> | false>(false);
    const [crosseyes, setCrosseyes] = useState<Array<PropsResults>>([]);
    const [survey, setSurvey] = useState(false);
    const [tab, setTab] = useState("carto");
    const busy = useRef(false);
    useEffect(() => {
        if (!results) {
            if (!busy.current) {
                GetResults({ token: cookies.access_token, id }, resolveGet);
                busy.current = true;
            }
        } else {
            if (results) {
                changeHeader(clearPsychology(results[0]?.psychology.name));
            }
            if (crosseyes) {
                changeHeader(clearPsychology(crosseyes[0]?.psychology.name));
            }
        }
    });

    const changeTab = (value: string) => {
        setTab(value);
    };
    const changeHeader = ({ img, color }: { img: string; color: string }) => {
        const header = document.querySelector<HTMLElement>(".results header");
        const SurveyTabs = document.querySelector<HTMLElement>("#SurveyTabs");
        if (!SurveyTabs) return;
        SurveyTabs.className = img;
        if (!header) return;
        header.style.background = `url(../${img}.png)`;
        header.style.backgroundSize = "cover";
    };

    const resolveGet = useCallback((response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                setSurvey(response.data.survey);
                setResult(response.data.results);
                setCrosseyes(response.data.crosseyes);
                break;

            default:
                console.log("error", response);
                break;
        }
        busy.current = false;
    }, []);

    const downloadPDF = () => {
        GetPDF({ token: cookies.access_token, id }, resolveDownload);
    };
    const resolveDownload = useCallback((response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "soft-skills-test-resultats.pdf");
                document.body.appendChild(link);
                link.click();
                link.remove();
                break;
        }
    }, []);
    if (!results) {
        return (
            <div id="SurveyTabs">
                <Waiting count={20} width={800} height={600} />
            </div>
        );
    }
    return (
        <div id="SurveyTabs">
            <ul>
                <li
                    className={tab === "carto" ? "active" : ""}
                    onClick={() => {
                        changeTab("carto");
                    }}
                >
                    {t("Map of the worlds")}
                </li>
                <li
                    className={tab === "detail" ? "active" : ""}
                    onClick={() => {
                        changeTab("detail");
                    }}
                >
                    {t("Your worlds")}
                </li>
                <li
                    className={tab === "capacity" ? "active" : ""}
                    onClick={() => {
                        changeTab("capacity");
                    }}
                >
                    {t("Your capacities")}
                </li>
                <RoleRestrictZone namespace="user" zoneId="view-survey-results">
                    <li
                        className={tab === "response" ? "active" : ""}
                        onClick={() => {
                            changeTab("response");
                        }}
                    >
                        {t("Your responses")}
                    </li>
                </RoleRestrictZone>
            </ul>
            <div className="content">
                {activeDL && (
                    <div className="download">
                        <Download size={40} onClick={downloadPDF} />
                    </div>
                )}
                {results && (
                    <>
                        {tab === "carto" && (
                            <CartoPsychology results={results} crosseyes={crosseyes} />
                        )}
                        {tab === "detail" && (
                            <DetailPsychology
                                results={results}
                                crosseyes={crosseyes}
                                survey={survey}
                            />
                        )}
                        {tab === "capacity" && (
                            <CapacityDetail
                                results={results}
                                crosseyes={crosseyes}
                                survey={survey}
                            />
                        )}
                        <RoleRestrictZone namespace="user" zoneId="view-survey-results">
                            {tab === "response" && <ResponseDetail survey={survey} />}
                        </RoleRestrictZone>
                    </>
                )}
            </div>
        </div>
    );
}
