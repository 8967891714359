import { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import List from "../assets/List";

export default function DropZone({ file, removeFile }: PropsDropZone) {
    const [t, i18n] = useTranslation("admin");
    return (
        <div className="DropZone">
            {!file ? (
                <>
                    {t("Drag / drop")}
                    <br />
                    {t("download file")}
                    <br />
                    {t("here")}
                    <br />
                    <List />
                </>
            ) : (
                <>
                    {file.name}
                    <div className="btn active" onClick={removeFile}>
                        {t("Cancel")}
                    </div>
                </>
            )}
        </div>
    );
}
interface PropsDropZone {
    file: any;
    removeFile: MouseEventHandler;
}
