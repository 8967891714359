import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

interface Label {
    lang: string;
    value: string;
    crosseyes: string;
}

interface QuestionProps {
    id: string;
    label: Array<Label>;
    part: number;
    index: number;
    currentQuestion: number;
    answer: { value: string };
    changeForm: () => void;
    type?: number;
}
const DataAnwsers = {
    notAgree: {
        id: 0,
        name: "Très rarement"
    },
    quiteAgree: {
        id: 1,
        name: "Parfois"
    },
    somewhatAgree: {
        id: 2,
        name: "Souvent"
    },
    totalyAgree: {
        id: 3,
        name: "Presque toujours"
    }
};

export default function Question({
    id,
    changeForm,
    label,
    part,
    answer,
    index,
    currentQuestion,
    type
}: QuestionProps) {
    const [t, i18n] = useTranslation("survey");
    const divQuestion = useRef<HTMLDivElement>(null);
    const inputCheckbox = useRef<HTMLInputElement>(null);
    const handleRadio = useCallback(() => {
        if (!divQuestion.current) return false;
        window.scroll({
            top: divQuestion.current.offsetTop + divQuestion.current.scrollHeight - 75,
            behavior: "smooth"
        });
        changeForm();
    }, [changeForm]);

    const findTranslate = (): Label => {
        const translateTitle = label.find((x) => x.lang === i18n.language);
        if (!translateTitle) {
            return label[0];
        }
        return translateTitle;
    };

    const [title, setTitle] = useState(findTranslate());
    i18n.on("languageChanged", () => {
        setTitle(findTranslate());
    });

    return (
        <div
            className={currentQuestion >= index ? "question" : "question blocked"}
            ref={divQuestion}
        >
            <div className="title">{type === 1 ? title?.crosseyes : title?.value}</div>
            <fieldset className="answers" data-id={`answer-${id}`}>
                {Object.keys(DataAnwsers).map((key) => {
                    return (
                        <div className="field" key={key}>
                            <label htmlFor={`${key}-${id}`} className="checkbox">
                                <input
                                    type="radio"
                                    name={`answer-${id}`}
                                    id={`${key}-${id}`}
                                    onChange={handleRadio}
                                    ref={inputCheckbox}
                                />
                                <div className="display">
                                    <div className="bulle"></div>
                                    <div className="label">{t(key)}</div>
                                </div>
                            </label>

                            {/* <div className="bulle"></div>
              <input
                type="radio"
                name={`answer-${id}`}
                id={`${key}-${id}`}
                onChange={handleRadio}
                ref={inputCheckbox}
              />
              <label htmlFor={`${key}-${id}`}>{DataAnswer.name}</label> */}
                        </div>
                    );
                })}
            </fieldset>
        </div>
    );
}
