import { useTranslation } from "react-i18next";
import UserDecay from "../../assets/UserDecay";

type errorProps = {
    errorMessage?: string;
};

export const BadRequest = ({ errorMessage = "" }: errorProps) => {
    const [t, i18n] = useTranslation("error");
    return (
        <>
            <h3>{t("Bad Request")}</h3>
            <p>{errorMessage}</p>
            <UserDecay size={60} />
        </>
    );
};
