import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { getPsychologies } from "../../services/psychology.service";
import { useLocalStorage } from "../../utils/useLocalStorage";
import Dropdown from "./Dropdown";
interface PropsPsychologyDropDown {
    refInput: React.RefObject<HTMLInputElement>;
    callback?: Function;
    defaultValue?: string | false;
    label?: string;
}

export default function PsychologyDropDown({
    refInput,
    callback,
    defaultValue,
    label
}: PropsPsychologyDropDown) {
    const [cookie] = useCookies(["access_token"]);
    const [psychology, setPsychology] = useState<false | Array<any>>(false);
    const [currentVersion, setCurrentVersion] = useLocalStorage("version", "");
    useEffect(() => {
        const resolveGet = (response: AxiosResponse) => {
            switch (response.status) {
                case 200:
                    setPsychology(response.data);
                    break;

                default:
                    break;
            }
        };
        if (!psychology) {
            getPsychologies(
                { token: cookie.access_token, version: currentVersion },
                resolveGet
            );
        }
    });
    if (!psychology) return null;
    return (
        <Dropdown
            label={label}
            refInput={refInput}
            values={psychology.map((psy: any) => psy.name)}
            defaultValue={defaultValue}
            callback={callback}
        />
    );
}
