import { PropsSVG } from "./SVG.interface";

export default function Psychology({ className, onClick, size = 24 }: PropsSVG) {
    return (
        <svg
            className={className}
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="#00ACE7"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
        >
            <path
                d="M15.8205 7.22L14.8205 7.62C14.6105 7.46 14.3905 7.33 14.1505 7.23L14.0005 6.17C13.9805 6.07 13.9005 6 13.8005 6H12.2005C12.1005 6 12.0205 6.07 12.0105 6.17L11.8605 7.23C11.6205 7.33 11.3905 7.46 11.1905 7.62L10.1905 7.22C10.1005 7.19 9.99054 7.22 9.95054 7.31L9.15054 8.69C9.10054 8.78 9.12054 8.89 9.20054 8.95L10.0505 9.61C10.0205 9.73 10.0005 9.87 10.0005 10C10.0005 10.13 10.0105 10.26 10.0305 10.39L9.19054 11.05C9.11054 11.11 9.09054 11.22 9.14054 11.3L9.94054 12.69C9.99054 12.78 10.0905 12.81 10.1905 12.78L11.1805 12.38C11.3905 12.54 11.6105 12.67 11.8605 12.77L12.0005 13.83C12.0205 13.93 12.1005 14 12.2005 14H13.8005C13.9005 14 13.9805 13.93 14.0005 13.83L14.1505 12.77C14.3905 12.67 14.6205 12.54 14.8205 12.38L15.8105 12.78C15.9005 12.82 16.0105 12.78 16.0505 12.69L16.8505 11.3C16.9005 11.21 16.8805 11.11 16.8005 11.05L15.9705 10.39C15.9905 10.26 16.0005 10.13 16.0005 10C16.0005 9.86 15.9905 9.73 15.9705 9.61L16.8205 8.95C16.9005 8.89 16.9205 8.78 16.8705 8.69L16.0705 7.31C16.0205 7.22 15.9105 7.19 15.8205 7.22ZM13.0005 11.43C12.2105 11.43 11.5705 10.79 11.5705 10C11.5705 9.21 12.2105 8.57 13.0005 8.57C13.7905 8.57 14.4305 9.21 14.4305 10C14.4305 10.79 13.7905 11.43 13.0005 11.43Z"
                fill="#00ACE7"
            />
            <path
                d="M19.9398 9.06C19.5098 5.79 16.7098 3.2 13.4098 3.01C13.2698 3 13.1398 3 12.9998 3C9.46985 3 6.56985 5.61 6.07985 9L4.14985 12.48C3.73985 13.14 4.21985 14 4.99985 14H5.99985V16C5.99985 17.1 6.89985 18 7.99985 18H8.99985V21H15.9998V16.32C18.6198 15.07 20.3498 12.24 19.9398 9.06ZM14.8898 14.63L13.9998 15.05V19H10.9998V16H7.99985V12H6.69985L8.02985 9.67C8.20985 7.06 10.3498 5 12.9998 5C15.7598 5 17.9998 7.24 17.9998 10C17.9998 12.09 16.7098 13.88 14.8898 14.63Z"
                fill="#00ACE7"
            />
        </svg>
    );
}
