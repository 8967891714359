import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AxiosResponse } from "axios";
import { ReactElement, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import Admin from "../../../assets/Admin";
import Edit from "../../../assets/Edit";
import Superuser from "../../../assets/Superuser";
import ValidDoc from "../../../assets/ValidDoc";
import { PostUpdateUser } from "../../../services/root.service";
import Btn from "../../Templates/Btn";
import Checkbox from "../../Templates/Checkbox";
import { reloadList } from "../../Templates/Dashboard/List";
import Dropdown from "../../Templates/Dropdown";
import Field, { RowFields } from "../../Templates/Field";
import Modal from "../../Templates/Modal";
import RoleRestrictZone from "../../Templates/RoleRestrictZone";
import SidePage from "../../Templates/SidePage";
import Tag, { TagObject } from "../../Templates/Tag";
import VersionDropDown from "../../Templates/VersionDropdown";
import Waiting from "../../Templates/Waiting";
import { User } from "../models";
import { DatasDropdown } from "./DatasDropdown";

export function EditUser({ user }: PropsEditUser) {
    const [t] = useTranslation("admin");
    const [cookies] = useCookies(["access_token"]);
    const id = useRef<HTMLInputElement | null>(null);
    const firstName = useRef<HTMLInputElement | null>(null);
    const lastName = useRef<HTMLInputElement | null>(null);
    const email = useRef<HTMLInputElement | null>(null);
    const age = useRef<HTMLInputElement | null>(null);
    const gender = useRef<HTMLInputElement | null>(null);
    const studies = useRef<HTMLInputElement | null>(null);
    // const nationality = useRef<HTMLInputElement | null>(null);

    const grade = useRef<HTMLInputElement | null>(null);
    const service = useRef<HTMLInputElement | null>(null);
    const company = useRef<HTMLInputElement | null>(null);
    const secteur = useRef<HTMLInputElement | null>(null);
    const country = useRef<HTMLInputElement | null>(null);
    const district = useRef<HTMLInputElement | null>(null);
    const tag = useRef<HTMLInputElement | null>(null);
    // const version = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        const handleEnter = (e: KeyboardEvent) => {
            if (e.key === "Enter" && document.activeElement === tag.current) {
                handleTag();
            }
        };
        if (tag.current) {
            tag.current.addEventListener("keydown", handleEnter);
        }
        return () => {
            if (tag!.current) {
                tag!.current.removeEventListener("keydown", handleEnter);
            }
        };
    });

    const [error, setError] = useState({});
    const [tags, setTags] = useState<string[]>(
        user.tags ? user.tags.map((tag) => tag.label) : []
    );
    const [tagToDelete, setTagToDelete] = useState<TagObject[]>([]);

    const [companySize, setCompanySize] = useState<false | string>(
        user.companySize ? user.companySize : false
    );
    const [seniority, setSeniority] = useState<false | string>(
        user.seniority ? user.seniority : false
    );
    const [role, setRole] = useState<false | string>(
        user.role ? String(user.role) : false
    );
    const [isNotify, setNotify] = useState<boolean>(false);
    const [hasANewTest, setHasANewTest] = useState<boolean>(false);
    const [hasToSendMail, setHasToSendMail] = useState<boolean>(false);

    const [activeBtn, setActiveBtn] = useState<boolean>(true);
    const [activeDistrict, setActiveDistrict] = useState<boolean>(false);

    const [modal, setModal] = useState<ReactElement | false>(false);

    const changeForm = () => {
        if (email.current === null) {
            return setActiveBtn(false);
        }
        return setActiveBtn(verifForm());
    };
    const verifForm = () => {
        const verifEmail = String(email.current?.value)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        if (verifEmail) return true;
        return false;
    };
    const handleForm = () => {
        if (!activeBtn || email.current === null) return false;
        if (verifForm()) {
            const formData = {
                token: cookies.access_token,
                id: id.current!.value,
                firstName: firstName.current!.value,
                lastName: lastName.current!.value,
                email: email.current!.value,
                age: age.current!.value,
                // nationality: nationality.current!.value,
                studies: studies.current!.value,
                grade: grade.current!.value,
                service: service.current!.value,
                company: company.current!.value,
                secteur: secteur.current!.value,
                country: country.current!.value,
                district: district.current!.value,
                gender: gender.current!.value,
                // gender: gender,
                companySize: companySize,
                seniority: seniority,
                role: role,
                // version: version.current!.value,
                tags: tags
            };
            PostUpdateUser(
                formData,
                isNotify,
                hasANewTest,
                hasToSendMail,
                tagToDelete,
                resolvePost
            );
            setModal(
                <>
                    <Waiting />
                </>
            );
        }
    };
    const resolvePost = (response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                setModal(
                    <>
                        <h3>{t("Your change has been made.")}</h3>
                        <ValidDoc
                            onClick={() => {
                                setModal(false);
                            }}
                        />
                    </>
                );
                reloadList();
                break;
            case 400:
            case 403:
                setModal(
                    <>
                        <h3>{t("Action forbidden", { ns: "common" })}</h3>
                    </>
                );
                break;
            default:
                console.log("error", response);
                break;
        }
    };

    const displayNewTag = (newTag: string) => {
        if (tags.includes(newTag)) return;
        const newList = [...tags, newTag];
        setTags(newList);
    };

    const handleTag = () => {
        if (!tag.current || !tag.current.value) {
            setError({ tag: true });
            setTimeout(() => {
                setError({});
            }, 2000);
            return;
        }
        displayNewTag(tag.current.value);
        tag.current.value = "";
    };

    const handleDeleteTag = (tagValue: string) => {
        const updateList = tags.filter((value) => value !== tagValue);
        const isAlreadyTag = user.tags.find((tag) => tag.label === tagValue);
        if (isAlreadyTag) {
            const newDeleteList = [isAlreadyTag, ...tagToDelete];
            setTagToDelete(newDeleteList);
        }
        setTags(updateList);
    };

    return (
        <div id="EditUser">
            <Modal content={modal} setContent={setModal} />
            <h3>
                {user.firstName} {user.lastName}
            </h3>
            <fieldset>
                <h5>{t("Personal data")}</h5>
                <Field
                    type="hidden"
                    id="id"
                    refField={id}
                    placeholder={"999"}
                    callback={undefined}
                    defaultValue={String(user.id)}
                />
                <RowFields>
                    <Field
                        type="text"
                        id="firstName"
                        label={t("First name")}
                        placeholder="John"
                        refField={firstName}
                        callback={changeForm}
                        defaultValue={user.firstName}
                    />
                    <Field
                        type="text"
                        id="lastName"
                        label={t("Last name")}
                        placeholder="Doe"
                        refField={lastName}
                        callback={changeForm}
                        defaultValue={user.lastName}
                    />
                </RowFields>

                <Field
                    type="text"
                    id="email"
                    label={`${t("Email")} *`}
                    placeholder="johnDoe@email.com"
                    defaultValue={user.email}
                    refField={email}
                    callback={changeForm}
                />
                <RowFields>
                    <Dropdown
                        label={t("Age")}
                        refInput={age}
                        values={DatasDropdown.age}
                        callback={changeForm}
                        defaultValue={user.age}
                    />
                    <Dropdown
                        label={t("Gender")}
                        refInput={gender}
                        values={DatasDropdown.gender}
                        callback={changeForm}
                        defaultValue={user.gender}
                    />
                </RowFields>
                <RowFields>
                    <Dropdown
                        label={t("Studies")}
                        refInput={studies}
                        values={DatasDropdown.studies}
                        callback={changeForm}
                        defaultValue={user.studies}
                    />

                    {/* <Dropdown
            label={"Nationalité"}
            refInput={nationality}
            values={DatasDropdown.nationality}
            callback={changeForm}
            defaultValue={user.nationality}
          /> */}
                </RowFields>
            </fieldset>
            <fieldset>
                <h5>{t("Professional data")}</h5>
                <RowFields>
                    <Dropdown
                        label={t("Grade")}
                        refInput={grade}
                        values={DatasDropdown.grade}
                        callback={changeForm}
                        defaultValue={user.grade}
                    />
                    <Dropdown
                        label={t("Service")}
                        refInput={service}
                        values={DatasDropdown.service}
                        callback={changeForm}
                        defaultValue={user.service}
                    />
                </RowFields>
                <RowFields>
                    <Field
                        type="text"
                        id="company"
                        label="Entreprise"
                        placeholder="ACT4 TALENTS"
                        refField={company}
                        callback={changeForm}
                        defaultValue={user.company}
                    />
                    <Dropdown
                        label={t("Sector")}
                        refInput={secteur}
                        values={DatasDropdown.secteur}
                        callback={changeForm}
                        defaultValue={user.secteur}
                    />
                </RowFields>
                {/* <RowFields label="Taille de l’entreprise">
          <Checkbox
            type={"radio"}
            value={"under10company"}
            name="companysize"
            callback={setCompanySize}
            defaultValue={user.companySize}
          >
            - de 10
          </Checkbox>
          <Checkbox
            type={"radio"}
            value={"under50company"}
            name="companysize"
            callback={setCompanySize}
            defaultValue={user.companySize}
          >
            - de 50
          </Checkbox>
          <Checkbox
            type={"radio"}
            value={"under100company"}
            name="companysize"
            callback={setCompanySize}
            defaultValue={user.companySize}
          >
            - de 100
          </Checkbox>
          <Checkbox
            type={"radio"}
            value={"under250company"}
            name="companysize"
            callback={setCompanySize}
            defaultValue={user.companySize}
          >
            - de 250
          </Checkbox>
          <Checkbox
            type={"radio"}
            value={"more250company"}
            name="companysize"
            callback={setCompanySize}
            defaultValue={user.companySize}
          >
            + de 250
          </Checkbox>
        </RowFields> */}
                <RowFields label={t("Seniority")}>
                    <Checkbox
                        type={"radio"}
                        value={"under1seniority"}
                        name="seniority"
                        callback={setSeniority}
                        defaultValue={user.seniority}
                    >
                        {t("under1seniority")}
                    </Checkbox>
                    <Checkbox
                        type={"radio"}
                        value={"under3seniority"}
                        name="seniority"
                        callback={setSeniority}
                        defaultValue={user.seniority}
                    >
                        {t("under3seniority")}
                    </Checkbox>
                    <Checkbox
                        type={"radio"}
                        value={"under6seniority"}
                        name="seniority"
                        callback={setSeniority}
                        defaultValue={user.seniority}
                    >
                        {t("under6seniority")}
                    </Checkbox>
                    <Checkbox
                        type={"radio"}
                        value={"under10seniority"}
                        name="seniority"
                        callback={setSeniority}
                        defaultValue={user.seniority}
                    >
                        {t("under10seniority")}
                    </Checkbox>
                    <Checkbox
                        type={"radio"}
                        value={"more10seniority"}
                        name="seniority"
                        callback={setSeniority}
                        defaultValue={user.seniority}
                    >
                        {t("more10seniority")}
                    </Checkbox>
                </RowFields>
                <RowFields>
                    <Dropdown
                        label={t("Country")}
                        refInput={country}
                        values={DatasDropdown.country}
                        defaultValue={user.country}
                        callback={() => {
                            if (country.current && country.current!.value === "France")
                                setActiveDistrict(true);
                            else setActiveDistrict(false);
                            changeForm();
                        }}
                    />
                    <Field
                        type="text"
                        id="district"
                        label={activeDistrict ? t("Postal code") : t("City")}
                        placeholder={activeDistrict ? "95001" : "Paris"}
                        refField={district}
                        callback={changeForm}
                    />
                </RowFields>
            </fieldset>
            <fieldset>
                <h5>{t("Add a tag")}</h5>
                <RowFields>
                    <Field
                        type="text"
                        id="tag"
                        placeholder={t("Add a tag")}
                        refField={tag}
                        callback={changeForm}
                        error={error}
                    />
                    <Btn className="margin-auto" callback={handleTag}>
                        <FontAwesomeIcon icon={faCirclePlus} />
                    </Btn>
                </RowFields>
                <div className="tags">
                    {tags.map((value) => {
                        return (
                            <Tag
                                key={value}
                                value={value}
                                color="primary"
                                active={true}
                                callback={handleDeleteTag}
                            />
                        );
                    })}
                </div>
            </fieldset>

            <RoleRestrictZone namespace="user" zoneId="change-role">
                <fieldset>
                    <h5>{t("Status")}</h5>
                    <RowFields>
                        <Checkbox
                            type={"radio"}
                            value={"0"}
                            name="role"
                            callback={setRole}
                            defaultValue={user.role}
                        >
                            <Superuser /> SuperAdmin
                        </Checkbox>
                        <Checkbox
                            type={"radio"}
                            value={"1"}
                            name="role"
                            callback={setRole}
                            defaultValue={user.role}
                        >
                            <Admin /> Admin
                        </Checkbox>
                        <Checkbox
                            type={"radio"}
                            value={"2"}
                            name="role"
                            callback={setRole}
                            defaultValue={user.role}
                        >
                            User
                        </Checkbox>
                    </RowFields>
                </fieldset>
            </RoleRestrictZone>

            {/* <fieldset>
                <h5>{t("Survey version")}</h5>
                <VersionDropDown
                    refInput={version}
                    callback={changeForm}
                    defaultValue={user.surveys[0] ? user.surveys[0].template.version : ""}
                />

                <RowFields>
                    <Checkbox
                        type={"checkbox"}
                        value={"addNewTest"}
                        name="addNewTest"
                        callback={() => setHasANewTest(!hasANewTest)}
                        checked={hasANewTest}
                    >
                        {t("Add a new test for this user")}
                    </Checkbox>
                    <Checkbox
                        type={"checkbox"}
                        value={"sendMail"}
                        name="sendMail"
                        callback={() => setHasToSendMail(!hasToSendMail)}
                        checked={hasToSendMail}
                    >
                        {t("Send an email to notify test access")}
                    </Checkbox>
                </RowFields>
            </fieldset> */}
            <fieldset>
                <Checkbox
                    type={"checkbox"}
                    value={"isNotify"}
                    name="isNotify"
                    callback={() => setNotify(!isNotify)}
                    checked={isNotify}
                >
                    {t("Send password to user")}
                </Checkbox>
            </fieldset>
            <Btn className={activeBtn ? "active" : "disabled"} callback={handleForm}>
                {t("Save my changes")}
            </Btn>
        </div>
    );
}
interface PropsEditUser {
    user: User;
}

export function IconeEditUser(props: PropsIconeEdituser) {
    const [sidepage, setSidepage] = useState<ReactElement | false>(false);
    return (
        <>
            <SidePage content={sidepage} setContent={setSidepage} />
            <Edit
                className="tool"
                onClick={() => {
                    setSidepage(<EditUser user={props.data} />);
                }}
            />
        </>
    );
}
interface PropsIconeEdituser {
    update: { date: number };
    data: User;
}
