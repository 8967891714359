import { AxiosResponse } from "axios";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetHomePageByLang } from "../../services/homePage.service";
import { styled4i, lineBreak } from "../../utils";
import Waiting from "../Templates/Waiting";

export default function HeaderHome() {
    const [, i18n] = useTranslation();
    const [textHome, setTextHome] = useState<ReactElement | null>(null);
    useEffect(() => {
        const resolveGet = (response: AxiosResponse) => {
            setTextHome(null);
            switch (response.status) {
                case 200:
                    setTextHome(
                        <>
                            <h1
                                dangerouslySetInnerHTML={{
                                    __html: `${styled4i(response.data.title)}`
                                }}
                            />
                            <h2
                                dangerouslySetInnerHTML={{
                                    __html: response.data.subTitle
                                }}
                            />
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: lineBreak(response.data.catchLine)
                                }}
                            />
                        </>
                    );
                    break;
                case 404:
                    setTextHome(<Waiting width={1000} height={220} count={30} />);
                    break;
                default:
                    break;
            }
        };

        if (!textHome) {
            GetHomePageByLang({ lang: i18n.language }, resolveGet);
        }
    }, [textHome, i18n]);
    i18n.on("languageChanged", () => {
        setTextHome(null);
    });
    return <header>{textHome}</header>;
}
