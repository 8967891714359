import { AxiosResponse } from "axios";
import { ReactElement, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import Edit from "../../../assets/Edit";
import Toc from "../../../assets/Toc";
import { PutQuestion } from "../../../services/question.service";
import Btn from "../../Templates/Btn";
import CapacityDropDown from "../../Templates/CapacityDropdown";
import Dropdown from "../../Templates/Dropdown";
import Field from "../../Templates/Field";
import Modal from "../../Templates/Modal";
import PsychologyDropDown from "../../Templates/PsychologyDropdown";
import SidePage from "../../Templates/SidePage";
import TextField from "../../Templates/TextField";
import Waiting from "../../Templates/Waiting";
import { Question } from "../models";

interface PropsEditQuestion {
    question: Question;
}

export function EditQuestion({ question }: PropsEditQuestion) {
    const [t] = useTranslation("admin");
    const [cookies] = useCookies(["access_token"]);

    const [modal, setModal] = useState<ReactElement | false>(false);

    const langue = question.label.map((label) => label.lang).filter((x) => x !== "fr");
    const [selectLanguage, setSelectLanguage] = useState<string | false>("en");
    const [tradText, setTradText] = useState<
        { lang: string; value: string; crosseyes: string } | false
    >(false);

    useEffect(() => {
        setTradText(
            question.label.find((label) => label.lang === selectLanguage) || false
        );
    }, [question, selectLanguage]);

    const selectLang = useRef<HTMLInputElement | null>(null);

    const French = question.label.find((label) => label.lang === "fr");
    const id = useRef<HTMLInputElement | null>(null);
    const psychology = useRef<HTMLInputElement | null>(null);
    const capacity = useRef<HTMLInputElement | null>(null);
    const [activeBtn, setActiveBtn] = useState<boolean>(false);
    const normalVersion = useRef<HTMLInputElement | null>(null);
    const crossEyeVersion = useRef<HTMLInputElement | null>(null);

    const normalTrad = useRef<HTMLInputElement | null>(null);
    const crossEyeTrad = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        setActiveBtn(verifForm());
    }, []);

    const changeForm = () => {
        return setActiveBtn(verifForm());
    };

    const verifForm = () => {
        if (normalVersion.current && normalVersion.current.value === "") return false;
        if (crossEyeVersion.current && crossEyeVersion.current.value === "") return false;
        if (psychology.current && psychology.current.value === "") return false;
        if (capacity.current && capacity.current.value === "") return false;

        return true;
    };

    const resolvePut = async (response: AxiosResponse) => {
        const { status } = response;
        switch (status) {
            case 200:
                setModal(
                    <>
                        <h4>{t("Modification of the question performed")}</h4>
                        <Toc size={80} />
                    </>
                );
                break;
            default:
                setModal(
                    <>
                        <h4>{t("An error has occurred please try again")}</h4>
                    </>
                );
                break;
        }
    };

    const handleForm = () => {
        if (!activeBtn) return false;
        setModal(<Waiting />);
        if (verifForm()) {
            const formData = {
                token: cookies.access_token,
                question: {
                    id: id.current!.value,
                    lang: selectLanguage,
                    psychology: psychology.current!.value,
                    capacity: capacity.current!.value,
                    value: normalVersion.current!.value,
                    crosseyes: crossEyeVersion.current!.value,
                    trad: {
                        value: normalTrad.current!.value,
                        crosseyes: crossEyeTrad.current!.value
                    }
                }
            };
            PutQuestion(formData, resolvePut);
        }
    };
    return (
        <div id="EditQuestion">
            <Modal content={modal} setContent={setModal} />
            <h3>Question n°{question.id}</h3>
            <div className="content">
                <div className="default">
                    <PsychologyDropDown
                        label={t("Psychology")}
                        refInput={psychology}
                        defaultValue={question.psychology.name}
                        callback={() => {}}
                    />
                    <CapacityDropDown
                        label={t("Capacity")}
                        refInput={capacity}
                        defaultValue={question.capacity.name}
                        callback={() => {}}
                    />
                    <TextField
                        defaultValue={French?.value}
                        label={t("First person")}
                        refField={normalVersion}
                        callback={changeForm}
                    />
                    <TextField
                        defaultValue={French?.crosseyes}
                        label={t("Third person")}
                        refField={crossEyeVersion}
                        callback={changeForm}
                    />
                </div>
                <div className="translate">
                    <h4>{t("Edit translation")}</h4>

                    <>
                        <Dropdown
                            label={t("Choose language")}
                            refInput={selectLang}
                            values={langue}
                            callback={() => {
                                if (selectLang.current)
                                    setSelectLanguage(selectLang.current?.value);
                            }}
                            defaultValue={"en"}
                        />
                    </>
                    {selectLanguage && (
                        <>
                            <TextField
                                defaultValue={(tradText && tradText.value) || ""}
                                label={t("First person")}
                                refField={normalTrad}
                            />
                            <TextField
                                defaultValue={(tradText && tradText.crosseyes) || ""}
                                label={t("Third person")}
                                refField={crossEyeTrad}
                            />
                        </>
                    )}
                </div>
            </div>
            <Btn
                className={activeBtn ? "active" : "disabled"}
                callback={() => {
                    if (verifForm()) {
                        handleForm();
                    }
                }}
            >
                {t("Save my changes")}
            </Btn>
            <Field
                type="hidden"
                id="id"
                refField={id}
                placeholder={"999"}
                callback={undefined}
                defaultValue={String(question.id)}
            />
        </div>
    );
}

interface PropsIconeEditQuestion {
    update: { date: number };
    data: Question;
}
export function IconeEditQuestion(props: PropsIconeEditQuestion) {
    useEffect(() => {
        setSidepage(false);
    }, [props]);
    const [sidepage, setSidepage] = useState<ReactElement | false>(false);
    return (
        <>
            <SidePage content={sidepage} setContent={setSidepage} />
            <Edit
                className="tool"
                onClick={() => {
                    setSidepage(<EditQuestion question={props.data} />);
                }}
            />
        </>
    );
}
