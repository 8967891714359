import { ReactElement, useRef, useState } from "react";
import Field from "../Templates/Field";
import Check from "../../assets/Check";
import Btn from "../Templates/Btn";
import { useCookies } from "react-cookie";
import { putMyPassword } from "../../services/profil.service";
import { AxiosResponse } from "axios";
import Modal from "../Templates/Modal";
import PadlockValid from "../../assets/PadlockValid";
import { isDev } from "../../utils/isDev";
import { useTranslation } from "react-i18next";

export default function ChangePassword() {
    const [t, i18n] = useTranslation("user");
    const [cookies, setCookie] = useCookies(["access_token", "id", "email"]);
    const oldPassword = useRef<HTMLInputElement | null>(null);
    const newPassword = useRef<HTMLInputElement | null>(null);
    const newPasswordBis = useRef<HTMLInputElement | null>(null);
    const [error, setError] = useState({});
    const [activeBtn, setActiveBtn] = useState<boolean>(false);
    const [modal, setModal] = useState<ReactElement | false>(false);
    const resetForm = () => {
        if (oldPassword.current && newPassword.current && newPasswordBis.current) {
            oldPassword.current.value = "";
            newPassword.current.value = "";
            newPasswordBis.current.value = "";
        }
    };
    const changeForm = () => {
        if (
            oldPassword.current === null &&
            newPassword.current === null &&
            newPasswordBis.current === null
        )
            return setActiveBtn(false);
        return setActiveBtn(verifForm());
    };

    const verifForm = () => {
        const verifEqualPassword =
            newPassword.current?.value === newPasswordBis.current?.value;
        const verifPassword = newPassword.current?.value !== "";
        const verifpasswordBis = newPasswordBis.current?.value !== "";
        if (verifEqualPassword && verifPassword && verifpasswordBis) return true;
        return false;
    };

    const handleForm = () => {
        if (!activeBtn) return false;
        if (verifForm()) {
            const formData = {
                id: cookies.id,
                token: cookies.access_token,
                oldPassword: oldPassword.current?.value || "",
                newPassword: newPassword.current?.value || ""
            };
            putMyPassword(formData, resolvePut);
        }
    };
    const resolvePut = (response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                setModal(
                    <>
                        <h3>{t("Your password has been successfully changed !")}</h3>
                        <PadlockValid />
                    </>
                );
                resetForm();
                break;

            default:
                setError({
                    oldPassword: true,
                    newPassword: true,
                    newPasswordBis: true
                });
                setTimeout(() => {
                    setError({});
                }, 2000);
                break;
        }
    };
    return (
        <div className="ChangePassword">
            <Modal content={modal} setContent={setModal} />
            <fieldset>
                <Field
                    type="password"
                    id="oldPassword"
                    label={t("Current password")}
                    placeholder="********"
                    refField={oldPassword}
                    callback={changeForm}
                    defaultValue={isDev ? "azerty" : ""}
                    error={error}
                />
            </fieldset>
            <fieldset>
                <Field
                    type="password"
                    id="newPassword"
                    label={t("Change my password")}
                    placeholder="********"
                    refField={newPassword}
                    callback={changeForm}
                    defaultValue={isDev ? "azerty" : ""}
                    error={error}
                />
                <Field
                    type="password"
                    id="newPasswordBis"
                    label={t("Confirm my new password")}
                    placeholder="********"
                    refField={newPasswordBis}
                    callback={changeForm}
                    defaultValue={isDev ? "azerty" : ""}
                    error={error}
                />
            </fieldset>
            <fieldset>
                <Btn className={activeBtn ? "active" : "disabled"} callback={handleForm}>
                    <Check /> {t("Change my password")}
                </Btn>
            </fieldset>
        </div>
    );
}
