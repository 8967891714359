import axios from "axios";
interface DataGetAllUsers {
    token: string;
    version : string;
}

export const GetAllQuestions = async (data: DataGetAllUsers, callback: Function) => {
    const { token } = data;
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .get("/api/question", { params: { version: data.version } })
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};
interface DataPutQuestion {
    token: string;
    question: {
        psychology: string;
        capacity: string;
        value: string;
        crosseyes: string;
    };
}

export const PutQuestion = async (data: DataPutQuestion, callback: Function) => {
    const { token, question } = data;
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .put("/api/question", { question })
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};
