export enum Role {
    root = '0',
    admin = '1',
    user = '2'
}

export type RestrictRule = {
    /**
     * Id of the zone to restrict.
     */
    zoneId: string;
    /**
     * List of roles that can't access the zone.
     */
    roles: Role[];
};

export type RestrictRules = RestrictRule[];

//NOTE NamespaceName must be change to add new namespaces
export type NamespaceName = "user" | "router" | "userAction" | "navbar";
export type NamespaceContent = RestrictRule[];
export type RestrictNamespaces = Record<NamespaceName, NamespaceContent>;