import { AxiosResponse } from "axios";
import { ReactElement, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import Delete from "../../../assets/Delete";
import Trash from "../../../assets/Trash";
import { DeleteWhiteLabelById } from "../../../services/whiteLabel.service";
import Btn from "../../Templates/Btn";
import { reloadList } from "../../Templates/Dashboard/List";
import { RowFields } from "../../Templates/Field";
import Modal from "../../Templates/Modal";
import Waiting from "../../Templates/Waiting";

interface PropsDeleteWhiteLabel {
    setModal: Function;
    whiteLabelId: string;
}

export function DeleteWhiteLabel({ setModal, whiteLabelId }: PropsDeleteWhiteLabel) {
    const [t, i18n] = useTranslation("admin");
    const [cookies] = useCookies(["access_token"]);

    const resolveDelete = (response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                setModal(
                    <>
                        <h3>{t("Delete white label succeded")}</h3>
                        <Trash size={60} />
                    </>
                );
                break;
            default: 
                setModal(
                    <>
                        <div>{t("Delete white label failed")}</div>
                        <Trash size={60} />
                        <div>
                            {t("Contact our support")} :{" "}
                            <a href="mailto:test@act4-talents.fr">test@act4-talents.fr</a>
                        </div>
                    </>
                );
                break;
        }
        reloadList();
    };
    return (
        <>
            <h3>{t("Delete white label")}</h3>
            <Trash size={60} />
            <RowFields>
                <Btn callback={setModal} className="cancel">
                    {t("Cancel", { ns: "common" })}
                </Btn>
                <Btn
                    callback={() => {
                        setModal(<Waiting />);
                        DeleteWhiteLabelById(
                            { whiteLabelId, token: cookies.access_token },
                            resolveDelete
                        );
                    }}
                    className="active"
                >
                    {t("Delete", { ns: "common" })}
                </Btn>
            </RowFields>
        </>
    );
}

export function IconeDeleteWhiteLabel({ id }: any) {
    
    const [modal, setModal] = useState<ReactElement | false>(false);
    return (
        <>
            <Modal content={modal} setContent={setModal} />
            <Delete
                size={24}
                className="tool"
                onClick={() => {
                    setModal(<DeleteWhiteLabel setModal={setModal} whiteLabelId={id} />);
                }}
            />
        </>
    );
}
