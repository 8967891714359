import { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { GetAllUsers } from "../../../services/profil.service";
import { useCookies } from "react-cookie";
import { AxiosResponse } from "axios";

import List from "../../Templates/Dashboard/List";
import { RowHeader } from "../../Templates/Dashboard/Row";
import Search from "../../Templates/Dashboard/Search";
import IconeImportCSV from "../../../assets/ImportCSV";
import IconNewUser from "../../../assets/NewUser";

import Modal from "../../Templates/Modal";
import ImportCSV from "./ImportCSV";
import NewUser from "./NewUser";
import Btn from "../../Templates/Btn";
import { useNavigate } from "react-router-dom";
import Waiting from "../../Templates/Waiting";
import SidePage from "../../Templates/SidePage";
import DeleteUser from "./DeleteUser";
import { IconeEditUser } from "./EditUser";
import { IconeDownloadUser } from "./DownloadUser";
import { User } from "../models";
import { IconePsychologyUser } from "./PsychologyUser";
import { GetGroup, GroupAction } from "../../Templates/Dashboard/Group";
import { RowFields } from "../../Templates/Field";
import {
    ExportResults,
    ManageTags,
    DeleteUsers,
    MailResults,
    // AddCrosseye,
    AddAdmin,
    MailSignin,
    ExportTeamPDF,
    MailNotify,
    ViewTeamResults,
    ExportUsers,
    AddSurvey
} from "./GroupedActions";
import { useTranslation } from "react-i18next";
import { IconeAddSurvey } from "./AddSurvey";

export default function Users() {
    const [t] = useTranslation("admin");
    const [cookies] = useCookies(["access_token"]);
    const [users, setUsers] = useState<Array<User> | boolean>(false);
    const [filters, setFilters] = useState<Object>({ role: "ASC" });

    let busy = useRef(false);

    let navigate = useNavigate();
    const resolveGetAll = useCallback(
        (response: AxiosResponse) => {
            switch (response.status) {
                case 200:
                    const flatUsers = response.data.map((user: User) => {
                        const flatUser = { ...user, ...user.profile };
                        delete flatUser.profile;
                        return flatUser;
                    });

                    setUsers(flatUsers);
                    break;
                case 401:
                    navigate("/");
                    break;
                default:
                    console.log("ERROR", response);
                    break;
            }
            busy.current = false;
        },
        [navigate]
    );
    useEffect(() => {
        if (!busy.current) {
            if (!users) {
                busy.current = true;
                GetAllUsers({ token: cookies.access_token, filters }, resolveGetAll);
            }
        }
    }, [users, cookies.access_token, filters, navigate, resolveGetAll]);

    const handleFilter = async (filter: any) => {
        if (!filter) {
            setFilters({});
        } else {
            setFilters((prevState: any) => {
                const newFilter = { search: prevState.search && prevState.search };
                Object.defineProperty(newFilter, filter.finder, {
                    value: filter.order,
                    writable: true,
                    enumerable: true
                });

                return { ...newFilter };
            });
        }

        setUsers(false);
    };

    const [Grouped, setGrouped] = useState<Array<any>>([]);

    const handleGroupAction = () => {
        setGrouped(GetGroup());
    };

    if (typeof users !== "object") {
        return <Waiting count={20} width={800} height={600} />;
    }
    return (
        <div className="table" id="User">
            <RowFields>
                <Search callback={handleFilter} />
                {Grouped.length >= 1 && (
                    <GroupAction ids={Grouped} actions={rowOptions.groupedAction} />
                )}
            </RowFields>
            <RowHeader
                {...{
                    cells: {
                        "role": { text: t("Role"), active: true },
                        "lastName": { text: t("Last name"), active: rowOptions.lastName },
                        "firstName": {
                            text: t("First name"),
                            active: rowOptions.firstName
                        },
                        "email": { text: t("Email"), active: rowOptions.email },
                        "company": { text: t("Company"), active: rowOptions.company },
                        "tags": { text: t("Tags"), active: false },
                        "surveys-date": { text: t("Date"), active: false },
                        "surveys-version": { text: t("Version"), active: false },
                        "reset": true
                    },
                    callback: handleFilter,
                    filters,
                    group: handleGroupAction
                }}
            />

            {typeof users === "object" && (
                <List
                    datas={users}
                    rowOptions={rowOptions}
                    getDatas={() => {
                        GetAllUsers(
                            { token: cookies.access_token, filters },
                            resolveGetAll
                        );
                    }}
                    displayField="isNotify"
                    group={handleGroupAction}
                />
            )}
        </div>
    );
}

export type GroupedAction = {
    type: string;
    /**
     * i18n key for the name of the action
     */
    name: string;
    content: React.ElementType;
    sidepage?: boolean;
};

export type UserAdminRowOptions = {
    "role": boolean;
    "lastName": boolean;
    "firstName": boolean;
    "email": boolean;
    "company": boolean;
    "tags": boolean;
    "surveys-date": boolean;
    "surveys-version": boolean;

    "tools": {
        edit: (props: any) => JSX.Element;
        add: (props: any) => JSX.Element;
        download: (props: any) => JSX.Element;
        psychology: (props: any) => JSX.Element;
        delete: (props: any) => JSX.Element;
    };

    "groupedAction": GroupedAction[];
};

export const rowOptions: UserAdminRowOptions = {
    "role": true,
    "lastName": true,
    "firstName": true,
    "email": true,
    "company": true,
    "tags": true,
    "surveys-date": true,
    "surveys-version": true,
    "tools": {
        add: (props: any) => <IconeAddSurvey {...props} />,
        edit: (props: any) => <IconeEditUser {...props} />,
        download: (props: any) => <IconeDownloadUser {...props} />,
        psychology: (props: any) => <IconePsychologyUser {...props} />,
        delete: (props: any) => <DeleteUser {...props} />
    },
    "groupedAction": [
        {
            type: "tags",
            name: "Manage tags",
            content: (props: any) => <ManageTags {...props} />
        },
        {
            type: "add-survey",
            name: "Assign individual survey",
            content: (props: any) => <AddSurvey {...props} />
        },
        {
            type: "view-team",
            name: "View Team results",
            content: (props: any) => <ViewTeamResults {...props} />,
            sidepage: true
        },
        {
            type: "export-team",
            name: "Export Team PDF",
            content: (props: any) => <ExportTeamPDF {...props} />
        },
        {
            type: "mail-signin",
            name: "Resend, sign in email",
            content: (props: any) => <MailSignin {...props} />
        },
        {
            type: "mail-results",
            name: "Resend, the results by email",
            content: (props: any) => <MailResults {...props} />
        },
        {
            type: "mail-notify",
            name: "Send an email to notify test access",
            content: (props: any) => <MailNotify {...props} />
        },
        {
            type: "add-admin",
            name: "Add admin to users",
            content: (props: any) => <AddAdmin {...props} />
        },
        {
            type: "export-users",
            name: "Export users",
            content: (props: any) => <ExportUsers {...props} />
        },
        {
            type: "export-results",
            name: "Export the results in CSV",
            content: (props: any) => <ExportResults {...props} />
        },
        {
            type: "delete",
            name: "Delete users",
            content: (props: any) => <DeleteUsers {...props} />
        }
    ]
};

export const Tools = (props: any) => {
    const [t] = useTranslation("admin");
    useEffect(() => {
        setSidePage(false);
        setModal(false);
    }, [props.update]);
    const [modal, setModal] = useState<ReactElement | false>(false);
    const [sidePage, setSidePage] = useState<ReactElement | false>(false);

    return (
        <>
            <Modal content={modal} setContent={setModal} />
            <SidePage content={sidePage} setContent={setSidePage} />

            <Btn
                className="admin"
                callback={() => {
                    setSidePage(
                        <ImportCSV setModal={setModal} closeModal={setSidePage} />
                    );
                }}
            >
                <IconeImportCSV />
                {t("Import a CSV")}
            </Btn>
            <Btn
                className="admin"
                callback={() => {
                    setSidePage(<NewUser closeModal={setSidePage} />);
                }}
            >
                <IconNewUser />
                {t("New user")}
            </Btn>
        </>
    );
};
