import { useTranslation } from "react-i18next";

type errorProps = {
    errorMessage: string;
    questionIdsNotFound: number[];
};

/**
 * Error message when a CSV file is partially imported
 *
 * Display questions ids not found in the database
 */
export const PartialImportError = ({ errorMessage, questionIdsNotFound }: errorProps) => {
    const [t] = useTranslation("error");
    return (
        <>
            <h3>{t("Partial CSV import")}</h3>
            <p>
                {errorMessage}{" "}
                {questionIdsNotFound.reduce(
                    (acc, id) => `${acc.length === 0 ? acc : `${acc}, `}${id}`,
                    ""
                )}
            </p>
        </>
    );
};
