import { useTranslation } from "react-i18next";
import UserAdd from "../../../assets/UserAdd";

export const SuccessLoading = () => {
    const [t, i18n] = useTranslation("admin");
    return (
        <>
            <h3>{t("Success Loading")}</h3>
            <UserAdd size={60} />
        </>
    );
};
