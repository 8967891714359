import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import Groups from "../../../../assets/Groups";
import ValidDelete from "../../../../assets/ValidDelete";
import { GroupedActionsUsers } from "../../../../services/root.service";
import Btn from "../../../Templates/Btn";
import { reloadList } from "../../../Templates/Dashboard/List";
import { RowFields } from "../../../Templates/Field";
import Waiting from "../../../Templates/Waiting";

export const DeleteUsers = ({ action, ids, token, setModal }: any) => {
    const [t, i18n] = useTranslation("admin");
    const callback = (response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                reloadList({ checkbox: true });
                setModal(
                    <>
                        <h4>{t("Users have been successfully deleted")}</h4>
                        <ValidDelete />
                    </>
                );

                break;
            case 401:
                setModal(<>ERROR</>);
                reloadList();
                break;
        }
    };
    return (
        <>
            <h4>{t("Delete selected users ?")}</h4>
            <Groups size={80} />
            <RowFields>
                <Btn
                    children={t("Cancel", { ns: "common" })}
                    className={"cancel"}
                    callback={() => {
                        setModal(false);
                    }}
                />
                <Btn
                    children={t("Delete", { ns: "common" })}
                    className={"active"}
                    callback={() => {
                        setModal(<Waiting />);
                        GroupedActionsUsers({
                            action,
                            data: { ids },
                            token,
                            callback
                        });
                    }}
                />
            </RowFields>
        </>
    );
};
