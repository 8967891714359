import { useTranslation } from "react-i18next";

export function LegendBar() {
    const { t } = useTranslation("survey");
    return (
        <div id="LegendBar" className="capacity legend">
            <div className={`capacityBar crosseye`}>
                <div className="purcent">{t("Average")}</div>

                <div className="bar">
                    <div className="circle" style={{ left: `calc(${20}% - 22px)` }}>
                        {t("Min")}
                    </div>
                    <div className="circle" style={{ left: `calc(${60}% - 23px)` }}>
                        {t("Max")}
                    </div>

                    <div
                        className="circle bottom"
                        style={{
                            left: `calc(${40}% - 23px)`
                        }}
                    >
                        {t("My result")}
                    </div>

                    <div className="value max" style={{ width: `${60}%` }}></div>

                    <div className="value" style={{ width: `${40}%` }}></div>

                    <div className="value min" style={{ width: `${20}%` }}></div>
                </div>
            </div>
        </div>
    );
}
