import { MouseEventHandler, useCallback, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { Link, useMatch, useNavigate } from "react-router-dom";
import Logout from "../../../assets/Logout";
import { Router } from "../../../router";
import RoleRestrictZone from "../RoleRestrictZone";
import Header from "./Header";
export default function Dashboard({ className, Router, path }: DashboardProps) {
    const [t] = useTranslation();
    const [, setCookie, removeCookie] = useCookies(["access_token"]);
    const navigate = useNavigate();
    const logout = async () => {
        setCookie("access_token", "", { path: "/" });
        removeCookie("access_token");
        navigate(`/`);
    };
    const [update, updateState] = useState({ date: Date.now() });
    const forceUpdate = useCallback((sub: any) => {
        updateState({ date: Date.now() });
    }, []);
    const sub = useMatch(path)?.params.sub;
    return (
        <div id={`Dashboard`} className={className}>
            <aside>
                <section>
                    {sub && <Aside sub={sub} Router={Router} forceUpdate={forceUpdate} />}
                </section>
                <a href="#top" className="link logout" onClick={logout}>
                    <Logout />
                    {t("Logout")}
                </a>
            </aside>
            <section>
                {sub && (
                    <Header
                        sub={sub}
                        Router={Router}
                        forceUpdate={forceUpdate}
                        update={update}
                    />
                )}
                <main>
                    {sub && (
                        <Main
                            sub={sub}
                            Router={Router}
                            forceUpdate={forceUpdate}
                            update={update}
                        />
                    )}
                </main>
            </section>
        </div>
    );
}
interface DashboardProps {
    className: string;
    Router: Router;
    path: string;
}
function Aside({ sub, Router, forceUpdate }: AsideProps) {
    const [t, i18n] = useTranslation("router");
    return (
        <>
            {Object.keys(Router).map((key) => {
                const findRoute = Object.getOwnPropertyDescriptor(Router, key);
                return (
                    <RoleRestrictZone key={key} namespace="router" zoneId={findRoute!.value.restrictZoneId || ''}>
                        <Link
                            to={`${findRoute!.value.path}`}
                            className={`link ${sub === key && "active"}`}
                            onClick={forceUpdate}
                        >
                            {findRoute && findRoute.value.icone}
                            {findRoute && t(findRoute.value.title)}
                        </Link>
                    </RoleRestrictZone>
                );
            })}
        </>
    );
}

function Main({ sub, Router, forceUpdate, update }: MainProps) {
    const findRoute = Object.getOwnPropertyDescriptor(Router, sub);
    if (findRoute) return findRoute.value.main({ forceUpdate, update });
    return <></>;
}
interface MainProps {
    sub: string;
    Router: Router;
    forceUpdate: MouseEventHandler;
    update: { date: number };
}
interface AsideProps {
    sub: string;
    Router: Router;
    forceUpdate: MouseEventHandler;
}
