import React from "react";
import { useCookies } from "react-cookie";
import { NamespaceName, Role } from "./restrictRule.types";
import { userHaveRestrictionToZone } from "./utils";

type CookieKey = "role";
type CookieContent = {
    role: Role;
};

type RoleRestrictZoneProps = {
    namespace: NamespaceName;
    zoneId: string;
    children: React.ReactNode;
};

/**
 * Component for restricting access to a zone based on the user's role.
 *
 * If user don't have the required role, the children would not be rendered.
 *
 * Get the user's role from the cookie.
 *
 * @example
 * <RoleRestrictZone namespace="user" zoneId="delete-user-button">
 *    <button>Delete user</button>
 * </RoleRestrictZone>
 */
const RoleRestrictZone: React.FC<RoleRestrictZoneProps> = ({ namespace, zoneId, children }) => {
    const [cookies] = useCookies<CookieKey, CookieContent>(["role"]);

    if (userHaveRestrictionToZone(namespace, zoneId, cookies.role)) {
        return null;
    } else {
        return <>{children}</>;
    }
};

export default RoleRestrictZone;
