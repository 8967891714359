interface PropsTextField {
    refField?: any;
    defaultValue?: string;
    label?: string;
    disabled?: boolean;
    callback?: any;
    size?: number;
    placeholder?: string;
}
export default function TextField({
    refField,
    defaultValue,
    label,
    disabled,
    callback,
    size = 4,
    placeholder
}: PropsTextField) {
    return (
        <div className="field">
            {label && <label>{label}</label>}
            {/* <label htmlFor="">Text</label> */}
            <textarea
                onBlur={callback}
                ref={refField}
                rows={size}
                disabled={disabled}
                defaultValue={defaultValue && defaultValue}
                placeholder={placeholder}
            ></textarea>
        </div>
    );
}
