import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
} from "chart.js";
import { Radar } from "react-chartjs-2";
import { ClearTitlePsychology } from ".";
import CapacityBar from "./CapacityBar";
import { LegendBar } from "./LegendBar";
import { compareCapacitiesByPosition } from "../../../utils/resultRadarUtils";
ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

interface PropsDetailPsychology {
    results: Array<{
        capacity: Array<{
            capacity: { id: number; name: string; description: string };
            psychology: { id: number; name: string; description: string };
            max: number;
            purcent: number;
            score: number;
        }>;
        psychology: { id: number; name: string; description: string };
        max: number;
        purcent: number;
        score: number;
    }>;
    crosseyes: Array<{
        capacity: Array<{
            capacity: { id: number; name: string; description: string };
            psychology: { id: number; name: string; description: string };
            max: number;
            purcent: number;
            score: number;
        }>;
        psychology: { id: number; name: string; description: string };
        max: number;
        purcent: number;
        score: number;
    }>;
    survey?: any;
}

type Capacities = PropsDetailPsychology['results'][0]['capacity'];
type Capacity = Capacities[0];

const PsychoColors = {
    "Monde de Soi": (alpha = 1) => `rgba(242, 153, 115, ${alpha})`,
    "Monde Inattendu": (alpha = 1) => `rgba(195, 64, 150, ${alpha})`,
    "Monde de l'Autre": (alpha = 1) => `rgba(138, 192, 225, ${alpha})`,
    "Nouveau Monde": (alpha = 1) => `rgba(140, 111, 168, ${alpha})`
};

const PsychoTags = {
    "Monde de Soi": `self`,
    "Monde Inattendu": `situational`,
    "Monde de l'Autre": `relational`,
    "Nouveau Monde": `creative`
};

export default function DetailPsychology({
    results,
    crosseyes,
    survey
}: PropsDetailPsychology) {
    const [t, i18n] = useTranslation("survey");
    const color = Object.getOwnPropertyDescriptor(
        PsychoColors,
        results[0].psychology.name
    )?.value;
    const [subTab, setSubTab] = useState(results[0]);
    const [dataset, setDataSet] = useState(() => {
        return {
            labels: results[0].capacity.map(({ capacity }) => capacity.name),
            datasets: [
                {
                    label: "Mes resultats",
                    data: results[0].capacity.map(({ purcent }) => purcent),
                    backgroundColor: color(0.5),
                    borderColor: color(1),
                    borderWidth: 1
                }
            ]
        };
    });

    const updateRadar = useCallback(() => {
        const color = Object.getOwnPropertyDescriptor(
            PsychoColors,
            subTab.psychology.name
        )?.value;

        let ownResults : Capacities = [];
        if (survey.templateId === 1) {
            // FIXME : remove condition when sort is tested with white label templates
            // NOTE : need create a copy of subTab.capacity because subTab.capacity can't be sorte
            ownResults = subTab.capacity.map((capacity) => {
                return { ...capacity };
            });
            ownResults.sort(compareCapacitiesByPosition);
        } else
            ownResults = subTab.capacity;

        setDataSet(() => {
            let set = [
                {
                    label: t("radar-label-my-test"),
                    data: ownResults.map(({ purcent }) => purcent),
                    backgroundColor: color(0.5),
                    borderColor: color(1),
                    borderWidth: 1
                }
            ];
            if (crosseyes) {
                let currentCrosseye = crosseyes.find(({ psychology }) => {
                    return psychology.id === subTab.psychology.id;
                });
                if (currentCrosseye) {
                    // Sort current crosseye by capacity order in subTab
                    currentCrosseye.capacity
                        .sort((a, b) => {
                            if (a.capacity.id < b.capacity.id) return -1;
                            if (a.capacity.id > b.capacity.id) return 1;
                            return 0;
                        }).sort((a, b) => {
                        return (
                            ownResults.findIndex(
                                ({ capacity }) => capacity.id === a.capacity.id
                            ) -
                            ownResults.findIndex(
                                ({ capacity }) => capacity.id === b.capacity.id
                            )
                        );
                    });
                    set.push({
                        label: t("radar-label-my-test-crosseye"),
                        data: currentCrosseye.capacity.map(({ purcent }) => purcent),
                        backgroundColor: color(0),
                        borderColor: color(1),
                        borderWidth: 1
                    });
                }
            }
            return {
                labels: ownResults.map(({ capacity }) =>
                    t(`capacity:${capacity.id}-name`)
                ),
                datasets: set
            };
        });
    }, [subTab, crosseyes, t]);

    i18n.on("languageChanged", () => {
        updateRadar();
    });

    useEffect(() => updateRadar(), [crosseyes, results, subTab, updateRadar]);
    const options = {
        aspectRatio: 2.5,
        elements: {
            line: {
                borderWidth: 3
            }
        },
        scales: {
            r: {
                beginAtZero: true,
                max: 100
            }
        },
        plugins: {
            legend: {
                display: crosseyes.length > 1 ? true : false
            },
            tooltip: {
                enabled: true
            }
        }
    };
    let capacitiesCross: Capacities = [];
    if (crosseyes) {
        capacitiesCross = crosseyes
            .map((x) => x.capacity)
            .flat()
            .sort((a, b) => {
                if (a.capacity.id < b.capacity.id) return -1;
                if (a.capacity.id > b.capacity.id) return 1;
                return 0;
            })
            .sort((a, b) => b.purcent - a.purcent);
    }

    const sortCapacitesByOwnCapacity = (a:  Capacity, b:  Capacity) => b.purcent - a.purcent;
    const sortCapacitesByCrosseyeCapacity = (a: Capacity, b: Capacity) => {
        const aIndex = capacitiesCross.findIndex(({ capacity }) => capacity.id === a.capacity.id);
        const bIndex = capacitiesCross.findIndex(({ capacity }) => capacity.id === b.capacity.id);
        return aIndex - bIndex;
    }

    return (
        <>
            <div className="subTab">
                {results.map((result) => {
                    return (
                        <span
                            className={
                                subTab.psychology.id === result.psychology.id
                                    ? "active"
                                    : ""
                            }
                            onClick={() => {
                                setSubTab(result);
                            }}
                            key={result.psychology.id}
                        >
                            {ClearTitlePsychology(result.psychology.name, false)}
                        </span>
                    );
                })}
            </div>
            <h3>{ClearTitlePsychology(subTab.psychology.name, false)}</h3>
            <Radar data={dataset} options={options} />
            <div className="separator" />
            {crosseyes.length >= 1 && (
                <>
                    <LegendBar />
                    <div className="separator" />
                </>
            )}

            <div className="detailPsychology">
                {subTab.capacity
                    .sort((a, b) => {
                        if (a.capacity.id < b.capacity.id) return -1;
                        if (a.capacity.id > b.capacity.id) return 1;
                        return 0;
                    })
                    .sort(crosseyes.length > 0 ? sortCapacitesByCrosseyeCapacity : sortCapacitesByOwnCapacity)
                    .map((data, index) => {
                        const capacitiesCrosseye = capacitiesCross.find(
                            (x: Capacity) => x.capacity.id === data.capacity.id
                        );

                        let userCapacities = results
                            .map((x) => x.capacity)
                            .flat()
                            .sort((a, b) => {
                                if (a.capacity.id < b.capacity.id) return -1;
                                if (a.capacity.id > b.capacity.id) return 1;
                                return 0;
                            })
                            .sort((a, b) => b.purcent - a.purcent);
                        return (
                            <div key={index}>
                                {crosseyes.length >= 1 ? (
                                    <CapacityBar
                                        crosseye={true}
                                        survey={survey}
                                        purcent={capacitiesCrosseye!.purcent}
                                        title={t(`capacity:${data.capacity.id}-name`)}
                                        color={
                                            Object.getOwnPropertyDescriptor(
                                                PsychoTags,
                                                data.psychology.name
                                            )?.value
                                        }
                                        data={{ ...capacitiesCrosseye, userCapacities }}
                                    />
                                ) : (
                                    <h4
                                        data-color={
                                            Object.getOwnPropertyDescriptor(
                                                PsychoTags,
                                                subTab.psychology.name
                                            )?.value
                                        }
                                    >
                                        {t(`capacity:${data.capacity.id}-name`)}&nbsp;
                                        <span>{data.purcent}%</span>
                                    </h4>
                                )}
                                <p>{t(`capacity:${data.capacity.id}-description`)}</p>
                                {index + 1 !== subTab.capacity.length && (
                                    <div className="separator" />
                                )}
                            </div>
                        );
                    })}
            </div>
        </>
    );
}
