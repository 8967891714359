import axios from "axios";

interface DataSignUp {
    email: string;
    password: string;
}
/**
 * It takes in a data object and a callback function, and then it makes a POST request to the server
 * with the data object, and then it calls the callback function with the response from the server
 * @param {DataSignUp} data - DataSignUp - this is the data that we're sending to the server.
 * @param {Function} callback - Function - This is the function that will be called after the request
 * is made.
 */
export const PostSignUp = (data: DataSignUp, callback: Function) => {
    const { email, password } = data;

    axios
        .post("/api/auth/signup", {
            email,
            password
        })
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};
interface DataSignIn {
    email: string;
    password: string;
}
/**
 * It takes in a data object and a callback function, and then it makes a POST request to the server
 * with the data object, and then it calls the callback function with the response from the server
 * @param {DataSignIn} data - DataSignIn - this is the data that we're sending to the server.
 * @param {Function} callback - Function - this is the function that will be called after the request
 * is made.
 */
export const PostSignIn = (data: DataSignIn, callback: Function) => {
    const { email, password } = data;
    axios
        .post("/api/auth/signin", { email, password })
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};
interface DataVerifToken {
    token: string;
}

/**
 * It sends a GET request to the server with the token in the header, and then calls the callback
 * function with the response
 * @param {DataVerifToken} data - DataVerifToken - this is the data that will be sent to the API.
 * @param {Function} callback - Function - this is the function that will be called after the request
 * is made.
 */
export const GetVerifToken = (data: DataVerifToken, callback: Function) => {
    const { token } = data;
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .get("/api/auth/verif")
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};
