import { useState, useEffect } from "react";

function getStorageValue<Type>(key: string, defaultValue: Type) {
    const saved: string = localStorage.getItem(key) || JSON.stringify(defaultValue);
    const initial: Type = JSON.parse(saved);
    return initial;
}

export function useLocalStorage<Type = string>(
    key: string,
    defaultValue: Type
): [Type, (value: Type) => void] {
    const [value, setValue] = useState<Type>(() => {
        return getStorageValue(key, defaultValue);
    });

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
}
