import Dashboard from "../Templates/Dashboard";
import { RoutingAdmin } from "../../router";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { Navigate, useMatch, useNavigate } from "react-router-dom";

export default function Admin() {
    const [cookies] = useCookies(["access_token"]);
    const navigate = useNavigate();
    const sub = useMatch("/admin/:sub")?.params.sub;
    useEffect(() => {
        if (cookies.access_token === "") {
            navigate("/");
        }
        if (!sub) {
            navigate("/admin/users");
        }
    });
    return cookies.access_token !== "" ? (
        <Dashboard className="Admin" Router={RoutingAdmin} path="/admin/:sub" />
    ) : (
        <Navigate to="/admin/users" />
    );
}
