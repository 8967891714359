import { PropsSVG } from "./SVG.interface";

export default function ShoppingBasket({
    className,
    onClick,
    size = 45,
    color = "#00ACE7"
}: PropsSVG) {
    return (
        <svg
            className={className}
            width={size}
            height={size}
            onClick={onClick}
            viewBox="0 0 45 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M41.75 16.8751H32.7687L24.5562 4.5751C24.2 4.0501 23.6 3.7876 23 3.7876C22.4 3.7876 21.8 4.0501 21.4438 4.59385L13.2312 16.8751H4.25C3.21875 16.8751 2.375 17.7188 2.375 18.7501C2.375 18.9188 2.39375 19.0876 2.45 19.2563L7.2125 36.6376C7.64375 38.2126 9.0875 39.3751 10.8125 39.3751H35.1875C36.9125 39.3751 38.3563 38.2126 38.8063 36.6376L43.5687 19.2563L43.625 18.7501C43.625 17.7188 42.7812 16.8751 41.75 16.8751ZM23 9.0001L28.25 16.8751H17.75L23 9.0001ZM35.1875 35.6251L10.8313 35.6438L6.70625 20.6251H23.0094H39.3125L35.1875 35.6251Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.8937 24.5375L28.2312 21.875L22.2875 27.8188L19.6437 25.1562L17 27.8L22.2875 33.125L30.8937 24.5375Z"
                fill={color}
            />
        </svg>
    );
}
