import Users, { Tools as ToolsUsers } from "./components/Admin/Users";
import Psychology, { Tools as ToolsPsychology } from "./components/Admin/Psychology";

import PsychologyIcone from "./assets/Psychology";
import GroupsIcone from "./assets/Groups";
import HomepageIcone from "./assets/Home";
import TocIcone from "./assets/Toc";
import CapacityIcone from "./assets/Capacity";
import PersonIcone from "./assets/Person";
import CheckIcone from "./assets/Check";
import Padlock from "./assets/Padlock";
import Trash from "./assets/Trash";
import EyeIcone from "./assets/Eye";
import KeyIcone from "./assets/Key";
import Homepage from "./assets/Home";
import Admin from "./assets/Admin";

import EditProfil from "./components/User/EditProfil";
import ChangePassword from "./components/User/ChangePassword";
import Capacity, { Tools as ToolsCapacity } from "./components/Admin/Capacity";
import Questions, { Tools as ToolsQuestions } from "./components/Admin/Questions";
import MySurvey from "./components/User/MySurvey";
import DeleteAccount from "./components/User/DeleteAccount";
import Crosseye, { Tools as ToolsCrosseye } from "./components/Admin/Crosseye";
import WhiteLabel, { Tools as ToolsWhiteLabel } from "./components/Admin/WhiteLabel";
import IconeWhiteLabel from "./assets/IconeWhiteLabel";
import RoleRestrictZone from "./components/Templates/RoleRestrictZone";
import CrosseyeIcone from "./assets/CrosseyeIcone";
import Token, { Tools as ToolsToken } from "./components/Admin/Token";
import HomePage from "./components/Admin/HomePage";
import MyData from "./components/User/MyData";
import FilePDF from "./assets/FilePDF";
import ImportCSV from "./assets/ImportCSV";
import Mail from "./assets/Mail";

export type Route = {
    path: string;
    // i18n key for the title
    title: string | false;
    // i18n key for the subtitle
    subTitle: string | false;
    tools: React.ElementType | false;
    icone: JSX.Element | false;
    main: React.ElementType;
    // restrict rule zone id (see RoleRestrictZone component)
    restrictZoneId?: string;
};

export type Router = {
    [key: string]: Route;
};

export const RoutingAdmin: Router = {
    "homePage": {
        path: "/admin/homePage",
        title: "Homepage",
        subTitle: false,
        icone: <Homepage />,
        tools: () => <></>,
        main: (props: any) => <HomePage {...props} />,
        restrictZoneId: "edit-homepage"
    },
    "psychology": {
        path: "/admin/psychology",
        title: "Intelligences",
        subTitle: false,
        icone: <PsychologyIcone />,
        tools: (props: any) => <ToolsPsychology {...props} />,
        main: (props: any) => <Psychology {...props} />,
        restrictZoneId: "edit-world"
    },
    "capacity": {
        path: "/admin/capacity",
        title: "Capacities",
        subTitle: false,
        icone: <CapacityIcone />,
        tools: (props: any) => <ToolsCapacity {...props} />,
        main: (props: any) => <Capacity {...props} />,
        restrictZoneId: "edit-capacities"
    },
    "questions": {
        path: "/admin/questions",
        title: "Questions",
        subTitle: false,
        icone: <TocIcone />,
        tools: (props: any) => <ToolsQuestions {...props} />,
        main: (props: any) => <Questions {...props} />,
        restrictZoneId: "edit-questions"
    },
    "users": {
        path: "/admin/users",
        title: "Users",
        subTitle: false,
        icone: <GroupsIcone />,
        tools: (props: any) => <ToolsUsers {...props} />,
        main: (props: any) => <Users {...props} />
    },
    "crosseye": {
        path: "/admin/crosseye",
        title: "Cross eyes",
        subTitle: false,
        icone: <CrosseyeIcone />,
        tools: (props: any) => <ToolsCrosseye {...props} />,
        main: (props: any) => <Crosseye {...props} />
    },
    "white-labels": {
        path: "/admin/white-labels",
        title: "White labels",
        subTitle: false,
        icone: <IconeWhiteLabel />,
        tools: (props: any) => <ToolsWhiteLabel {...props} />,
        main: (props: any) => <WhiteLabel {...props} />,
        restrictZoneId: "edit-white-labels"
    },
    "token": {
        path: "/admin/token",
        title: "Token",
        subTitle: false,
        icone: <KeyIcone />,
        tools: (props: any) => <ToolsToken {...props} />,
        main: (props: any) => <Token {...props} />
    }
};

/**
 * Add render restriction by role on component on admin routes. (see RoleRestrictZone component)
 */
Object.keys(RoutingAdmin).forEach((key) => {
    const Tool = RoutingAdmin[key].tools;
    if (Tool)
        RoutingAdmin[key].tools = (props: any) => (
            <RoleRestrictZone
                namespace="router"
                zoneId={RoutingAdmin[key].restrictZoneId || ""}
            >
                <Tool {...props} />
            </RoleRestrictZone>
        );

    const Main = RoutingAdmin[key].main;
    RoutingAdmin[key].main = (props: any) => (
        <RoleRestrictZone
            namespace="router"
            zoneId={RoutingAdmin[key].restrictZoneId || ""}
        >
            <Main {...props} />
        </RoleRestrictZone>
    );
});

export const RoutingUser: Router = {
    profile: {
        path: "/user/profile",
        title: "My profile",
        subTitle: "View / edit my profile :",
        icone: <PersonIcone />,
        tools: () => <></>,
        main: (props: any) => <EditProfil {...props} />
    },
    survey: {
        path: "/user/survey",
        title: "My surveys",
        subTitle: "View, download my tests",
        icone: <CheckIcone />,
        tools: () => <></>,
        main: (props: any) => <MySurvey {...props} />
    },
    password: {
        path: "/user/password",
        title: "My password",
        subTitle: false,
        icone: <Padlock />,
        tools: () => <></>,
        main: (props: any) => <ChangePassword {...props} />
    },
    rgpd: {
        path: "/user/rgpd",
        title: "Recovery of my data",
        subTitle: false,
        icone: <Mail size={24} />,
        tools: () => <></>,
        main: (props: any) => <MyData {...props} />
    },
    delete: {
        path: "/user/delete",
        title: "Delete my account",
        subTitle: false,
        icone: <Trash />,
        tools: () => <></>,
        main: (props: any) => <DeleteAccount {...props} />
    }
};
