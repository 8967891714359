import axios from "axios";
interface DataGetAllCrosseyeSurvey {
    token: string;
}
/**
 * This function adds a crosseye survey to a list of users.
 * @param {DataGetAllCrosseyeSurvey} data - DataAllCrosseyeSurvey
 * @param {Function} callback - Function - This is the function that will be called when the request is
 * complete.
 */
export const GetAllCrosseyeSurvey = async (
    data: DataGetAllCrosseyeSurvey,
    callback: Function
) => {
    const { token } = data;
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .get("/api/crosseye")
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};

interface DataDeleteCrosseyeFromIdSurvey {
    token: string;
    surveyId: string;
}
/**
 * It deletes a crosseye from a survey
 * @param {DataDeleteCrosseyeFromIdSurvey} data - DataDeleteCrosseyeFromIdSurvey
 * @param {Function} callback - Function
 */
export const DeleteCrosseyeFromIdSurvey = async (
    data: DataDeleteCrosseyeFromIdSurvey,
    callback: Function
) => {
    const { token, surveyId } = data;
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .delete(`/api/crosseye/`, { data: { surveyId } })
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};
interface DataDeleteUserFromCrosseye {
    token: string;
    surveyId: string;
    userId: string;
}
/**
 * It deletes a user from a survey
 * @param {DataDeleteUserFromCrosseye} data - DataDeleteUserFromCrosseye - this is the data that is
 * being sent to the backend.
 * @param {Function} callback - Function - This is the function that will be called after the request
 * is made.
 */
export const DeleteUserFromCrosseye = async (
    data: DataDeleteUserFromCrosseye,
    callback: Function
) => {
    const { token, surveyId, userId } = data;
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .delete(`/api/crosseye/user`, { data: { userId, surveyId } })
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};

interface DataNotifyUsersFromCrosseye {
    token: string;
    testerIds: string[];
    userId: string;
    lang?: string;
    customMessage?: string;
}
/**
 * Send a email to all tester users attributed on a crosseye for the user defined by userId.
 * @param {DataNotifyUsersFromCrosseye} data
 * @param {Function} callback
 */
export const NotifyUsersFromCrosseye = async (
    data: DataNotifyUsersFromCrosseye,
    callback: Function
) => {
    const { token, userId, testerIds, lang = "fr", customMessage } = data;
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .post(`/api/crosseye/notify`, { userId, testerIds, lang, customMessage })
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};
