import axios from "axios";

interface ResetPasswordData {
    token: string;
    password: string;
    userId: number;
}

/**
 * It makes a PUT request to the /api/auth/reset endpoint with the data passed in as the body of the
 * request, and then calls the callback function with the response
 * @param {ResetPasswordData} data - ResetPasswordData - this is the data that will be sent to the
 * server.
 * @param {Function} callback - Function - This is the function that will be called after the request
 * is made.
 */
export const putResetPassword = async (data: ResetPasswordData, callback: Function) => {
    axios
        .put(`/api/auth/reset`, data)
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};

/**
 * It sends a POST request to the server with the email address of the user who wants to reset their
 * password
 * @param {string} email - The email address of the user who wants to reset their password.
 * @param {Function} callback - This is a function that will be called when the request is complete.
 */
export const postResetPassword = async (email: string, callback: Function) => {
    axios
        .post("/api/auth/reset", { email })
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};

/**
 * It sends a DELETE request to the server to delete a user
 * @param {number} userId - The id of the user you want to delete
 * @param {string} token - The token that was returned from the login request.
 * @param {Function} callback - Function - this is the function that will be called after the request
 * is made.
 */
export const postDeleteUser = async (
    userId: number,
    token: string,
    callback: Function
) => {
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .delete(`/api/user/${userId}`)
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};

/**
 * This function makes a GET request to the server to get all the surveys that a user has created
 * @param {number} userId - The id of the user who's surveys you want to get.
 * @param {string} token - The token that was returned from the login request.
 * @param {Function} callback - Function - this is the function that will be called when the request is
 * complete.
 */
export const getSurveys = async (userId: number, token: string, callback: Function) => {
    axios.defaults.headers.common = {
        "Authorization": `Bearer ${token}`,
        "Cache-Control": "no-cache",
        "Pragma": "no-cache",
        "Expires": "0"
    };
    axios
        .get(`/api/user/surveys/${userId}`)
        .then((response) => {
            callback(response);
        })
        .catch((error) => {
            callback(error.response);
        });
};

/**
 * This function is used to find a user by their firstname, lastname, email or id
 * @param {string} data - The data to be sent to the server.
 * @param {string} token - The token that was returned from the login request.
 * @param {Function} callback - Function - This is the function that will be called when the request is
 * complete.
 */
export const findUserAbstract = async (
    data: string,
    token: string,
    callback: Function
) => {
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .get(`/api/user/find/${data}`)
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};

/**
 * It's a function that takes a token and a callback function as parameters, and then makes a request
 * to the server to get the user's data
 * @param {string} token - The token that was returned from the login request.
 * @param {Function} callback - Function - This is the function that will be called when the request is
 * complete.
 */
export const getMyData = async (token: string, callback: Function) => {
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .get(`/api/user/data`)
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};
