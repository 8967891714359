import Download from "../../assets/Download";

type errorProps = {
    errorMessage?: string;
};

export const ImageSizeError = ({ errorMessage = "" }: errorProps) => {
    return (
        <>
            <h3>{errorMessage}</h3>
            <Download size={60} />
        </>
    );
};
