import axios from "axios";
import i18n from "i18next";
interface DataPostQuestionsCSV {
    token: string;
    file: any;
    tag: string;
    name: string;
}
/**
 * It takes in a data object and a callback function, and then it makes a post request to the server
 * with the data object, and then it calls the callback function with the response from the server
 * @param {DataPostQuestionsCSV} data - DataPostQuestionsCSV
 * @param {Function} callback - Function
 */
export const PostQuestionsCSV = async (
    data: DataPostQuestionsCSV,
    callback: Function
) => {
    const { token, file, tag, name } = data;
    var formData = new FormData();
    formData.append("file", file);
    formData.append("tag", tag);
    formData.append("name", name);
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .post("/api/translate/csv/questions", formData)
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};

interface DataGetTemplateTranslateCSV {
    token: string;
    version: string;
}

/**
 * It gets the template for the CSV file that is used to translate the CSV file
 * @param {DataGetTemplateTranslateCSV} data - DataGetTemplateTranslateCSV - this is the data that will
 * be sent to the API.
 * @param {Function} callback - Function - This is the function that will be called when the request is
 * complete.
 */
export const getTemplateTranslateCSV = async (
    data: DataGetTemplateTranslateCSV,
    callback: Function
) => {
    const { token, version } = data;
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .get("/api/translate/csv/template", { params: { version: version } })
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};

/**
 * It gets the translations from the database and adds them to the i18n object
 */
export const getTradFromDB = async () => {
    await axios
        .get("/api/translate")
        .then((response) => {
            response.data.forEach((element: any) => {
                const fields = element.referencial.fields.reduce(
                    (current: any, field: any) => {
                        const { type } = field;
                        current[type] = current[type] ?? [];
                        current[type].push(field);
                        return current;
                    },
                    {}
                );

                Object.entries(fields).forEach(
                    ([name, arrayTrad]: [name: string, arrayTrad: any]) => {
                        let traduction: any = {};
                        arrayTrad.forEach((item: any) => {
                            Object.keys(item).forEach((key) => {
                                if (key !== "type" && key !== "id") {
                                    traduction[`${item.id}-${key}`] = item[key];
                                }
                            });
                        });
                        i18n.removeResourceBundle(element.tag, name);
                        i18n.addResourceBundle(element.tag, name, traduction);
                    }
                );
            });
        })
        .catch(({ response }) => {
            console.log("ERROR:", response);
        });
};
