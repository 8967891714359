import { PropsSVG } from "./SVG.interface";

export default function Toc({
    className,
    onClick,
    size = 24,
    color = "#00ACE7"
}: PropsSVG) {
    return (
        <svg
            className={className}
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
        >
            <path
                d="M3 9H17V7H3V9ZM3 13H17V11H3V13ZM3 17H17V15H3V17ZM19 17H21V15H19V17ZM19 7V9H21V7H19ZM19 13H21V11H19V13Z"
                fill={color}
            />
        </svg>
    );
}
