export const enRouter = {
    // Admin router
    "Homepage" : "Homepage",
    "Intelligences": "Intelligences",
    "Capacities": "Capacities",
    "Questions": "Questions",
    "Users": "Users",
    "Cross eyes": "Cross tests",
    "White labels": "White labels",

    // User router
    "My profile": "My profile",
    "View / edit my profile :": "View / edit my profile :",
    "My surveys": "My tests",
    "View, download my tests": "View, download my tests",
    "My password": "My password",
    "Delete my account": "Delete my account",
    "Recovery of my data": "Recover my data"
};
