export default function User() {
    return (
        <>
            <svg
                className="user"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M20 7.74194C15.7258 7.74194 12.2581 11.2097 12.2581 15.4839C12.2581 19.7581 15.7258 23.2258 20 23.2258C24.2742 23.2258 27.7419 19.7581 27.7419 15.4839C27.7419 11.2097 24.2742 7.74194 20 7.74194ZM20 19.3548C17.8629 19.3548 16.129 17.621 16.129 15.4839C16.129 13.3468 17.8629 11.6129 20 11.6129C22.1371 11.6129 23.871 13.3468 23.871 15.4839C23.871 17.621 22.1371 19.3548 20 19.3548ZM20 0C8.95161 0 0 8.95161 0 20C0 31.0484 8.95161 40 20 40C31.0484 40 40 31.0484 40 20C40 8.95161 31.0484 0 20 0ZM20 36.129C15.9919 36.129 12.3306 34.6532 9.50806 32.2258C10.7097 30.371 12.7661 29.1129 15.121 29.0403C16.7984 29.5564 18.3952 29.8145 20 29.8145C21.6048 29.8145 23.2016 29.5645 24.879 29.0403C27.2339 29.121 29.2903 30.371 30.4919 32.2258C27.6694 34.6532 24.0081 36.129 20 36.129ZM33.121 29.3468C31.1532 26.8145 28.1129 25.1613 24.6452 25.1613C23.8226 25.1613 22.5484 25.9355 20 25.9355C17.4597 25.9355 16.1774 25.1613 15.3548 25.1613C11.8952 25.1613 8.85484 26.8145 6.87903 29.3468C4.99194 26.7097 3.87097 23.4839 3.87097 20C3.87097 11.1048 11.1048 3.87097 20 3.87097C28.8952 3.87097 36.129 11.1048 36.129 20C36.129 23.4839 35.0081 26.7097 33.121 29.3468Z"
                    fill="#00ace7"
                />
            </svg>
        </>
    );
}
