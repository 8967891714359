import axios from "axios";

export const getAllVersions = async (token: string, callback: Function) => {
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .get(`/api/admin/versions`)
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};