import { AxiosResponse } from "axios";
import { ReactElement, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import Mail from "../../../assets/Mail";
import UserDecay from "../../../assets/UserDecay";
import ValidMail from "../../../assets/ValidMail";
import { NotifyUsersFromCrosseye } from "../../../services/crosseye.service";
import Btn from "../../Templates/Btn";
import Checkbox from "../../Templates/Checkbox";
import { reloadList } from "../../Templates/Dashboard/List";
import { RowFields } from "../../Templates/Field";
import LangDropdown from "../../Templates/LangDropdown";
import Modal from "../../Templates/Modal";
import TextField from "../../Templates/TextField";
import Waiting from "../../Templates/Waiting";
import { Crosseye } from "../models";

export function NotifyUserInCrossEye({ setModal, data }: any) {
    const [t, i18n] = useTranslation("admin");
    const [cookies] = useCookies(["access_token"]);
    const { userId, crosseyes } = data;
    const testerIds = crosseyes.map((crosseye: Crosseye) => crosseye.userId);
    const chooseLang = useRef(null);
    const [lang, setLang] = useState(i18n.language);
    const [hasCustomMessage, setHasCustomMessage] = useState(false);
    const [customMessage, setCustomMessage] = useState("");
    const refCustomMessage = useRef<HTMLInputElement | null>(null);

    const resolveNotify = (response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                reloadList();
                setModal(
                    <>
                        <h3>{t("The emails have been sent successfully")}</h3>
                        <ValidMail />
                    </>
                );
                break;
            case 404 | 500:
                setModal(
                    <>
                        <div>{t("Your email has not been sent")}</div>
                        <UserDecay size={60} />
                        <div>
                            {t("Contact our support")} :{" "}
                            <a href="mailto:test4i@act4-talents.fr">
                                test4i@act4-talents.fr
                            </a>
                        </div>
                    </>
                );
                break;
            default:
                break;
        }
    };
    return (
        <>
            <h4>
                {t("Send an email to notify test access")} ?<br />
            </h4>
            <ValidMail size={60} />
            <LangDropdown refInput={chooseLang} defaultValue={lang} callback={setLang} />
            <Checkbox
                type={"checkbox"}
                value={"customMessage"}
                name="customMessage"
                callback={() => {
                    setHasCustomMessage(!hasCustomMessage);
                    if (hasCustomMessage) {
                        setCustomMessage("");
                    }
                }}
                checked={hasCustomMessage}
            >
                {t("Custom message")}
            </Checkbox>
            {hasCustomMessage && (
                <TextField
                    defaultValue={customMessage}
                    callback={() => {
                        if (refCustomMessage.current) {
                            setCustomMessage(refCustomMessage.current.value);
                        }
                    }}
                    refField={refCustomMessage}
                    placeholder={t("Custom message")}
                />
            )}
            <RowFields>
                <Btn callback={setModal} className="cancel">
                    {t("Cancel", { ns: "common" })}
                </Btn>
                <Btn
                    callback={() => {
                        setModal(<Waiting />);
                        NotifyUsersFromCrosseye(
                            { userId, testerIds, token: cookies.access_token, lang, customMessage },
                            resolveNotify
                        );
                    }}
                    className="active"
                >
                    {t("Send", { ns: "common" })}
                </Btn>
            </RowFields>
        </>
    );
}

export function IconeNotifyUserInCrossEye(props: any) {
    const [modal, setModal] = useState<ReactElement | false>(false);
    return (
        <>
            <Modal content={modal} setContent={setModal} />
            <Mail
                size={24}
                className="tool"
                onClick={() => {
                    setModal(
                        <NotifyUserInCrossEye setModal={setModal} data={props.data} />
                    );
                }}
            />
        </>
    );
}
