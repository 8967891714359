import { PropsSVG } from "./SVG.interface";

export default function Logout({
    className,
    onClick,
    size = 24,
    color = "#FFFFFF"
}: PropsSVG) {
    return (
        <svg
            className={className}
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
        >
            <path
                d="M21 3.01H3C1.9 3.01 1 3.91 1 5.01V9H3V4.99H21V19.02H3V15H1V19.01C1 20.11 1.9 20.99 3 20.99H21C22.1 20.99 23 20.11 23 19.01V5.01C23 3.9 22.1 3.01 21 3.01V3.01ZM11 16L15 12L11 8V11H1V13H11V16ZM21 3.01H3C1.9 3.01 1 3.91 1 5.01V9H3V4.99H21V19.02H3V15H1V19.01C1 20.11 1.9 20.99 3 20.99H21C22.1 20.99 23 20.11 23 19.01V5.01C23 3.9 22.1 3.01 21 3.01V3.01ZM11 16L15 12L11 8V11H1V13H11V16Z"
                fill={color}
            />
        </svg>
    );
}

{
    /* <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20 0.0100021H2C0.9 0.0100021 0 0.910002 0 2.01V6H2V1.99H20V16.02H2V12H0V16.01C0 17.11 0.9 17.99 2 17.99H20C21.1 17.99 22 17.11 22 16.01V2.01C22 0.900002 21.1 0.0100021 20 0.0100021ZM10 13L14 9L10 5V8H0V10H10V13ZM20 0.0100021H2C0.9 0.0100021 0 0.910002 0 2.01V6H2V1.99H20V16.02H2V12H0V16.01C0 17.11 0.9 17.99 2 17.99H20C21.1 17.99 22 17.11 22 16.01V2.01C22 0.900002 21.1 0.0100021 20 0.0100021ZM10 13L14 9L10 5V8H0V10H10V13Z" fill="#323232"/>
</svg> */
}

{
    /* <svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M21 3.01H3C1.9 3.01 1 3.91 1 5.01V9H3V4.99H21V19.02H3V15H1V19.01C1 20.11 1.9 20.99 3 20.99H21C22.1 20.99 23 20.11 23 19.01V5.01C23 3.9 22.1 3.01 21 3.01V3.01ZM11 16L15 12L11 8V11H1V13H11V16ZM21 3.01H3C1.9 3.01 1 3.91 1 5.01V9H3V4.99H21V19.02H3V15H1V19.01C1 20.11 1.9 20.99 3 20.99H21C22.1 20.99 23 20.11 23 19.01V5.01C23 3.9 22.1 3.01 21 3.01V3.01ZM11 16L15 12L11 8V11H1V13H11V16Z"
    fill="#323232"
  />
</svg>; */
}
