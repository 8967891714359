import { RestrictNamespaces, Role } from "./restrictRule.types";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { root, admin, user } = Role;

/**
 * Object of rules for restricting access to a zone. (see RoleRestrictZone component)
 *
 * Each key is a namespace which contains a list of rules.
 */
export const restrictRulesNamepaces: RestrictNamespaces = {
    //NOTE NamespaceName must be change to add new namespaces
    user: [
        {
            zoneId: "dl-survey-csv",
            roles: [admin]
        },
        {
            zoneId: "change-role",
            roles: [admin]
        },
        {
            zoneId: "view-survey-results",
            roles: [user]
        }
    ],
    /**
     * Rule of actions on users dropdown. (see GroupAction component)
     */
    userAction: [
        {
            zoneId: "export",
            roles: [admin]
        },
        {
            zoneId: "add-admin",
            roles: [admin]
        }
    ],
    router: [
        {
            zoneId: "edit-homepage",
            roles: [admin]
        },
        {
            zoneId: "edit-world",
            roles: [admin]
        },
        {
            zoneId: "edit-capacities",
            roles: [admin]
        },
        {
            zoneId: "edit-questions",
            roles: [admin]
        },
        {
            zoneId: "edit-white-labels",
            roles: [admin]
        }
    ],
    navbar: [
        {
            zoneId: "admin-panel-link",
            roles: [user]
        }
    ]
};

/**
 * Check if zoneId key is unique in each namespace of `restrictRulesNamepaces`.
 */
const restrictRulesNoDuplicates = Object.values(restrictRulesNamepaces).reduce(
    (acc, rules) => {
        const noDuplicates =
            [...new Set(rules.map((rule) => rule.zoneId))].length === rules.length;
        return acc && noDuplicates;
    },
    true
);

if (!restrictRulesNoDuplicates) console.error("RestrictRules: zoneIds are not unique");
