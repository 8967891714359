import { ReactNode, useEffect, useState } from "react";
interface CheckboxProps {
    type: "checkbox" | "radio";
    value: string;
    name: string;
    defaultValue?: string | number;
    callback?: any;
    children?: ReactNode;
    error?: object;
    disabled?: boolean;
    checked?: boolean;
}

export default function Checkbox({
    value,
    callback,
    error,
    type,
    name,
    disabled,
    checked,
    children,
    defaultValue
}: CheckboxProps) {
    const findError = error && Object.getOwnPropertyDescriptor(error, value);
    const [defValue, setDefValue] = useState<boolean>(false);
    useEffect(() => {
        if (!defValue) {
            document
                .querySelectorAll<HTMLInputElement>(`[name=${name}]`)
                .forEach((el: HTMLInputElement) => {
                    if (el.value === `${defaultValue}`) {
                        el.checked = true;
                    }
                });
            setDefValue(true);
        }
    }, [defValue, defaultValue, name]);
    return (
        <div
            className={!findError ? "checkbox" : `checkbox ${findError.value && "error"}`}
            data-name={name}
        >
            <input
                type={type}
                id={name + value}
                name={name}
                value={value}
                disabled={disabled && disabled}
                checked={checked && checked}
                onChange={() => {
                    if (callback) {
                        callback(value);
                    }
                }}
            />
            <label htmlFor={name + value}>{children && children}</label>
        </div>
    );
}
