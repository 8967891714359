import { useTranslation } from "react-i18next";
import Return from "../../assets/Return";
import Smile from "../../assets/Smile";
import Time from "../../assets/Time";
import { Colors } from "../../utils";

export default function Caution({ type }: { type: number }) {
    const [t, i18n] = useTranslation("survey");

    return (
        <div className="caution">
            <div className="card time">
                <Time color={Colors.TranquilBay} />
                <p>{t("Schedule time to answer.")}</p>
            </div>
            <div className="card smile">
                <Smile color={Colors.TranquilBay} />
                <p>
                    {t("Respond intuitively and spontaneously.")}
                    {type === 0 && t("This test is strictly personal")}
                </p>
            </div>
            <div className="card return">
                <Return color={Colors.TranquilBay} />
                <p>{t("Any test started must be completed.")}</p>
            </div>
        </div>
    );
}
