import _ from "lodash";
import { useTranslation } from "react-i18next";
import { PSYCHOLOGY } from ".";

export const TeamDominante = ({ results, global }: any) => {
    const [t] = useTranslation("survey");
    const sortedCapacity = _.sortBy(global.capacity, "purcent");
    const GroupedCapacity = _.groupBy(sortedCapacity, "psychology");

    const render = Object.entries(PSYCHOLOGY.def).map(([key, psychology]) => {
        const users = results
            .filter(({ dominante }: any) => dominante)
            .map(({ user, dominanteCapacity }: any) => {
                return {
                    firstName: user.profile.firstName,
                    dominanteCapacity: dominanteCapacity.capacity.name
                };
            });

        return (
            <div
                className="banner"
                key={psychology.tag}
                style={{ background: `${psychology.color}` }}
            >
                {GroupedCapacity[psychology.name].map(({ name, capacityId }) => {
                    const filteredUsers = users
                        .filter((user: any) => user.dominanteCapacity === name)
                        .map((user: any) => user.firstName)
                        .join(", ");
                    return (
                        <p
                            key={name}
                            className={filteredUsers !== "" ? "black" : "white"}
                        >
                            {t(`capacity:${capacityId}-name`)}
                            {filteredUsers !== "" && ` : ${filteredUsers}`}
                        </p>
                    );
                })}
                <div className="title">{t(`${psychology.trad}`)}</div>
            </div>
        );
    });
    return <div className="team-bar">{render}</div>;
};
