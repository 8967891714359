import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import Btn from "./Btn";
import { reloadList } from "./Dashboard/List";

export default function SidePage({ content, setContent, reloadListOnClose = true }: PropsSidePage) {
    const [t] = useTranslation("admin");
    return (
        <div className={`SidePage ${!content ? "hide" : "open"}`}>
            <div className="body">
                <Btn
                    className="simply"
                    callback={() => {
                        setContent(false);
                        reloadListOnClose && reloadList();
                    }}
                >
                    <FontAwesomeIcon icon={faChevronLeft} />
                    {t("back to the list")}
                </Btn>
                <div className="content">{content && <>{content}</>}</div>
            </div>

            {/* <a href="#top" onClick={}></a> */}
        </div>
    );
}
interface PropsSidePage {
    content: ReactNode | false;
    setContent: Function;
    reloadListOnClose?: boolean;
}
