import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Dispatch, ReactElement, useEffect, useRef, useState } from "react";
import Field from "../Templates/Field";
import { PostSignIn, GetVerifToken } from "../../services/auth.service";

import { useCookies } from "react-cookie";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import Btn from "../Templates/Btn";
import { isDev } from "../../utils/isDev";
import Modal from "../Templates/Modal";
import ValidDoc from "../../assets/ValidDoc";
import { postResetPassword } from "../../services/user.service";
import { useTranslation } from "react-i18next";
import {
    setAllUserDataCookie,
    UserDataInCookieAttributs
} from "../../utils/userDataCookie";

export default function Login({ setShape }: LoginProps) {
    const [t, i18n] = useTranslation("home");
    const navigate = useNavigate();
    const login = useRef<HTMLInputElement | null>(null);
    const password = useRef<HTMLInputElement | null>(null);
    const [activeBtn, setActiveBtn] = useState<Boolean>(isDev);
    const [modal, setModal] = useState<ReactElement | false>(false);
    const [cookies, setCookie, removeCookie] = useCookies<UserDataInCookieAttributs>([
        "access_token",
        "id",
        "email",
        "role",
        "firstName",
        "lastName",
        "inProgress"
    ]);
    const [error, setError] = useState({});
    useEffect(() => {
        if (cookies.access_token && cookies.access_token !== "") {
            GetVerifToken(
                { token: cookies.access_token },
                resolveGet
                // (response: AxiosResponse) => {
                //   navigate(`${response.data.redirect}`);
                // }
            );
        }
    });

    const verifForm = () => {
        const verifLogin = String(login.current?.value)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        const verifPassword = password.current?.value !== "";

        if (verifLogin && verifPassword) return true;
        return false;
    };
    const changeForm = () => {
        if (login.current === null && password.current === null) {
            return setActiveBtn(false);
        }
        return setActiveBtn(verifForm());
    };
    const handleForm = () => {
        if (!activeBtn || login.current === null || password.current === null)
            return false;
        if (verifForm())
            PostSignIn(
                {
                    email: login.current.value,
                    password: password.current.value
                },
                resolvePost
            );
    };
    const resolveGet = (response: AxiosResponse) => {
        switch (response.status) {
            case 202:
                if (response.data.redirect === "/user/profile")
                    return navigate(`${response.data.redirect}`, {
                        state: {
                            firstLogin: true
                        }
                    });
                navigate(`${response.data.redirect}`);
                break;
            case 401:
                setCookie("access_token", "", { path: "/" });
                removeCookie("access_token");
                navigate(`/`);
                break;

            default:
                break;
        }
    };
    const resolvePost = (response: AxiosResponse) => {
        switch (response.status) {
            case 201:
                setAllUserDataCookie(response.data, setCookie);
                break;
            case 401:
            case 404:
                setError({ login: true, password: true });
                setTimeout(() => {
                    setError({});
                }, 2000);
                break;

            default:
                break;
        }
    };

    const resolveResetPassword = (response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                setModal(
                    <>
                        <h3>
                            {t("Your request has been taken into account")}
                            <br /> {t("An email has been sent to change your password")}
                        </h3>
                        <ValidDoc
                            onClick={() => {
                                setModal(false);
                            }}
                        />
                    </>
                );
                break;
            case 500:
                setError({ login: true });
                setTimeout(() => {
                    setError({});
                }, 2000);
                break;
            default:
                break;
        }
    };

    const sendResetPassword = async () => {
        const isEmailComplete = String(login.current?.value)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        if (login.current === null || !isEmailComplete) {
            setError({ login: true });
            setTimeout(() => {
                setError({});
            }, 2000);
            return null;
        }
        const email = login.current.value;
        await postResetPassword(email, resolveResetPassword);
    };

    return (
        <div className="login">
            <Modal content={modal} setContent={setModal} />
            <h4>{t("Enter your login and password")}</h4>

            <Field
                type="text"
                id="login"
                label={t("Username")}
                placeholder="johndoe@email.com"
                refField={login}
                callback={changeForm}
                onChange={changeForm}
                defaultValue={isDev ? "jmartin@palo-it.com" : ""}
                error={error}
            />
            <Field
                type="password"
                id="password"
                label={t("Password")}
                placeholder="*******"
                refField={password}
                callback={changeForm}
                onChange={changeForm}
                defaultValue={isDev ? "azerty" : ""}
                error={error}
            />
            <Btn className={activeBtn ? "active" : "disabled"} callback={handleForm}>
                {t("Login")} <FontAwesomeIcon icon={faChevronRight} />
            </Btn>

            {/* <div>
                <a href="#top" onClick={() => setShape("signup")}>
                    {t("Register")}
                </a>
            </div> */}
            <div>
                <a href="#top" onClick={sendResetPassword}>
                    {t("Forgot password ?")}
                </a>
            </div>
        </div>
    );
}
interface LoginProps {
    setShape: Dispatch<string>;
}
