import { PropsSVG } from "./SVG.interface";

export default function ValidDoc({ onClick }: PropsSVG) {
    return (
        <svg
            width="45"
            height="45"
            viewBox="0 0 45 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M37.5 5.625H7.5C5.4375 5.625 3.75 7.3125 3.75 9.375V35.625C3.75 37.6875 5.4375 39.375 7.5 39.375H37.5C39.5625 39.375 41.25 37.6875 41.25 35.625V9.375C41.25 7.3125 39.5625 5.625 37.5 5.625ZM37.5 35.625H7.5V9.375H37.5V35.625Z"
                fill="#00ACE7"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M36.3937 19.5375L33.7312 16.875L27.7875 22.8188L25.1437 20.1562L22.5 22.8L27.7875 28.125L36.3937 19.5375Z"
                fill="#00ACE7"
            />
            <path d="M18.75 13.125H9.375V16.875H18.75V13.125Z" fill="#00ACE7" />
            <path d="M18.75 20.625H9.375V24.375H18.75V20.625Z" fill="#00ACE7" />
            <path d="M18.75 28.125H9.375V31.875H18.75V28.125Z" fill="#00ACE7" />
        </svg>
    );
}
