import { AxiosResponse } from "axios";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ValidGroups from "../../../../assets/ValidGroups";
import { GroupedActionsUsers } from "../../../../services/root.service";
import Btn from "../../../Templates/Btn";
import Checkbox from "../../../Templates/Checkbox";
import { reloadList } from "../../../Templates/Dashboard/List";
import { RowFields } from "../../../Templates/Field";
import LangDropdown from "../../../Templates/LangDropdown";
import TextField from "../../../Templates/TextField";
import VersionDropdown from "../../../Templates/VersionDropdown";
import Waiting from "../../../Templates/Waiting";

export function AddSurvey({ action, ids, token, setModal }: any) {
    const [t, i18n] = useTranslation("admin");
    const [lang, setLang] = useState<string>(i18n.language);
    const [hasToSendMail, setHasToSendMail] = useState<boolean>(true);
    const [customMessage, setCustomMessage] = useState<string>("");
    const [hasCustomMessage, setHasCustomMessage] = useState<boolean>(false);

    const refCustomMessage = useRef<HTMLInputElement | null>(null);
    const version = useRef<HTMLInputElement | null>(null);
    const chooseLang = useRef<HTMLInputElement | null>(null);

    const callback = (response: AxiosResponse) => {
        switch (response.status) {
            case 201:
                reloadList({ checkbox: true });
                setModal(
                    <>
                        <h4>{t("Survey added")}</h4>
                        <ValidGroups />
                    </>
                );
                break;
            case 402:
                setModal(
                    <>
                        <h4>{t("Survey not added")}</h4>
                    </>
                );
                break;
            default:
                setModal(<>{response.data.message}</>);
                reloadList();
                break;
        }
    };

    return (
        <div id="AddSurvey">
            <h4>{t("Add title")}</h4>
            <VersionDropdown label={t("Survey version")} refInput={version} />
            <RowFields>
                <Checkbox
                    type={"checkbox"}
                    value={"sendMail"}
                    name="sendMail"
                    callback={() => setHasToSendMail(!hasToSendMail)}
                    checked={hasToSendMail}
                >
                    {t("Send an email to notify test access")}
                </Checkbox>
            </RowFields>
            {hasToSendMail && (
                <>
                    <RowFields>
                        <LangDropdown
                            refInput={chooseLang}
                            defaultValue={lang}
                            callback={setLang}
                        />
                    </RowFields>
                    <RowFields>
                        <Checkbox
                            type={"checkbox"}
                            value={"customMessage"}
                            name="customMessage"
                            callback={() => {
                                setHasCustomMessage(!hasCustomMessage);
                                if (hasCustomMessage) {
                                    setCustomMessage("");
                                }
                            }}
                            checked={hasCustomMessage}
                        >
                            {t("Custom message")}
                        </Checkbox>
                    </RowFields>

                    {hasCustomMessage && (
                        <TextField
                            defaultValue={customMessage}
                            callback={() => {
                                if (refCustomMessage.current) {
                                    setCustomMessage(refCustomMessage.current.value);
                                }
                            }}
                            refField={refCustomMessage}
                            placeholder={t("Custom message")}
                        />
                    )}
                </>
            )}
            <Btn
                className={"active"}
                callback={() => {
                    setModal(<Waiting />);
                    GroupedActionsUsers({
                        action,
                        data: {
                            version: version.current!.value,
                            ids,
                            hasToSendMail,
                            lang,
                            customMessage
                            // survey
                        },
                        token,
                        callback
                    });
                }}
            >
                {t("Send", { ns: "common" })}
            </Btn>
        </div>
    );
}
