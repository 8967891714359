import { ReactNode } from "react";
import Close from "../../assets/Close";
interface PropsModal {
    content: ReactNode | false;
    setContent: Function;
    callback?: Function;
    body?: boolean;
}

export default function Modal({
    content,
    setContent,
    callback,
    body = true
}: PropsModal) {
    //TO DO Revoir le Typage MouseEventHandler =  error ????
    const closeModal = (e: any) => {
        const target = e.target as HTMLElement;
        if (
            // Permet de close la Modal si l'on click sur le fond
            target.id === "Modal" ||
            target.classList.contains("close")
        ) {
            if (callback) {
                callback();
            }
            return setContent(false);
        }
    };
    return (
        <div
            id="Modal"
            data-body={body}
            className={!content ? "hide" : "open"}
            onClick={closeModal}
        >
            <Close onClick={() => setContent(false)} className="close" />
            <div className="modalBody">{content ? <>{content}</> : ""}</div>
        </div>
    );
}
