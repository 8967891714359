import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { ReactNode, useEffect, useRef, useState } from "react";
interface FieldProps {
    className?: string;
    label?: string;
    error?: object;
    type: string;
    placeholder?: string;
    id: string;
    refField?: any;
    callback?: any;
    onChange?: any;
    defaultValue?: string;
    listenerEnter?: any;
    disabled?: boolean;
}

export default function Field({
    className,
    type,
    id,
    label,
    placeholder,
    refField,
    callback,
    onChange,
    defaultValue,
    error,
    listenerEnter,
    disabled = false
}: FieldProps) {
    const [showPass, setShowPass] = useState<Boolean>(false);
    const selfRef = useRef<any>(null);
    const currentRef = refField || selfRef;
    const togglePassword = () => {
        setShowPass(!showPass);
        currentRef.current.type =
            currentRef.current.type === "text" ? "password" : "text";
        currentRef.current.focus();
    };

    useEffect(() => {
        if (listenerEnter) {
            currentRef.current.addEventListener("keydown", (e: any) => {
                if (e.key === "Enter") {
                    listenerEnter();
                }
            });
        }
    });
    const findError = error && Object.getOwnPropertyDescriptor(error, id);
    return (
        <div className={!findError ? "field" : `field ${findError.value && "error"}`}>
            {label && <label htmlFor={id}>{label}</label>}
            <input
                type={type}
                id={id}
                ref={currentRef}
                onBlur={callback}
                onChange={onChange}
                placeholder={placeholder}
                defaultValue={defaultValue}
                className={className}
                disabled={disabled}
            />
            {type === "password" && (
                <div className="toggle">
                    <FontAwesomeIcon
                        icon={!showPass ? faEye : faEyeSlash}
                        onClick={togglePassword}
                    />
                </div>
            )}
        </div>
    );
}

export const RowFields = ({ children, label }: RowFieldPRops) => {
    return (
        <div className="rowFields">
            {label && <label>{label}</label>}
            <div className="fields">{children}</div>
        </div>
    );
};
interface RowFieldPRops {
    children: ReactNode;
    label?: string;
}
