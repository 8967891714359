import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Dispatch, useRef, useState } from "react";
import Field from "../Templates/Field";
import { PostSignUp } from "../../services/auth.service";
import Btn from "../Templates/Btn";
import { isDev } from "../../utils/isDev";
import { AxiosResponse } from "axios";
import Checkbox from "../Templates/Checkbox";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

export default function SignIn({ setShape }: SignInProps) {
    const [t, i18n] = useTranslation("home");
    const login = useRef<HTMLInputElement | null>(null);
    const password = useRef<HTMLInputElement | null>(null);
    const passwordBis = useRef<HTMLInputElement | null>(null);
    const [activeBtn, setActiveBtn] = useState<Boolean>(isDev);
    const [rgpdCompliance, setRgpdCompliance] = useState<boolean>(false);

    const [error, setError] = useState({});

    const verifForm = () => {
        const verifLogin = String(login.current?.value)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        const verifPassword = password.current?.value !== "";

        const verifEqualPassword = password.current?.value === passwordBis.current?.value;

        if (verifLogin && verifPassword && verifEqualPassword) return true;

        return false;
    };

    const changeForm = () => {
        if (
            login.current === null &&
            password.current === null &&
            passwordBis.current === null
        )
            return setActiveBtn(false);

        return setActiveBtn(verifForm());
    };
    const handleForm = () => {
        if (!activeBtn || login.current === null || password.current === null)
            return false;

        if (verifForm())
            PostSignUp(
                {
                    email: login.current.value,
                    password: password.current.value
                },
                resolvePost
            );
    };

    const resolvePost = (response: AxiosResponse) => {
        switch (response.status) {
            case 201:
                if (
                    !activeBtn ||
                    login.current === null ||
                    password.current === null ||
                    passwordBis.current === null
                )
                    return false;
                login.current.value = "";
                password.current.value = "";
                passwordBis.current.value = "";
                setTimeout(() => {
                    setShape("login");
                }, 2000);
                break;
            case 403:
                setError({ login: true, password: true, passwordBis: true });
                setTimeout(() => {
                    setError({});
                }, 2000);
                break;

            default:
                break;
        }
    };

    return (
        <div className="login">
            <h4>{t("Enter your login and password")}</h4>

            <Field
                type="text"
                id="login"
                label={t("Username")}
                placeholder="johndoe@email.com"
                refField={login}
                callback={changeForm}
                onChange={changeForm}
                defaultValue={isDev ? "jmartin@palo-it.com" : ""}
                error={error}
            />
            <Field
                type="password"
                id="password"
                label={t("Password")}
                placeholder="*******"
                refField={password}
                callback={changeForm}
                onChange={changeForm}
                defaultValue={isDev ? "azerty" : ""}
                error={error}
            />
            <Field
                type="password"
                id="passwordBis"
                label={t("Password")}
                placeholder="*******"
                refField={passwordBis}
                callback={changeForm}
                onChange={changeForm}
                defaultValue={isDev ? "azerty" : ""}
                error={error}
            />
            <Checkbox
                type={"checkbox"}
                value={"RGPD"}
                name="rgpd_compliance"
                callback={() => setRgpdCompliance(!rgpdCompliance)}
                checked={rgpdCompliance}
            >
                <h5>
                    {t("RGPD-start")}
                    <Link to="/cgu">{t("RGPD-innerLink")}</Link> {t("RGPD-end")}
                </h5>
            </Checkbox>
            <Btn
                className={activeBtn && rgpdCompliance ? "active" : "disabled"}
                callback={handleForm}
            >
                {t("Register")} <FontAwesomeIcon icon={faChevronRight} />
            </Btn>

            <div>
                <a href="#top" onClick={() => setShape("login")}>
                    {t("Login")}
                </a>
            </div>
        </div>
    );
}
interface SignInProps {
    setShape: Dispatch<string>;
}
