import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "../../../utils/useLocalStorage";
import List from "../../Templates/Dashboard/List";
import { RowHeader } from "../../Templates/Dashboard/Row";
import VersionDropdown from "../../Templates/VersionDropdown";
import { CapacityModel } from "../models";
import { AxiosResponse } from "axios";
import { useCookies } from "react-cookie";
import { getCapacities } from "../../../services/capacity.service";
import { IconeEditCapacity } from "./EditCapacity";

export default function Capacity() {
    const [t, i18n] = useTranslation("admin");
    const [cookies] = useCookies(["access_token"]);
    const [currentVersion, setCurrentVersion] = useLocalStorage("version", "");
    const refVersion = useRef<HTMLInputElement | null>(null);
    const [capacities, setCapacities] = useState<CapacityModel[]>([]);
    const resolveGetCapacities = (response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                const { data } = response;
                const capacity = data.map((capa: any) => {
                    return {
                        id: capa.id,
                        capacity: `capacity:${capa.id}-name`,
                        world: `psychology:${capa.psychology.id}-name`,
                        description: `capacity:${capa.id}-description`,
                        count: capa.count
                    };
                });
                setCapacities(capacity);
                break;
            case 404 | 500:
            default:
                console.error("Internal server error");
        }
    };
    useEffect(() => {
        getCapacities(
            { token: cookies.access_token, version: currentVersion },
            resolveGetCapacities
        );
    }, [cookies.access_token, currentVersion]);

    return (
        <div className="table" id="Capacity">
            <h5>{t("Survey version")}</h5>
            <VersionDropdown
                callback={setCurrentVersion}
                refInput={refVersion}
                defaultValue={currentVersion}
            />
            <RowHeader
                {...{
                    cells: {
                        capacity: { text: t("Capacities"), active: false },
                        world: { text: t("Psychology"), active: false },
                        description: { text: t("Description"), active: false },
                        count: { text: t("Questions"), active: false },
                        reset: false
                    }
                }}
            />

            {typeof capacities === "object" && (
                <List
                    datas={capacities}
                    rowOptions={rowOptions}
                    getDatas={() => {
                        getCapacities(
                            {
                                token: cookies.access_token,
                                version: currentVersion
                            },
                            resolveGetCapacities
                        );
                    }}
                />
            )}
        </div>
    );
}

export const rowOptions = {
    capacity: true,
    world: true,
    description: true,
    count: true,
    tools: {
        edit: IconeEditCapacity
    }
};

export const Tools = () => {
    return <></>;
};
