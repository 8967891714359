export const enSurvey = {
    /**
     * Survey landing page
     */
    "Schedule time to answer.":
        "Schedule 10 minutes to answer the questions in a quiet space.",
    "Respond intuitively and spontaneously.": "Respond intuitively and spontaneously.",
    "This test is strictly personal": " This test is strictly personal.",
    "Any test started must be completed.":
        "Any test started must be completed, it is not possible to go back to previous pages.",

    "Start": "Start",

    "pages": "pages",

    "You will begin the TEST": "You will begin the TEST",
    "in cross eye !": "in cross test !",
    "your TEST": "your TEST",
    "begins !": "begins !",

    /**
     * Survey landing page & Quiz page
     */
    "See my results": "See my results",
    "Submit": "Submit",

    /**
     * Quiz page
     */
    "notAgree": "Very rarely",
    "quiteAgree": "Sometimes",
    "somewhatAgree": "Often",
    "totalyAgree": "Almost always",
    "Next": "Next",

    /**
     * Result page
     */
    "Well done": "Well done",
    "Here are the results": "Here are the results of your TEST 4i :",
    "Here are the results in cross eye":
        "Here are the results of your TEST 4i in cross test :",
    "Thank you": "Thank you",

    "Your participation in the cross eye test":
        "Your participation in the cross test has been taken into account :",
    "If you want to perform a 4i TEST for you":
        "If you want to perform a 4i TEST for you",

    "Buy online": "Buy online",
    "Contact The ACT4 TALENTS team": "Contact The ACT4 TALENTS team",

    "radar-label-my-test": "My test",
    "radar-label-my-test-crosseye": "My cross test",

    // Tabs
    "Map of the worlds": "Map of the worlds",
    "Your worlds": "Your worlds",
    "Your capacities": "Your capacities",
    "Your responses": "Your responses",
    "in detail": "in detail :",

    // Crosseye caption
    "Average": "Cross test average",
    "Min": "Lowest score",
    "Max": "Highest score",
    "My result": "Your score",

    // Worlds
    "The world of self": "My inner world",
    "Self intelligence": "Self intelligence",
    "The unexpected world": "The unexpected world",
    "Situational intelligence": "Situational intelligence",
    "The world of the other": "The world of others",
    "Relational intelligence": "Relational intelligence",
    "The new world": "The new world",
    "Creative intelligence": "Creative intelligence",

    "start-self-world": "My inner",
    "end-self-world": "world",
    "start-situational-world": "The unexpected",
    "end-situational-world": "world",
    "start-relational-world": "The world",
    "end-relational-world": "of others",
    "start-creative-world": "The new",
    "end-creative-world": "world",

    // Carto
    "Explore your worlds": "Explore your worlds",
    "Each of the worlds is rated at 100%": "Each of the worlds is rated at 100%",
    "Cross eye": "Cross test"
};
