import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Router } from "../../../router";
import RoleRestrictZone from "../RoleRestrictZone";

export default function Header({ sub, Router, update }: PropsHeader) {
    const [Title, setTitle] = useState<React.ElementType | false>(false);
    const [SubTitle, setSubTitle] = useState<React.ElementType | false>(false);
    const [Tools, setTools] = useState<any>(false);
    const [restrictZoneId, setRestrictZoneId] = useState<string | false>(false);
    const [t, i18n] = useTranslation("router");
    useEffect(() => {
        if (sub) {
            const findRoute = Object.getOwnPropertyDescriptor(Router, sub);
            if (findRoute) {
                const Route = findRoute.value;
                const Tools = Route.tools;
                setTitle(Route.title && t(Route.title));
                setSubTitle(Route.subTitle && t(Route.subTitle));
                setTools(<Tools update={update} />);
                setRestrictZoneId(Route.restrictZoneId || "");
            }
        }
    }, [Router, sub, update, i18n.language]);
    return (
        <RoleRestrictZone namespace="router" zoneId={restrictZoneId || ""}>
            <header>
                {Title && typeof Title === "string" ? <h2>{Title}</h2> : <>{Title}</>}
                {SubTitle && typeof SubTitle === "string" ? (
                    <h3>{SubTitle}</h3>
                ) : (
                    <>{SubTitle}</>
                )}
                {Tools && (
                    <div className="tools">
                        <>{Tools}</>
                    </div>
                )}
            </header>
        </RoleRestrictZone>
    );
}
interface PropsHeader {
    sub?: string;
    Router: Router;
    update: { date: number };
    forceUpdate: Function;
}
