import { PropsSVG } from "./SVG.interface";

export default function ValidDelete({
    className,
    onClick,
    size = 60,
    color = "#EB864B"
}: PropsSVG) {
    return (
        <svg
            className={className}
            width={size}
            height={size}
            viewBox="0 0 52 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
        >
            <path
                d="M26.6667 15V31.6667H13.3334V15H26.6667ZM24.1667 5H15.8334L14.1667 6.66667H8.33337V10H31.6667V6.66667H25.8334L24.1667 5ZM30 11.6667H10V31.6667C10 33.5 11.5 35 13.3334 35H26.6667C28.5 35 30 33.5 30 31.6667V11.6667Z"
                fill="#00ACE7"
            />
            <path
                d="M27.0001 47L20.0001 40L17.6667 42.3334L27.0001 51.6667L47.0001 31.6667L44.6667 29.3334L27.0001 47Z"
                fill="#EB864B"
            />
        </svg>
    );
}
