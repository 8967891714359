import { IntRange } from "./typingUtils";

/**
 * Position in radar :
 * - 1 : top
 * - 2 : right
 * - 3 : bottom
 * - 4 : left
 */
type RadarPosition = 1 | 2 | 3 | 4;

/**
 * Capacity id between 1 (included) and 16 (included)
 */
type CapacitySimplifiedId = IntRange<1, 17>;

type CapacityRadarPosition = {
    id: CapacitySimplifiedId;
    position: RadarPosition;
};

/**
 * List of all the radar positions for each capacity simplified
 * (same as backend/src/libs/resultPdfUtils.ts, need to be sync)
 */
 const capacitiesRadarPositions: CapacityRadarPosition[] = [
    {
        // 1 self : Capacité à prendre conscience de soi
        id: 1,
        position: 4
    },
    {
        // 4 creativity : Capacité à penser différemment
        id: 2,
        position: 4
    },
    {
        // 2 situationnal : Capacité d'ouverture, de découverte, de curiosité
        id: 3,
        position: 2
    },
    {
        // 1 self : Capacité à s'accepter et se réguler
        id: 4,
        position: 1
    },
    {
        // 3 relationnal : Capacité à se relier à l'autre
        id: 5,
        position: 3
    },
    {
        // 2 situationnal : Capacité à prendre du recul et analyser
        id: 6,
        position: 3
    },
    {
        // 3 relationnal : Capacité à collaborer et travailler avec les autres
        id: 7,
        position: 1
    },
    {
        // 4 creativity : Capacité à mobiliser sa créativité
        id: 8,
        position: 1
    },
    {
        // 1 self : Capacité à mobiliser et cultiver ses ressources
        id: 9,
        position: 2
    },
    {
        // 2 situationnal : Capacité à agir dans l’incertitude
        id: 10,
        position: 1
    },
    {
        // 3 relationnal : Capacité à engager et développer les autres
        id: 11,
        position: 2
    },
    {
        // 4 creativity : Capacité à expérimenter
        id: 12,
        position: 3
    },
    {
        // 2 situationnal : Capacité à s'adapter et à rebondir
        id: 13,
        position: 4
    },
    {
        // 1 self : Capacité à apprendre et à se développer
        id: 14,
        position: 3
    },
    {
        // 4 creativity : Capacité à innover et apporter des solutions
        id: 15,
        position: 2
    },
    {
        // 3 relationnal : Capacité à communiquer
        id: 16,
        position: 4
    }
];

export const calculateSimpleId = (id: number): CapacitySimplifiedId => {
    const simplifiedId = id % 16;
    return (simplifiedId === 0 ? 16 : simplifiedId) as CapacitySimplifiedId;
};

export const getCapacityRadarPosition = (id: CapacitySimplifiedId): RadarPosition => {
    const capacityRadarPosition = capacitiesRadarPositions.find((crp) => crp.id === +id);
    if (!capacityRadarPosition) {
        const errorDescription = `Capacity with id "${id}" not found in capacities radar positions`;
        throw new Error(errorDescription);
    }
    return capacityRadarPosition.position;
};

/**
 * Compare 2 capacity by position in "capacitiesRadarPositions" (adapted for pdf service)
 */
export function compareCapacitiesByPosition(capacityA: any, capacityB: any) {
    return (
        getCapacityRadarPosition(calculateSimpleId(capacityA.capacity.id)) -
        getCapacityRadarPosition(calculateSimpleId(capacityB.capacity.id))
    );
}
