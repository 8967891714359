import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { isDev } from "../../utils/isDev";
import Btn from "../Templates/Btn";
import Waiting from "../Templates/Waiting";
import Question from "./Question";

interface PropsQuiz {
    questions: Array<any> | false;
    handleForm: Function;
    changeForm: Function;
    showNext: boolean;
    part: number;
    type?: any;
}

export default function Quiz({
    questions,
    changeForm,
    handleForm,
    showNext,
    part,
    type = false
}: PropsQuiz) {
    const [t] = useTranslation("survey");
    const [currentQuestion, setCurrentQuestion] = useState(0);
    if (!questions)
        return (
            <div className="survey">
                <Waiting
                    height={window.innerHeight - 100}
                    width={window.innerWidth}
                    count={20}
                />
            </div>
        );

    return (
        <div className="survey">
            {questions.map((question, index) => (
                <Question
                    {...question}
                    changeForm={() => {
                        changeForm();
                        setCurrentQuestion((currentQuestionIndex) =>
                            currentQuestionIndex <= index ? index + 1 : currentQuestionIndex
                        );
                    }}
                    key={question.id}
                    part={part}
                    index={index}
                    currentQuestion={currentQuestion}
                    type={type}
                />
            ))}
            <Btn
                dataset={isDev && "next-part"}
                className={showNext ? "active" : "disabled"}
                callback={() => {
                    if (showNext) {
                        setCurrentQuestion(0);
                        handleForm();
                    }
                }}
            >
                {part < 10 ? (
                    <>
                        {t("Next")}
                        <FontAwesomeIcon icon={faChevronRight} />
                    </>
                ) : (
                    <>
                        {type === 1 ? t("Submit") : t("See my results")}
                        <FontAwesomeIcon icon={faChevronRight} />
                    </>
                )}
            </Btn>
        </div>
    );
}
