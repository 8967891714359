import { ReactElement, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { GroupedAction } from "../../Admin/Users";
import Checkbox from "../Checkbox";
import Modal from "../Modal";
import RoleRestrictZone from "../RoleRestrictZone";
import SidePage from "../SidePage";

export function CellGroup({ value, label, callback }: any) {
    return (
        <div className="cell group">
            <Checkbox
                type={"checkbox"}
                value={value}
                name={`${label}-${value}`}
                callback={() => {
                    if (value === "all") {
                        let allChecked: HTMLInputElement | null;
                        allChecked = document.querySelector(
                            `input[name=${label}-${value}]`
                        );
                        const checkboxes =
                            document.querySelectorAll<HTMLInputElement>(
                                `input[name^=group]`
                            );
                        checkboxes.forEach((el: HTMLInputElement) => {
                            el.checked = allChecked?.checked || false;
                        });
                    }
                    callback(value);
                }}
            />
        </div>
    );
}
export function GetGroup(value?: any) {
    let checkboxes: any = [];
    document
        .querySelectorAll<HTMLInputElement>(`input[name^=group]`)
        .forEach((el: HTMLInputElement) => {
            if (el.checked) {
                checkboxes.push(el.value);
            }
        });
    return checkboxes;
}

export function GroupAction({ ids, actions }: { ids: any; actions: GroupedAction[] }) {
    const [t, i18n] = useTranslation("admin");
    const [cookies] = useCookies(["access_token"]);
    const [modal, setModal] = useState<ReactElement | false>(false);
    const [sidePage, setSidePage] = useState<ReactElement | false>(false);
    const grouped = useRef<ReactElement | any>(false);
    const [value, setValue] = useState<string | false>(false);
    const [open, setOpen] = useState(false);
    const handleClick = (item: any) => {
        grouped.current.value = item;
        setValue(item.name);
        if (item.sidepage) {
            setSidePage(
                <>
                    {item.content({
                        action: item.type,
                        ids,
                        token: cookies.access_token,
                        setModal
                    })}
                </>
            );
        } else {
            setModal(
                <>
                    {item.content({
                        action: item.type,
                        ids,
                        token: cookies.access_token,
                        setModal
                    })}
                </>
            );
        }
    };

    useEffect(() => setValue(t("Choose an action")), [i18n.language, t]);

    useEffect(() => {
        if (open) {
            document.addEventListener(
                "click",
                () => {
                    setOpen(false);
                },
                true
            );
        }
        return () => {
            if (open) {
                document.removeEventListener("click", () => {});
            }
        };
    }, [open]);

    return (
        <div className="Dropdown" style={{ width: "350px" }}>
            <Modal content={modal} setContent={setModal} />
            <SidePage content={sidePage} setContent={setSidePage} />
            <div className={open ? "body open" : "body"}>
                <div className="value">{value ? t(value) : ""}</div>
                <div className="arrow">{"‹"}</div>
                <input
                    type="text"
                    autoComplete="off"
                    defaultValue={value ? value : ""}
                    ref={grouped}
                    onClick={() => {
                        setOpen(!open);
                    }}
                />
                <div className="list">
                    {actions.map((item: GroupedAction) => {
                        return (
                            <RoleRestrictZone
                                namespace="userAction"
                                zoneId={item.type}
                                key={`${item.type}`}
                            >
                                <div
                                    className="choice"
                                    onClick={() => {
                                        handleClick(item);
                                    }}
                                >
                                    {t(item.name)}
                                </div>
                            </RoleRestrictZone>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
