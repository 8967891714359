import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import Field from "../Field";
import { useTranslation } from "react-i18next";

export default function Search({ callback }: PropsSearch) {
    const search = useRef<HTMLInputElement>(null);
    const [activeBtn, setActiveBtn] = useState<Boolean>(false);
    const { t } = useTranslation("admin");
    const changeForm = () => {
        setActiveBtn(true);
    };
    useEffect(() => {
        const handleEnter = (e: KeyboardEvent) => {
            if (e.key === "Enter") {
                callback({
                    finder: "search",
                    order: search.current && search.current.value
                });
            }
        };
        if (search.current) {
            search.current.addEventListener("keydown", handleEnter);
        }
        return () => {
            if (search!.current) {
                search!.current.removeEventListener("keydown", handleEnter);
            }
        };
    });
    return (
        <div className="search">
            <Field
                className="text"
                type="text"
                id="firstname"
                placeholder={t("Search")}
                refField={search}
                callback={changeForm}
            />
            <div
                className={`btn search ${activeBtn ? "active" : "disabled"}`}
                onClick={() => {
                    callback &&
                        callback({
                            finder: "search",
                            order: search.current && search.current.value
                        });
                }}
            >
                <FontAwesomeIcon icon={faMagnifyingGlass} />
            </div>
        </div>
    );
}
interface PropsSearch {
    callback: Function;
}
