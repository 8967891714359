import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
import UserIcone from "../assets/User";
import SwitchLangDropdown from "./SwitchLangDropdown";
import RoleRestrictZone from "./Templates/RoleRestrictZone";

type PropsNavBar = {
    navLogo: string;
};

export default function NavBar({ navLogo }: PropsNavBar) {
    const [cookies, setCookie, removeCookie] = useCookies([
        "access_token",
        "role",
        "firstName",
        "lastName",
        "inProgress"
    ]);

    return (
        <nav>
            <Link to="/">
                <img src={navLogo} height="76px" alt="ACT4 TALENTS" />
            </Link>
            <div className="menu">
                <SwitchLangDropdown />
                {cookies.access_token !== "" && cookies.access_token && (
                    <>
                        {/* {cookies.inProgress === "true" && <Link to="/survey">Test</Link>} */}
                        <RoleRestrictZone namespace="navbar" zoneId="admin-panel-link">
                            <Link to="/admin/users">Admin</Link>
                        </RoleRestrictZone>
                        {parseInt(cookies.role) >= 0 && (
                            <Link
                                to="/user/profile"
                                onDoubleClick={() => {
                                    setCookie("access_token", "", { path: "/" });
                                    removeCookie("access_token");
                                }}
                            >
                                <UserIcone />
                            </Link>
                        )}
                    </>
                )}
            </div>
        </nav>
    );
}
