import Dashboard from "../Templates/Dashboard";

import { RoutingUser } from "../../router";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function User() {
    const [cookies] = useCookies(["access_token"]);
    const navigate = useNavigate();
    useEffect(() => {
        if (cookies.access_token === "") {
            navigate("/");
        }
    });
    return (
        cookies.access_token && (
            <Dashboard className="User" Router={RoutingUser} path={"/user/:sub"} />
        )
    );
}
