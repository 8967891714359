import { AxiosResponse } from "axios";
import { ReactElement, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import Check from "../../assets/Check";
import Padlock from "../../assets/Padlock";
import PadlockValid from "../../assets/PadlockValid";
import { putResetPassword } from "../../services/user.service";
import { isDev } from "../../utils/isDev";
import HeaderHome from "../Home/HeaderHome";
import Btn from "../Templates/Btn";
import Field from "../Templates/Field";
import Modal from "../Templates/Modal";

export default function ResetPassword() {
    const [t, i18n] = useTranslation("resetPassword");
    const [tHome, i18nHome] = useTranslation("home");
    const navigate = useNavigate();
    const [modal, setModal] = useState<ReactElement | false>(false);
    const newPassword = useRef<HTMLInputElement | null>(null);
    const newPasswordBis = useRef<HTMLInputElement | null>(null);
    const [error, setError] = useState({});
    const [activeBtn, setActiveBtn] = useState<boolean>(false);
    const [getParams, setGetParams] = useSearchParams();

    const changeForm = () => {
        if (newPassword.current === null && newPasswordBis.current === null)
            return setActiveBtn(false);
        return setActiveBtn(verifForm());
    };

    const verifForm = () => {
        const verifEqualPassword =
            newPassword.current?.value === newPasswordBis.current?.value;
        const verifpasswordBis = newPasswordBis.current?.value !== "";
        if (verifEqualPassword && verifpasswordBis) return true;
        return false;
    };

    const handleForm = () => {
        if (!activeBtn) return false;
        if (verifForm()) {
            const formData = {
                userId: Number(getParams.get("id")),
                token: getParams.get("token") || "",
                password: newPassword.current?.value || ""
            };
            putResetPassword(formData, resolvePut);
        }
    };

    const resolvePut = (response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                setModal(
                    <>
                        <h3>{t("Your password has been successfully changed !")}</h3>
                        <PadlockValid />
                    </>
                );
                navigate("/");
                break;
            case 401 || 404 || 500:
                setModal(
                    <>
                        <h3>{t("We were unable to update your password.")}</h3>
                        <h4>
                            <br /> {t("Your token may have expired, try again.")}
                            <br />
                            {t(
                                "If the situation persists, do not hesitate to contact our support."
                            )}
                        </h4>
                        <Padlock />
                    </>
                );
                break;
            default:
                break;
        }
    };

    return (
        <div className="Home">
            <div className="bubble" />
            <HeaderHome />
            <div className="login">
                <Modal content={modal} setContent={setModal} />
                <Field
                    type="password"
                    id="newPassword"
                    label={t("Change my password")}
                    placeholder="********"
                    refField={newPassword}
                    callback={changeForm}
                    defaultValue={isDev ? "azerty" : ""}
                    error={error}
                />
                <Field
                    type="password"
                    id="newPasswordBis"
                    label={t("Confirm my new password")}
                    placeholder="********"
                    refField={newPasswordBis}
                    callback={changeForm}
                    defaultValue={isDev ? "azerty" : ""}
                    error={error}
                />
                <Btn className={activeBtn ? "active" : "disabled"} callback={handleForm}>
                    <Check /> {t("Change my password")}
                </Btn>
            </div>
        </div>
    );
}
