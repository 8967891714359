import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
interface DropDownProps {
    id?: string;
    placeholder?: string;
    label?: string;
    defaultValue?: string | false;
    values: Array<string> | Array<{ label: string; value: string }>;
    refInput: any;
    callback?: any;
}
// [{key: "string", value: "str"}]
// ["string","string"]

export default function Dropdown({
    id,
    placeholder,
    label,
    values,
    refInput,
    callback,
    defaultValue
}: DropDownProps) {
    const [t, i18n] = useTranslation("dropdown");
    const [value, setValue] = useState<string | false>(
        defaultValue ? t(defaultValue) : false
    );
    const [open, setOpen] = useState(false);
    const handleClick = (item: string) => {
        refInput.current.value = item;
        setValue(item);
        callback && callback(item);
    };
    // i18n.on("languageChanged", () => {
    //   // value && setValue(t(value));
    // });

    useEffect(() => {
        if (open) {
            document.addEventListener(
                "click",
                () => {
                    setOpen(false);
                },
                true
            );
        }
        return () => {
            if (open) {
                document.removeEventListener("click", () => {});
            }
        };
    }, [open]);

    return (
        <div className="Dropdown" id={id}>
            {label && <label>{label}</label>}
            <div className={open ? "body open" : "body"}>
                <div className="value">{!value ? placeholder : t(value)}</div>
                <div className="arrow">{"‹"}</div>
                <input
                    type="text"
                    placeholder={placeholder && placeholder}
                    autoComplete="off"
                    defaultValue={!value ? "" : placeholder ? placeholder : value}
                    onChange={callback}
                    ref={refInput}
                    onClick={() => {
                        setOpen(!open);
                    }}
                />
                <div className="list">
                    {values.map((value) => {
                        return (
                            <div
                                className="choice"
                                id={typeof value === "string" ? value : value.label}
                                key={typeof value === "string" ? value : value.label}
                                onClick={() => {
                                    // setOpen(false);
                                    handleClick(
                                        typeof value === "string" ? value : value.value
                                    );
                                }}
                            >
                                {t(typeof value === "string" ? value : value.label)}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
