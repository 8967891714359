import { ReactNode } from "react";

export default function Btn({
    children,
    className,
    callback,
    style,
    dataset,
    id
}: PropsBtn) {
    return (
        <div
            id={id}
            className={`btn ${className}`}
            onClick={() => {
                callback && callback();
            }}
            style={style}
            data-dev={dataset && dataset}
        >
            {children}
        </div>
    );
}
interface PropsBtn {
    id?: string;
    dataset?: string | false;
    children: ReactNode;
    className:
        | "disabled"
        | "active"
        | "cancel"
        | "admin"
        | "simply"
        | "search"
        | "margin-auto"
        | "admin margin-auto";
    callback?: Function;
    style?: {};
}
