import { AxiosResponse } from "axios";
import { ReactElement, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import IconeImportCSV from "../../../assets/ImportCSV";
import { GetAllQuestions } from "../../../services/question.service";
import { useLocalStorage } from "../../../utils/useLocalStorage";
import Btn from "../../Templates/Btn";
import List from "../../Templates/Dashboard/List";
import { RowHeader } from "../../Templates/Dashboard/Row";
import Modal from "../../Templates/Modal";
import SidePage from "../../Templates/SidePage";
import VersionDropdown from "../../Templates/VersionDropdown";
import { Question } from "../models";
import { IconeEditQuestion } from "./EditQuestion";
import ImportTranslate from "./ImportTranslate";

export default function Questions() {
    const [t] = useTranslation("admin");
    const [cookies] = useCookies(["access_token"]);
    const [questions, setQuestions] = useState<Array<Question> | boolean>(false);
    const [currentVersion, setCurrentVersion] = useLocalStorage("version", "");
    const refVersion = useRef<HTMLInputElement | null>(null);

    const resolveGetAll = (response: AxiosResponse<{ questions: Array<Question> }>) => {
        switch (response.status) {
            case 200:
                const orderedQuestions = response.data.questions.sort(
                    (a, b) =>
                        // @ts-ignore because templates[0] have QuestionsTemplates
                        a.templates[0].QuestionsTemplates.order -
                        // @ts-ignore because templates[0] have QuestionsTemplates
                        b.templates[0].QuestionsTemplates.order
                );
                setQuestions(orderedQuestions);
                break;
            case 401:
            default:
                console.log("ERROR", response);
                break;
        }
    };

    useEffect(() => {
        GetAllQuestions(
            { token: cookies.access_token, version: currentVersion },
            resolveGetAll
        );
    }, [cookies.access_token, currentVersion]);

    const handleFilter = () => {};
    return (
        <div className="table" id="Questions">
            <h5>{t("Survey version")}</h5>
            <VersionDropdown
                callback={setCurrentVersion}
                refInput={refVersion}
                defaultValue={currentVersion}
            />
            <RowHeader
                {...{
                    cells: {
                        "capacity-name": { text: t("Capacities"), active: false },
                        "psychology-name": { text: t("Psychology"), active: false },
                        "answers-value": { text: t("Questions"), active: false },
                        "answers-crosseye": { text: t("Cross eye"), active: false },
                        "reset": false
                    },
                    callback: handleFilter,
                    filters: false
                }}
            />

            {typeof questions === "object" && (
                <List
                    datas={questions}
                    rowOptions={rowOptions}
                    getDatas={() => {
                        GetAllQuestions(
                            { token: cookies.access_token, version: currentVersion },
                            resolveGetAll
                        );
                    }}
                />
            )}
        </div>
    );
}
export const rowOptions = {
    "capacity-name": true,
    "psychology-name": true,
    "answers-value": true,
    "answers-crosseye": true,
    "tools": {
        edit: IconeEditQuestion
    }
};
export const Tools = (props: any) => {
    const [sidepage, setSidepage] = useState<ReactElement | false>(false);
    const [modal, setModal] = useState<ReactElement | false>(false);
    const [t] = useTranslation("admin");

    useEffect(() => {
        setSidepage(false);
        setModal(false);
    }, [props.update]);

    return (
        <>
            <Modal content={modal} setContent={setModal} />
            <SidePage content={sidepage} setContent={setSidepage} />
            <Btn
                className="admin"
                callback={() => {
                    setSidepage(
                        <ImportTranslate closeModal={setSidepage} setModal={setModal} />
                    );
                }}
            >
                <IconeImportCSV />
                {t("import_translation")}
            </Btn>
        </>
    );
};
