/**
 * Get the language of the browser and parse it to a language code like "fr" or "en" if it's "fr-FR" or "en-US"
 */
export function getNavigatorLang() {
    const lang: string | undefined =
        (navigator.languages && navigator.languages[0]) ||
        navigator.language ||
        // @ts-ignore because ".userLanguage" can be defined in the browser
        navigator.userLanguage;

    // get only lang prefix like "fr" in "fr-FR"
    return lang?.split("-")[0];
}
