import { useRef, useState } from "react";

import { FileUploader } from "react-drag-drop-files";
import { getTemplateImportCSV, PostUsersCSV } from "../../../services/root.service";
import { useCookies } from "react-cookie";
import { AxiosResponse } from "axios";
import { reloadList } from "../../Templates/Dashboard/List";
import Checkbox from "../../Templates/Checkbox";
import Btn from "../../Templates/Btn";
import { useTranslation } from "react-i18next";
import DropZone from "../../DropZone";
import LangDropdown from "../../Templates/LangDropdown";
import TextField from "../../Templates/TextField";
import { BadRequest } from "../../Error/BadRequest";
import { SuccessLoading } from "./SuccessLoading";
import { UserAlreadyExist } from "../../Error/UserAlreadyExist";

const fileTypes = ["CSV"];

export default function ImportCSV({ closeModal, setModal }: PropsImportCSV) {
    const [t, i18n] = useTranslation("admin");
    const [file, setFile] = useState(false);
    const [isNotify, setNotify] = useState(true);
    const [activeBtn, setActiveBtn] = useState(false);
    const [cookies] = useCookies(["access_token"]);
    const chooseLang = useRef(null);
    const [lang, setLang] = useState(i18n.language);
    const [hasCustomMessage, setHasCustomMessage] = useState(false);

    const [customMessage, setCustomMessage] = useState("");
    const refCustomMessage = useRef<HTMLInputElement | null>(null);

    const removeFile = () => {
        setFile(false);
    };
    const changeForm = (file: any) => {
        setFile(file);
        setActiveBtn(true);
    };
    const resolvePost = (response: AxiosResponse) => {
        switch (response.status) {
            case 201:
                reloadList();
                setModal(<SuccessLoading />);
                break;
            case 409:
                setModal(<UserAlreadyExist />);
                break;
            default:
                setModal(<BadRequest errorMessage={response.data.message} />);
                break;
        }
        closeModal(false);
    };
    const handleForm = () => {
        if (file) {
            PostUsersCSV(
                { token: cookies.access_token, isNotify, file, lang, customMessage },
                resolvePost
            );
        }
    };

    const handleSampleCSV = async () => {
        getTemplateImportCSV({ token: cookies.access_token }, resolveSampleCSV);
    };
    const resolveSampleCSV = (response: AxiosResponse) => {
        const { data, status } = response;
        switch (status) {
            case 200:
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "sampleImportCSV.csv");
                document.body.appendChild(link);
                link.click();
                link.remove();
                break;
            default:
                console.log("ERROR", response);
                break;
        }
    };

    return (
        <div id="ImportCSV">
            <h3>{t("Import the CSV")}</h3>
            {!file ? (
                <FileUploader
                    handleChange={changeForm}
                    name="file"
                    types={fileTypes}
                    children={<DropZone file={file} removeFile={removeFile} />}
                />
            ) : (
                <DropZone file={file} removeFile={removeFile} />
            )}
            <Checkbox
                type={"checkbox"}
                value={"isNotify"}
                name="isNotify"
                callback={() => setNotify(!isNotify)}
                checked={isNotify}
            >
                {t("Send email to users")}
            </Checkbox>
            {isNotify && (
                <>
                    <LangDropdown
                        refInput={chooseLang}
                        defaultValue={lang}
                        callback={setLang}
                    />
                    <Checkbox
                        type={"checkbox"}
                        value={"customMessage"}
                        name="customMessage"
                        callback={() => {
                            setHasCustomMessage(!hasCustomMessage);
                            if (hasCustomMessage) {
                                setCustomMessage("");
                            }
                        }}
                        checked={hasCustomMessage}
                    >
                        {t("Custom message")}
                    </Checkbox>
                    {hasCustomMessage && (
                        <TextField
                            defaultValue={customMessage}
                            callback={() => {
                                if (refCustomMessage.current) {
                                    setCustomMessage(refCustomMessage.current.value);
                                }
                            }}
                            refField={refCustomMessage}
                            placeholder={t("Custom message")}
                        />
                    )}
                </>
            )}

            <Btn className="admin" callback={handleSampleCSV}>
                {t("CSV template")}
            </Btn>

            <Btn className={!activeBtn ? "disabled" : "active"} callback={handleForm}>
                {t("Import CSV")}
            </Btn>
        </div>
    );
}

interface PropsImportCSV {
    closeModal: Function;
    setModal: Function;
}
