import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import FilePDF from "../../../../assets/FilePDF";
import ValidDoc from "../../../../assets/ValidDoc";
import { GroupedActionsUsers } from "../../../../services/root.service";
import Btn from "../../../Templates/Btn";
import { reloadList } from "../../../Templates/Dashboard/List";
import { RowFields } from "../../../Templates/Field";
import Waiting from "../../../Templates/Waiting";

export function ExportTeamPDF({ action, ids, token, setModal }: any) {
    const [t] = useTranslation("admin");
    const callback = (response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                setModal(
                    <>
                        <h3>{t("The PDF file has been successfully downloaded !")}</h3>
                        <ValidDoc />
                    </>
                );
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "soft-skills-team-resultats.pdf");
                document.body.appendChild(link);
                link.click();
                link.remove();
                reloadList();
                break;
            case 401:
                setModal(<>ERROR</>);
                reloadList();
                break;
        }
    };
    return (
        <>
            <h4>
                {t("Confirm list export")}
                <br /> {t("selected users in PDF file ?")}
            </h4>
            <FilePDF color={"#00ACE7"} />
            <RowFields>
                <Btn
                    children={t("Cancel", { ns: "common" })}
                    className={"cancel"}
                    callback={() => {
                        setModal(false);
                    }}
                />
                <Btn
                    children={t("Export", { ns: "common" })}
                    className={"active"}
                    callback={() => {
                        setModal(<Waiting />);
                        GroupedActionsUsers({
                            action,
                            data: { ids },
                            token,
                            callback
                        });
                    }}
                />
            </RowFields>
        </>
    );
}
