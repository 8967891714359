import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Eye from "../../../assets/Eye";
import NewUser from "../../../assets/NewUser";
import Btn from "../../Templates/Btn";
import List from "../../Templates/Dashboard/List";
import { RowHeader } from "../../Templates/Dashboard/Row";
import Modal from "../../Templates/Modal";
import SidePage from "../../Templates/SidePage";
import { AddUser } from "./AddUser";
import { IconeDeleteUserInCrossEye } from "./DeleteUserInCrossEye";

export function ShowCrosseye(props: any) {
    const [t, i18n] = useTranslation("admin");
    const survey = props.survey;
    const [modal, setModal] = useState<ReactElement | false>(false);
    return (
        <div className="table" id="ShowCrosseye">
            <Modal content={modal} setContent={setModal} />
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "1rem"
                }}
            >
                <h3>
                    {t("Cross eye")} {survey.user.profile.firstName}{" "}
                    {survey.user.profile.lastName}
                </h3>
                <Btn
                    callback={() => {
                        setModal(<AddUser setModal={setModal} {...props} />);
                    }}
                    className="admin"
                    style={{ padding: 0 }}
                >
                    <NewUser /> {t("Add user")}
                </Btn>
            </div>

            <RowHeader
                {...{
                    cells: {
                        "user-lastName": {
                            text: t("Last name"),
                            active: false
                        },
                        "user-firstName": { text: t("First name"), active: false },
                        "user-email": { text: t("Email"), active: false },
                        "user-company": { text: t("Company"), active: false },
                        "crosseye-start": {
                            text: t("Cross eye start date"),
                            active: false
                        },
                        "crosseye-state": {
                            text: t("State"),
                            active: false
                        }
                    },
                    callback: console.log,
                    filters: false
                }}
            />
            {typeof survey.crosseyes === "object" && (
                <List
                    datas={survey.crosseyes}
                    rowOptions={rowOptions}
                    getDatas={() => {}}
                />
            )}
        </div>
    );
}
export const rowOptions = {
    "user-lastName": true,
    "user-firstName": true,
    "user-email": true,
    "user-company": true,
    "crosseye-start": true,
    "crosseye-state": true,
    "tools": {
        delete: (props: any) => <IconeDeleteUserInCrossEye {...props} />
    }
};
export function IconeShowCrosseye(props: any) {
    useEffect(() => {
        setSidepage(false);
    }, [props]);
    const [sidepage, setSidepage] = useState<ReactElement | false>(false);
    return (
        <>
            <SidePage content={sidepage} setContent={setSidepage} />
            <Eye
                className="tool"
                onClick={() => {
                    setSidepage(<ShowCrosseye survey={props.data} />);
                }}
            />
        </>
    );
}
