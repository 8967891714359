export const frAdmin = {
    /** ==================================================================================================================
     * Users tab
     */
    "Search": "Recherche",
    "New user": "Nouvel utilisateur",
    "Import a CSV": "Importer un CSV",
    "Success Loading": "Vos utilisateurs ont été crées.",
    "Success download": "Le template a bien été téléchargé.",

    // Table column names
    "Role": "Rôle",
    "Last name": "Nom",
    "First name": "Prénom",
    "Email": "Email",
    "Company": "Entreprise",
    "Tags": "Tags",
    "Date": "Date",
    "Version": "Version",
    "Result value": "Réponse",

    // Users tabs - grouped actions
    "Choose an action": "Choisir une action",
    "Export the results in CSV": "Exporter les résultats en CSV",
    "CSV bad request":
        "Une erreur est survenue, vérifiez que vos utilisateurs ont la même version de tests",
    "Manage tags": "Gérer les tags",
    "Delete users": "Supprimer les utilisateurs",
    "Assign individual survey": "Attribuer un test",
    "Resend, the results by email": "Renvoyer, les résultats par mail",
    "Resend, sign in email": "Renvoyer, l'email d'inscription",
    "Export Team PDF": "Exporter le PDF d'équipe",
    "View Team results": "Voir les résultats de l'équipe",
    "Change Language of mail": "Changer la langue du mail",
    "Custom message": "Message personnalisé",
    "Export users": "Exporter les utilisateurs en CSV",
    /**
     * Users tabs - tool new user
     */
    // Personal data
    "Personal data": "Données personnelles",
    // "First name": "Prénom",
    // "Last name": "Nom",
    // "Email": "Email",
    "The password is automatically generated when sending the email to the user.":
        "Le mot de passe est généré automatiquement à l'envoie du mail à l'utilisateur.",
    "Age": "Âge",
    "Gender": "Sexe",
    "Studies": "Niveau d’étude",
    "Grade": "Position",

    // Professional data
    "Professional data": "Données professionnelles",
    "Service": "Service / Département dans l'entreprise",
    // "Company": "Entreprise",
    "Sector": "Secteur",
    "Seniority": "Ancienneté dans l’entreprise",
    "Country": "Pays",
    "City": "Ville",
    "Postal code": "Code postal",

    // Seniority values
    "under1seniority": "- d'un an",
    "under3seniority": "1 - 2 ans",
    "under6seniority": "3 - 5 ans",
    "under10seniority": "6 - 10 ans",
    "more10seniority": "+ de 10 ans",

    "Status": "Statut",

    "Add a tag": "Ajouter un tag",

    "Survey version": "Version du test",
    "Send password to user": "Envoyer un mot de passe à l'utilisateur",
    "Add a new test for this user": "Ajouter un nouveau test à l'utilisateur",
    "Send an email to notify test access": "Envoyer un email pour accéder au test",
    "Add this user": "Ajouter cet utilisateur",
    "Add admin to users": "Ajouter un admin aux utilisateurs",

    "The user has been successfully created": "L'utilisateur a bien été créé",
    "Create another": "Créer un autre",
    "Back to the list": "Retour à la liste",
    "User already exists": "Cet utilisateur existe déjà",

    /**
     * Users tabs - tool import csv
     */
    "Import the CSV": "Importer le CSV",
    "Drag / drop": "Glisser / déposer",
    "download file": "le fichier",
    "here": "ici",
    "Cancel": "Annuler", // NOTE : same as other tab
    "Send email to users": "Envoyer un mail aux utilisateurs",
    "CSV template": "Template CSV",
    "Import CSV": "Importer le CSV",

    /**
     * Users tabs - edit user tool
     */
    "Save my changes": "Enregistrer mes modifications",
    "Your change has been made.": "Votre modification a bien été efféctuée.",
    "Your creation has been made.": "Votre création a bien été effectuée.",
    "Your update has been made.": "Votre mise à jour a bien été effectuée.",

    /**
     * Users tabs - download user tool
     */
    "You want to download the user survey as a file":
        "Vous désirez télécharger le test utilisateur en fichier",
    "click on the icon": "cliquez sur l'icône",
    "Versions": "Versions",

    "The user survey as file": "Le test utilisateur en fichier",
    "has been downloaded successfully !": "a été téléchargé avec succès !",
    "has not been downloaded !": "n'a pas été téléchargé !",

    /**
     * Users tabs - delete user tool
     */
    "Are you sure you want to delete this account ?":
        "Voulez-vous vraiment supprimer ce compte ?",

    /**
     * Users tabs - psychology user tool
     */
    "The result of": "Les résultats de",

    /** =======================================================
     * Users tabs - grouped actions
     */

    /**
     * Users tabs - grouped actions - add survey
     */
    "Add title": "Ajouter un test avec : ",
    "Reviewed user(s)": "Utilisateur(s) évalué(s)",
    "Survey added": "Un test à bien été ajouté à tous les utilisateurs",
    "Survey not added": "Nous n'avons pas pu ajouter de test à tous les utilisateurs",

    /**
     * Users tabs - grouped actions - delete user
     */
    "Delete selected users ?": "Supprimer les utilisateurs sélectionnés ?",
    "Users have been successfully deleted": "Les utilisateurs ont bien été supprimés",

    /**
     * Users tabs - grouped actions - export as CSV
     */
    "Confirm list export": "Confirmer l'export de la liste",
    "selected users in CSV file ?": "des utilisateurs sélectionnés en fichier CSV ?",
    "selected users in PDF file ?": "des utilisateurs sélectionnés en fichier PDF ?",
    "The CSV file has been exported successfully !":
        "Le fichier CSV a été exporté avec succès !",
    "The PDF file has been successfully downloaded !":
        "Le fichier PDF a été téléchargé avec succès !",
    /**
     * Users tabs - grouped actions - mail results
     */
    "Confirm sending emails to selected users ?":
        "Confirmer l’envoi d’emails aux utilisateurs séléctionnés ?",
    "The emails have been sent successfully": "Vos messages ont bien été envoyés",
    "Your are not authorized to send this email": "Vous n'êtes pas autorisé à envoyer cet email",
    "An error occurred, the sending of the email failed": "Une erreur est survenue, l'envoi de l'email a échoué",

    /**
     * Users tabs - grouped actions - mail sign in
     */
    // "Confirm sending emails to selected users ?": "Confirmer l’envoi d’emails aux utilisateurs séléctionnés ?",
    "Your messages have been sent successfully": "Vos messages ont bien été envoyés",

    /**
     * Users tabs - grouped actions - manage tags
     */
    "Do you want to add or remove a tag ?": "Voulez-vous ajouter ou retirer un tag ?",
    "Tag": "Tag",
    "The tags have been administered successfully": "Les tags ont bien été administrés",

    /**
     * Users tabs - grouped actions - add admin
     */
    "Whose do you want to assign these users ?":
        "A qui voulez-vous assigner ces utilisateurs ?",
    "Administrator(s)": "Administrateur(s)",
    "Users have been successfully added to the administrator":
        "Les utilisateurs ont bien été ajoutés a l'administrateur",

    /**
     * Users tabs - grouped actions - view team results
     */
    "Mapping the worlds": "Cartographie des mondes",
    "Strong capacities": "Capacités fortes",
    "Areas of improvement": "Axes d'amélioration",
    "Dominant intelligence": "Intelligence dominante",
    "Dominant capacity": "Capacité dominante",

    /**
     * Users tabs - add survey
     */
    "Template not found": "Version non trouvé",
    "No survey found": "Pas de TEST trouvé",
    "No more token available": "Pas de clé disponible",
    "Survey added to User": "TEST ajouté à l'utilisateur",

    /** Users tabs - add test */
    "Add test to user": "Attribuer un test à",
    "Individual": "Individuel",
    "Crosseye": "Regards croisés",

    /** ==================================================================================================================
     * Commons
     */

    /**
     * Side page
     */
    "back to the list": "retour à la liste",

    /** ==================================================================================================================
     * Psychology tab
     */
    "New psychology": "Nouvelle intelligence",

    // Table column names
    "Psychology": "Intelligence", // NOTE : same as other table
    "Title": "Intitulé",
    "Subtitle": "Sous-titre",
    "Capacities": "Capacités", // NOTE : same as other table
    "Modification of the psychology performed": "Modification de l'intélligence effectué",

    /** ==================================================================================================================
     * Capacity tab
     */
    "New capacity": "Nouvelle capacité",

    // Table column names
    // "Capacities": "Capacités", // NOTE : same as other table
    // "Psychology": "Intelligence", // NOTE : same as other table
    "Questions": "Questions", // NOTE : same as other table
    "Name": "Nom",
    "Modification of the capacity performed": "Modification de la capacité effectué",

    /** ==================================================================================================================
     * Questions tab
     */
    "import_translation": "Importer une traduction",
    "New question": "Nouvelle question",
    "language_tag": "Tag de cette langue",
    "language_name": "Nom de la langue",
    "Question ids not found :": "Identifiants de questions non trouvés :",

    // Table column names
    "Id": "Id",
    // "Capacities": "Capacités", // NOTE : same as other table
    // "Psychology": "Intelligence", // NOTE : same as other table
    // "Questions": "Questions", // NOTE : same as other table
    "Cross eye": "Regard croisé",

    "First person": "Première personne",
    "Third person": "Troisième personne",
    "Choose language": "Choisir une autre langue",
    "Edit translation": "Modifier la traduction",
    "Modification of the question performed": "Modification de la question effectué",
    "An error has occurred please try again": "Une erreur est survenue veillez ressayer",

    /** ==================================================================================================================
     * Cross eye tab
     */
    "New Cross Eye": "Nouveau Regard Croisé",

    // Table column names
    // "Last name": "Nom", // NOTE : same as other table
    // "First name": "Prénom", // NOTE : same as other table
    // "Email": "Email", // NOTE : same as other table
    // "Company": "Entreprise", // NOTE : same as other table
    "Cross eye done": "Evaluations réalisées",
    "Cross eye start date": "Date de début du test",

    /**
     * Cross eye tabs - tool new cross eye
     */
    "Rated": "Evalué",
    "Evaluator": "Testeur",
    // "Last name": "Nom", // NOTE : same as other table
    // "First name": "Prénom", // NOTE : same as other table
    // "Email": "Email", // NOTE : same as other table
    // "Company": "Entreprise", // NOTE : same as other table

    // "Cancel": "Annuler", // NOTE : same as other tab
    "Active": "Valider",

    /**
     * Cross eye tabs - tool view cross eye
     */
    "New cross eye": "Nouveau regard croisé",
    "Add user": "Ajouter un utilisateur",
    "State": "Etat",

    // Add user
    "find an user": "A qui voulez vous attribuer ce token ?",
    "Search a user": "Attribuer à ...",
    "Search user to add for cross eye":
        "Rechercher un utilisateur a ajouter a ce regard croisé",
    "search input place holder": "email, prenom, nom...",
    "search input place holder token": "email",
    "Adding user to cross eye succeded":
        "Les utilisateurs ont bien été ajouter au test regard croisé",

    // Delete user
    "Are you sure to delete user from cross eye (start)":
        "Etes vous sur de vouloir enlever",
    "Are you sure to delete user from cross eye (end)": "de ce TEST regard croisé ?",
    "Delete user from cross eye succeded": "Ce regard croisé a été annulé.",
    "Delete user from cross eye failed": "Nous n'avons pas pu annulé ce regard croisé.",
    "Contact our support": "Contactez notre support",

    // Email to users
    "Your email has not been sent": "Votre email n'a pas pu être envoyé",

    // Delete cross eye
    "Are you sure to delete cross eye":
        "Voulez-vous vraiment supprimer ce TEST regard croisé ?",
    "Delete cross eye succeded": "Ce regard croisé a été supprimer.",
    "Delete cross eye failed": "Nous n'avons pas pu supprimer ce regard croisé.",

    /** ==================================================================================================================
     * White label tab
     */
    "New white label": "Nouvelle marque blanche",
    "Update white label logo": "Modifier le logo de la marque blanche",
    "White label name": "Nom de la marque blanche",
    "Administrator": "Administrateur/Administratrice",
    "White label logo": "Logo de la marque blanche",
    "Select version": "Selectionné la version du test qui sert de référence",
    "Create this white label": "Ajouter cette marque blanche",
    "Validate the modification of the logo": "Valider la modification du logo",
    "White label file types": "Vous pouvez utiliser des fichiers .svg, .png ou .jpg",
    "Find user by email": "Chercher l'utilisateur par email :",
    "Go back": "Retour aux marques blanches",
    "White label url": "URL",
    "Delete white label succeded": "La marque blanche a été supprimé",
    "Delete white label failed": "Nous n'avons pas pu supprimer cette marque blanche",
    "Delete white label": "Supprimer cette marque blanche ?",
    /**
     * Token tab
     */
    "BuyTokenAction": "Acheter des tokens",
    "SurveyDeleted": "Questionnaire supprimé",
    "AvailableTokens": "Tokens disponibles",
    "UsedTokens": "Tokens utilisés",
    "NoTokens": "Aucun token disponible",

    // Purchase confirmation modal
    "purchaseTokenConfirmationTitle": "Votre achat a bien été pris en compte !",
    "purchaseTokenConfirmationBody1": "Néanmoins,",
    "purchaseTokenConfirmationBody2": "sa prise en compte par notre système d’achat",
    "purchaseTokenConfirmationBody3": "peut prendre quelques minutes.",

    "Key": "Clé",
    "Creation date": "Date de création",
    "User": "Utilisateur",
    "Capacity": "Capacité",

    /**
     * HomePage tab
     */
    "title": "Titre",
    "subTtle": "Sous-titre",
    "catchPhrase": "Phrase d'accroche"
};
