import axios from "axios";
interface DataStartSurvey {
    token: string;
    surveyId: number | false;
    id: number;
}

/**
 * It takes in a token, surveyId, and id, and then it makes a post request to the server with the
 * surveyId and id
 * @param {DataStartSurvey} data - DataStartSurvey - this is the data that is being sent to the server.
 * @param {Function} callback - This is the function that will be called after the request is made.
 */
export const StartSurvey = (data: DataStartSurvey, callback: Function) => {
    const { token, surveyId, id } = data;
    if (surveyId) {
        axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
        axios
            .post(`/api/survey/start/${id}`, { surveyId, userId: id })
            .then((response) => {
                callback(response);
            })
            .catch(({ response }) => {
                callback(response);
            });
    }
};

interface DataPostResults {
    token: string;
    surveyId: number | false;
    answers: Array<{ id: number; value: string } | false>;
    id: number;
}

/**
 * It takes in a token, answers, and surveyId, and then posts the answers to the surveyId
 * @param {DataPostResults} data - DataPostResults - this is the data that we're sending to the server.
 * @param {Function} callback - This is the function that will be called when the request is complete.
 */
export const PostResults = (data: DataPostResults, callback: Function) => {
    const { token, answers, surveyId, id } = data;
    if (surveyId) {
        axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
        axios.defaults.timeout = 1000 * 30;
        axios
            .post(`/api/survey/result/${id}`, { answers, surveyId })
            .then((response) => {
                callback(response);
            })
            .catch(({ response }) => {
                callback(response);
            });
    }
};

interface DataGetResults {
    token: string;
    id: number;
}
/**
 * It gets the results of a survey
 * @param {DataGetResults} data - DataGetResults - this is the data that is being sent to the API.
 * @param {Function} callback - This is the function that will be called after the request is made.
 */
export const GetResults = (data: DataGetResults, callback: Function) => {
    const { token, id } = data;
    if (id) {
        axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

        axios
            .get(`/api/survey/results/${id}`)
            .then((response) => {
                callback(response);
            })
            .catch(({ response }) => {
                callback(response);
            });
    }
};

interface DataGetPDF {
    token: string;
    id: number;
}

/**
 * It takes in a token and an id, and returns a PDF file
 * @param {DataGetPDF} data - DataGetPDF - this is the data that is being sent to the API.
 * @param {Function} callback - Function - This is the function that will be called after the request
 * is made.
 */
export const GetPDF = (data: DataGetPDF, callback: Function) => {
    const { token, id } = data;
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

    axios
        .get(`/api/survey/pdf/${id}`, { responseType: "blob" })
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};
interface DataGetCSV {
    token: string;
    id: number;
}

/**
 * It takes in a token and survey id, and returns a CSV file
 * @param {DataGetCSV} data - DataGetCSV - this is the data that is passed to the function.
 * @param {Function} callback - Function - This is the function that will be called when the request is
 * complete.
 */
export const GetCSV = (data: DataGetCSV, callback: Function) => {
    const { token, id } = data;
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

    axios
        .get(`/api/survey/csv/${id}`, { responseType: "blob" })
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};
