export const enAdmin = {
    /** ==================================================================================================================
     * Users tab
     */
    "Search": "Search",
    "New user": "New user",
    "Import a CSV": "Import a CSV",
    "Success Loading": "Users have successfully been uploaded.",
    "Success download": "Template have successfully been downloaded.",

    // Table column names
    "Role": "Role",
    "Last name": "Last name",
    "First name": "First name",
    "Email": "Email",
    "Company": "Company",
    "Tags": "Tags",
    "Date": "Date",
    "Version": "Version",
    "Result value": "Answer",

    // Users tabs - grouped actions
    "Choose an action": "Choose an action",
    "Export the results in CSV": "Export the results in CSV",
    "CSV bad request": "Bad request, check if your users have the same test's version",
    "Manage tags": "Manage tags",
    "Delete users": "Delete users",
    "Assign individual survey": "Assign individual test",
    "Resend, the results by email": "Send the results by email back",
    "Resend, sign in email": "Send the sign in by email back",
    "Export Team PDF": "Export Team PDF",
    "View Team results": "View Team results",
    "Change Language of mail": "Change Language of mail",
    "Custom message": "Custom message",
    "Export users": "Export users in CSV",

    /**
     * Users tabs - tool new user
     */
    // Personal data
    "Personal data": "Personal data",
    // "First name": "First name",
    // "Last name": "Last name",
    // "Email": "Email",
    "The password is automatically generated when sending the email to the user.":
        "The password is automatically generated when sending the email to the user.",
    "Age": "Age",
    "Gender": "Gender",
    "Studies": "Studies",
    "Grade": "Job title",

    // Professional data
    "Professional data": "Professional details",
    "Service": "Area / Department in the company",
    // "Company": "Company",
    "Sector": "Business sector",
    "Seniority": "Seniority in the compagny",
    "Country": "Country",
    "City": "City",
    "Postal code": "Postal code",

    // Seniority values
    "under1seniority": "- 1 year",
    "under3seniority": "1 - 2 years",
    "under6seniority": "3 - 5 years",
    "under10seniority": "6 - 10 years",
    "more10seniority": "+ 10 years",

    "Status": "Status",

    "Add a tag": "Add a tag",

    "Survey version": "Test version",
    "Send password to user": "Send password to user",
    "Add a new test for this user": "Add a new test for this user",
    "Send an email to notify test access": "Send an email to notify test access",
    "Add this user": "Add this user",
    "Add admin to users": "Add admin to users",

    "The user has been successfully created": "The user has been successfully created",
    "Create another": "Create another",
    "Back to the list": "Back to the list",

    "User already exists": "This user already exists",
    /**
     * Users tabs - tool import csv
     */
    "Import the CSV": "Import the CSV",
    "Drag / drop": "Drag / drop",
    "download file": "the file",
    "here": "here",
    "Cancel": "Cancel", // NOTE : same as other tab
    "Send email to users": "Send email to users",
    "CSV template": "CSV template",
    "Import CSV": "Import CSV",

    /**
     * Users tabs - edit user tool
     */
    "Save my changes": "Save my changes",
    "Your change has been made.": "Your change has been made.",
    "Your creation has been made.": "Your creation has been made.",
    "Your update has been made.": "Your update has been made.",

    /**
     * Users tabs - download user tool
     */
    "You want to download the user survey as a file":
        "You want to download the user survey as a file",
    "click on the icon": "click on the icon",
    "Versions": "Versions",

    "The user survey as file": "The user survey as file",
    "has been downloaded successfully !": "has been downloaded successfully!",
    "has not been downloaded !": "has not been downloaded!",

    /**
     * Users tabs - delete user tool
     */
    "Are you sure you want to delete this account ?":
        "Are you sure you want to delete this account?",

    /**
     * Users tabs - psychology user tool
     */
    "The result of": "The result of",

    /** =======================================================
     * Users tabs - grouped actions
     */

    /**
     * Users tabs - grouped actions - add survey
     */
    "Add title": "Set a new test as :",
    "Reviewed user(s)": "Reviewed user(s)",
    "Survey added": "A test has been successfully added to all users",
    "Survey not added": "We got an error when we tried to add a survey to all users",

    /**
     * Users tabs - grouped actions - delete user
     */
    "Delete selected users ?": "Delete selected users ?",
    "Users have been successfully deleted": "Users have been successfully deleted",

    /**
     * Users tabs - grouped actions - export as CSV
     */
    "Confirm list export": "Confirm list export",
    "selected users in CSV file ?": "selected users in CSV file ?",
    "selected users in PDF file ?": "selected users in PDF file ?",
    "The CSV file has been exported successfully !":
        "The CSV file has been exported successfully!",
    "The PDF file has been successfully downloaded !":
        "The PDF file has been successfully downloaded !",

    /**
     * Users tabs - grouped actions - mail results
     */
    "Confirm sending emails to selected users ?":
        "Confirm sending emails to selected users?",
    "The emails have been sent successfully": "The emails have been sent successfully",
    "Your are not authorized to send this email": "Your are not authorized to send this email",
    "An error occurred, the sending of the email failed": "An error occurred, the sending of the email failed",

    /**
     * Users tabs - grouped actions - mail sign in
     */
    // "Confirm sending emails to selected users ?": "Confirm sending emails to selected users ?",
    "Your messages have been sent successfully":
        "Your messages have been sent successfully",

    /**
     * Users tabs - grouped actions - manage tags
     */
    "Do you want to add or remove a tag ?": "Do you want to add or remove a tag?",
    "Tag": "Tag",
    "The tags have been administered successfully":
        "The tags have been administered successfully",

    /**
     * Users tabs - grouped actions - add admin
     */
    "Whose do you want to assign these users ?":
        "Whose do you want to assign these users ?",
    "Administrator(s)": "Administrator(s)",
    "Users have been successfully added to the administrator":
        "Users have been successfully added to the administrator",

    /**
     * Users tabs - grouped actions - view team results
     */
    "Mapping the worlds": "Mapping the worlds",
    "Strong capacities": "Strong capacities",
    "Areas of improvement": "Areas of improvement",
    "Dominant intelligence": "Dominant intelligence",
    "Dominant capacity": "Dominant capacity",

    /**
     * Users tabs - add survey
     */
    "Template not found": "Template not found",
    "No survey found": "No survey found",
    "No more token available": "No more token available",
    "Survey added to User": "Survey added to user",

    /** Users tabs - add test */
    "Add test to user": "Assign a test to",
    "Individual": "Individual",
    "Crosseye": "Cross test",
    /** ==================================================================================================================
     * Commons
     */

    /**
     * Side page
     */
    "back to the list": "back to the list",

    /** ==================================================================================================================
     * Psychology tab
     */
    "New psychology": "New psychology",

    // Table column names
    "Psychology": "Intelligences", // NOTE : same as other table
    "Title": "Title",
    "Subtitle": "Subtitle",
    "Capacities": "Capacities", // NOTE : same as other table
    "Modification of the psychology performed":
        "Modification of the psychology performed",

    /** ==================================================================================================================
     * Capacity tab
     */
    "New capacity": "New capacity",

    // Table column names
    // "Capacities": "Capacity", // NOTE : same as other table
    // "Psychology": "Psychology", // NOTE : same as other table
    "Questions": "Questions",
    "Name": "Name",
    "Modification of the capacity performed": "Modification de la capacité effectué",

    /** ==================================================================================================================
     * Questions tab
     */
    "import_translation": "Import a translation",
    "New question": "New question",
    "language_tag": "Language tag",
    "language_name": "Language name",
    "Question ids not found :": "Question ids not found :",

    // Table column names
    "Id": "Id",
    // "Capacities": "Capacity", // NOTE : same as other table
    // "Psychology": "Psychology", // NOTE : same as other table
    // "Questions": "Questions", // NOTE : same as other table
    "Cross eye": "Cross test",

    "First person": "First person",
    "Third person": "Third person",
    "Choose language": "Choose another language",
    "Edit translation": "Edit translation",
    "Modification of the question performed": "Modification of the question performed",
    "An error has occurred please try again": "An error has occurred please try again",

    /** ==================================================================================================================
     * Cross eye tab
     */
    "New Cross Eye": "New Cross Test",

    // Table column names
    // "Last name": "Last name", // NOTE : same as other table
    // "First name": "First name", // NOTE : same as other table
    // "Email": "Email", // NOTE : same as other table
    // "Company": "Company", // NOTE : same as other table
    "Cross eye done": "Cross test done",
    "Cross eye start date": "Cross test start date",

    "Rated": "Rated",
    "Evaluator": "Evaluator",
    // "Last name": "Last name", // NOTE : same as other table
    // "First name": "First name", // NOTE : same as other table
    // "Email": "Email", // NOTE : same as other table
    // "Company": "Company", // NOTE : same as other table

    // "Cancel": "Annuler", // NOTE : same as other tab
    "Active": "Validate",

    /**
     * Cross eye tabs - tool new cross eye
     */
    "New cross eye": "New cross test",
    "Add user": "Add user",
    "State": "State",

    // Add user
    "find an user": "Who will have the chance to get this token ?",
    "Search a user": "Search an user",
    "Search user to add for cross eye": "Find a user to add to this cross test",
    "search input place holder": "email, first name, last name...",
    "search input place holder token": "email",
    "Adding user to cross eye succeded":
        "Users have been successfully added to the cross test survey",

    // Delete user
    "Are you sure to delete user from cross eye (start)":
        "Are you sure you want to remove",
    "Are you sure to delete user from cross eye (end)": "of this cross test ?",
    "Delete user from cross eye succeded": "This cross look has been cancelled.",
    "Delete user from cross eye failed": "We could not cancel this crossed look.",
    "Contact our support": "Contact our support",

    // Email to users
    "Your email has not been sent": "Your email has not been sent",

    // Delete cross eye
    "Are you sure to delete cross eye":
        "Are you sure you want to remove this cross test?",
    "Delete cross eye succeded": "This cross test has been deleted.",
    "Delete cross eye failed": "We could not delete this cross test.",

    /** ==================================================================================================================
     * White label tab
     */
    "New white label": "New white label",
    "Update white label logo": "Update white label logo",
    "White label name": "White label name",
    "Administrator": "Administrator",
    "White label logo": "White label logo",
    "Select version": "Selected the version of the test that serves as a reference",
    "Create this white label": "Create this white label",
    "Validate the modification of the logo": "Validate the modification of the logo",
    "White label file types": "You can use .svg, .png or .jpg files",
    "Find user by email": "Find user by email:",
    "Go back": "Back to white labels menu",
    "White label url": "URL",
    "Delete white label succeded": "The white label has been removed",
    "Delete white label failed": "The white label hasn't been removed",
    "Delete white label": "Remove this white label ?",
    /** ==================================================================================================================
     * Token tab
     */
    "BuyTokenAction": "Buy tokens",
    "SurveyDeleted": "Survey deleted",
    "AvailableTokens": "Available tokens",
    "UsedTokens": "Used tokens",
    "NoTokens": "No tokens",

    // Purchase confirmation modal
    "purchaseTokenConfirmationTitle": "Your purchase has been taken into account!",
    "purchaseTokenConfirmationBody1": "However,",
    "purchaseTokenConfirmationBody2": "its consideration by our purchasing system",
    "purchaseTokenConfirmationBody3": "it may take a few minutes.",

    "Key": "Key",
    "Creation date": "Creation date",
    "User": "User",
    "Capacity": "Capacity",

    /**
     * HomePage tab
     */
    "title": "Title",
    "subTtle": "Subtitle",
    "catchPhrase": "Catch phrase"
};
