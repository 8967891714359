export default function Tools({ type, Icone, data, ...props }: PropsTools) {
    switch (type) {
        case "add":
        case "edit":
        case "download":
        case "psychology":
        case "capacity":
        case "question":
        case "delete":
        case "view":
        case "mail":
            return <Icone data={data} {...props} />;
        default:
            return <></>;
    }
}

interface PropsTools {
    type: string;
    data: any;
    Icone: any;
}
