import { useNavigate } from "react-router-dom";
import Capacity from "../../../assets/Capacity";
import { useLocalStorage } from "../../../utils/useLocalStorage";

type PropsToolsWhiteLabel = {
    name: string;
    page: string;
    Icone: any;
};

export default function ToolsWhiteLabel({
    name,
    page,
    Icone
}: PropsToolsWhiteLabel) {
    const [currentVersion, setCurrentVersion] = useLocalStorage<string>("version", "");

    const navigate = useNavigate();

    return (
        <>
            <Icone
                className="tool"
                onClick={() => {
                    setCurrentVersion(name);
                    setTimeout(() => navigate(`/admin/${page}`), 200);
                }}
            />
        </>
    );
}
