import axios from "axios";

type DataWhiteLabel = {
    adminId: number;
    whiteLabelName: string;
    version?: string;
    file: any;
    token: string;
};
/**
 * Send information to create a new white label.
 * @param {DataWhiteLabel} data Admin id, white label name and logo file
 * @param {Function} callback Function called after the response
 */
export const PostNewWhiteLabel = async (data: DataWhiteLabel, callback: Function) => {
    const { token, file, adminId, whiteLabelName, version } = data;
    var formData = new FormData();
    formData.append("logo", file);
    formData.append("adminId", String(adminId));
    formData.append("whiteLabelName", whiteLabelName);
    formData.append("version", String(version));
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .post("/api/whiteLabel", formData)
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};
type DataGetWhiteLabel = {
    token: string;
};
interface DataPutUpdateWhiteLabel {
    id: number;
    token: string;
    logo: File | false;
}

export const PutUpdateWhiteLabel = (
    data: DataPutUpdateWhiteLabel,
    callback: Function
) => {
    const { token, logo, id } = data;
    var formData = new FormData();
    const logoFile = typeof logo === "object" ? logo : String(false);
    formData.append("logo", logoFile);
    formData.append("id", String(id));
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .put("/api/whiteLabel", formData, {
            headers: { 'content-type': 'multipart/form-data' }
          })
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};
/**
 * Endpoint to collect all white labels in database and display in white labels homepage.
 * @param {DataGetWhiteLabel} data Contains access token
 * @param {Function} callback function called after response
 */
export const GetAllWhiteLabels = async (data: DataGetWhiteLabel, callback: Function) => {
    const { token } = data;
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .get("/api/whiteLabel")
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};

type DataDeleteWhiteLabel = {
    token: string;
    whiteLabelId: string;
};
/**
 * Endpoint to delete a white label.
 * @param {DataGetWhiteLabel} data Contains access token and white label's id to delete
 * @param {Function} callback function called after response
 */
export const DeleteWhiteLabelById = async (
    data: DataDeleteWhiteLabel,
    callback: Function
) => {
    const { token } = data;
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .delete(`/api/whiteLabel/${data.whiteLabelId}`)
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};

export const getWhiteLabelLogo = async (callback: Function) => {
    const subDomain = document.location.hostname.split(".")[0];
    axios
        .get(`/api/whiteLabel/logo`, { params: { subDomain } })
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};
