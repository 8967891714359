export default function ValidGroups() {
    return (
        <svg
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M35.8751 41.9999L28.8751 34.9999L26.5417 37.3333L35.8751 46.6666L55.8751 26.6666L53.5417 24.3333L35.8751 41.9999Z"
                fill="#EB864B"
            />
            <g clipPath="url(#clip0_2143_63605)">
                <path
                    d="M6.79167 21.6667C8.625 21.6667 10.125 20.1667 10.125 18.3333C10.125 16.5 8.625 15 6.79167 15C4.95833 15 3.45833 16.5 3.45833 18.3333C3.45833 20.1667 4.95833 21.6667 6.79167 21.6667ZM8.675 23.5C8.05833 23.4 7.44167 23.3333 6.79167 23.3333C5.14167 23.3333 3.575 23.6833 2.15833 24.3C0.925 24.8333 0.125 26.0333 0.125 27.3833V30H7.625V27.3167C7.625 25.9333 8.00833 24.6333 8.675 23.5ZM33.4583 21.6667C35.2917 21.6667 36.7917 20.1667 36.7917 18.3333C36.7917 16.5 35.2917 15 33.4583 15C31.625 15 30.125 16.5 30.125 18.3333C30.125 20.1667 31.625 21.6667 33.4583 21.6667ZM40.125 27.3833C40.125 26.0333 39.325 24.8333 38.0917 24.3C36.675 23.6833 35.1083 23.3333 33.4583 23.3333C32.8083 23.3333 32.1917 23.4 31.575 23.5C32.2417 24.6333 32.625 25.9333 32.625 27.3167V30H40.125V27.3833ZM27.1917 22.75C25.2417 21.8833 22.8417 21.25 20.125 21.25C17.4083 21.25 15.0083 21.9 13.0583 22.75C11.2583 23.55 10.125 25.35 10.125 27.3167V30H30.125V27.3167C30.125 25.35 28.9917 23.55 27.1917 22.75ZM13.575 26.6667C13.725 26.2833 13.7917 26.0167 15.0917 25.5167C16.7083 24.8833 18.4083 24.5833 20.125 24.5833C21.8417 24.5833 23.5417 24.8833 25.1583 25.5167C26.4417 26.0167 26.5083 26.2833 26.675 26.6667H13.575ZM20.125 13.3333C21.0417 13.3333 21.7917 14.0833 21.7917 15C21.7917 15.9167 21.0417 16.6667 20.125 16.6667C19.2083 16.6667 18.4583 15.9167 18.4583 15C18.4583 14.0833 19.2083 13.3333 20.125 13.3333ZM20.125 10C17.3583 10 15.125 12.2333 15.125 15C15.125 17.7667 17.3583 20 20.125 20C22.8917 20 25.125 17.7667 25.125 15C25.125 12.2333 22.8917 10 20.125 10Z"
                    fill="#00ACE7"
                />
            </g>
            <defs>
                <clipPath id="clip0_2143_63605">
                    <rect
                        width="40"
                        height="40"
                        fill="white"
                        transform="translate(0.125)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
