/**
 * It takes a number and returns a string that is the number padded with a leading zero if the number
 * is less than 10
 * @param {number} num - The number to pad.
 * @returns A function that takes a number and returns a string.
 */
export function padTo2Digits(num: number) {
    return num.toString().padStart(2, "0");
}

/**
 * It takes a date and a boolean, and returns a string
 * @param {Date} date - Date - The date to format
 * @param {boolean} [time=false] - boolean = false
 * @returns A function that takes a date and returns a string
 */
export function formatDate(date: Date, time: boolean = false) {
    const resultDate = [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getFullYear())
    ].join("/");
    const resultTime = [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds())
    ].join(":");
    return `${resultDate}${time ? ` ${resultTime}` : ""}`;
}

/**
 * It takes a date and returns an object with a value and a text property. The value property is the
 * number of time units since the date, and the text property is the name of the time unit
 * @param {Date} date - The date to format.
 * @returns An object with two properties: value and text.
 */
export function formatDateSince(date: Date) {
    const now = new Date();
    const past = new Date(date);
    const diff = now.getTime() - past.getTime();
    const diffSeconds = Math.floor(diff / 1000);
    const diffMinutes = Math.floor(diff / 60000);
    const diffHours = Math.floor(diff / 3600000);
    const diffDays = Math.floor(diff / 86400000);
    const diffWeeks = Math.floor(diff / 604800000);
    const diffMonths = Math.floor(diff / 2628000000);
    const diffYears = Math.floor(diff / 31536000000);

    if (diffSeconds < 60) {
        return { value: false, text: "Just now" };
    }
    if (diffMinutes < 60) {
        return { value: diffMinutes, text: `minutes ago` };
    }
    if (diffHours < 24) {
        return { value: diffHours, text: `hours ago` };
    }
    if (diffDays < 7) {
        return { value: diffDays, text: `days ago` };
    }
    if (diffWeeks < 4) {
        return { value: diffWeeks, text: `weeks ago` };
    }
    if (diffMonths < 12) {
        return { value: diffMonths, text: `months ago` };
    }
    return { value: diffYears, text: `years ago` };
}

/**
 * Return a random integer between min and max, inclusive.
 * @param [min=1] - The minimum number that can be returned.
 * @param [max=30] - The maximum number to return.
 * @returns a random number between 1 and 30.
 */
export const randomInt = (min = 1, max = 30) => {
    return Math.floor(Math.random() * max) + min;
};

export const Colors = {
    BoatBlue: `rgb(41, 83, 128)`,
    TranquilBay: `rgb(138, 192, 225)`,
    VividCerulean: `rgb(0, 172, 231)`,
    Zambia: `rgb(255, 153, 12)`,
    Flamenco: `rgb(242, 153, 115)`,
    EyePoppingCherry: `rgb(195, 64, 150)`,
    PassionFlower: `rgb(140, 111, 168)`,
    White: `rgb(255, 255, 255)`,
    NoghreiSilver: `rgb(189, 189, 189)`,
    Black: `rgb(0, 0, 0)`
};

export const formatPrice = (price: number) => {
    // Divide by 100 to get the price in euros (stripe uses cents)
    price = price / 100;
    return new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "EUR"
    }).format(price);
};

export const calculatePriceWithTax = (price: number) => {
    // 0.2 is the tax rate in percent ("TVA" at 20%)
    return price + price * 0.2;
};

export const calculatedPriceWithoutTax = (price: number) => {
    return price;
};

export const styled4i = (string: string) => {
    return string
        .split("4i")
        .join(
            `<span class="text-color-orange">4</span><span class="text-color-white">i</span>`
        );
};
export const lineBreak = (string: string) => {
    return string.replace(/(?:\r\n|\r|\n)/g, "<br>");
};
