export const frRouter = {
    // Admin router
    "Homepage" : "Page d'accueil",
    "Intelligences": "Intelligences",
    "Capacities": "Capacités",
    "Questions": "Questions",
    "Users": "Utilisateurs",
    "Cross eyes": "Regards Croisés",
    "White labels" : "Marques blanches",

    // User router
    "My profile": "Mon profil",
    "View / edit my profile :": "Consulter / modifier mon profil :",
    "My surveys": "Mes tests",
    "View, download my tests": "Consulter, télécharger mes tests",
    "My password": "Mon mot de passe",
    "Delete my account": "Supprimer mon compte",
    "Recovery of my data": "Récupération de mes données"
};
