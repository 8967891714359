import FilterBy from "./FilterBy";
import Cell from "./Cell";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { CellGroup } from "./Group";
import { UserAdminRowOptions } from "../../Admin/Users";

interface PropsRow {
    nRow: number;
    data: any;
    rowOptions: any | UserAdminRowOptions;
    displayField?: string;
    group?: Function | false;
}
export function Row({ data, rowOptions, nRow, displayField, group = false }: PropsRow) {
    let isDisable = displayField && data[displayField] === false ? true : false;

    return (
        <div className={`row${isDisable ? " disableRow" : ""}`} data-id={data.id}>
            {group && <CellGroup value={data.id} label={"group"} callback={group} />}
            {Object.keys(rowOptions).map((type, nCell) => {
                let value = data[type];
                return (
                    rowOptions[type] && (
                        <Cell
                            data={data}
                            key={nRow + nCell + type}
                            nRow={nRow}
                            type={type}
                            value={value}
                            active={rowOptions[type]}
                        />
                    )
                );
            })}
        </div>
    );
}

interface PropsRowHeader {
    cells: any;
    callback?: Function;
    filters?: any;
    group?: Function | false;
}

export function RowHeader({
    cells,
    filters,
    callback = () => {},
    group = false
}: PropsRowHeader) {
    return (
        <div className="row header">
            {group && <CellGroup value={"all"} label={"all"} callback={group} />}
            {Object.keys(cells).map((key: string) => {
                if (key === "reset") return false;
                return (
                    <FilterBy
                        key={key}
                        finder={key}
                        active={cells[key].active}
                        text={cells[key].text}
                        callback={callback}
                        filters={filters}
                    />
                );
            })}
            {cells.reset ? (
                <ResetFilter callback={callback} />
            ) : (
                <div className="tools"></div>
            )}
        </div>
    );
}

function ResetFilter({ callback }: PropsResetFilter) {
    return (
        <div
            className="tools resetFilter"
            onClick={() => {
                if (callback) callback(false);
            }}
        >
            <FontAwesomeIcon icon={faRotateLeft} />
        </div>
    );
}
interface PropsResetFilter {
    callback?: Function;
}
