import { AxiosResponse } from "axios";
import { useCallback, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import ValidGroups from "../../../assets/ValidGroups";
import { GroupedActionsUsers } from "../../../services/root.service";
import { findUserAbstract } from "../../../services/user.service";
import Btn from "../../Templates/Btn";
import Checkbox from "../../Templates/Checkbox";
import { reloadList } from "../../Templates/Dashboard/List";
import Field, { RowFields } from "../../Templates/Field";
import Waiting from "../../Templates/Waiting";

export function AddUser({ setModal, survey }: any) {
    const [t, i18n] = useTranslation("admin");
    const valuedUser = useRef<HTMLInputElement | null>(null);
    const [selectUsers, setSelectUsers] = useState<any[]>([]);
    const [listUser, setListUser] = useState<any[]>([]);
    const [cookies] = useCookies(["access_token"]);
    const callback = (response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                reloadList({ checkbox: true });
                setModal(
                    <>
                        <h4>{t("Adding user to cross eye succeded")}</h4>
                        <ValidGroups />
                    </>
                );

                break;
            case 401:
                setModal(<>ERROR</>);
                reloadList();
                break;
        }
    };
    const resolveFindUser = useCallback(
        (response: AxiosResponse) => {
            switch (response.status) {
                case 200:
                    setListUser([]);
                    const users = response.data;
                    if (users.length > 0) {
                        setListUser(users);
                    }
                    break;
                case 401:
                    setModal(<>ERROR</>);
                    reloadList();
                    break;
            }
        },
        [setModal]
    );

    const handleField = () => {
        if (
            valuedUser.current &&
            valuedUser.current.value &&
            valuedUser.current.value.length > 2
        ) {
            findUserAbstract(
                valuedUser.current.value,
                cookies.access_token,
                resolveFindUser
            );
        } else {
            setListUser([]);
        }
    };

    return (
        <div id="AddCrosseye">
            <h4>{t("Search user to add for cross eye")}</h4>
            <fieldset>
                <Field
                    type="text"
                    id="valuedUser"
                    //   label="Utilisateur(s) évalué(s)"
                    placeholder={t("search input place holder")}
                    refField={valuedUser}
                    onChange={handleField}
                />
                <ul className="listUsers">
                    {listUser.map((user: any, index: number) => (
                        <li
                            key={`${index}-${user.id}`}
                            className={
                                selectUsers.find((x) => x === user) ? "selected" : ""
                            }
                        >
                            <Checkbox
                                type={"checkbox"}
                                value={user.id}
                                name="companysize"
                                callback={(value: any) => {
                                    if (value) {
                                        setSelectUsers([...selectUsers, user.id]);
                                    }
                                    if (!value) {
                                        setSelectUsers(
                                            selectUsers.filter(
                                                (userSelect: any) => userSelect !== user
                                            )
                                        );
                                    }
                                }}
                                // defaultValue={user.id}
                            />
                            <span>
                                {user.profile.firstName} {user.profile.lastName}
                            </span>
                            <span>{user.email}</span>
                            {/* <span>{user.profile.company}</span> */}
                            {/* <span>{user.id}</span> */}
                        </li>
                    ))}
                </ul>

                <RowFields>
                    <Btn
                        children={t("Cancel", { ns: "common" })}
                        className={"cancel"}
                        callback={() => {
                            setModal(false);
                        }}
                    />
                    <Btn
                        children={t("Validate", { ns: "common" })}
                        className={"active"}
                        callback={() => {
                            setModal(<Waiting />);
                            GroupedActionsUsers({
                                action: "add-crosseye",
                                data: {
                                    ids: selectUsers,
                                    selectUsers: [survey.userId],
                                    survey
                                },
                                token: cookies.access_token,
                                callback
                            });
                        }}
                    />
                </RowFields>
            </fieldset>
        </div>
    );
}
