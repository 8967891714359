import { useTranslation } from "react-i18next";
import { ClearTitlePsychology } from ".";
import GraphCarto from "./GraphCarto";

interface PropsCartoPsychology {
    results: Array<{
        capacity: Array<{
            capacity: { id: number; name: string; description: string };
            psychology: { id: number; name: string; description: string };
            max: number;
            purcent: number;
            score: number;
        }>;
        psychology: { id: number; name: string; description: string };
        max: number;
        purcent: number;
        score: number;
    }>;
    crosseyes?:
        | Array<{
              capacity: Array<{
                  capacity: { id: number; name: string; description: string };
                  psychology: { id: number; name: string; description: string };
                  max: number;
                  purcent: number;
                  score: number;
              }>;
              psychology: { id: number; name: string; description: string };
              max: number;
              purcent: number;
              score: number;
          }>
        | false;
}

const NamePsycho = {
    1: "self",
    2: "situational",
    3: "relational",
    4: "creative"
};

export default function CartoPsychology({
    results,
    crosseyes = false
}: PropsCartoPsychology) {
    const [t, i18n] = useTranslation("survey");
    const carto = {
        self: 0,
        relational: 0,
        creative: 0,
        situational: 0
    };

    results.forEach(({ psychology, purcent }) => {
        let name = Object.getOwnPropertyDescriptor(NamePsycho, psychology.id)?.value;
        Object.defineProperty(carto, name, { value: purcent, writable: true });
    });
    const cartoCrosseye = {
        self: 0,
        relational: 0,
        creative: 0,
        situational: 0
    };
    if (crosseyes && crosseyes.length >= 1) {
        crosseyes.forEach(({ psychology, purcent }) => {
            let name = Object.getOwnPropertyDescriptor(NamePsycho, psychology.id)?.value;
            Object.defineProperty(cartoCrosseye, name, {
                value: purcent,
                writable: true
            });
        });
    }

    return (
        <>
            <h3>{t("Explore your worlds")}</h3>
            <p>{t("Each of the worlds is rated at 100%")}</p>
            <div
                style={{
                    width: "100%",
                    background: `url(../carte.png)`,
                    backgroundSize: `cover`,
                    backgroundPosition: "center center",
                    backgroundRepeat: `no-repeat`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <GraphCarto carto={carto} crosseye={cartoCrosseye} />
            </div>
            {/* <GraphCarto carto={cartoCrosseye} /> */}
            <div className="separator" />
            <div className="detail">
                {crosseyes && crosseyes.length >= 1
                    ? crosseyes.map(({ psychology, purcent, ...data }, index) => {
                          const name = Object.getOwnPropertyDescriptor(
                              NamePsycho,
                              psychology.id
                          )?.value;
                          return (
                              <div key={name}>
                                  <h4 className={name}>
                                      {ClearTitlePsychology(psychology.name)}&nbsp;
                                  </h4>
                                  <h4>
                                      {t("radar-label-my-test")}
                                      {" : "}
                                      {
                                          results.find(
                                              (result) =>
                                                  result.psychology.id === psychology.id
                                          )!.purcent
                                      }
                                      % / {t("Cross eye")} : {purcent}%
                                  </h4>
                                  <p>{psychology.description}</p>
                                  {index + 1 !== results.length && (
                                      <div className="separator" />
                                  )}
                              </div>
                          );
                      })
                    : results.map(({ psychology, purcent }, index) => {
                          const name = Object.getOwnPropertyDescriptor(
                              NamePsycho,
                              psychology.id
                          )?.value;
                          return (
                              <div key={name}>
                                  <h4 className={name}>
                                      {ClearTitlePsychology(psychology.name)}&nbsp;
                                      <span>{purcent}%</span>
                                  </h4>
                                  <p>{psychology.description}</p>
                                  {index + 1 !== results.length && (
                                      <div className="separator" />
                                  )}
                              </div>
                          );
                      })}
            </div>
        </>
    );
}
