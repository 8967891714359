enum NamePsycho {
    "self",
    "situational",
    "relational",
    "creative"
}

export type ScoreInfo = {
    prefix: string;
    scores: any;
};

type MultiScoresProps = {
    scoresInfo: ScoreInfo[];
};

export default function MultiScores({ scoresInfo }: MultiScoresProps) {
    return (
        <>
            <div className="score-list">
                {scoresInfo.map(({ prefix, scores }) => (
                    <>
                        <div className="score-prefix">{prefix}</div>
                        {scores.map((score: any, index: number) => (
                            <span
                                key={index}
                                className={`score ${NamePsycho[score.psychology.id - 1]}`}
                            >
                                {score.purcent}%
                            </span>
                        ))}
                        <br />
                    </>
                ))}
            </div>
        </>
    );
}
