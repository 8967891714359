import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Btn from "../../Templates/Btn";
import SurveyTabs from "./SurveyTabs";

interface PropsComponent {
    finished: boolean;
    type: any;
    userId: any;
    surveyId: any;
}

export default function Results({ finished, type, userId, surveyId }: PropsComponent) {
    const [t, i18n] = useTranslation("survey");
    const [cookies] = useCookies(["firstName", "access_token", "id"]);
    const navigate = useNavigate();
    if (!finished) return <></>;
    if (type === 1) {
        if (userId === parseInt(cookies.id)) {
            return (
                <div className="results">
                    <header>
                        <h1>
                            {t("Well done")} {cookies.firstName} !
                        </h1>
                        <h2>{t("Here are the results in cross eye")}</h2>
                    </header>
                    {<SurveyTabs id={surveyId} />}
                </div>
            );
        } else {
            return (
                <div className="results">
                    <header>
                        <h1>
                            {t("Thank you")} {cookies.firstName} !
                        </h1>
                        <h2>{t("Your participation in the cross eye test")}</h2>
                        <p>{t("If you want to perform a 4i TEST for you")}</p>
                        <div className="calltoactions">
                            <a
                                href="https://www.act4-talents.fr/contact"
                                target={"_blank"}
                                rel="noreferrer"
                                style={{ fontSize: "1rem", fontFamily: "FuturaLT " }}
                            >
                                <Btn callback={() => {}} className="active">
                                    {t("Contact The ACT4 TALENTS team")}
                                </Btn>
                            </a>
                        </div>
                    </header>
                </div>
            );
        }
    }
    return (
        <div className="results">
            <header>
                <h1>
                    {t("Well done")} {cookies.firstName} !
                </h1>
                <h2>{t("Here are the results")}</h2>
            </header>
            {<SurveyTabs id={surveyId} />}
        </div>
    );
}

export const ClearTitlePsychology = (
    title:
        | "Monde de Soi"
        | "Monde Inattendu"
        | "Monde de l'Autre"
        | "Nouveau Monde"
        | string,
    separator: string | false = " - "
): JSX.Element => {
    const [t, i18n] = useTranslation("survey");
    const Mondes = {
        "Monde de Soi": [t("The world of self"), t("Self intelligence")],
        "Monde Inattendu": [t("The unexpected world"), t("Situational intelligence")],
        "Monde de l'Autre": [t("The world of the other"), t("Relational intelligence")],
        "Nouveau Monde": [t("The new world"), t("Creative intelligence")]
    };
    const monde = Object.getOwnPropertyDescriptor(Mondes, title);
    if (!separator) {
        return (
            <>
                {monde?.value[0]}
                <br />
                {monde?.value[1]}
            </>
        );
    }
    return <>{monde?.value.join(separator)}</>;
};
