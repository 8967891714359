import { PropsSVG } from "./SVG.interface";

export default function Time({
    className,
    onClick,
    size = 80,
    color = "#00ACE7"
}: PropsSVG) {
    return (
        <svg
            className={className}
            width={size}
            height={size}
            onClick={onClick}
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M73.3334 19.0666L58.0001 6.19995L53.7001 11.3L69.0334 24.1666L73.3334 19.0666ZM26.2667 11.3L22.0001 6.19995L6.66675 19.0333L10.9667 24.1333L26.2667 11.3ZM41.6667 26.6666H36.6667V46.6666L52.5001 56.1666L55.0001 52.0666L41.6667 44.1666V26.6666ZM40.0001 13.3333C23.4334 13.3333 10.0001 26.7666 10.0001 43.3333C10.0001 59.9 23.4001 73.3333 40.0001 73.3333C56.5667 73.3333 70.0001 59.9 70.0001 43.3333C70.0001 26.7666 56.5667 13.3333 40.0001 13.3333ZM40.0001 66.6666C27.1001 66.6666 16.6667 56.2333 16.6667 43.3333C16.6667 30.4333 27.1001 20 40.0001 20C52.9001 20 63.3334 30.4333 63.3334 43.3333C63.3334 56.2333 52.9001 66.6666 40.0001 66.6666Z"
                fill={color}
            />
        </svg>
    );
}
