import { AxiosResponse } from "axios";
import { ReactElement, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import Delete from "../../../assets/Delete";
import UserDecay from "../../../assets/UserDecay";
import { DeleteUserFromCrosseye } from "../../../services/crosseye.service";
import Btn from "../../Templates/Btn";
import { reloadList } from "../../Templates/Dashboard/List";
import { RowFields } from "../../Templates/Field";
import Modal from "../../Templates/Modal";
import Waiting from "../../Templates/Waiting";

export function DeleteUserInCrossEye({ setModal, data }: any) {
    const [t, i18n] = useTranslation("admin");
    const [cookies] = useCookies(["access_token"]);
    const { surveyId, userId, user } = data;
    const resolveDelete = (response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                reloadList();
                setModal(
                    <>
                        <h3>{t("Delete user from cross eye succeded")}</h3>
                        <UserDecay size={60} />
                    </>
                );
                break;
            case 404 | 500:
                setModal(
                    <>
                        <div>{t("Delete user from cross eye failed")}</div>
                        <UserDecay size={60} />
                        <div>
                            {t("Contact our support")} :{" "}
                            <a href={`mailto:${process.env.MAIL_ACCOUNT_SUPPORT}`}>{process.env.MAIL_ACCOUNT_SUPPORT}</a>
                        </div>
                    </>
                );
                break;
            default:
                break;
        }
    };
    return (
        <>
            <h4>
                {t("Are you sure to delete user from cross eye (start)")} <br />
                <br />
                {user.profile.firstName}{" "}
                {t("Are you sure to delete user from cross eye (end)")}
            </h4>
            <UserDecay size={60} />
            <RowFields>
                <Btn callback={setModal} className="cancel">
                    {t("Cancel", { ns: "common" })}
                </Btn>
                <Btn
                    callback={() => {
                        setModal(<Waiting />);
                        DeleteUserFromCrosseye(
                            { surveyId, userId, token: cookies.access_token },
                            resolveDelete
                        );
                    }}
                    className="active"
                >
                    {t("Delete", { ns: "common" })}
                </Btn>
            </RowFields>
        </>
    );
}

export function IconeDeleteUserInCrossEye(props: any) {
    //   return (
    //     <>
    // <h3>
    //   Etes vous sur de vouloir enlever FIRST_NAME_TESTER du TEST regard croisé
    //   de FRISTNAME_EVALUED
    // </h3>
    //     </>
    //   );
    const [modal, setModal] = useState<ReactElement | false>(false);
    return (
        <>
            <Modal content={modal} setContent={setModal} />
            <Delete
                size={24}
                className="tool"
                onClick={() => {
                    setModal(
                        <DeleteUserInCrossEye setModal={setModal} data={props.data} />
                    );
                }}
            />
        </>
    );
}
