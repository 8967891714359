export const enUser = {
    /**
     * Edit profile page
     */

    // Personal data
    "Welcome modal":
        "Welcome to the TEST 4i platform.\nOnce you validate your profile you will be able to access the test.",
    "Personal data": "Personal data",
    "First name": "First name",
    "Last name": "Last name",
    "Email": "Email",
    "Age": "Age",
    "Gender": "Gender",
    "Studies": "Studies",
    "Grade": "Job title",

    // Professional data
    "Professional data": "Professional details",
    "Service": "Area / Department in the company",
    "Company": "Company",
    "Sector": "Business sector",
    "Seniority": "Seniority in the company",
    "Country": "Country",
    "City": "City",
    "Postal code": "Zip code",

    "Validate my profile": "Validate my profile",
    "Your profile has been modified": "Your profile has been modified",
    "An error has occurred": "An error has occurred",
    "You will be redirected to your surveys": "You will be redirected to your surveys",

    // Seniority values
    "under1seniority": "- of 1 year",
    "under3seniority": "1 - 2 years",
    "under6seniority": "3 - 5 years",
    "under10seniority": "6 - 10 years",
    "more10seniority": "+ of 10 years",

    /**
     * My survey page
     */
    // Routing & header
    "My surveys": "My tests",
    "Consult, download my tests": "Consult, download my tests",

    // Table
    "My last survey": "My available tests",
    "Tests in progress": "Tests in progress",
    "version": "Version",
    "Scores": "Scores",
    "Finished": "Finished",
    "Started": "Started",
    "Progress": "Progress",
    "My history": "My history",
    "Test history": "Test history",
    "No test": "No test",
    "Buy a test": "Buy a TEST 4i",
    "Evalueted": "Evaluated",
    "Evaluators": "Evaluators",

    /**
     * Change password page
     */
    // Routing & header
    "My password": "My password",

    "Current password": "Current password",
    "Change my password": "Change my password",
    "Confirm my new password": "Confirm my new password",
    "Your password has been successfully changed!":
        "Your password has been successfully changed!",

    /**
     * Delete account page
     */
    // Routing & header
    "Delete account": "Delete account",
    "A your sure ? Personnal data will be deleted and only surveys will be kept anonymized.":
        "Are you sure you want to delete your account? Your personal data will be deleted and only your tests will be kept anonymized for study purposes operated solely by Act4 Talents.",
    "Account deleted later": "After deleting your account, it will be kept for 14 days.",
    "If you want to reactivate account": `If you want to reactivate it, you can contact our support: test@act4-talents.fr.`,
    "Yes I want it": "Yes, I confirm",

    "Your account has been successfully deleted.":
        "Your account has been successfully deleted.",
    "We could not delete your account.": "We couldn't delete your account",
    "Contact our support :": "Contact our support :",

    /**
     * My Data
     * */
    "You will make the request to recover your personal data.":
        "You will make the request to recover your personal data.",
    "You will receive by email all the data related to your account.":
        "You will receive by email all the data related to your account.",
    "An email has been sent to you!": "An email has been sent to you!"
};
