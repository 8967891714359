import { AxiosResponse } from "axios";
import { ReactElement, useState } from "react";
import { useCookies } from "react-cookie";
import { FileUploader } from "react-drag-drop-files";
import { useTranslation } from "react-i18next";
import Edit from "../../../assets/Edit";
import ValidDoc from "../../../assets/ValidDoc";
import { getTradFromDB } from "../../../services/translate.service";
import { PutUpdateWhiteLabel } from "../../../services/whiteLabel.service";
import DropZone from "../../DropZone";
import { BadRequest } from "../../Error/BadRequest";
import { ImageSizeError } from "../../Error/ImageSizeError";
import Btn from "../../Templates/Btn";
import { reloadList } from "../../Templates/Dashboard/List";
import Modal from "../../Templates/Modal";
import SidePage from "../../Templates/SidePage";
import Waiting from "../../Templates/Waiting";
import { WhiteLabelModel } from "../models";

const fileTypes = ["PNG", "JPG", "SVG", "JPEG"];

interface PropsNewWhiteLabel {
    closeModal: Function;
}

export function EditWhiteLabel(
    { whiteLabel }: PropsEditWhiteLabel,
    { closeModal }: PropsNewWhiteLabel
) {
    const [t] = useTranslation("admin");
    const [tError] = useTranslation("error");
    const [cookies] = useCookies<"access_token", { access_token: string }>([
        "access_token"
    ]);

    const [activeBtn, setActiveBtn] = useState(false);
    const [file, setFile] = useState<File | false>(false);
    const [callbackRequest, setCallbackRequest] = useState<ReactElement | false>(false);

    const addFile = (file: File) => {
        setFile(file);
        setActiveBtn(true);
    };

    const removeFile = () => {
        setFile(false);
        setActiveBtn(false);
    };

    const handleForm = () => {
        setCallbackRequest(
            <>
                <Waiting />
            </>
        );
        const formData = {
            token: cookies.access_token,
            id: whiteLabel.id,
            logo: file
        };
        PutUpdateWhiteLabel(formData, resolvePut);
    };

    const resolvePut = (response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                getTradFromDB();
                setCallbackRequest(
                    <>
                        <h3>{t("Your update has been made.")}</h3>
                        <ValidDoc
                            onClick={() => {
                                closeModal(false);
                            }}
                        />
                        <Btn className="active" callback={() => reloadList()}>
                            {t("Go back")}
                        </Btn>
                    </>
                );
                break;
            case 413:
                setCallbackRequest(
                    <ImageSizeError errorMessage={tError("Size error")} />
                );
                break;
            default:
                setCallbackRequest(
                    <BadRequest errorMessage={response.data.message ?? ""} />
                );
                break;
        }
    };

    return (
        <div id="NewWhiteLabel">
            <Modal content={callbackRequest} setContent={setCallbackRequest} />
            <h3>{t("Update white label logo")}</h3>
            <fieldset>
                <label>{t("Update white label logo")}</label>
                {!file ? (
                    <FileUploader
                        handleChange={addFile}
                        name="file"
                        types={fileTypes}
                        children={<DropZone file={file} removeFile={removeFile} />}
                    />
                ) : (
                    <DropZone file={file} removeFile={removeFile} />
                )}
                <h6>{t("White label file types")}</h6>
            </fieldset>
            <Btn className={activeBtn ? "active" : "disabled"} callback={handleForm}>
                {t("Validate the modification of the logo")}
            </Btn>
        </div>
    );
}

interface PropsEditWhiteLabel {
    whiteLabel: WhiteLabelModel;
}

interface PropsIconeEditWhiteLabel {
    update: { date: number };
    data: WhiteLabelModel;
}

export function IconeEditWhiteLabel(props: PropsIconeEditWhiteLabel) {
    const [sidepage, setSidepage] = useState<ReactElement | false>(false);
    return (
        <>
            <SidePage content={sidepage} setContent={setSidepage} />
            <Edit
                className="tool"
                onClick={() => {
                    setSidepage(<EditWhiteLabel whiteLabel={props.data} />);
                }}
            />
        </>
    );
}
