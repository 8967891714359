export const frSurvey = {
    /**
     * Survey landing  page
     */
    "Schedule time to answer.":
        "Prévoyez 10 minutes pour répondre aux questions dans un cadre calme.",
    "Respond intuitively and spontaneously.":
        "Répondez de manière intuitive et spontanée.",
    "This test is strictly personal": " Ce test est strictement personnel.",
    "Any test started must be completed.":
        "Tout test commencé doit être terminé, il n'est pas possible de revenir aux pages précédentes.",

    "Start": "Commencer",

    "pages": "pages",

    "You will begin the TEST": "Vous allez commencer le TEST",
    "in cross eye !": "en regard croisé !",
    "your TEST": "votre TEST",
    "begins !": "commence !",

    /**
     * Survey landing page & Quiz page
     */
    "See my results": "Voir mes résultats",
    "Submit": "Soumettre",

    /**
     * Quiz page
     */
    "notAgree": "Très rarement",
    "quiteAgree": "Parfois",
    "somewhatAgree": "Souvent",
    "totalyAgree": "Presque toujours",
    "Next": "Suivant",

    /**
     * Result page
     */
    "Well done": "Bravo",
    "Here are the results": "Voici les résultats de votre TEST 4i :",
    "Here are the results in cross eye":
        "Voici les résultats de votre TEST 4i en regard croisé:",
    "Thank you": "Merci",

    "Your participation in the cross eye test":
        "Votre participation au test regard croisé a bien été prise en compte",
    "If you want to perform a 4i TEST for you":
        "Si vous souhaitez réaliser un TEST 4i pour vous",

    "Buy online": "Acheter en ligne",
    "Contact The ACT4 TALENTS team": "Contacter l'équipe ACT4 TALENTS",

    "radar-label-my-test": "Mon test",
    "radar-label-my-test-crosseye": "Mon regard croisé",

    // Tabs
    "Map of the worlds": "Carte des mondes",
    "Your worlds": "Vos mondes",
    "Your capacities": "Vos capacités",
    "Your responses": "Vos réponses",
    "in detail": "en détail :",

    // Crosseye caption
    "Average": "Moyenne du regard croisé",
    "Min": "Plus petit score",
    "Max": "Plus grand score",
    "My result": "Votre résultat",

    // Worlds
    "The world of self": "Le monde de soi",
    "Self intelligence": "Intelligence de soi",
    "The unexpected world": "Le monde inattendu",
    "Situational intelligence": "Intelligence situationnelle",
    "The world of the other": "Le monde de l’autre",
    "Relational intelligence": "Intelligence relationnelle",
    "The new world": "Le nouveau monde",
    "Creative intelligence": "Intelligence créative",

    "start-self-world": "Le monde",
    "end-self-world": "de soi",
    "start-situational-world": "Le monde",
    "end-situational-world": "inattendu",
    "start-relational-world": "Le monde",
    "end-relational-world": "de l’autre",
    "start-creative-world": "Le nouveau",
    "end-creative-world": "monde",

    // Carto
    "Explore your worlds": "Explorez vos mondes",
    "Each of the worlds is rated at 100%": "Chacun des mondes est évalué sur 100%",
    "Cross eye": "Regard croisé"
};
