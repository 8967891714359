import { PSYCHOLOGY } from ".";
import GraphCarto from "../../../../Survey/Results/GraphCarto";

export const TeamMap = (results: any) => {
    let carto = {
        self: 0,
        relational: 0,
        creative: 0,
        situational: 0
    };
    Object.entries(results.global.psychology).forEach(([, psychology]: any[]) => {
        let tag = PSYCHOLOGY.getbyName(psychology.name)?.tag;
        if (tag)
            Object.defineProperty(carto, tag, {
                value: psychology.purcent,
                writable: true
            });
    });
    return (
        <>
            <div
                style={{
                    width: "70vw",
                    background: `url(../carte.png)`,
                    backgroundSize: `contain`,
                    backgroundPosition: "center center",
                    backgroundRepeat: `no-repeat`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0 auto"
                }}
            >
                <GraphCarto carto={carto} />
            </div>
        </>
    );
};
