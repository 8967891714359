import { AxiosResponse } from "axios";
import { MouseEventHandler, useCallback, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { FileUploader } from "react-drag-drop-files";
import { useTranslation } from "react-i18next";
import List from "../../../assets/List";
import {
    getTemplateTranslateCSV,
    PostQuestionsCSV
} from "../../../services/translate.service";
import { isDev } from "../../../utils/isDev";
import { useLocalStorage } from "../../../utils/useLocalStorage";
import { BadRequest } from "../../Error/BadRequest";
import Btn from "../../Templates/Btn";
import { reloadList } from "../../Templates/Dashboard/List";
import Field from "../../Templates/Field";
import Waiting from "../../Templates/Waiting";
import { SuccessDownload } from "./SuccessDownload";
import { PartialImportError } from "./PartialWritingError";

const fileTypes = ["CSV"];
export default function ImportTranslate({ closeModal, setModal, version }: any) {
    const [file, setFile] = useState(false);
    const [activeBtn, setActiveBtn] = useState(false);
    const [cookies] = useCookies(["access_token"]);
    const tagRef = useRef<HTMLInputElement | null>(null);
    const nameRef = useRef<HTMLInputElement | null>(null);
    const [t] = useTranslation("admin");
    const [currentVersion, setCurrentVersion] = useLocalStorage("version", "");

    const verifForm = useCallback(() => {
        if (tagRef.current && tagRef.current.value === "") {
            return false;
        }
        if (nameRef.current && nameRef.current.value === "") {
            return false;
        }
        if (!file) {
            return false;
        }
        return true;
    }, [file, tagRef, nameRef]);

    useEffect(() => {
        const changeForm = () => {
            return setActiveBtn(verifForm());
        };

        changeForm();
    }, [file, tagRef, nameRef, verifForm]);

    const removeFile = () => {
        setFile(false);
    };
    const changeFileInput = (file: any) => {
        setFile(file);
    };

    const resolvePost = (response: AxiosResponse) => {
        if (response)
            switch (response.status) {
                case 201:
                    reloadList();
                    closeModal(false);
                    break;
                case 206:
                    setModal(
                        <PartialImportError
                            errorMessage={t("Question ids not found :")}
                            questionIdsNotFound={response.data.questionIdsNotFound}
                        />
                    );
                    reloadList();
                    closeModal(false);
                    break;
                default:
                    console.log("error", response);
                    break;
            }
    };
    const handleForm = () => {
        if (verifForm()) {
            closeModal(<Waiting />);
            PostQuestionsCSV(
                {
                    token: cookies.access_token,
                    file,
                    tag: tagRef.current!.value,
                    name: nameRef.current!.value
                },
                resolvePost
            );
        }
    };

    const handleSampleCSV = async () => {
        getTemplateTranslateCSV(
            { token: cookies.access_token, version: currentVersion },
            resolveSampleCSV
        );
    };
    const resolveSampleCSV = (response: AxiosResponse) => {
        const { data, status } = response;
        switch (status) {
            case 200:
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "questionsTranslateCSV.csv");
                document.body.appendChild(link);
                link.click();
                link.remove();
                setModal(<SuccessDownload />);
                break;
            default:
                setModal(<BadRequest errorMessage={data.message} />);
                break;
        }
    };
    return (
        <div id="ImportCSV">
            <h3>{t("Import CSV")}</h3>
            <Field
                id="tag"
                type="text"
                placeholder="en, fr, de, it, es,..."
                label={t("language_tag")}
                refField={tagRef}
                defaultValue={isDev ? "en" : ""}
                onChange={() => setActiveBtn(verifForm())}
            />
            <Field
                id="name"
                type="text"
                placeholder="English, Francais, Deutsch, Italiano, Español,..."
                label={t("language_name")}
                refField={nameRef}
                defaultValue={isDev ? "English" : ""}
                onChange={() => setActiveBtn(verifForm())}
            />

            {!file ? (
                <FileUploader
                    handleChange={changeFileInput}
                    name="file"
                    types={fileTypes}
                    children={<DropZone file={file} removeFile={removeFile} />}
                />
            ) : (
                <DropZone file={file} removeFile={removeFile} />
            )}
            <Btn className="admin" callback={handleSampleCSV}>
                {t("CSV template")}
            </Btn>

            <Btn className={!activeBtn ? "disabled" : "active"} callback={handleForm}>
                {t("Import CSV")}
            </Btn>
        </div>
    );
}

interface PropsDropZone {
    file: any;
    removeFile: MouseEventHandler;
}

function DropZone({ file, removeFile }: PropsDropZone) {
    const [t, i18next] = useTranslation("admin");

    return (
        <div className="DropZone">
            {!file ? (
                <>
                    {t("Drag / drop")}
                    <br />
                    {t("download file")}
                    <br />
                    {t("here")}
                    <br />
                    <List />
                </>
            ) : (
                <>
                    {file.name}
                    <div className="btn active" onClick={removeFile}>
                        {t("Cancel")}
                    </div>
                </>
            )}
        </div>
    );
}
