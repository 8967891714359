enum NamePsycho {
    "self",
    "situational",
    "relational",
    "creative"
}

export default function Scores({ scores }: any) {
    return (
        <>
            {scores.map((score: any, index: number) => {
                return (
                    <div
                        key={index}
                        className={`score ${NamePsycho[score.psychology.id - 1]}`}
                    >
                        {score.purcent}%
                    </div>
                );
            })}
        </>
    );
}
