import { useTranslation } from "react-i18next";
import { Languages } from "../../langs";
import Dropdown from "./Dropdown";

interface PropsLangDropdown {
    refInput: React.RefObject<HTMLInputElement>;
    callback?: Function;
    defaultValue?: string | false;
}
export default function LangDropdown({
    refInput,
    callback,
    defaultValue
}: PropsLangDropdown) {
    const [t, i18n] = useTranslation("admin");

    let [defaultLang] = Object.entries(Languages).find(
        ([key]) => key === i18n.language
    ) || ["fr"];
    return (
        <Dropdown
            id="LangDropdown"
            label={t("Change Language of mail")}
            refInput={refInput}
            values={Object.keys(Languages).map((lang) => {
                return lang;
            })}
            defaultValue={defaultValue ? defaultValue : defaultLang}
            callback={callback}
        />
    );
}
