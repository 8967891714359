import { AxiosResponse } from "axios";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Mail from "../../../../assets/Mail";
import ValidMail from "../../../../assets/ValidMail";
import { GroupedActionsUsers } from "../../../../services/root.service";
import Btn from "../../../Templates/Btn";
import Checkbox from "../../../Templates/Checkbox";
import { reloadList } from "../../../Templates/Dashboard/List";
import { RowFields } from "../../../Templates/Field";
import LangDropdown from "../../../Templates/LangDropdown";
import TextField from "../../../Templates/TextField";
import Waiting from "../../../Templates/Waiting";

export const MailSignin = ({ action, ids, token, setModal }: any) => {
    const [t, i18n] = useTranslation("admin");
    const chooseLang = useRef(null);
    const [lang, setLang] = useState(i18n.language);
    const [hasCustomMessage, setHasCustomMessage] = useState(false);
    const [customMessage, setCustomMessage] = useState("");
    const refCustomMessage = useRef<HTMLInputElement | null>(null);

    const callback = (response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                reloadList();
                setModal(
                    <>
                        <h4>{t("Your messages have been sent successfully")}</h4>
                        <ValidMail />
                    </>
                );

                break;
            case 401:
                setModal(<>ERROR</>);
                reloadList();
                break;
        }
    };
    return (
        <>
            <h4>{t("Confirm sending emails to selected users ?")}</h4>
            <Mail size={80} />
            <LangDropdown refInput={chooseLang} defaultValue={lang} callback={setLang} />
            <Checkbox
                type={"checkbox"}
                value={"customMessage"}
                name="customMessage"
                callback={() => {
                    setHasCustomMessage(!hasCustomMessage);
                    if (hasCustomMessage) {
                        setCustomMessage("");
                    }
                }}
                checked={hasCustomMessage}
            >
                {t("Custom message")}
            </Checkbox>
            {hasCustomMessage && (
                <TextField
                    defaultValue={customMessage}
                    callback={() => {
                        if (refCustomMessage.current) {
                            setCustomMessage(refCustomMessage.current.value);
                        }
                    }}
                    refField={refCustomMessage}
                    placeholder={t("Custom message")}
                />
            )}

            <RowFields>
                <Btn
                    children={t("Cancel", { ns: "common" })}
                    className={"cancel"}
                    callback={() => {
                        setModal(false);
                    }}
                />
                <Btn
                    children={t("Send", { ns: "common" })}
                    className={"active"}
                    callback={() => {
                        setModal(<Waiting />);
                        GroupedActionsUsers({
                            action,
                            data: { ids, lang, customMessage },
                            token,
                            callback
                        });
                    }}
                />
            </RowFields>
        </>
    );
};
