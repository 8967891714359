import FilterDown from "../../../assets/FilterDown";
import FilterUp from "../../../assets/FilterUp";

export default function FilterBy({
    finder,
    text = false,
    active = true,
    callback,
    filters
}: PropsFilterBy) {
    return (
        <div className={`${finder} filter `}>
            {active && (
                <span>
                    <FilterUp
                        className={`${
                            filters.hasOwnProperty(finder) &&
                            String(filters[finder]) === "ASC"
                                ? "active"
                                : ""
                        }`}
                        onClick={() => {
                            callback && callback({ finder, order: "ASC" });
                        }}
                    />
                    <FilterDown
                        className={`${
                            filters.hasOwnProperty(finder) &&
                            String(filters[finder]) === "DESC"
                                ? "active"
                                : ""
                        }`}
                        onClick={() => {
                            callback && callback({ finder, order: "DESC" });
                        }}
                    />
                </span>
            )}
            {text && <span>{text}</span>}
        </div>
    );
}

interface PropsFilterBy {
    finder: string;
    text?: string | false;
    active?: boolean;
    callback?: Function;
    filters?: any;
}
