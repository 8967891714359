import { AxiosResponse } from "axios";
import { ReactElement, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import Delete from "../../../assets/Delete";
import UserDecay from "../../../assets/UserDecay";
import { RemoveUser } from "../../../services/root.service";
import Btn from "../../Templates/Btn";
import { RowFields } from "../../Templates/Field";
import Modal from "../../Templates/Modal";
import { User } from "../models";

export default function DeleteUser({ data }: PropsDeleteUser) {
    const [t, i18n] = useTranslation("admin");
    const [cookies] = useCookies(["access_token"]);
    const [modal, setModal] = useState<ReactElement | false>(false);

    const resolveDelete = (response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                document.querySelector("[data-id='" + data.id + "']")?.remove();
                break;
            case 403:
                setModal(
                    <>
                        <h3>{t("Action forbidden", { ns: "common" })}</h3>
                    </>
                );
                break;
            default:
                console.log("error", response);
                break;
        }
    };

    return (
        <>
            <Modal content={modal} setContent={setModal} />
            <Delete
                className="tool"
                onClick={() => {
                    setModal(
                        <>
                            <h3>{t("Are you sure you want to delete this account ?")}</h3>
                            <UserDecay size={60} />
                            <RowFields>
                                <Btn callback={setModal} className="cancel">
                                    {t("Cancel", { ns: "common" })}
                                </Btn>
                                <Btn
                                    callback={() => {
                                        RemoveUser(
                                            { id: data.id, token: cookies.access_token },
                                            resolveDelete
                                        );
                                    }}
                                    className="active"
                                >
                                    {t("Delete", { ns: "common" })}
                                </Btn>
                            </RowFields>
                        </>
                    );
                }}
            />
        </>
    );
}
interface PropsDeleteUser {
    data: User;
}
