import { AxiosResponse } from "axios";
import { ReactElement, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import Mail from "../../assets/Mail";
import { getMyData } from "../../services/user.service";
import { BadRequest } from "../Error/BadRequest";
import Btn from "../Templates/Btn";
import Modal from "../Templates/Modal";
import Waiting from "../Templates/Waiting";

export default function MyData() {
    const [t] = useTranslation("user");
    const [modal, setModal] = useState<ReactElement | false>(false);
    const [cookies] = useCookies(["access_token"]);
    const resolveGetMyData = async (response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                setModal(
                    <>
                        <h4>{t("An email has been sent to you!")}</h4>
                        <Mail size={60} />
                    </>
                );
                break;

            default:
                setModal(
                    <>
                        <BadRequest errorMessage={response.data.message ?? ""} />
                    </>
                );
                break;
        }
    };

    const handleMyData = () => {
        setModal(<Waiting />);
        getMyData(cookies.access_token, resolveGetMyData);
    };

    return (
        <div className="MyData">
            <Modal content={modal} setContent={setModal} />
            <p>{t("You will make the request to recover your personal data.")}</p>
            <em>
                {t("You will receive by email all the data related to your account.")}
            </em>
            <Btn className="active" callback={handleMyData}>
                {t("Yes I want it")}
            </Btn>
        </div>
    );
}
