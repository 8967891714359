import { PropsSVG } from "./SVG.interface";

export default function CrosseyeIcone({
    className,
    onClick,
    size = 80,
    color = "#00ACE7"
}: PropsSVG) {
    return (
        <svg
            className={className}
            width='24'
            height='12'
            onClick={onClick}
            viewBox="0 0 24 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.71429 1.4026C10.3722 1.4026 12.7426 2.89636 13.8997 5.25974C12.7426 7.62312 10.3722 9.11688 7.71429 9.11688C5.05636 9.11688 2.68597 7.62312 1.52883 5.25974C2.68597 2.89636 5.05636 1.4026 7.71429 1.4026ZM7.71429 0C4.20779 0 1.21325 2.18104 0 5.25974C1.21325 8.33844 4.20779 10.5195 7.71429 10.5195C11.2208 10.5195 14.2153 8.33844 15.4286 5.25974C14.2153 2.18104 11.2208 0 7.71429 0ZM7.71429 3.50649C8.68208 3.50649 9.46753 4.29195 9.46753 5.25974C9.46753 6.22753 8.68208 7.01299 7.71429 7.01299C6.74649 7.01299 5.96104 6.22753 5.96104 5.25974C5.96104 4.29195 6.74649 3.50649 7.71429 3.50649ZM7.71429 2.1039C5.97507 2.1039 4.55844 3.52052 4.55844 5.25974C4.55844 6.99896 5.97507 8.41558 7.71429 8.41558C9.45351 8.41558 10.8701 6.99896 10.8701 5.25974C10.8701 3.52052 9.45351 2.1039 7.71429 2.1039Z"
                fill="#00ACE7"
            />
            <path
                d="M18.8571 8.14286C17.1379 8.14286 13.7142 9.00245 13.7142 10.7143V12H23.9999V10.7143C23.9999 9.00245 20.5763 8.14286 18.8571 8.14286ZM15.4334 10.5306C16.0506 10.1045 17.542 9.61225 18.8571 9.61225C20.1722 9.61225 21.6636 10.1045 22.2808 10.5306H15.4334ZM18.8571 6.85714C20.275 6.85714 21.4285 5.70367 21.4285 4.28571C21.4285 2.86776 20.275 1.71429 18.8571 1.71429C17.4391 1.71429 16.2857 2.86776 16.2857 4.28571C16.2857 5.70367 17.4391 6.85714 18.8571 6.85714ZM18.8571 3.18367C19.4669 3.18367 19.9591 3.67592 19.9591 4.28571C19.9591 4.89551 19.4669 5.38776 18.8571 5.38776C18.2473 5.38776 17.755 4.89551 17.755 4.28571C17.755 3.67592 18.2473 3.18367 18.8571 3.18367Z"
                fill="#00ACE7"
            />
        </svg>
    );
}
