import { AxiosResponse } from "axios";
import { ReactElement, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import Delete from "../../../assets/Delete";
import UserDecay from "../../../assets/UserDecay";
import { DeleteCrosseyeFromIdSurvey } from "../../../services/crosseye.service";
import Btn from "../../Templates/Btn";
import { reloadList } from "../../Templates/Dashboard/List";
import { RowFields } from "../../Templates/Field";
import Modal from "../../Templates/Modal";
import Waiting from "../../Templates/Waiting";
interface PropsDeleteCrosseye {
    setModal: Function;
    surveyId: string;
}

export function DeleteCrosseye({ setModal, surveyId }: PropsDeleteCrosseye) {
    const [t, i18n] = useTranslation("admin");
    const [cookies] = useCookies(["access_token"]);
    const resolveDelete = (response: AxiosResponse) => {
        reloadList();
        switch (response.status) {
            case 200:
                setModal(
                    <>
                        <h3>{t("Delete cross eye succeded")}</h3>
                        <UserDecay size={60} />
                    </>
                );
                break;
            case 404 | 500:
                setModal(
                    <>
                        <div>{t("Delete cross eye failed")}</div>
                        <UserDecay size={60} />
                        <div>
                            {t("Contact our support")} :{" "}
                            <a href={`mailto:${process.env.MAIL_ACCOUNT_SUPPORT}`}>{process.env.MAIL_ACCOUNT_SUPPORT}</a>
                        </div>
                    </>
                );
                break;
            default:
                break;
        }
    };
    return (
        <>
            <h3>{t("Are you sure to delete cross eye")}</h3>
            <UserDecay size={60} />
            <RowFields>
                <Btn callback={setModal} className="cancel">
                    {t("Cancel", { ns: "common" })}
                </Btn>
                <Btn
                    callback={() => {
                        setModal(<Waiting />);
                        DeleteCrosseyeFromIdSurvey(
                            { surveyId, token: cookies.access_token },
                            resolveDelete
                        );
                    }}
                    className="active"
                >
                    {t("Delete", { ns: "common" })}
                </Btn>
            </RowFields>
        </>
    );
}

export function IconeDeleteCrosseye({ id }: any) {
    const [modal, setModal] = useState<ReactElement | false>(false);
    return (
        <>
            <Modal content={modal} setContent={setModal} />
            <Delete
                size={24}
                className="tool"
                onClick={() => {
                    setModal(<DeleteCrosseye setModal={setModal} surveyId={id} />);
                }}
            />
        </>
    );
}
