import axios from "axios";

interface DataPutPsychology {
    token: string;
    psychology: {
        id: string;
        name: string;
        description: string;
        trad: { [key: string]: any };
    };
}
export const putPsychology = (data: DataPutPsychology, callback: Function) => {
    const { token, psychology } = data;
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .put(`/api/psychology/`, { psychology })
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};
type DataGetPhychologies = {
    token: string;
    version: string;
};

/**
 * It gets the versions of the app from the server
 * @param {DataGetPhychologies} data - access token
 * @param {Function} callback - This is the function that will be called after the request is made.
 */
export const getPsychologies = async (data: DataGetPhychologies, callback: Function) => {
    axios.defaults.headers.common = { Authorization: `Bearer ${data.token}` };
    axios
        .get("/api/psychology", { params: { version: data.version } })
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};
