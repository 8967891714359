import { PropsSVG } from "./SVG.interface";

export default function ValidMail({
    className,
    onClick,
    size = 60,
    color = "#00ACE7"
}: PropsSVG) {
    return (
        <svg
            className={className}
            width={size}
            height={size}
            onClick={onClick}
            viewBox="0 0 63 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M41.5 11.25C41.5 9.1875 39.8125 7.5 37.75 7.5H7.75C5.6875 7.5 4 9.1875 4 11.25V33.75C4 35.8125 5.6875 37.5 7.75 37.5H37.75C39.8125 37.5 41.5 35.8125 41.5 33.75V11.25ZM37.75 11.25L22.75 20.625L7.75 11.25H37.75ZM37.75 33.75H7.75V15L22.75 24.375L37.75 15V33.75Z"
                fill={color}
            />
            <path
                d="M37.7498 46.9999L30.7498 39.9999L28.4165 42.3333L37.7498 51.6666L57.7498 31.6666L55.4165 29.3333L37.7498 46.9999Z"
                fill="#EB864B"
            />
        </svg>
    );
}
