import Caution from "./Caution";
import Progress from "./Progress";
import { useCallback, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useMatch, useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";
import { getMySurvey } from "../../services/profil.service";
import { PostResults, StartSurvey } from "../../services/survey.service";
import gsap from "gsap";
import Quiz from "./Quiz";
import Results from "./Results";
import Waiting from "../Templates/Waiting";
import { useTranslation } from "react-i18next";

export default function Survey() {
    const [t] = useTranslation("survey");
    const [showNext, setShowNext] = useState(false);
    const [questions, setQuestions] = useState<false | Array<any>>(false);
    const [part, setPart] = useState<number>(0);
    const [surveyId, setSurveyId] = useState<number | false>(
        parseInt(useMatch("/survey/:id")?.params.id || "0")
    );
    const [currentPart, setCurrentPart] = useState<number>(0);
    const [finished, setFinished] = useState(false);
    const [type, SetType] = useState<string | false>(false);
    const [targetSurvey, setTargetSurvey] = useState<number | false>(false);
    const [wait, setWait] = useState(true);
    let busy = useRef(false);

    const [cookies] = useCookies(["access_token", "inProgress", "id", "firstName"]);
    const navigate = useNavigate();

    const getAnswers = () => {
        return [...document.querySelectorAll<HTMLElement>("fieldset")].map((fieldset) => {
            return (
                [
                    ...fieldset.querySelectorAll<HTMLInputElement>(
                        `[name="${fieldset.dataset.id}"]`
                    )
                ].find((input) => input.checked) || false
            );
        });
    };
    const changeForm = () => {
        const answers = getAnswers();
        if (answers.find((input) => !input) === false) {
            setShowNext(false);
            return false;
        }
        setShowNext(true);
        return answers;
    };

    const resolvePost = (response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                getMySurvey(
                    {
                        token: cookies.access_token,
                        userId: cookies.id,
                        surveyId: surveyId || 0
                    },
                    resolveGet
                );
                break;

            default:
                navigate("/");
                break;
        }
    };

    const handleForm = () => {
        if (!showNext) return false;
        setShowNext(false);
        const answers = getAnswers().map<{ id: number; value: string } | false>(
            (input: HTMLInputElement | false) => {
                if (input) {
                    const [answer, id] = input.id.split("-");
                    return { id: parseInt(id), value: answer };
                }
                return false;
            }
        );
        PostResults(
            {
                token: cookies.access_token,
                answers,
                surveyId,
                id: cookies.id
            },
            resolvePost
        );
    };
    useEffect(() => {
        if (cookies.access_token === "") {
            navigate("/");
        }
        if (cookies.inProgress === "false") {
            navigate("/user/profile");
        }
    });

    const resolveGet = useCallback(
        (response: AxiosResponse) => {
            window.scrollTo(0, 0);
            switch (response.status) {
                case 200:
                    setWait(false);
                    if (response.data !== "") {
                        if (currentPart !== part) setPart(response.data.part);
                        setCurrentPart(response.data.part);
                        setQuestions(response.data.questions);
                        setSurveyId(response.data.surveyId);
                        SetType(response.data.type);
                        setTargetSurvey(response.data.userId);
                        if (response.data.finished) {
                            setFinished(response.data.finished);
                        }
                    } else {
                        setFinished(true);
                        showResult();
                    }
                    break;
                default:
                    navigate("/");
                    break;
            }
            busy.current = false;
        },
        [currentPart, part]
    );
    useEffect(() => {
        if (!busy.current) {
            // if (!surveyId) {
            busy.current = true;
            getMySurvey(
                {
                    token: cookies.access_token,
                    userId: cookies.id,
                    surveyId: surveyId || 0
                },
                resolveGet
            );

            // }
        }
        if (finished) {
            showResult();
        }
    }, [busy, cookies.access_token, cookies.id, finished, resolveGet, surveyId]);

    const startSurvey = () => {
        StartSurvey({ token: cookies.access_token, surveyId, id: cookies.id }, () => {});

        gsap.timeline()
            .to(".caution", { opacity: 0, height: 0, margin: 0, delay: 0 })
            .set(".caution", { display: "none" });
        gsap.timeline()
            .to("h1", { opacity: 0, height: 0, margin: 0, delay: 0 })
            .set("h1", { display: "none" });
        gsap.timeline().to(".survey", { opacity: 1, height: "auto", delay: 0.5 });
        gsap.timeline().to("header", {
            background:
                "linear-gradient(to bottom, rgb(41, 83, 128) 0, rgb(148, 186, 224) 0)",
            padding: "3.5rem 0.5rem 2rem"
        });
        setPart(currentPart);
    };
    const showResult = () => {
        gsap.timeline().set("header#survey", { display: "none" });
        gsap.timeline().set(".survey", { display: "none" });
        gsap.timeline().set(".results", { height: "auto" });
    };
    if (wait) return <Waiting count={20} width={800} height={800} />;
    return (
        <div className="Test">
            <div className="bubbles" />
            <header id="survey">
                {type === "crosseye" && targetSurvey !== parseInt(cookies.id) ? (
                    <h1>
                        {cookies.firstName},<br />
                        {t("You will begin the TEST")}{" "}
                        <span className="text-color-orange">4</span>
                        <span className="text-color-white">i</span> {t("in cross eye !")}
                    </h1>
                ) : (
                    <h1>
                        {cookies.firstName}, {t("your TEST")}{" "}
                        <span className="text-color-orange">4</span>
                        <span className="text-color-white">i</span> {t("begins !")}
                    </h1>
                )}
                <Caution type={type === "crosseye" ? 1 : 0} />
                <Progress
                    max={10}
                    value={part}
                    currentPart={currentPart}
                    start={startSurvey}
                    end={() => {}}
                />
            </header>
            <Quiz
                questions={questions}
                changeForm={changeForm}
                handleForm={handleForm}
                showNext={showNext}
                part={currentPart}
                type={
                    type === "crosseye" && targetSurvey !== parseInt(cookies.id) ? 1 : 0
                }
            />
            <Results
                finished={finished}
                type={type === "crosseye" ? 1 : 0}
                userId={targetSurvey}
                surveyId={surveyId}
            />
        </div>
    );
}
