import { AxiosResponse } from "axios";
import { ReactElement, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { GetHomePageByLang, PutHomePage } from "../../../services/homePage.service";
import Btn from "../../Templates/Btn";
import Dropdown from "../../Templates/Dropdown";
import Field from "../../Templates/Field";
import Modal from "../../Templates/Modal";
import TextField from "../../Templates/TextField";
import Waiting from "../../Templates/Waiting";
import { BadRequest } from "../../Error/BadRequest";

export default function HomePage() {
    const [t, i18n] = useTranslation("admin");
    const [cookies] = useCookies(["access_token"]);

    const title = useRef<HTMLInputElement | null>(null);
    const subTitle = useRef<HTMLInputElement | null>(null);
    const catchLine = useRef<HTMLInputElement | null>(null);
    const titleTrad = useRef<HTMLInputElement | null>(null);
    const subTitleTrad = useRef<HTMLInputElement | null>(null);
    const catchLineTrad = useRef<HTMLInputElement | null>(null);
    const selectLang = useRef<HTMLInputElement | null>(null);

    const [modal, setModal] = useState<ReactElement | null>(null);
    const [activeBtn, setActiveBtn] = useState<boolean>(true);
    const [langTrad, setLangTrad] = useState<string | false>("en");
    const [textHomePageFR, setTextHomePageFR] = useState<{
        id: number;
        title: string;
        subTitle: string;
        catchLine: string;
    } | null>(null);

    const [textHomePageTrad, setTextHomePageTrad] = useState<{
        id: number;
        title: string;
        subTitle: string;
        catchLine: string;
    } | null>(null);

    const changeForm = () => {
        return setActiveBtn(verifForm());
    };

    const verifForm = () => {
        if (title.current && title.current.value === "") return false;
        if (subTitle.current && subTitle.current.value === "") return false;
        if (catchLine.current && catchLine.current.value === "") return false;
        if (!textHomePageFR || !textHomePageFR.id) return false;
        if (!textHomePageTrad || !textHomePageTrad) return false;

        return true;
    };

    const resolvePutHomePage = (response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                setModal(
                    <>
                        <h3>{t("Your change has been made.")}</h3>
                    </>
                );

                break;

            default:
                setModal(<BadRequest errorMessage={response.data.message ?? ""} />);
                break;
        }
    };

    const handleForm = () => {
        if (!activeBtn) return false;
        setModal(<Waiting />);
        if (verifForm()) {
            const formData = {
                token: cookies.access_token,
                id: textHomePageFR!.id,
                title: title.current!.value,
                subTitle: subTitle.current!.value,
                catchLine: catchLine.current!.value,
                trad: {
                    id: textHomePageTrad!.id,
                    title: titleTrad.current!.value,
                    subTitle: subTitleTrad.current!.value,
                    catchLine: catchLineTrad.current!.value
                }
            };
            PutHomePage(formData, resolvePutHomePage);
        } else {
            setModal(null);
        }
    };

    useEffect(() => {
        const resolveGet = (response: AxiosResponse) => {
            switch (response.status) {
                case 200:
                    setTextHomePageFR(response.data);
                    break;
                default:
                    break;
            }
        };

        if (!textHomePageFR) {
            GetHomePageByLang({ lang: "fr" }, resolveGet);
        }
    }, [textHomePageFR]);

    useEffect(() => {
        const resolveGet = (response: AxiosResponse) => {
            switch (response.status) {
                case 200:
                    setTextHomePageTrad(response.data);
                    break;
                default:
                    break;
            }
        };
        if (langTrad) GetHomePageByLang({ lang: langTrad }, resolveGet);
    }, [langTrad]);

    if (!textHomePageFR) return <Waiting />;

    return (
        <div id="HomePage">
            <Modal content={modal} setContent={setModal} />
            <fieldset>
                <div className="default">
                    <Field
                        defaultValue={textHomePageFR.title}
                        label={t("title")}
                        refField={title}
                        onChange={changeForm}
                        type={"text"}
                        id={"title"} // disabled={true}
                    />
                    <Field
                        defaultValue={textHomePageFR.subTitle}
                        label={t("subTitle")}
                        refField={subTitle}
                        onChange={changeForm}
                        type={"text"}
                        id={"subTitle"} // disabled={true}
                    />
                    <TextField
                        refField={catchLine}
                        label={t("catchPhrase")}
                        defaultValue={textHomePageFR.catchLine}
                        size={3}
                    />
                </div>
                <div className="translate">
                    <h4>{t("Edit translation")}</h4>

                    <Dropdown
                        label={t("Choose language")}
                        refInput={selectLang}
                        callback={() => {
                            if (selectLang.current) {
                                setLangTrad(selectLang.current?.value);
                            }
                        }}
                        values={i18n.languages.filter((x) => x !== "fr") as string[]}
                        defaultValue={"en"}
                    />
                    {/* {textHomePageTrad && ( */}
                    <>
                        <Field
                            defaultValue={textHomePageTrad?.title}
                            label={t("title")}
                            refField={titleTrad}
                            callback={changeForm}
                            type={"text"}
                            id={"titleTrad"} // disabled={true}
                        />
                        <Field
                            defaultValue={textHomePageTrad?.subTitle}
                            label={t("subTitle")}
                            refField={subTitleTrad}
                            callback={changeForm}
                            type={"text"}
                            id={"subTitleTrad"} // disabled={true}
                        />
                        <TextField
                            refField={catchLineTrad}
                            label={t("catchPhrase")}
                            defaultValue={textHomePageTrad?.catchLine}
                            size={3}
                        />
                        {/* <Field
                                type="hidden"
                                id="idTrad"
                                refField={idTrad}
                                placeholder={"999"}
                                callback={undefined}
                                defaultValue={String(textHomePageTrad.id)}
                            /> */}
                    </>
                    {/* )} */}
                </div>
            </fieldset>
            <Btn
                className={activeBtn ? "active" : "disabled"}
                callback={() => {
                    if (verifForm()) {
                        handleForm();
                    }
                }}
            >
                {t("Save my changes")}
            </Btn>
        </div>
    );
}
