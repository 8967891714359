export default function NewUser() {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="40" height="40" rx="20" fill="#EB864B" />
            <path
                d="M23 20C25.21 20 27 18.21 27 16C27 13.79 25.21 12 23 12C20.79 12 19 13.79 19 16C19 18.21 20.79 20 23 20ZM23 14C24.1 14 25 14.9 25 16C25 17.1 24.1 18 23 18C21.9 18 21 17.1 21 16C21 14.9 21.9 14 23 14ZM23 22C20.33 22 15 23.34 15 26V28H31V26C31 23.34 25.67 22 23 22ZM17 26C17.22 25.28 20.31 24 23 24C25.7 24 28.8 25.29 29 26H17ZM14 23V20H17V18H14V15H12V18H9V20H12V23H14Z"
                fill="white"
            />
        </svg>
    );
}
