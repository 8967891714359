import { useTranslation } from "react-i18next";
interface PropsGraphCarto {
    carto: {
        self: number;
        relational: number;
        creative: number;
        situational: number;
    };
    crosseye?: {
        self: number;
        relational: number;
        creative: number;
        situational: number;
    };
}
export default function GraphCarto({ carto, crosseye }: PropsGraphCarto) {
    const middleRepere = { x: 300, y: 300 };
    const size = 60;
    const halfSize = size / 2 - 5;
    const [t, i18n] = useTranslation("survey");

    return (
        <svg
            width="600"
            height="600"
            viewBox="0 0 600 600"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx={
                    middleRepere.x -
                    ((crosseye?.relational ? crosseye.relational : carto.relational) +
                        size) +
                    halfSize
                }
                cy={
                    middleRepere.y -
                    ((crosseye?.relational ? crosseye.relational : carto.relational) +
                        size) +
                    halfSize
                }
                r={
                    (crosseye?.relational ? crosseye.relational : carto.relational) +
                    size +
                    "px"
                }
                stroke="#00ACE7"
                fill="#8AC0E1BF"
                strokeWidth="1"
            />
            <circle
                cx={
                    middleRepere.x +
                    ((crosseye?.creative ? crosseye.creative : carto.creative) + size) -
                    halfSize
                }
                cy={
                    middleRepere.y -
                    ((crosseye?.creative ? crosseye.creative : carto.creative) + size) +
                    halfSize
                }
                r={(crosseye?.creative ? crosseye.creative : carto.creative) + size}
                stroke="#8C6FA8"
                fill="#8C6FA8BF"
                strokeWidth="1"
            />
            <circle
                cx={
                    middleRepere.x -
                    ((crosseye?.self ? crosseye.self : carto.self) + size) +
                    halfSize
                }
                cy={
                    middleRepere.y +
                    ((crosseye?.self ? crosseye.self : carto.self) + size) -
                    halfSize
                }
                r={(crosseye?.self ? crosseye.self : carto.self) + size}
                stroke="#EB864B"
                fill="#F39972BF"
                strokeWidth="1"
            />
            <circle
                cx={
                    middleRepere.x +
                    ((crosseye?.situational ? crosseye.situational : carto.situational) +
                        size) -
                    halfSize
                }
                cy={
                    middleRepere.y +
                    ((crosseye?.situational ? crosseye.situational : carto.situational) +
                        size) -
                    halfSize
                }
                r={
                    (crosseye?.situational ? crosseye.situational : carto.situational) +
                    size
                }
                stroke="#B5007F"
                fill="#C34096BF"
                strokeWidth="1"
            />

            <text
                y={
                    middleRepere.y -
                    ((crosseye?.relational ? crosseye.relational : carto.relational) +
                        size)
                }
                fill="#FFF"
                textAnchor="middle"
                // stroke="#00ACE7"
                // strokeWidth="1"
            >
                <tspan
                    x={
                        middleRepere.x -
                        ((crosseye?.relational ? crosseye.relational : carto.relational) +
                            size) +
                        halfSize
                    }
                    dy="0"
                    fontFamily="FuturaLT Bold"
                    fontSize="20"
                >
                    {t("start-relational-world")}
                </tspan>
                <tspan
                    x={
                        middleRepere.x -
                        ((crosseye?.relational ? crosseye.relational : carto.relational) +
                            size) +
                        halfSize
                    }
                    dy="20"
                    fontFamily="FuturaLT Bold"
                    fontSize="20"
                >
                    {t("end-relational-world")}
                </tspan>

                <tspan
                    x={
                        middleRepere.x -
                        ((crosseye?.relational ? crosseye.relational : carto.relational) +
                            size) +
                        halfSize
                    }
                    dy="50"
                    fontFamily="FrederickatheGreat"
                    fontSize="50"
                >
                    {crosseye?.relational ? crosseye.relational : carto.relational}%
                </tspan>
            </text>

            <text
                y={
                    middleRepere.y -
                    ((crosseye?.creative ? crosseye.creative : carto.creative) + size)
                }
                fill="#FFF"
                textAnchor="middle"
            >
                <tspan
                    x={
                        middleRepere.x +
                        ((crosseye?.creative ? crosseye.creative : carto.creative) +
                            size) -
                        halfSize
                    }
                    dy="0"
                    fontFamily="FuturaLT Bold"
                    fontSize="20"
                >
                    {t("start-creative-world")}
                </tspan>
                <tspan
                    x={
                        middleRepere.x +
                        ((crosseye?.creative ? crosseye.creative : carto.creative) +
                            size) -
                        halfSize
                    }
                    dy="20"
                    fontFamily="FuturaLT Bold"
                    fontSize="20"
                >
                    {t("end-creative-world")}
                </tspan>
                <tspan
                    x={
                        middleRepere.x +
                        ((crosseye?.creative ? crosseye.creative : carto.creative) +
                            size) -
                        halfSize
                    }
                    dy="50"
                    fontFamily="FrederickatheGreat"
                    fontSize="50"
                >
                    {crosseye?.creative ? crosseye.creative : carto.creative}%
                </tspan>
            </text>

            <text
                y={
                    middleRepere.y +
                    ((crosseye?.self ? crosseye.self : carto.self) + size) -
                    halfSize -
                    30
                }
                fill="#FFF"
                textAnchor="middle"
                // stroke="#EB864B"
                // strokeWidth="1"
            >
                <tspan
                    x={
                        middleRepere.x -
                        ((crosseye?.self ? crosseye.self : carto.self) + size) +
                        halfSize
                    }
                    dy="0"
                    fontFamily="FuturaLT Bold"
                    fontSize="20"
                >
                    {t("start-self-world")}
                </tspan>
                <tspan
                    x={
                        middleRepere.x -
                        ((crosseye?.self ? crosseye.self : carto.self) + size) +
                        halfSize
                    }
                    dy="20"
                    fontFamily="FuturaLT Bold"
                    fontSize="20"
                >
                    {t("end-self-world")}
                </tspan>
                <tspan
                    x={
                        middleRepere.x -
                        ((crosseye?.self ? crosseye.self : carto.self) + size) +
                        halfSize
                    }
                    dy="50"
                    fontFamily="FrederickatheGreat"
                    fontSize="50"
                >
                    {crosseye?.self ? crosseye.self : carto.self}%
                </tspan>
            </text>

            <text
                y={
                    middleRepere.y +
                    ((crosseye?.situational ? crosseye.situational : carto.situational) +
                        size) -
                    halfSize -
                    30
                }
                fill="#FFF"
                textAnchor="middle"
            >
                <tspan
                    x={
                        middleRepere.x +
                        ((crosseye?.situational
                            ? crosseye.situational
                            : carto.situational) +
                            size) -
                        halfSize
                    }
                    dy="0"
                    fontFamily="FuturaLT Bold"
                    fontSize="20"
                >
                    {t("start-situational-world")}
                </tspan>
                <tspan
                    x={
                        middleRepere.x +
                        ((crosseye?.situational
                            ? crosseye.situational
                            : carto.situational) +
                            size) -
                        halfSize
                    }
                    dy="25"
                    fontFamily="FuturaLT Bold"
                    fontSize="20"
                >
                    {t("end-situational-world")}
                </tspan>
                <tspan
                    x={
                        middleRepere.x +
                        ((crosseye?.situational
                            ? crosseye.situational
                            : carto.situational) +
                            size) -
                        halfSize
                    }
                    dy="50"
                    fontFamily="FrederickatheGreat"
                    fontSize="50"
                >
                    {crosseye?.situational ? crosseye.situational : carto.situational}%
                </tspan>
            </text>

            {/* <line x1="300" y1="0" x2="300" y2="600" stroke="#BDBDBD" /> */}
            {/* <line x1="0" y1="300" x2="600" y2="300" stroke="#BDBDBD" /> */}
        </svg>
    );
}
