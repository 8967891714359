import { AxiosResponse } from "axios";
import { ReactElement, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import Edit from "../../../assets/Edit";
import StartSurvey from "../../../assets/StartSurvey";
import { AddSurveyToUser } from "../../../services/root.service";
import Btn from "../../Templates/Btn";
import Checkbox from "../../Templates/Checkbox";
import { reloadList } from "../../Templates/Dashboard/List";
import { RowFields } from "../../Templates/Field";
import LangDropdown from "../../Templates/LangDropdown";
import Modal from "../../Templates/Modal";
import SidePage from "../../Templates/SidePage";
import SwitchField from "../../Templates/SwitchField";
import TextField from "../../Templates/TextField";
import VersionDropdown from "../../Templates/VersionDropdown";
import Waiting from "../../Templates/Waiting";
import { User } from "../models";

interface PropsAddSurvey {
    user: User;
    setModal: (modal: ReactElement) => void;
}

export function AddSurvey({ user, setModal }: PropsAddSurvey) {
    const [t, i18n] = useTranslation("admin");
    const [cookies] = useCookies(["access_token"]);

    const version = useRef<HTMLInputElement | null>(null);
    const chooseLang = useRef<HTMLInputElement | null>(null);
    const refCustomMessage = useRef<HTMLInputElement | null>(null);

    const [hasToSendMail, setHasToSendMail] = useState<boolean>(true);
    const [lang, setLang] = useState<string>(i18n.language);
    const [hasCustomMessage, setHasCustomMessage] = useState<boolean>(false);
    const [customMessage, setCustomMessage] = useState<string>("");

    const verifForm = () => {
        if (version.current === null) return false;
        return true;
    };
    const resolvePost = (response: AxiosResponse) => {
        reloadList();
        switch (response.status) {
            case 200:
            case 401:
                setModal(
                    <>
                        <h3>{t(response.data.message)}</h3>
                    </>
                );
                break;
            default:
                break;
        }
    };
    const handleForm = () => {
        if (verifForm()) {
            setModal(<Waiting />);
            AddSurveyToUser(
                {
                    token: cookies.access_token,
                    userId: user.id,
                    version: version.current!.value,
                    hasToSendMail: hasToSendMail,
                    customMessage: customMessage
                },
                resolvePost
            );
            return true;
        }
        return false;
    };

    return (
        <div id="AddSurvey">
            <h3>
                {t("Add test to user")} {user.firstName}&nbsp;{user.lastName}
            </h3>
            <VersionDropdown callback={console.log} refInput={version} />
            <RowFields>
                <Checkbox
                    type={"checkbox"}
                    value={"sendMail"}
                    name="sendMail"
                    callback={() => setHasToSendMail(!hasToSendMail)}
                    checked={hasToSendMail}
                >
                    {t("Send an email to notify test access")}
                </Checkbox>
            </RowFields>
            {hasToSendMail && (
                <>
                    <RowFields>
                        <LangDropdown
                            refInput={chooseLang}
                            defaultValue={lang}
                            callback={setLang}
                        />
                    </RowFields>
                    <RowFields>
                        <Checkbox
                            type={"checkbox"}
                            value={"customMessage"}
                            name="customMessage"
                            callback={() => {
                                setHasCustomMessage(!hasCustomMessage);
                                if (hasCustomMessage) {
                                    setCustomMessage("");
                                }
                            }}
                            checked={hasCustomMessage}
                        >
                            {t("Custom message")}
                        </Checkbox>
                    </RowFields>

                    {hasCustomMessage && (
                        <TextField
                            defaultValue={customMessage}
                            callback={() => {
                                if (refCustomMessage.current) {
                                    setCustomMessage(refCustomMessage.current.value);
                                }
                            }}
                            refField={refCustomMessage}
                            placeholder={t("Custom message")}
                        />
                    )}
                </>
            )}

            <Btn className={"active"} callback={handleForm}>
                {t("Send", {ns: "common"})}
            </Btn>
        </div>
    );
}
interface PropsIconeAddSurvey {
    update: { date: number };
    data: User;
}
export function IconeAddSurvey(props: PropsIconeAddSurvey) {
    const [modal, setModal] = useState<ReactElement | false>(false);
    return (
        <>
            <Modal content={modal} setContent={setModal} />
            <StartSurvey
                className="tool"
                onClick={() => {
                    setModal(<AddSurvey user={props.data} setModal={setModal} />);
                }}
            />
        </>
    );
}
