export const frHome = {
    /**
     * Login page
     */
    "Welcome": "Bienvenue sur votre TEST",
    "Welcome_question" : "Savez-vous qu’il existe plusieurs intelligences ?",
    "Welcome_sentence1" : "Nous les possédons toutes et les maitrisons à différents niveaux.",
    "Welcome_sentence2" : "Découvrez votre/vos intelligence(s) dominante(s) !",
    "Forgot password ?": "Mot de passe oublié ?",

    /**
     * Reset password modal
     */
    "Your request has been taken into account":
        "Votre demande a bien été prise en compte.",
    "An email has been sent to change your password":
        "Un email de changement de mot de passe a été envoyé.",

    /**
     * Common login & signup page
     */
    // "Want to discover yours ?": "Vous voulez découvrir les vôtres ?",
    "Enter your login and password": "Saisissez votre identifiant et mot de passe",
    "Register": "Inscription",
    "Login": "Connexion",
    "Username": "Identifiant",
    "Password": "Mot de passe",

    /**
     * Register page
     */
    "RGPD-start":
        "Je consens à la collecte et l'utilisation de mes données dans le cadre définit dans les ",
    "RGPD-innerLink": "mentions légales",
    "RGPD-end": " de ACT4 TALENTS."
};
