import { AxiosResponse } from "axios";
import { ReactElement, useCallback, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import Key from "../../../assets/Key";
import UserAdd from "../../../assets/UserAdd";
import ValidGroups from "../../../assets/ValidGroups";
import { AddTokenToUser } from "../../../services/token.service";
import { findUserAbstract } from "../../../services/user.service";
import Btn from "../../Templates/Btn";
import Checkbox from "../../Templates/Checkbox";
import { reloadList } from "../../Templates/Dashboard/List";
import Field from "../../Templates/Field";
import Modal from "../../Templates/Modal";
import Waiting from "../../Templates/Waiting";

export default function AssignUser({ setModal, token }: any) {
    const [cookies] = useCookies(["access_token"]);
    const [t] = useTranslation("admin");
    const valuedUser = useRef<HTMLInputElement | null>(null);
    const [listUser, setListUser] = useState<any>([]);
    const [selectUsers, setSelectUsers] = useState<any>(null);
    const callback = (response: AxiosResponse) => {
        switch (response.status) {
            case 201:
                reloadList({ checkbox: true });
                setModal(
                    <>
                        <h4>{t("Token has been successfully added to the user")}</h4>
                        <Key size={80} />
                    </>
                );

                break;
            case 401:
                setModal(<>ERROR</>);
                reloadList();
                break;
        }
    };
    const resolveFindUser = useCallback(
        (response: AxiosResponse) => {
            switch (response.status) {
                case 200:
                    setListUser([]);
                    const users = response.data;
                    if (users.length > 0) {
                        setListUser(users);
                    }
                    break;
                case 401:
                    setModal(<>ERROR</>);
                    reloadList();
                    break;
            }
        },
        [setModal]
    );

    const handleField = () => {
        if (
            valuedUser.current &&
            valuedUser.current.value &&
            valuedUser.current.value.length > 2
        ) {
            findUserAbstract(
                valuedUser.current.value,
                cookies.access_token,
                resolveFindUser
            );
        } else {
            setListUser([]);
        }
    };
    return (
        <div id="AssignUser">
            <h4>{t("find an user")}</h4>
            <fieldset>
                <Field
                    type="text"
                    id="valuedUser"
                    label={t("Search a user")}
                    placeholder={t("search input place holder token")}
                    refField={valuedUser}
                    onChange={handleField}
                />
                <ul className="listUsers">
                    {listUser.map((user: any, index: number) => (
                        <li
                            key={`${index}-${user.id}`}
                            className={selectUsers === user ? "selected" : ""}
                        >
                            <Checkbox
                                type={"radio"}
                                value={user.id}
                                name="companysize"
                                callback={(value: any) => {
                                    if (value) {
                                        setSelectUsers(value);
                                    }
                                    if (!value) {
                                        setSelectUsers(false);
                                    }
                                }}
                                // defaultValue={user.id}
                            />
                            <span>
                                {user.profile.firstName} {user.profile.lastName}
                            </span>
                            <span>{user.email}</span>
                            <span>{user.profile.company}</span>
                            {/* <span>{user.id}</span> */}
                        </li>
                    ))}
                </ul>
                <Btn
                    children={t("Validate", { ns: "common" })}
                    className={"active"}
                    callback={() => {
                        setModal(<Waiting />);
                        AddTokenToUser(
                            {
                                token: cookies.access_token,
                                tokenId: token.id,
                                userId: selectUsers
                            },
                            callback
                        );
                    }}
                />
            </fieldset>
        </div>
    );
}

export function IconeAssignUser(props: PropsIconeEdituser) {
    const [modal, setModal] = useState<ReactElement | false>(false);
    return (
        <>
            <Modal content={modal} setContent={setModal} />
            {!props.data?.used && (
                <UserAdd
                    className="tool"
                    size={24}
                    onClick={() => {
                        setModal(<AssignUser {...{ token: props.data, setModal }} />);
                    }}
                />
            )}
        </>
    );
}

interface PropsIconeEdituser {
    update: { date: number };
    data: any;
}
