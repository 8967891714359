export const frResetPassword = {
    /**
     * Reset password page
     */
    "Change my password": "Changer mon mot de passe",
    "Confirm my new password": "Confirmer mon nouveau mot de passe",

    "Your password has been successfully changed !":
        "Votre mot de passe a été changé avec succès !",
    "We were unable to update your password.":
        "Nous n'avons pas pu mettre à jour votre mot de passe.",
    "Your token may have expired, try again.": "Votre token peut être expiré, réessayez.",
    "If the situation persists, do not hesitate to contact our support.":
        "Si la situation persiste, n'hésitez pas à contacter notre support."
};
