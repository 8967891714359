import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en";
import fr from "./fr";

const i18nResources = {
    en,
    fr
};

export type InternationalLanguage = keyof typeof i18nResources;
export type LanguagesType = {
    [key in InternationalLanguage]: string;
};

export const Languages: LanguagesType = {
    en: "English",
    fr: "Français"
};

i18n.use(initReactI18next).init({
    ns: [
        "common",
        "home",
        "user",
        "survey",
        "resetPassword",
        "admin",
        "router",
        "dropdown",
        "purchase"
    ],
    defaultNS: "common",

    resources: i18nResources,
    lng: "fr",
    fallbackLng: ["fr", "en"], // INFO : list order of languages to use

    interpolation: {
        escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
});

export const accessibleLanguages = i18n.languages.map(
    (l) => Object.getOwnPropertyDescriptor(Languages, l)?.value
);
