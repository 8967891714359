import { NamespaceName, RestrictRule, Role } from "./restrictRule.types";
import { restrictRulesNamepaces } from "./rules.config";

export function findRuleInNamespace(
    namespace: NamespaceName,
    zoneId: string
): RestrictRule | undefined {
    const rules = restrictRulesNamepaces[namespace];

    if (!rules) return undefined;

    return rules.find((rule) => rule.zoneId === zoneId);
}

export function userHaveRestrictionToZone(
    namespace: NamespaceName,
    zoneId: string,
    userRole: Role
): boolean {
    const rule = findRuleInNamespace(namespace, zoneId);

    if (!rule) return false;

    return rule.roles.includes(userRole);
}
