import { Version } from "../Admin/models";
import { getAllVersions } from "../../services/template.service";
import { AxiosResponse } from "axios";
import Dropdown from "../Templates/Dropdown";
import { useEffect, useState } from "react";
import { useLocalStorage } from "../../utils/useLocalStorage";
import { useCookies } from "react-cookie";
import React from "react";

type PropsVersionDropdown = {
    callback?: Function;
    refInput: React.RefObject<HTMLInputElement>;
    defaultValue?: string | false;
    label?: string;
};

export default function VersionDropdown({
    callback,
    refInput,
    defaultValue,
    label
}: PropsVersionDropdown) {
    const [cookies] = useCookies(["access_token"]);
    const [versions, setVersions] = useState<string[]>([]);
    const [currentVersion, setCurrentVersion] = useLocalStorage<string>("version", "");

    const handleChangeVersion = (version: string) => {
        setCurrentVersion(version);
    };

    useEffect(() => {
        const resolveGetAllVersions = (response: AxiosResponse) => {
            switch (response.status) {
                case 200:
                    const versionsList = response.data.versions.map(
                        (object: Version) => object.version
                    );
                    setVersions(versionsList);
                    break;
                case 400 | 500:
                default:
            }
        };

        if (versions.length === 0) {
            getAllVersions(cookies.access_token, resolveGetAllVersions);
        }
    }, []);

    return (
        <Dropdown
            defaultValue={currentVersion ? currentVersion : defaultValue}
            values={versions}
            callback={(item: string) => {
                handleChangeVersion(item);
                callback && callback(item);
            }}
            label={label}
            refInput={refInput}
        />
    );
}
