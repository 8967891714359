import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AxiosResponse } from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ValidTag from "../../../../assets/ValidTag";
import { FindCommunTags, GroupedActionsUsers } from "../../../../services/root.service";
import Btn from "../../../Templates/Btn";
import { reloadList } from "../../../Templates/Dashboard/List";
import Field, { RowFields } from "../../../Templates/Field";
import Tag from "../../../Templates/Tag";
import Waiting from "../../../Templates/Waiting";

export const ManageTags = ({ action, ids, token, setModal }: any) => {
    const [t, i18n] = useTranslation("admin");
    const [tags, setTags] = useState<string[] | false>(false);
    const [tagsToDelete, setTagsToDelete] = useState<string[]>([]);
    const refInput = useRef<HTMLInputElement>(null);
    const callback = (response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                setModal(
                    <>
                        <h4>{t("The tags have been administered successfully")}</h4>
                        <ValidTag />
                    </>
                );
                reloadList();
                break;
            case 401:
                setModal(<>ERROR</>);
                reloadList();
                break;
        }
    };
    const addTag = useCallback(() => {
        if (refInput.current && tags) {
            if (tags.includes(refInput.current.value)) return;
            const newList = [...tags, refInput.current.value];
            setTags(newList);
            setTimeout(() => {
                if (refInput.current) {
                    refInput.current.value = "";
                }
            }, 100);
        }
    }, [tags]);
    const deleteTag = (tagValue: string) => {
        if (tags) {
            const updateList = tags.filter((value) => value !== tagValue);
            const isAlreadyTag = tags.find((tag) => tag === tagValue);
            if (isAlreadyTag) {
                const newDeleteList = [isAlreadyTag, ...tagsToDelete];
                setTagsToDelete(newDeleteList);
            }
            setTags(updateList);
        }
    };

    useEffect(() => {
        const resolveGetAllTags = async (response: AxiosResponse) => {
            switch (response.status) {
                case 200:
                    const tags = response.data;
                    setTags(tags);
                    break;
                default:
                case 500:
                    setModal(<>ERROR</>);
                    reloadList();
                    break;
            }
        };
        if (!tags) {
            FindCommunTags({ token: token, ids: ids }, resolveGetAllTags);
        }

        document.addEventListener("keydown", (e) => {
            if (e.key === "Enter") {
                addTag();
            }
        });
        return () => {
            document.removeEventListener("keydown", (e) => {
                if (e.key === "Enter") {
                    addTag();
                }
            });
        };
    }, [tags, addTag, token, ids, setModal]);

    return (
        <div id="Grouped-tags">
            <h4>{t("Do you want to add or remove a tag ?")}</h4>
            <RowFields>
                <Field
                    type="text"
                    id="group-tag"
                    placeholder={t("Tag")}
                    refField={refInput}
                />
                <Btn className="margin-auto" callback={addTag}>
                    <FontAwesomeIcon icon={faCirclePlus} />
                </Btn>
            </RowFields>
            <div className="tags">
                {tags &&
                    tags.map((tag: string) => (
                        <Tag
                            key={tag}
                            value={tag}
                            color="primary"
                            active={true}
                            callback={deleteTag}
                        />
                    ))}
            </div>
            <div className="separator"></div>
            <RowFields>
                <Btn
                    children={t("Cancel", { ns: "common" })}
                    className={"cancel"}
                    callback={() => {
                        setModal(false);
                    }}
                />
                <Btn
                    children={t("Validate", { ns: "common" })}
                    className={"active"}
                    callback={() => {
                        setModal(<Waiting />);
                        GroupedActionsUsers({
                            action,
                            data: { ids, tags, tagsToDelete },
                            token,
                            callback
                        });
                    }}
                />
            </RowFields>
        </div>
    );
};
