import { AnyAaaaRecord } from "dns";
import { ReactElement, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Psychology from "../../../assets/Psychology";
import SurveyTabs from "../../Survey/Results/SurveyTabs";
import SidePage from "../../Templates/SidePage";
import { User } from "../models";
import SurveyHistory from "./SurveyHistory";

export function PsychologyUser(props: PropsPsychologyUser) {
    const [t, i18n] = useTranslation("admin");
    const user = props.data;
    const surveys = props.data.surveys
        .filter((survey: any) => survey.end)
        .map((survey: any) => {
            return {
                id: survey.id,
                version: survey.template.version,
                end: survey.end
            };
        })
        .sort((a: any, b: any) => a.end - b.end);
    const [selectedSurvey, setSelectedSurvey] = useState<any>(false);
    const version = useRef<ReactElement | false>(false);

    const changeVersion = (versionHash: string) => {
        setSelectedSurvey(false);
        const [id, version] = versionHash.split(" - ");
        const survey = surveys.find(
            (survey: any) => survey.id === id && survey.version === version
        );
        if (survey) {
            setSelectedSurvey(survey);
        }
    };

    useEffect(() => {
        if (!selectedSurvey && surveys.length > 0) {
            setSelectedSurvey(surveys[0]);
        }
    }, [selectedSurvey, surveys]);
    return (
        <div id="PsychologyUser">
            <h3>
                {t("The result of")} {user.firstName} {user.lastName}
            </h3>
            <fieldset>
                {selectedSurvey.id && (
                    <SurveyTabs id={selectedSurvey.id} activeDL={false} />
                )}
            </fieldset>
        </div>
    );
}

interface PropsPsychologyUser {
    update: { date: number };
    data: User;
}
export function IconePsychologyUser(props: PropsPsychologyUser) {
    useEffect(() => {
        setSidepage(false);
    }, [props]);
    const [sidepage, setSidepage] = useState<ReactElement | false>(false);
    const user = props.data;
    const endingSurveys =
        user.surveys
            .filter((survey: any) => survey.end)
            .sort((a: any, b: any) => a.end - b.end) || [];
    return (
        <>
            <SidePage content={sidepage} setContent={setSidepage} />
            <Psychology
                className={
                    endingSurveys.length >= 1 && endingSurveys[0].end
                        ? "tool active"
                        : "tool inactive"
                }
                onClick={() => {
                    if (endingSurveys.length >= 1 && endingSurveys[0].end)
                        // WIP
                        // setSidepage(<PsychologyUser {...props} />);
                        setSidepage(<SurveyHistory {...props} />);
                }}
            />
        </>
    );
}
