import axios from "axios";
interface DataGetAllUsers {
    token: string;
    filters: Object;
}

/**
 * GetMyProfile is a function that takes in a DataGetMyProfile object and a callback function and
 * returns a response object
 * @param {DataGetAllUsers} data - DataGetMyProfile - this is the data that will be passed to the
 * function
 * @param {Function} callback - Function - This is the function that will be called when the request is
 * complete.
 */
export const GetAllUsers = async (data: DataGetAllUsers, callback: Function) => {
    const { token, filters } = data;
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .get("/api/profile", { params: filters })
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};
interface DataGetMyProfile {
    token: string;
    id: number;
}

/**
 * It gets the profile of the user with the given id
 * @param {DataGetMyProfile} data - DataGetMyProfile - this is the data that is passed to the function.
 * @param {Function} callback - Function - This is the function that will be called when the request is
 * complete.
 */
export const getMyProfile = async (data: DataGetMyProfile, callback: Function) => {
    const { token, id } = data;
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .get(`/api/profile/${id}`)
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};
interface DataPutMyProfile {
    token: string;
    userId: string;
    id: string;
    firstName: string | false;
    lastName: string | false;
    age: string | false;
    // nationality: string | false;
    studies: string | false;
    grade: string | false;
    service: string | false;
    company: string | false;
    secteur: string | false;
    country: string | false;
    district: string | false;
    gender: string | false;
    companySize: string | false;
    seniority: string | false;
}

/**
 * It takes in a data object and a callback function, and then it makes a PUT request to the server
 * with the data object, and then it calls the callback function with the response from the server
 * @param {DataPutMyProfile} data - DataPutMyProfile - this is the data that will be sent to the
 * server.
 * @param {Function} callback - Function - this is the function that will be called after the request
 * is made.
 */
export const putMyProfile = async (data: DataPutMyProfile, callback: Function) => {
    const { token, userId, ...profile } = data;
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .put(`/api/profile/${userId}`, profile)
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};

interface DataPutMyPassword {
    token: string;
    id: number;
    oldPassword: string;
    newPassword: string;
}
/**
 * It takes in a token, an old password, a new password, and an id, and then it makes a PUT request to
 * the server with the old password and the new password
 * @param {DataPutMyPassword} data - DataPutMyPassword - this is the data that is being sent to the
 * API.
 * @param {Function} callback - Function - this is the function that will be called after the request
 * is made.
 */
export const putMyPassword = async (data: DataPutMyPassword, callback: Function) => {
    const { token, oldPassword, newPassword, id } = data;
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .put(`/api/profile/${id}/password`, { oldPassword, newPassword })
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};

interface DataGetMySurvey {
    token: string;
    userId: number;
    surveyId?: number;
}
/**
 * It gets the survey data for a user
 * @param {DataGetMySurvey} data - DataGetMySurvey - this is the data that is passed to the function.
 * @param {Function} callback - Function - this is the function that will be called after the request
 * is made.
 */
export const getMySurvey = async (data: DataGetMySurvey, callback: Function) => {
    const { token, userId, surveyId = 0 } = data;
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .get(`/api/profile/${userId}/survey`, { params: { surveyId } })
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};
