import NavBar from "./components/NavBar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { withCookies } from "react-cookie";
import Home from "./components/Home";
import Survey from "./components/Survey";
import User from "./components/User";
import Admin from "./components/Admin";
import ResetPassword from "./components/ResetPassword";
import { useCallback, useEffect, useState } from "react";
import { useLocalStorage } from "./utils/useLocalStorage";
import { useTranslation } from "react-i18next";
import { getNavigatorLang } from "./utils/getNavigatorLang";
import logo from "./assets/logo.svg";
import { AxiosResponse } from "axios";
import { getWhiteLabelLogo } from "./services/whiteLabel.service";
import Waiting from "./components/Templates/Waiting";

import { getTradFromDB } from "./services/translate.service";

export default withCookies(function App() {
    const [lang, setLang] = useLocalStorage<string>("lang", getNavigatorLang() || "fr");
    const [t, i18n] = useTranslation();

    const [navLogo, setNavLogo] = useState(logo);
    const [loading, setLoading] = useState(true);

    const resolveGet = useCallback((response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                setNavLogo(`/api${response.data}`);
                break;
            case 400 | 500:
                break;
            default:
                break;
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        getWhiteLabelLogo(resolveGet);
    }, [resolveGet]);

    useEffect(() => {
        // Init lang from local storage
        i18n.changeLanguage(lang);
        getTradFromDB();
    }, [i18n, lang]);
    // console.log(i18n.language);

    if (loading) return <Waiting count={20} width={800} height={800} />;
    return (
        <Router>
            <div className="App">
                <NavBar navLogo={navLogo} />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/survey/:id" element={<Survey />} />
                    <Route path="/resetPassword" element={<ResetPassword />} />
                    <Route path="/user/:sub" element={<User />} />
                    <Route path="/admin/:sub" element={<Admin />} />
                    <Route path="/admin" element={<Admin />} />
                    {/* <Route path="/purchase" element={<Purchase />} /> */}
                </Routes>
                <footer>
                    <a href="https://www.act4-talents.fr/contact">{t("Contact")}</a>
                    <a href="https://www.act4-talents.fr/mentions-legales">
                        {t("Legal information")}
                    </a>
                </footer>
            </div>
        </Router>
    );
});
