import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getResultsTeam } from "../../../../../services/team.service";
import { Colors } from "../../../../../utils";
import Waiting from "../../../../Templates/Waiting";
import { TeamAxe } from "./TeamAxe";
import { TeamCapa } from "./TeamCapa";
import { TeamPsychology } from "./TeamPsychology";
import { TeamMap } from "./TeamMap";
import { TeamDominante } from "./TeamDominante";
import Download from "../../../../../assets/Download";
import ValidDoc from "../../../../../assets/ValidDoc";
import { GroupedActionsUsers } from "../../../../../services/root.service";

export const PSYCHOLOGY = {
    def: [
        {
            name: "Monde de l'Autre",
            tag: "relational",
            color: Colors.TranquilBay,
            trad: "The world of the other"
        },
        {
            name: "Nouveau Monde",
            tag: "creative",
            color: Colors.PassionFlower,
            trad: "The new world"
        },
        {
            name: "Monde de Soi",
            tag: "self",
            color: Colors.Flamenco,
            trad: "The world of self"
        },
        {
            name: "Monde Inattendu",
            tag: "situational",
            color: Colors.EyePoppingCherry,
            trad: "The unexpected world"
        }
    ],

    getbyName(name: string) {
        return PSYCHOLOGY.def.find((psy) => psy.name === name);
    },
    getByTag(tag: string) {
        return PSYCHOLOGY.def.find((psy) => psy.tag === tag);
    }
};

export const Tabs = {
    carto: {
        text: "Mapping the worlds",
        content: (props: any) => <TeamMap {...props} />
    },
    detail: {
        text: "Strong capacities",
        content: (props: any) => <TeamCapa {...props} />
    },
    axe: {
        text: "Areas of improvement",
        content: (props: any) => <TeamAxe {...props} />
    },
    dominantepsy: {
        text: "Dominant intelligence",
        content: (props: any) => <TeamPsychology {...props} />
    },
    dominantecapa: {
        text: "Dominant capacity",
        content: (props: any) => <TeamDominante {...props} />
    }
};

export type TabIndex = keyof typeof Tabs;

export const ViewTeamResults = ({ action, ids, token, setModal }: any) => {
    const [t] = useTranslation("admin");
    const [tab, setTab] = useState<TabIndex>("carto");
    const [results, setResults] = useState<any>(null);
    const [error, setError] = useState<string | null>(null);

    const callback = (response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                setModal(
                    <>
                        <h3>{t("The PDF file has been successfully downloaded !")}</h3>
                        <ValidDoc />
                    </>
                );
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "soft-skills-team-resultats.pdf");
                document.body.appendChild(link);
                link.click();
                link.remove();
                break;
            case 401:
                setModal(<>ERROR</>);
                break;
        }
    };
    
    const resolveGetResults = (response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                setResults(response.data);
                break;
            case 404:
                setError(t(response.data.message));
                break;
        }
    };

    useEffect(() => {
        if (!results) {
            getResultsTeam({ ids, token }, resolveGetResults);
        }
    }, [results, ids, token]);

    const downloadTeamPDF = () => {
        setModal(<Waiting />);
        GroupedActionsUsers({
            action: "export-team",
            data: { ids },
            token,
            callback
        });
        
    }
    //TODO: J'arrive pas a typer comme il faut pourtant j'ai essayer (Kevin)
    const changeTab = (value: any) => {
        setTab(value);
    };
    return (
        <>
            <div id="ViewTeamResults">
                <div className="download">
                    <Download size={40} onClick={downloadTeamPDF} />
                </div>
                <ul>
                    {Object.keys(Tabs).map((key: string) => {
                        let text = Object.getOwnPropertyDescriptor(Tabs, key)?.value.text;
                        return (
                            <li
                                className={tab === key ? "active" : ""}
                                key={key}
                                onClick={() => {
                                    changeTab(key);
                                }}
                            >
                                {t(text)}
                            </li>
                        );
                    })}
                </ul>
                <div className="tab">
                    <h3>{t(Tabs[tab].text)}</h3>
                    {results && Tabs[tab].content({ ...results })}
                    {error && <p className="error">{error}</p>}
                </div>
            </div>
        </>
    );
};
