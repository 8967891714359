import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface PropsTag {
    value: string;
    color?: "primary" | "secondary" | "success" | "danger" | "warning";
    active?: boolean;
    callback?: Function;
}
export type TagObject = {
    id: number;
    createdAt: Date;
    label: string;
    updatedAt: Date;
};

export default function Tag({ value, color, active, callback }: PropsTag) {
    if (!value) return null;
    if (active) {
        return (
            <div
                className={color ? `btn simply tag ${color}` : "btn simply tag"}
                onClick={() => {
                    callback && callback(value);
                }}
            >
                {value} <FontAwesomeIcon icon={faXmark} />
            </div>
        );
    }
    return <div className={color ? `tag ${color}` : "tag"}>{value}</div>;
}
