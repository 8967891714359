export default function List() {
    return (
        <svg
            width="59"
            height="56"
            viewBox="0 0 59 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.375 30.008H12.2917V25.3914H7.375V30.008ZM7.375 39.2411H12.2917V34.6246H7.375V39.2411ZM7.375 20.7748H12.2917V16.1582H7.375V20.7748ZM17.2083 30.008H51.625V25.3914H17.2083V30.008ZM17.2083 39.2411H51.625V34.6246H17.2083V39.2411ZM17.2083 16.1582V20.7748H51.625V16.1582H17.2083ZM7.375 30.008H12.2917V25.3914H7.375V30.008ZM7.375 39.2411H12.2917V34.6246H7.375V39.2411ZM7.375 20.7748H12.2917V16.1582H7.375V20.7748ZM17.2083 30.008H51.625V25.3914H17.2083V30.008ZM17.2083 39.2411H51.625V34.6246H17.2083V39.2411ZM17.2083 16.1582V20.7748H51.625V16.1582H17.2083Z"
                fill="#BDBDBD"
            />
        </svg>
    );
}
