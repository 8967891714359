import { useCookies } from "react-cookie";
import { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { AxiosResponse } from "axios";

import { Survey } from "../models";

import Btn from "../../Templates/Btn";
import SidePage from "../../Templates/SidePage";
import { RowHeader } from "../../Templates/Dashboard/Row";
import List from "../../Templates/Dashboard/List";

import IconeImportCSV from "../../../assets/ImportCSV";

import NewCrosseye from "./NewCrosseye";

import { GetAllCrosseyeSurvey } from "../../../services/crosseye.service";
import { IconeShowCrosseye } from "./ShowCrosseye";

import { IconeDeleteCrosseye } from "./DeleteCrossEye";
import { useTranslation } from "react-i18next";
import { IconeNotifyUserInCrossEye } from "./NotifyUserInCrossEye";

export default function Crosseye() {
    const [t] = useTranslation("admin");
    const [cookies] = useCookies(["access_token"]);
    const [crosseyes, setCrosseyes] = useState<Array<Survey> | boolean>(false);
    const busy = useRef(false);
    const resolveGetAll = useCallback((response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                const allsurveys = response.data.filter((survey: any) => {
                    survey.crosseyes = survey.crosseyes.filter((crosseye: any) => {
                        return crosseye.user !== null;
                    });
                    return survey.user !== null;
                });
                setCrosseyes(allsurveys);
                break;
            case 401:
            default:
                console.log("ERROR", response);
                break;
        }
        busy.current = false;
    }, []);
    useEffect(() => {
        if (!crosseyes && !busy.current) {
            GetAllCrosseyeSurvey({ token: cookies.access_token }, resolveGetAll);
            busy.current = true;
        }
    }, [cookies.access_token, crosseyes, resolveGetAll]);

    return (
        <div className="table" id="Crosseye">
            <RowHeader
                {...{
                    cells: {
                        // id: { text: "Id", active: false },
                        "user-lastName": {
                            text: t("Last name"),
                            active: false
                        },
                        "user-firstName": { text: t("First name"), active: false },
                        "user-email": { text: t("Email"), active: false },
                        "user-company": { text: t("Company"), active: false },
                        "crosseye-count-valid": {
                            text: t("Cross eye done"),
                            active: false
                        },
                        "crosseye-date-start": {
                            text: t("Cross eye start date"),
                            active: false
                        }
                    },
                    callback: console.log,
                    filters: false
                }}
            />
            {typeof crosseyes === "object" && (
                <List
                    datas={crosseyes}
                    rowOptions={rowOptions}
                    getDatas={() => setCrosseyes(false)}
                />
            )}
        </div>
    );
}
export const rowOptions = {
    "user-lastName": true,
    "user-firstName": true,
    "user-email": true,
    "user-company": true,
    "crosseye-count-valid": true,
    "crosseye-date-start": true,
    "tools": {
        view: (props: any) => <IconeShowCrosseye {...props} />,
        mail: (props: any) => <IconeNotifyUserInCrossEye {...props} />, 
        delete: (props: any) => <IconeDeleteCrosseye {...props.data} />
    }
};
export const Tools = () => {
    const [t, i18n] = useTranslation("admin");
    const [sidePage, setSidePage] = useState<ReactElement | false>(false);
    return (
        <>
            <SidePage content={sidePage} setContent={setSidePage} />
            <Btn
                className="admin"
                callback={() => {
                    setSidePage(<NewCrosseye closeModal={setSidePage} />);
                }}
            >
                <IconeImportCSV />
                {t("New Cross Eye")}
            </Btn>
        </>
    );
};
