import axios from "axios";

interface DataGetAllUsers {
    token: string;
    userId: string;
}
export const GetAllTokensByUserId = async (data: DataGetAllUsers, callback: Function) => {
    const { token } = data;
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .get(`/api/token/${data.userId}`)
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};

interface DataUpdateToken {
    token: string;
    tokenId: number;
    userId: string;
}
export const AddTokenToUser = async (data: DataUpdateToken, callback: Function) => {
    const { token, tokenId, userId } = data;
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .put(`/api/token/touser/${tokenId}`, { userId })
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};

// interface DataGetToken {
//     tokenId: number;
//     token: string;
// }
// export const GetTokenById = async (data: DataGetToken, callback: Function) => {
//     const { token } = data;
//     axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
//     axios
//         .get(`/api/token/${data.tokenId}`)
//         .then((response) => {
//             callback(response);
//         })
//         .catch(({ response }) => {
//             callback(response);
//         });
// }

// interface DataCreateToken {
//     token: string;
//     userId: string;
//     tokenName: string;
//     tokenValue: string;
//     tokenType: string;
//     tokenDescription: string;
//     tokenExpirationDate: string;
// }
// export const CreateToken = async (data: DataCreateToken, callback: Function) => {
//     const { token } = data;
//     axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
//     axios
//         .post(`/api/token`, data)
//         .then((response) => {
//             callback(response);
//         })
//         .catch(({ response }) => {
//             callback(response);
//         });
// }

// interface DataDeleteToken {
//     token: string;
//     tokenId: number;
// }
// export const DeleteToken = async (data: DataDeleteToken, callback: Function) => {
//     const { token } = data;
//     axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
//     axios
//         .delete(`/api/token/${data.tokenId}`)
//         .then((response) => {
//             callback(response);
//         })
//         .catch(({ response }) => {
//             callback(response);
//         });
// }
