import { AxiosResponse } from "axios";
import { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import Eye from "../../../assets/Eye";
import { getSurveys } from "../../../services/user.service";
import { formatDate, formatDateSince } from "../../../utils";
import SurveyTabs from "../../Survey/Results/SurveyTabs";
import Collapse from "../../Templates/Collapse";
import List from "../../Templates/Dashboard/List";
import { RowHeader } from "../../Templates/Dashboard/Row";
import SidePage from "../../Templates/SidePage";
import Waiting from "../../Templates/Waiting";
import { GetPDF } from "../../../services/survey.service";
import Download from "../../../assets/Download";
import Modal from "../../Templates/Modal";

type SurveyHistoryTableProps = {
    userId: number;
    onClickViewResult: (surveyId: number) => void;
};

function SurveyHistoryTable({ userId, onClickViewResult }: SurveyHistoryTableProps) {
    const [t] = useTranslation("user");
    const [cookies] = useCookies<
        "access_token" | "id",
        { access_token: string; id: string }
    >(["access_token", "id"]);
    const [surveys, setSurveys] = useState<Array<any> | false>(false);
    const [lastSurvey, setLastSurvey] = useState<Array<any> | false>(false);
    const busy = useRef<boolean>(false);

    const resolveGettingSurveys = useCallback(
        (response: AxiosResponse) => {
            switch (response.status) {
                case 200:
                case 304:
                    const allSurveys = surveysParser(response.data, userId.toString());
                    setLastSurvey(allSurveys.filter((survey: any) => !survey.finished));
                    setSurveys(allSurveys.filter((survey: any) => survey.finished));
                    break;
            }
            busy.current = false;
        },
        [userId]
    );

    useEffect(() => {
        if (!surveys && !busy.current) {
            getSurveys(userId, cookies.access_token, resolveGettingSurveys);
            busy.current = true;
        }
    }, [cookies.access_token, resolveGettingSurveys, surveys, userId]);

    const pendingSurveysRowOptions = {
        "mysurvey-version": true,
        "type-survey": true,
        "progress": true,
        "part": true
    };

    const doneSurveyRowOptions = {
        "mysurvey-version": true,
        "type-survey": true,
        "admin-user-scores": true,
        "end": true,
        "tools": {
            view: (props: any) => {
                // if survey is not finished (example: evaluator has finished but not the rated)
                if (!props.data.end) return <Eye className={`tool inactive`} size={24} />;

                return (
                    <Eye
                        className={`tool`}
                        size={24}
                        onClick={() => onClickViewResult(props.data.id)}
                    />
                );
            },
            download: (props: any) => <DownloadResult {...props} />
        }
    };

    if (!surveys) return <Waiting />;
    return (
        <div id="SurveyHistoryTable">
            {/* User pending surveys */}
            <Collapse label={<h5>{t("Tests in progress")}</h5>} active={true}>
                <div className="table">
                    {lastSurvey && lastSurvey.length === 0 ? (
                        <h5>{t("No test")}</h5>
                    ) : (
                        <>
                            <RowHeader
                                {...{
                                    cells: {
                                        "mysurvey-version": {
                                            text: t("Version"),
                                            active: false
                                        },
                                        "type-survey": {
                                            text: t("Type"),
                                            active: false
                                        },
                                        "progress": {
                                            text: t("Started"),
                                            active: false
                                        },
                                        "part": {
                                            text: t("Progress"),
                                            active: false
                                        }
                                        // "reset": true
                                    },
                                    callback: () => {},
                                    filters: {},
                                    group: false
                                }}
                            />
                            <List
                                datas={lastSurvey}
                                rowOptions={pendingSurveysRowOptions}
                                getDatas={() => {
                                    if (!busy.current) {
                                        getSurveys(
                                            userId,
                                            cookies.access_token,
                                            resolveGettingSurveys
                                        );
                                    }
                                }}
                                group={false}
                            />
                        </>
                    )}
                </div>
            </Collapse>

            {/* User surveys history */}
            <Collapse label={<h5>{t("Test history")}</h5>} active={true}>
                <div className="table">
                    {surveys.length === 0 ? (
                        <h5>{t("No test")}</h5>
                    ) : (
                        <>
                            <RowHeader
                                {...{
                                    cells: {
                                        "mysurvey-version": {
                                            text: t("Version"),
                                            active: false
                                        },
                                        "type-survey": {
                                            text: t("Type"),
                                            active: false
                                        },
                                        "admin-user-scores": {
                                            text: t("Scores"),
                                            active: false
                                        },
                                        "end": {
                                            text: t("Finished"),
                                            active: false
                                        }
                                    },
                                    callback: () => {},
                                    filters: {},
                                    group: false
                                }}
                            />
                            <List
                                datas={surveys}
                                rowOptions={doneSurveyRowOptions}
                                getDatas={() => {
                                    if (!busy.current) {
                                        getSurveys(
                                            userId,
                                            cookies.access_token,
                                            resolveGettingSurveys
                                        );
                                    }
                                }}
                                group={false}
                            />
                        </>
                    )}
                </div>
            </Collapse>
        </div>
    );
}

// NOTE : Reuse of sanitizer() from MySurveys component
const surveysParser = (datas: any[], userId: string) => {
    return datas.map(({ survey, scores, evaluatedScores }) => {
        const user = { ...survey.user, ...survey.user.profile };
        let finished = () => {
            if (survey.type === "normal") {
                if (survey.end !== null) return true;
            }
            if (survey.type === "crosseye") {
                if (survey.userId === parseInt(userId) && survey.end) return true;
                const isMySurveyEnded = survey.crosseyes.filter(
                    (crosseye: any) =>
                        crosseye.end && crosseye.userId === parseInt(userId)
                ).length;
                if (isMySurveyEnded) return true;
            }
            return false;
        };
        return {
            id: survey.id,
            type: survey.type,
            version: survey.template.version,
            end: survey.end && formatDate(new Date(survey.end), true),
            start: survey.start && formatDateSince(survey.start),
            scores: scores && scores,
            evaluatedScores: evaluatedScores && evaluatedScores,
            countAnswers: survey.results.length,
            crosseyes: survey.crosseyes && survey.crosseyes,
            user,
            finished: finished()
        };
    });
};

const DownloadResult = ({ data }: any) => {
    const [cookies] = useCookies<
        "firstName" | "access_token" | "id",
        { firstName: string; access_token: string; id: string }
    >(["firstName", "access_token", "id"]);
    const [modal, setModal] = useState<ReactElement | false>(false);
    const { t } = useTranslation("error");

    const surveyId: number = data.id;
    const downloadPDF = () => {
        GetPDF({ token: cookies.access_token, id: surveyId }, resolveDownload);
    };
    const resolveDownload = useCallback((response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(new Blob([response.data]));
                link.setAttribute("download", "soft-skills-test-resultats.pdf");
                document.body.appendChild(link);
                link.click();
                link.remove();
                break;
            default:
                setModal(
                    <>
                        <p>{t("Error while downloading the PDF")}</p>
                    </>
                );
                break;
        }
    }, []);

    return (
        <>
            <Modal content={modal} setContent={setModal} />
            {(data.type === "crosseye" || data.type === "normal") && data.finished && (
                <Download className={`tool`} size={24} onClick={downloadPDF} />
            )}
        </>
    );
};

export default function SurveyHistory(props: any) {
    const [t] = useTranslation("admin");
    const user = props.data;

    useEffect(() => {
        setSidepage(false);
    }, [props]);
    const [sidepage, setSidepage] = useState<ReactElement | false>(false);

    return (
        <div id="SurveyHistory">
            <SidePage
                content={sidepage}
                setContent={setSidepage}
                reloadListOnClose={false}
            />
            <h3>
                {t("The result of")} {user.firstName} {user.lastName}
            </h3>
            <fieldset>
                <SurveyHistoryTable
                    userId={user.id}
                    onClickViewResult={(surveyId: number) => {
                        setSidepage(<SurveyTabs id={surveyId} activeDL={true} />);
                    }}
                />
            </fieldset>
        </div>
    );
}
