import { useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import Admin from "../../../assets/Admin";
import Check from "../../../assets/Check";
import Superuser from "../../../assets/Superuser";
import { formatDate, formatDateSince } from "../../../utils";
import Checkbox from "../Checkbox";
import Tag, { TagObject } from "../Tag";
import Scores from "./Scores";
import Tools from "./Tools";
import MultiScores, { ScoreInfo } from "./MultiScores";
export default function Cell({ data, type, value, nRow, active }: PropsCell) {
    const [t, i18n] = useTranslation("common");

    const [lang, setLang] = useState(i18n.language);
    const [cookies] = useCookies(["id"]);

    i18n.on("languageChanged", () => {
        if (i18n.language !== lang) {
            setLang(i18n.language);
        }
    });

    switch (type) {
        case "id":
            return <div className={`cell ${type}`}>{data.id}</div>;
        case "user-scores":
            const currentUserIsCrosseyeEvaluator =
                data.type === "crosseye" &&
                data.crosseyes.find(
                    (crossEvaluation: any) => crossEvaluation.userId === +cookies.id
                );
            if (data.scores && !currentUserIsCrosseyeEvaluator) {
                return (
                    <div className={`cell ${type}`}>
                        <Scores scores={data.scores} />
                    </div>
                );
            } else {
                return <div className={`cell ${type}`}> </div>;
            }
        case "admin-user-scores":
            if (data.scores) {
                if (data.type === "crosseye") {
                    const scoresInfo: ScoreInfo[] = [];
                    if (data.scores && data.scores.length > 0) {
                        scoresInfo.push({
                            prefix: t("Evaluators", { ns: "user" }),
                            scores: data.scores
                        });
                    }
                    if (data.evaluatedScores && data.evaluatedScores.length > 0) {
                        scoresInfo.push({
                            prefix: t("Evalueted", { ns: "user" }),
                            scores: data.evaluatedScores
                        });
                    }

                    return (
                        <div className={`cell ${type}`}>
                            <MultiScores scoresInfo={scoresInfo} />
                        </div>
                    );
                } else
                    return (
                        <div className={`cell ${type}`}>
                            <MultiScores
                                scoresInfo={[{ prefix: "", scores: data.scores }]}
                            />
                        </div>
                    );
            } else {
                return <div className={`cell ${type}`}> </div>;
            }
        case "type-survey":
            return (
                <div className={`cell ${type}`}>
                    {data.type === "normal" ? (
                        t("Individual")
                    ) : (
                        <>
                            {t("CrossEye")}{" "}
                            {+cookies.id === data.user.id
                                ? t("You")
                                : data.user.firstName
                                ? data.user.firstName
                                : data.user.email}
                        </>
                    )}
                </div>
            );

        case "progress":
            return (
                <div className={`cell ${type}`}>
                    {/* {data.start ? (
                        <>
                            {t("Started")} {data.start.value} {t(data.start.text)}
                        </>
                    ) : ( */}
                    <>{t("Pending")}</>
                    {/* )} */}
                </div>
            );
        case "part":
            let countFinishedCross = data.crosseyes.filter(
                (cross: any) => cross.end
            ).length;
            return (
                <div className={`cell ${type}`}>
                    {data.type === "normal" ? (
                        <>
                            {data.countAnswers > 0 && (
                                <>Pages : {Math.floor(data.countAnswers / 8)}/ 10</>
                            )}
                        </>
                    ) : (
                        <>
                            {countFinishedCross > 0 && (
                                <>
                                    Participant : {countFinishedCross}/
                                    {data.crosseyes.length}
                                </>
                            )}
                        </>
                    )}
                </div>
            );
        case "tags":
            return (
                <div className={`cell ${type}`}>
                    {value.map((tag: TagObject, index: number) => {
                        return <Tag key={`tag-${index}`} value={tag.label} />;
                    })}
                </div>
            );
        case "capacity-name":
            return (
                <div className={`cell ${type}`}>
                    {t(`capacity:${data.capacity.id}-name`)}
                </div>
            );
        case "psychology-name":
            return (
                <div className={`cell ${type}`}>
                    {t(`psychology:${data.psychology.id}-name`)}
                </div>
            );
        case "answers-value":
            const questionValue = data.label.find((label: any) => label.lang === lang);

            return (
                <div className={`cell ${type}`}>
                    {questionValue ? questionValue.value : data.label[0].value}
                </div>
            );
        case "answers-result":
            return <div className={`cell ${type}`}>{data.value}</div>;
        case "answers-crosseye":
            const questionCrosseye = data.label.find((label: any) => label.lang === lang);
            return (
                <div className={`cell ${type}`}>
                    {questionCrosseye
                        ? questionCrosseye.crosseyes
                        : data.label[0].crosseyes}
                </div>
            );
        case "crosseye-start":
            let crosseyeStart = data.start;
            return (
                <div className={`cell ${type}`}>
                    {crosseyeStart && formatDate(new Date(crosseyeStart))}
                    {crosseyeStart === null && t("Pending")}
                </div>
            );
        case "crosseye-state":
            let crosseyeState = data.end;
            return (
                <div className={`cell ${type}`}>
                    {crosseyeState ? <Check /> : t("Pending")}
                </div>
            );
        case "crosseye-select-valued":
            return (
                <div className={`cell ${type}`}>
                    <Checkbox
                        type={"checkbox"}
                        value={`select-valued-${data.id}`}
                        name={`select-valued`}
                    />
                </div>
            );
        case "crosseye-select-tester":
            return (
                <div className={`cell ${type}`}>
                    <Checkbox
                        type={"checkbox"}
                        value={`select-tester-${data.id}`}
                        name="select-tester"
                    />
                </div>
            );
        case "surveys-date":
            let dateSurvey = data.surveys[0]?.end;
            return (
                <div className={`cell ${type}`}>
                    {dateSurvey && formatDate(new Date(dateSurvey))}
                    {dateSurvey === null && t("Pending")}
                </div>
            );
        case "surveys-version":
            let version = data.surveys[0]?.template.version;
            return (
                <div className={`cell ${type}`}>
                    <Tag
                        value={version ? version : false}
                        color={
                            data.surveys[0]?.type === "crosseye" ? "success" : "secondary"
                        }
                    />
                </div>
            );
        case "translate":
            return (
                <div className={`cell ${type}`}>
                    {value.map((lang: string) => {
                        return <div className="cell-content">{lang}</div>;
                    })}
                </div>
            );
        case "role":
            return (
                <div className={`cell ${type}`}>
                    {parseInt(value) === 0 && <Superuser />}
                    {parseInt(value) === 1 && <Admin />}
                </div>
            );
        case "tools":
        case "surveys-history-tools":
            return (
                <div className={`cell ${type}`}>
                    {Object.keys(active).map((type) => {
                        return (
                            <Tools
                                key={nRow + type}
                                type={type}
                                Icone={active[type]}
                                data={data}
                            />
                        );
                    })}
                </div>
            );

        case "user-email":
            return <div className={`cell ${type}`}>{data.user.email}</div>;
        case "user-lastName":
            return (
                <div className={`cell ${type}`}>
                    {data.user.profile && data.user.profile.lastName}
                </div>
            );
        case "user-firstName":
            return (
                <div className={`cell ${type}`}>
                    {data.user.profile && data.user.profile.firstName}
                </div>
            );
        case "user-company":
            return <div className={`cell ${type}`}>{data.user.profile.company}</div>;

        case "crosseye-count-valid":
            const countValid = data.crosseyes.filter((crosseye: any) => {
                return crosseye.start;
            }).length;

            return (
                <div className={`cell ${type}`}>
                    <span className="color VividCerulean">{countValid}</span> /{" "}
                    {data.crosseyes.length}
                </div>
            );

        case "crosseye-date-start":
            const dateCrosseye = data.crosseyes.find((crosseye: any) => {
                return crosseye.start !== null;
            })?.start;
            return (
                <div className={`cell ${type}`}>
                    {dateCrosseye && formatDate(new Date(dateCrosseye))}
                    {!dateCrosseye && t("Pending")}
                </div>
            );
        case "white-label-name":
            return <div className={`cell ${type}`}>{data.name}</div>;
        case "white-label-admin":
            return <div className={`cell ${type}`}>{data.user.email}</div>;
        case "white-label-url":
            const urlName = "https://" + data.subDomain + ".soft-skills-test.com";
            return (
                <a href={urlName} className={`cell ${type}`}>
                    {urlName}
                </a>
            );
        case "psychology":
            return <div className={`cell ${type}`}>{data.psychology.name}</div>;

        case "expiration":
        case "creation":
            return (
                <div className={`cell ${type}`}>
                    {data[type] && formatDate(new Date(data[type]))}
                </div>
            );
        case "token-survey-version":
            return (
                <div className={`cell ${type}`}>
                    {data.survey && (
                        <Tag
                            value={data.survey.template.version}
                            color={
                                data.survey.type === "crosseye" ? "success" : "secondary"
                            }
                        />
                    )}
                    {/*  */}
                </div>
            );
        case "token-survey-user":
            return (
                <div className={`cell ${type}`}>
                    {data.survey?.user
                        ? `${data.survey.user.profile.firstName} ${data.survey.user.profile.lastName}`
                        : data.used && !data.survey
                        ? t("SurveyDeleted", { ns: "admin" })
                        : ""}
                </div>
            );
        default:
            return <div className={`cell ${type}`}>{t(value)}</div>;
    }
}
interface PropsCell {
    data: any;
    nRow: number;
    active: any;
    type: string;
    value: string | any;
}
