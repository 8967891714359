import { useEffect } from "react";
import { UserAdminRowOptions } from "../../Admin/Users";
import { Row } from "./Row";

interface PropsList {
    datas: Array<any> | false;
    rowOptions: Object | UserAdminRowOptions;
    getDatas: Function;
    displayField?: string;
    group?: Function | false;
}

export default function List({
    datas,
    rowOptions,
    getDatas,
    displayField,
    group = false
}: PropsList) {
    useEffect(() => {
        const UpdateList = () => {
            getDatas();
        };

        const list = document.querySelector<HTMLElement>(".table .list");
        if (list === null) return;
        list.addEventListener("reloadList", UpdateList);
        return () => {
            const list = document.querySelector<HTMLElement>(".table .list");
            if (list) list.removeEventListener("reloadList", UpdateList);
        };
    }, [getDatas]);
    return (
        <div className="list">
            {datas &&
                datas.map((data, index) => {
                    return (
                        <Row
                            key={index}
                            data={data}
                            rowOptions={rowOptions}
                            nRow={index}
                            displayField={displayField}
                            group={group}
                        />
                    );
                })}
        </div>
    );
}
interface PropsReloadList {
    checkbox?: boolean;
}
export const reloadList = ({ checkbox }: PropsReloadList = {}) => {
    setTimeout(() => {
        const list = document.querySelector<HTMLElement>(".table .list");
        const event = new Event("reloadList");
        if (list) {
            list.dispatchEvent(event);
        }
        if (checkbox) {
            const allCheck = document.querySelector<HTMLInputElement>("[name^=all]");
            if (allCheck) allCheck.checked = false;
            const checkboxes =
                document.querySelectorAll<HTMLInputElement>("[name^=group]");
            checkboxes.forEach((checkbox) => {
                checkbox.checked = false;
            });
        }
    }, 500);
};
