interface PropsCapacity {
    purcent: number;
    title: string;
    color: string;
    data?: any;
    crosseye?: boolean;
    survey?: any;
}

export default function Capacity({
    crosseye = false,
    survey = false,
    purcent,
    title,
    color,
    data
}: PropsCapacity) {
    const { userCapacities } = data;
    let evaluedUser;
    if (crosseye) {
        evaluedUser = userCapacities.find(
            ({ capacity }: any) => capacity.id === data.capacity.id
        );
    }
    const user = { ...survey.user, ...survey.user.profile };
    return (
        <div className={`capacity ${color}`}>
            <div className="title">{title}</div>
            <div className={`capacityBar ${!crosseye ? "normal" : "crosseye"}`}>
                <div className="purcent">{purcent}%</div>

                <div className="bar">
                    {crosseye && (
                        <>
                            <div
                                className="circle"
                                style={{ left: `calc(${data.minScore}% - 22px)` }}
                            >{`${data.minScore}%`}</div>
                            <div
                                className="circle"
                                style={{ left: `calc(${data.maxScore}% - 23px)` }}
                            >{`${data.maxScore}%`}</div>
                            {evaluedUser && (
                                <div
                                    className="circle bottom"
                                    style={{
                                        left: `calc(${evaluedUser.purcent}% - 23px)`
                                    }}
                                >
                                    <span>{`${evaluedUser.purcent}%`}</span>
                                     {` ${user.firstName}`} {/*${user.lastName}`} */}
                                </div>
                            )}

                            <div
                                className="value max"
                                style={{ width: `${data.maxScore}%` }}
                            ></div>
                        </>
                    )}

                    <div className="value" style={{ width: `${purcent}%` }}></div>
                    {crosseye && (
                        <>
                            <div
                                className="value min"
                                style={{ width: `${data.minScore}%` }}
                            ></div>
                        </>
                    )}
                </div>
                {/* {data.minScore && <div className="bar max"></div>} */}
            </div>
        </div>
    );
}
