import { PropsSVG } from "./SVG.interface";

export default function FilterUp({ onClick, className }: PropsSVG) {
    return (
        <svg
            className={className}
            width="12"
            height="5"
            viewBox="0 0 12 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
        >
            <path
                d="M6 1.04907e-06L11.1962 4.5L0.803847 4.5L6 1.04907e-06Z"
                fill="#295380"
            />
        </svg>
    );
}
