export const frPurchase = {
    BuyAction: "Acheter",
    WichTestTypeQuestion: "Quel TEST 4i voulez vous acheter ?",
    HowManyQuestion: "Combien de TEST 4i voulez vous acheter ?",
    Individual: "Individuel",
    Crosseye: "Regard croisé",
    withoutTax: "HT",
    totalPriceWithTax: "Prix total TTC",
    totalPriceWithoutTax: "Prix total HT",
    subTotalPriceWithTax: "Prix sous-total TTC"
};
