import { AxiosResponse } from "axios";
import { useCallback, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import ValidGroups from "../../../../assets/ValidGroups";
import { GroupedActionsUsers } from "../../../../services/root.service";
import { findUserAbstract } from "../../../../services/user.service";
import Btn from "../../../Templates/Btn";
import Checkbox from "../../../Templates/Checkbox";
import { reloadList } from "../../../Templates/Dashboard/List";
import Field, { RowFields } from "../../../Templates/Field";
import Waiting from "../../../Templates/Waiting";

export function AddAdmin({ action, ids, token, setModal }: any) {
    const [t] = useTranslation("admin");
    const valuedUser = useRef<HTMLInputElement | null>(null);
    const [selectUser, setSelectUser] = useState<string | null>(null);
    const [listUser, setListUser] = useState<any[]>([]);
    const [cookies] = useCookies(["access_token"]);
    const callback = (response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                reloadList({ checkbox: true });
                setModal(
                    <>
                        <h4>
                            {t("Users have been successfully added to the administrator")}
                        </h4>
                        <ValidGroups />
                    </>
                );
                break;
            case 401:
                setModal(<>ERROR</>);
                reloadList();
                break;
        }
    };
    const resolveFindUser = useCallback(
        (response: AxiosResponse) => {
            switch (response.status) {
                case 200:
                    setListUser([]);
                    const users = response.data;
                    if (users.length > 0) {
                        setListUser(users);
                    }
                    break;
                case 401:
                    setModal(<>ERROR</>);
                    reloadList();
                    break;
            }
        },
        [setModal]
    );

    const handleField = () => {
        if (
            valuedUser.current &&
            valuedUser.current.value &&
            valuedUser.current.value.length > 2
        ) {
            findUserAbstract(
                valuedUser.current.value,
                cookies.access_token,
                resolveFindUser
            );
        } else {
            setListUser([]);
        }
    };

    return (
        <div id="AddAdmin">
            <h4>{t("Whose do you want to assign these users ?")}</h4>
            <fieldset>
                <Field
                    type="text"
                    id="valuedUser"
                    label={t("Administrator(s)")}
                    placeholder={t("search input place holder")}
                    refField={valuedUser}
                    onChange={handleField}
                />
                <ul className="listUsers">
                    {listUser
                        .filter((user) => {
                            return user.role === "1";
                        })
                        .map((user: any, index: number) => (
                            <li
                                key={`${index}-${user.id}`}
                                className={selectUser === user ? "selected" : ""}
                            >
                                <Checkbox
                                    type={"radio"}
                                    value={user.id}
                                    name="companysize"
                                    callback={(value: any) => {
                                        if (value) {
                                            setSelectUser(user.id);
                                        }
                                        if (!value) {
                                            setSelectUser(null);
                                        }
                                    }}
                                />
                                <span>
                                    {user.profile.firstName} {user.profile.lastName}
                                </span>
                                <span>{user.email}</span>
                                <span>{user.profile.company}</span>
                                {/* <span>{user.id}</span> */}
                            </li>
                        ))}
                </ul>
                <RowFields>
                    <Btn
                        children={t("Cancel", { ns: "common" })}
                        className={"cancel"}
                        callback={() => {
                            setModal(false);
                        }}
                    />
                    <Btn
                        children={t("Validate", { ns: "common" })}
                        className={"active"}
                        callback={() => {
                            if (selectUser) {
                                setModal(<Waiting />);
                                GroupedActionsUsers({
                                    action,
                                    data: { ids, selectUser },
                                    token,
                                    callback
                                });
                            }
                        }}
                    />
                </RowFields>
            </fieldset>
        </div>
    );
}
