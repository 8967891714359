import { PSYCHOLOGY } from ".";
import { TeamZone } from "./TeamZone";

export const TeamPsychology = ({ results }: any) => {
    let Quarter: { self: any[]; relational: any[]; creative: any[]; situational: any[] } =
        {
            self: [],
            relational: [],
            creative: [],
            situational: []
        };
    results
        .filter(({ dominante }: any) => dominante)
        .forEach(({ user, dominante }: any) => {
            let psycology = PSYCHOLOGY.getbyName(dominante.psychology.name);
            if (psycology) {
                let currentQuarter = Object.getOwnPropertyDescriptor(
                    Quarter,
                    psycology.tag
                )?.value;
                currentQuarter.push(user);

                Object.defineProperty(Quarter, psycology.tag, {
                    value: currentQuarter,
                    writable: true
                });
            }
        });
    Quarter.self = [
        <p key={"self"}>
            {Quarter.self.map((user: any) => user.profile.firstName).join(", ")}
        </p>
    ];
    Quarter.relational = [
        <p key={"relational"}>
            {Quarter.relational.map((user: any) => user.profile.firstName).join(", ")}
        </p>
    ];
    Quarter.creative = [
        <p key={"creative"}>
            {Quarter.creative.map((user: any) => user.profile.firstName).join(", ")}
        </p>
    ];
    Quarter.situational = [
        <p key={"situational"}>
            {Quarter.situational.map((user: any) => user.profile.firstName).join(", ")}
        </p>
    ];
    return (
        <>
            <TeamZone Quarter={Quarter} />
        </>
    );
};
