import axios from "axios";

export type SurveyTokenType = "individual" | "crosseye";

type DataGetStripePublicKey = {
    token: string;
};

export const getStripePublicKey = async (
    data: DataGetStripePublicKey,
    callback: Function
) => {
    const { token } = data;
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .get("/api/purchase/public-key")
        .then((response) => {
            callback(response);
        })
        .catch((error) => {
            console.error("Can't get stripe public key", error);
            callback(error.response);
        });
};

type DataCreateSurveyTokenPurchaseIntent = {
    token: string;
    individualQuantity: number;
    crosseyeQuantity: number;
    version: string;
};

export const createSurveyTokenPurchaseIntent = async (
    data: DataCreateSurveyTokenPurchaseIntent,
    callback: Function
) => {
    const { token, ...payload } = data;
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .get<any>("/api/purchase/survey-token-purchase-intent", {
            params: { ...payload }
        })
        .then((response) => {
            callback(response);
        })
        .catch((error) => {
            console.error("Can't create survey token purchase intent", error);
            callback(error.response);
        });
};

type DataGetSurveyTokenPrice = {
    token: string;
    surveyTokenType: SurveyTokenType;
};

export const getSurveyTokenPrice = async (
    data: DataGetSurveyTokenPrice,
    callback: Function
) => {
    const { token, surveyTokenType } = data;
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .get<any>("/api/purchase/survey-token-price", { params: { surveyTokenType } })
        .then((response) => {
            callback(response);
        })
        .catch((error) => {
            console.error("Can't get survey token price", error);
            callback(error.response);
        });
};
