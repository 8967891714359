import { AxiosResponse } from "axios";
import { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { FileUploader } from "react-drag-drop-files";
import { useTranslation } from "react-i18next";

import { findUserAbstract } from "../../../services/user.service";

import Btn from "../../Templates/Btn";
import { reloadList } from "../../Templates/Dashboard/List";
import Field from "../../Templates/Field";
import Waiting from "../../Templates/Waiting";
import DropZone from "../../DropZone";
import { User } from "../models";
import { PostNewWhiteLabel } from "../../../services/whiteLabel.service";
import ValidDoc from "../../../assets/ValidDoc";
import Modal from "../../Templates/Modal";
import { getTradFromDB } from "../../../services/translate.service";
import VersionDropdown from "../../Templates/VersionDropdown";
import { BadRequest } from "../../Error/BadRequest";
import { Role } from "../../Templates/RoleRestrictZone/restrictRule.types";
import { ImageSizeError } from "../../Error/ImageSizeError";

const fileTypes = ["PNG", "JPG", "SVG", "JPEG"];

interface PropsNewWhiteLabel {
    closeModal: Function;
}
export default function NewWhiteLabel({ closeModal }: PropsNewWhiteLabel) {
    const [t, i18n] = useTranslation("admin");
    const [tError, i18nError] = useTranslation("error");
    const [cookies] = useCookies(["access_token"]);

    const [callbackRequest, setCallbackRequest] = useState<ReactElement | false>(false);
    const [activeBtn, setActiveBtn] = useState(false);
    const [file, setFile] = useState<File | boolean>(false);
    const [listUsers, setListUser] = useState<User[]>([]);
    const [adminId, setAdminId] = useState<number>(0);

    const whiteLabelName = useRef<HTMLInputElement | null>(null);
    const administrator = useRef<HTMLInputElement | null>(null);
    const version = useRef<HTMLInputElement | null>(null);

    const verifForm = () => {
        if (
            whiteLabelName.current === null ||
            whiteLabelName.current.value === "" ||
            administrator.current === null ||
            administrator.current.value === "" ||
            file === false ||
            version.current === null
        )
            return false;
        return true;
    };

    const changeForm = () => {
        return setActiveBtn(verifForm());
    };

    useEffect(() => {
        changeForm();
    }, [whiteLabelName, administrator, file]);

    const addFile = (file: File) => {
        setFile(file);
    };

    const removeFile = () => {
        setFile(false);
    };

    const handleForm = () => {
        if (!activeBtn) return false;

        if (verifForm()) {
            setCallbackRequest(
                <>
                    <Waiting />
                </>
            );
            const dataWhiteLabel = {
                whiteLabelName: whiteLabelName.current!.value,
                adminId,
                version: version.current!.value,
                file,
                token: cookies.access_token
            };
            PostNewWhiteLabel(dataWhiteLabel, resolvePost);
        }
    };

    const resolvePost = (response: AxiosResponse) => {
        switch (response.status) {
            case 201:
                getTradFromDB();
                setCallbackRequest(
                    <>
                        <h3>{t("Your creation has been made.")}</h3>
                        <ValidDoc
                            onClick={() => {
                                closeModal(false);
                            }}
                        />
                        <Btn className="active" callback={() => closeModal(false)}>
                            {t("Go back")}
                        </Btn>
                    </>
                );
                break;
            case 413:
                setCallbackRequest(
                    <ImageSizeError errorMessage={tError("Size error")} />
                );
                break;
            default:
                setCallbackRequest(
                    <BadRequest errorMessage={response.data.message ?? ""} />
                );
                break;
        }
        reloadList();
    };

    const resolveFindUser = useCallback((response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                setListUser([]);
                const users = response.data;
                const usersRootOrAdmin = users.filter(
                    (user: User) => +user.role !== +Role.user
                );

                if (usersRootOrAdmin.length > 0) {
                    setListUser(usersRootOrAdmin);
                }
                break;
            default:
                reloadList();
                break;
        }
    }, []);
    const handleAdmin = () => {
        if (
            administrator.current &&
            administrator.current.value &&
            administrator.current.value.length > 2
        ) {
            findUserAbstract(
                administrator.current.value,
                cookies.access_token,
                resolveFindUser
            );
        } else {
            setListUser([]);
        }
    };

    const handleSelect = (user: User) => {
        if (administrator.current) {
            administrator.current.value = user.email;
        }
        setAdminId(user.id);
        setListUser([]);
    };

    return (
        <div id="NewWhiteLabel">
            <Modal content={callbackRequest} setContent={setCallbackRequest} />
            <h3>{t("New white label")}</h3>
            <fieldset>
                <Field
                    type="text"
                    id="whiteLabelName"
                    label={t("White label name")}
                    placeholder="Act4 Talents"
                    refField={whiteLabelName}
                />
            </fieldset>
            <fieldset>
                <div id="listUser">
                    <Field
                        type="text"
                        id="Administrator"
                        label={t("Administrator")}
                        placeholder={t("Find user by email")}
                        refField={administrator}
                        onChange={handleAdmin}
                    />
                    <div className="list">
                        {listUsers.map((user: User) => {
                            return (
                                <div
                                    className="choice"
                                    id={String(user.id)}
                                    key={user.id}
                                    onClick={() => {
                                        handleSelect(user);
                                    }}
                                >
                                    {user.email}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <label>{t("Select version")}</label>
                <VersionDropdown
                    refInput={version}
                    callback={changeForm}
                    defaultValue="1.0.0"
                />
            </fieldset>
            <fieldset>
                <label>{t("White label logo")}</label>
                {!file ? (
                    <FileUploader
                        handleChange={addFile}
                        name="file"
                        types={fileTypes}
                        children={<DropZone file={file} removeFile={removeFile} />}
                    />
                ) : (
                    <DropZone file={file} removeFile={removeFile} />
                )}
                <h6>{t("White label file types")}</h6>
            </fieldset>
            <Btn className={activeBtn ? "active" : "disabled"} callback={handleForm}>
                {t("Create this white label")}
            </Btn>
        </div>
    );
}
