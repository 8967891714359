import { ReactElement, useEffect, useRef, useState } from "react";
import Field, { RowFields } from "../Templates/Field";
import Check from "../../assets/Check";
import Btn from "../Templates/Btn";
import { DatasDropdown } from "../Admin/Users/DatasDropdown";
import Dropdown from "../Templates/Dropdown";
import { useCookies } from "react-cookie";
import Checkbox from "../Templates/Checkbox";
import { getMyProfile, putMyProfile } from "../../services/profil.service";
import Waiting from "../Templates/Waiting";
import Modal from "../Templates/Modal";
import UserValid from "../../assets/UserValid";
import { useTranslation } from "react-i18next";
import {
    setAllUserDataCookie,
    UserDataInCookieAttributs
} from "../../utils/userDataCookie";
import { useNavigate, useLocation } from "react-router-dom";

export default function EditProfil() {
    const [t, i18n] = useTranslation("user");
    const navigate = useNavigate();
    const locationState = useLocation().state as { firstLogin: boolean };
    const isFirstLogin: boolean = locationState ? locationState.firstLogin : false;
    const [cookies, setCookie] = useCookies<UserDataInCookieAttributs>([
        "access_token",
        "id",
        // "email",
        // "role",
        "firstName",
        "lastName"
        // "inProgress",
    ]);
    const [profile, setProfile] = useState<any | false>(false);
    const [firstLogin, setFirstLogin] = useState<boolean>(false);
    const [surveyCount, setSurveyCount] = useState<number>(0);
    const [activeDistrict, setActiveDistrict] = useState<Boolean>(false);

    const id = useRef<HTMLInputElement | null>(null);
    const firstName = useRef<HTMLInputElement | null>(null);
    const lastName = useRef<HTMLInputElement | null>(null);
    const age = useRef<HTMLInputElement | null>(null);
    const gender = useRef<HTMLInputElement | null>(null);
    const studies = useRef<HTMLInputElement | null>(null);
    // const nationality = useRef<HTMLInputElement | null>(null);

    const grade = useRef<HTMLInputElement | null>(null);
    const service = useRef<HTMLInputElement | null>(null);
    const company = useRef<HTMLInputElement | null>(null);
    const secteur = useRef<HTMLInputElement | null>(null);
    const country = useRef<HTMLInputElement | null>(null);
    const district = useRef<HTMLInputElement | null>(null);

    const [companySize, setCompanySize] = useState<false | string>(false);
    const [seniority, setSeniority] = useState<false | string>(false);

    const [activeBtn, setActiveBtn] = useState<Boolean>(false);

    const [modal, setModal] = useState<ReactElement | false>(false);

    useEffect(() => {
        const resolveGet = (response: any) => {
            setProfile(response.data.profile);
            setFirstLogin(response.data.firstLogin);
            setSurveyCount(response.data.surveyCount);
            if (isFirstLogin)
                setModal(
                    <>
                        <h3> {t("Welcome modal")} </h3>
                        <UserValid />
                    </>
                );
        };
        if (!profile) {
            getMyProfile({ token: cookies.access_token, id: cookies.id }, resolveGet);
        }
    });

    const changeForm = () => {
        return setActiveBtn(verifForm());
    };
    const verifForm = () => {
        return true;
        // const verifEmail = String(email.current?.value)
        //   .toLowerCase()
        //   .match(
        //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        //   );
        // if (verifEmail) return true;
        // return false;
    };

    useEffect(changeForm, []); // Check form contente at start

    const handleForm = () => {
        if (!activeBtn) return false;
        if (verifForm()) {
            const formData = {
                token: cookies.access_token,
                userId: cookies.id,
                id: id.current!.value,
                firstName: firstName.current!.value,
                lastName: lastName.current!.value,
                // email: email.current!.value,
                age: age.current!.value,
                // nationality: nationality.current!.value,
                studies: studies.current!.value,
                grade: grade.current!.value,
                service: service.current!.value,
                company: company.current!.value,
                secteur: secteur.current!.value,
                country: country.current!.value,
                district: district.current!.value,
                gender: gender.current!.value,
                companySize: companySize,
                seniority: seniority
            };
            putMyProfile(formData, resolvePut);
            setModal(
                <>
                    <Waiting />
                </>
            );
        }
    };

    const resolvePut = (response: any) => {
        switch (response.status) {
            case 201:
                const firstLoginWithSurveys = !firstLogin;
                setAllUserDataCookie(response.data, setCookie); // Refresh cookie with updated data

                setModal(
                    <>
                        <h3> {t("Your profile has been modified")} </h3>
                        <UserValid />
                        {firstLoginWithSurveys && (
                            <h3> {t("You will be redirected to your surveys")} </h3>
                        )}
                    </>
                );
                setActiveBtn(false);
                setProfile(response.data);
                setFirstLogin(false);

                if (firstLoginWithSurveys)
                    setTimeout(() => navigate("/user/survey"), 2 * 1000);

                break;

            default:
                setModal(
                    <>
                        <h3>{t("An error has occurred")}</h3>
                    </>
                );
                break;
        }
    };
    if (!profile) return <Waiting />;
    return (
        <div className="EditProfil">
            <Modal content={modal} setContent={setModal} />
            <fieldset className="perso">
                <h5>{t("Personal data")}</h5>
                <Field
                    type="hidden"
                    id="userId"
                    refField={id}
                    placeholder={"999"}
                    callback={undefined}
                    defaultValue={cookies.id}
                />
                <RowFields>
                    <Field
                        type="text"
                        id="firstName"
                        label={t("First name")}
                        placeholder="John"
                        refField={firstName}
                        callback={changeForm}
                        defaultValue={profile.firstName}
                    />
                    <Field
                        type="text"
                        id="lastName"
                        label={t("Last name")}
                        placeholder="Doe"
                        refField={lastName}
                        callback={changeForm}
                        defaultValue={profile.lastName}
                    />
                </RowFields>

                {/* <Field
          type="text"
          id="email"
          label="Email *"
          placeholder="johnDoe@email.com"
          refField={email}
          callback={changeForm}
          defaultValue={cookies.email}
        /> */}
                <RowFields>
                    <Dropdown
                        label={t("Age")}
                        refInput={age}
                        values={DatasDropdown.age}
                        callback={changeForm}
                        defaultValue={profile.age}
                    />
                    <Dropdown
                        label={t("Gender")}
                        refInput={gender}
                        values={DatasDropdown.gender}
                        callback={changeForm}
                        defaultValue={profile.gender}
                    />
                </RowFields>
                <RowFields>
                    <Dropdown
                        label={t("Studies")}
                        refInput={studies}
                        values={DatasDropdown.studies}
                        callback={changeForm}
                        defaultValue={profile.studies}
                    />

                    {/* <Dropdown
            label={"Nationalité"}
            refInput={nationality}
            values={DatasDropdown.nationality}
            callback={changeForm}
            defaultValue={profile.nationality}
          /> */}
                </RowFields>
            </fieldset>

            <fieldset className="pro">
                <h5>{t("Professional data")}</h5>
                <RowFields>
                    <Dropdown
                        label={t("Grade")}
                        refInput={grade}
                        values={DatasDropdown.grade}
                        callback={changeForm}
                        defaultValue={profile.grade}
                    />
                    <Dropdown
                        label={t("Service")}
                        refInput={service}
                        values={DatasDropdown.service}
                        callback={changeForm}
                        defaultValue={profile.service}
                    />
                </RowFields>
                <RowFields>
                    <Field
                        type="text"
                        id="company"
                        label={t("Company")}
                        placeholder="ACT4 TALENTS"
                        refField={company}
                        callback={changeForm}
                        defaultValue={profile.company}
                    />
                    <Dropdown
                        label={t("Sector")}
                        refInput={secteur}
                        values={DatasDropdown.secteur}
                        callback={changeForm}
                        defaultValue={profile.secteur}
                    />
                </RowFields>
                <RowFields label={t("Seniority")}>
                    <Checkbox
                        type={"radio"}
                        value={"under1seniority"}
                        name="seniority"
                        callback={(value: string) => {
                            setSeniority(value);
                            changeForm();
                        }}
                        defaultValue={profile.seniority}
                    >
                        {t("under1seniority")}
                    </Checkbox>
                    <Checkbox
                        type={"radio"}
                        value={"under3seniority"}
                        name="seniority"
                        callback={(value: string) => {
                            setSeniority(value);
                            changeForm();
                        }}
                        defaultValue={profile.seniority}
                    >
                        {t("under3seniority")}
                    </Checkbox>
                    <Checkbox
                        type={"radio"}
                        value={"under6seniority"}
                        name="seniority"
                        callback={(value: string) => {
                            setSeniority(value);
                            changeForm();
                        }}
                        defaultValue={profile.seniority}
                    >
                        {t("under6seniority")}
                    </Checkbox>
                    <Checkbox
                        type={"radio"}
                        value={"under10seniority"}
                        name="seniority"
                        callback={(value: string) => {
                            setSeniority(value);
                            changeForm();
                        }}
                        defaultValue={profile.seniority}
                    >
                        {t("under10seniority")}
                    </Checkbox>
                    <Checkbox
                        type={"radio"}
                        value={"more10seniority"}
                        name="seniority"
                        callback={(value: string) => {
                            setSeniority(value);
                            changeForm();
                        }}
                        defaultValue={profile.seniority}
                    >
                        {t("more10seniority")}
                    </Checkbox>
                </RowFields>
                <RowFields>
                    <Dropdown
                        label={t("Country")}
                        refInput={country}
                        values={DatasDropdown.country}
                        defaultValue={profile.country}
                        callback={() => {
                            if (country.current && country.current!.value === "France")
                                setActiveDistrict(true);
                            else setActiveDistrict(false);
                            changeForm();
                        }}
                    />
                    <Field
                        type="text"
                        id="district"
                        label={activeDistrict ? t("Postal code") : t("City")}
                        placeholder={activeDistrict ? "95001" : "Paris"}
                        refField={district}
                        callback={changeForm}
                        defaultValue={profile.district}
                    />
                </RowFields>
            </fieldset>
            <fieldset className="Valid">
                <Btn className={activeBtn ? "active" : "disabled"} callback={handleForm}>
                    <Check /> {t("Validate my profile")}
                </Btn>
            </fieldset>
        </div>
    );
}
