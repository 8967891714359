import { PropsSVG } from "./SVG.interface";

export default function FilterDown({ onClick, className }: PropsSVG) {
    return (
        <svg
            className={className}
            width="12"
            height="5"
            viewBox="0 0 12 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
        >
            <path d="M6 5L0.803849 0.499999L11.1962 0.5L6 5Z" fill="#295380" />
        </svg>
    );
}
