import { divide } from "lodash";
import { ReactNode, useState } from "react";

interface PropsCollapse {
    active: boolean;
    children: ReactNode;
    label: ReactNode;
    calltoAction?: ReactNode;
}

export default function Collapse({
    label,
    children,
    active = false,
    calltoAction = false
}: PropsCollapse) {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className={isOpen ? "Collapse" : "Collapse open"}>
            {label && (
                <div className="label">
                    {active && (
                        <span
                            className="arrow"
                            onClick={() => active && setIsOpen(!isOpen)}
                        >
                            {"‹"}
                        </span>
                    )}
                    {label}
                    {calltoAction ? calltoAction : <div></div>}
                </div>
            )}

            <div className="content">{children}</div>
        </div>
    );
}
