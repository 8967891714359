import { useTranslation } from "react-i18next";
import ValidCSV from "../../../assets/ValidCSV";

type errorProps = {
    errorMessage?: string;
};

export const SuccessDownload = () => {
    const [t, i18n] = useTranslation("admin");
    return (
        <>
            <h3>{t("Success download")}</h3>
            <ValidCSV />
        </>
    );
};
