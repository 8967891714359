import { PropsSVG } from "./SVG.interface";

export default function Return({
    className,
    onClick,
    size = 80,
    color = "#00ACE7"
}: PropsSVG) {
    return (
        <svg
            className={className}
            width={size}
            height={size}
            onClick={onClick}
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M65.0602 15L50.6024 30H61.4458C61.4458 42.4125 51.7229 52.5 39.759 52.5C36.1084 52.5 32.6385 51.5625 29.6386 49.875L24.3614 55.35C28.8072 58.275 34.0843 60 39.759 60C55.7349 60 68.6747 46.575 68.6747 30H79.5181L65.0602 15ZM18.0723 30C18.0723 17.5875 27.7952 7.5 39.759 7.5C43.4096 7.5 46.8795 8.4375 49.8795 10.125L55.1566 4.65C50.7108 1.725 45.4337 0 39.759 0C23.7831 0 10.8434 13.425 10.8434 30H0L14.4578 45L28.9157 30H18.0723Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M62.573 10.9089L22.0911 52.9089L17.0511 48.0511L57.533 6.05109L62.573 10.9089Z"
                fill={color}
            />
        </svg>
    );
}
