import axios from "axios";
interface DataGetResultsTeam {
    token: string;
    ids: string[];
}

export const getResultsTeam = async (data: DataGetResultsTeam, callback: Function) => {
    const { token, ids } = data;
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .get("/api/team", { params: { ids } })
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};
