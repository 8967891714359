import { t } from "i18next";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { PSYCHOLOGY } from ".";
import { TeamZone } from "./TeamZone";

export const TeamCapa = (results: any) => {
    const [t] = useTranslation("admin");
    const sortedCapacity = _.sortBy(results.global.capacity, "purcent")
        .reverse()
        .slice(0, 5);
    sortedCapacity[0].colored = true;
    const Quarter = {
        self: null,
        relational: null,
        creative: null,
        situational: null
    };

    Object.entries(PSYCHOLOGY.def).forEach(([key, psychology]) => {
        let capacities = sortedCapacity.filter((c) => c.psychology === psychology.name);

        capacities = capacities.map((capacity) => {
            return (
                <p
                    style={
                        capacity.colored && {
                            color: psychology.color
                        }
                    }
                    className={capacity.colored ? "colored" : ""}
                    key={capacity.name}
                >
                    {t(`capacity:${capacity.capacityId}-name`)}&nbsp;{capacity.purcent}%
                </p>
            );
        });
        Object.defineProperty(Quarter, psychology.tag, {
            value: capacities,
            writable: true
        });
    });
    return (
        <>
            <TeamZone Quarter={Quarter} />
        </>
    );
};
