import { AxiosResponse } from "axios";
import { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { getPsychologies } from "../../../services/psychology.service";
import { useLocalStorage } from "../../../utils/useLocalStorage";
import List from "../../Templates/Dashboard/List";
import { RowHeader } from "../../Templates/Dashboard/Row";
import VersionDropdown from "../../Templates/VersionDropdown";
import { IconeEditPsychology } from "./EditPsychology";

export default function Psychology() {
    const [t] = useTranslation("admin");
    const [psychologies, setPsychologies] = useState<any[] | null>(null);
    const [cookie] = useCookies(["access_token"]);
    const busy = useRef<boolean>(false);
    const [currentVersion, setCurrentVersion] = useLocalStorage("version", "");
    const refVersion = useRef<HTMLInputElement | null>(null);
    const resolveGet = (response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                const { data } = response;
                const psychologies = data.map((psycho: any) => {
                    return {
                        id: psycho.id,
                        name: `psychology:${psycho.id}-name`,
                        count: psycho.count,
                        description: `psychology:${psycho.id}-description`
                    };
                });
                setPsychologies(psychologies);
                break;

            default:
                console.log(response);
                break;
        }
        busy.current = false;
    };
    useEffect(() => {
        if (!psychologies) {
            getPsychologies(
                { token: cookie.access_token, version: currentVersion },
                resolveGet
            );
            busy.current = true;
        }
    });

    return (
        <div className="table" id="Psychology">
            <h5>{t("Survey version")}</h5>
            <VersionDropdown
                callback={setCurrentVersion}
                refInput={refVersion}
                defaultValue={currentVersion}
            />
            <RowHeader
                {...{
                    cells: {
                        name: { text: t("Psychology"), active: false },
                        count: { text: t("Capacities"), active: false },
                        description: { text: t("Description"), active: false },
                        reset: true
                    },
                    filters: false
                }}
            />

            {psychologies && (
                <List
                    datas={psychologies}
                    rowOptions={rowOptions}
                    getDatas={() =>
                        getPsychologies(
                            { token: cookie.access_token, version: currentVersion },
                            resolveGet
                        )
                    }
                />
            )}
        </div>
    );
}
export const rowOptions = {
    name: true,
    count: true,
    description: true,
    tools: {
        edit: IconeEditPsychology
    }
};
export const Tools = () => {
    return <></>;
};
