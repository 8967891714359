export const enHome = {
    /**
     * Login page
     */
     "Welcome": "Welcome to your TEST",
     "Welcome_question" : "Did you know that there are many intelligences?",
     "Welcome_sentence1" : "We have them all and master them at different levels.",
     "Welcome_sentence2" : "Discover your dominant intelligence(s)!",
    "Forgot password ?": "Forgot password?",

    /**
     * Reset password modal
     */
    "Your request has been taken into account":
        "Your request has been taken into account.",
    "An email has been sent to change your password":
        "An email has been sent to change your password.",

    /**
     * Common login & signup page
     */
    // "Want to discover yours ?": "Want to discover yours?",
    "Enter your login and password": "Enter your login and password",
    "Register": "Register",
    "Login": "Login",
    "Username": "Username",
    "Password": "Password",

    /**
     * Register page
     */
    "RGPD-start":
        "I consent to the collection and use of my data within the framework defined in the ",
    "RGPD-innerLink": "legal notice",
    "RGPD-end": " of ACT4 TALENTS."
};
