import { PropsSVG } from "./SVG.interface";

export default function Smile({
    className,
    onClick,
    size = 80,
    color = "#00ACE7"
}: PropsSVG) {
    return (
        <svg
            className={className}
            width={size}
            height={size}
            onClick={onClick}
            viewBox="0 0 65 65"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M56.5 32.5C56.5 45.4787 45.9787 56 33 56C20.0213 56 9.5 45.4787 9.5 32.5C9.5 19.5213 20.0213 9 33 9C45.9787 9 56.5 19.5213 56.5 32.5ZM63 32.5C63 49.0685 49.5685 62.5 33 62.5C16.4315 62.5 3 49.0685 3 32.5C3 15.9315 16.4315 2.5 33 2.5C49.5685 2.5 63 15.9315 63 32.5ZM49.5384 28.5768C49.5384 31.3383 47.2998 33.5768 44.5384 33.5768C41.777 33.5768 39.5384 31.3383 39.5384 28.5768C39.5384 25.8154 41.777 23.5768 44.5384 23.5768C47.2998 23.5768 49.5384 25.8154 49.5384 28.5768ZM21.5383 33.5769C24.2997 33.5769 26.5383 31.3383 26.5383 28.5769C26.5383 25.8155 24.2997 23.5769 21.5383 23.5769C18.7769 23.5769 16.5383 25.8155 16.5383 28.5769C16.5383 31.3383 18.7769 33.5769 21.5383 33.5769ZM23.8248 42.4331C22.6408 41.7227 21.1052 42.1067 20.3948 43.2906C19.6844 44.4746 20.0683 46.0102 21.2523 46.7206C25.2429 49.1149 29.0644 50.4518 32.9135 50.4518C36.7921 50.4518 40.4044 49.095 43.9616 46.6323C45.0968 45.8464 45.3799 44.289 44.594 43.1538C43.8081 42.0186 42.2507 41.7355 41.1155 42.5214C38.1726 44.5587 35.535 45.4518 32.9135 45.4518C30.2627 45.4518 27.3342 44.5388 23.8248 42.4331Z"
                fill="#8AC0E1"
            />
        </svg>
    );
}
