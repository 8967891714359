export default function UserValid() {
    return (
        <svg
            width="47"
            height="34"
            viewBox="0 0 47 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.3696 8.33333C24.3696 12.9375 20.8833 16.6667 16.5791 16.6667C12.2748 16.6667 8.78857 12.9375 8.78857 8.33333C8.78857 3.72917 12.2748 0 16.5791 0C20.8833 0 24.3696 3.72917 24.3696 8.33333ZM20.4743 8.33333C20.4743 6.04167 18.7215 4.16667 16.5791 4.16667C14.4367 4.16667 12.6838 6.04167 12.6838 8.33333C12.6838 10.625 14.4367 12.5 16.5791 12.5C18.7215 12.5 20.4743 10.625 20.4743 8.33333ZM0.998047 29.1667C0.998047 23.625 11.3789 20.8333 16.5791 20.8333C21.7793 20.8333 32.1601 23.625 32.1601 29.1667V33.3333H0.998047V29.1667ZM16.5791 25C11.34 25 5.32178 27.6667 4.89331 29.1667H28.2649C27.8753 27.6875 21.8377 25 16.5791 25ZM45.4574 9.3716C46.2149 8.5677 46.1773 7.30193 45.3734 6.54442C44.5695 5.78691 43.3037 5.82451 42.5462 6.6284L31.7519 18.0837L27.695 13.7784C26.9375 12.9745 25.6717 12.9369 24.8678 13.6944C24.0639 14.4519 24.0263 15.7177 24.7838 16.5216L30.2963 22.3716C30.6742 22.7726 31.2008 23 31.7519 23C32.3029 23 32.8296 22.7726 33.2075 22.3716L45.4574 9.3716Z"
                fill="#00ACE7"
            />
        </svg>
    );
}
