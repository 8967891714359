import axios from "axios";

interface DataPutCapacity {
    token: string;
    capacity: {
        id: string;
        name: string;
        description: string;
        psychology: string;
        trad: { [key: string]: any };
    };
}
export const putCapacity = (data: DataPutCapacity, callback: Function) => {
    const { token, capacity } = data;
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .put("/api/capacity/", { capacity })
        .then((response) => {
            callback(response);
        })
        .catch(({response}) => {
            callback(response);
        });
};

type DataGetCapacities = {
    token: string;
    version: string;
};

/**
 * It gets the versions of the app from the server
 * @param {DataGetCapacities} data - access token
 * @param {Function} callback - This is the function that will be called after the request is made.
 */
export const getCapacities = async (data: DataGetCapacities, callback: Function) => {
    axios.defaults.headers.common = { Authorization: `Bearer ${data.token}` };
    axios
        .get("/api/capacity", { params: { version: data.version } })
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};
