export const frUser = {
    /**
     * Edit profile page
     */

    // Personal data
    "Welcome modal":
        "Bienvenue à la plateforme du TEST 4i.\nMerci de valider votre profil pour accéder au test. ",
    "Personal data": "Données personnelles",
    "First name": "Prénom",
    "Last name": "Nom",
    "Email": "Email",
    "Age": "Âge",
    "Gender": "Sexe",
    "Studies": "Niveau d’étude",
    "Grade": "Position",

    // Professional data
    "Professional data": "Données professionnelles",
    "Service": "Service / Département dans l'entreprise",
    "Company": "Entreprise",
    "Sector": "Secteur",
    "Seniority": "Ancienneté dans l’entreprise",
    "Country": "Pays",
    "City": "Ville",
    "Postal code": "Code postal",

    "Validate my profile": "Valider mon profil",
    "Your profile has been modified": "Votre profil a bien été modifié",
    "An error has occurred": "Une erreur est survenue",
    "You will be redirected to your surveys":
        "Vous allez être redirigé vers la page d'accès au test",

    // Seniority values
    "under1seniority": "- d'un an",
    "under3seniority": "1 - 2 ans",
    "under6seniority": "3 - 5 ans",
    "under10seniority": "6 - 10 ans",
    "more10seniority": "+ de 10 ans",

    /**
     * My survey page
     */
    // Routing & header
    "My surveys": "Mes tests",
    "Consult, download my tests": "Consulter, télécharger mes tests",

    // Table
    "My last survey": "Mes test disponibles",
    "Tests in progress": "Tests en cours",
    "Version": "Version",
    "Scores": "Scores",
    "Finished": "Terminé",
    "Started": "Commencé",
    "Progress": "Progression",
    "My history": "Mon historique",
    "Test history": "Historique de test",
    "No test": "Aucun test",
    "Buy a test": "Acheter un TEST 4i",
    "Evalueted": "Évalué",
    "Evaluators": "Évaluateurs",

    /**
     * Change password page
     */
    // Routing & header
    "My password": "Mon mot de passe",

    "Current password": "Mot de passe actuel",
    "Change my password": "Changer mon mot de passe",
    "Confirm my new password": "Confirmer mon nouveau mot de passe",
    "Your password has been successfully changed !":
        "Votre mot de passe a été changé avec succès !",

    /**
     * Delete account page
     */
    // Routing & header
    "Delete my account": "Supprimer mon compte",

    "A your sure ? Personnal data will be deleted and only surveys will be kept anonymized.":
        "Êtes-vous sûr de vouloir supprimer votre compte ? Vos données personnelles seront supprimées et seuls vos tests seront gardés anonymisés à des fins d'études exploitées uniquement par Act4 Talents.",
    "Account deleted later":
        "Après la suppression de votre compte, celui-ci sera gardé 14 jours.",
    "If you want to reactivate account":
        "Si vous souhaitez le réactiver, vous pourrez contacter notre support : test@act4-talents.fr.",
    "Yes I want it": "Oui, je confirme",

    "Your account has been successfully deleted.": "Votre compte a bien été supprimé.",
    "We could not delete your account.": "Nous n'avons pas pu supprimer votre compte.",
    "Contact our support :": "Contactez notre support :",

    /**
     * My Data
     * */
    "You will make the request to recover your personal data.":
        "Vous allez faire la demande de récupération de vos données personnelles.",
    "You will receive by email all the data related to your account.":
        "Vous receverez par mail toutes les données relatives a votre compte.",
    "An email has been sent to you!": "Un email vous a été envoyer !"
};
