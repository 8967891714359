import axios from "axios";
import { TagObject } from "../components/Templates/Tag";

interface DataPostUsersCSV {
    customMessage?: string;
    lang?: string;
    token: string;
    isNotify: boolean;
    file: any;
}

/**
 * It takes a file, a boolean, and a token, and sends them to the server
 * @param {DataPostUsersCSV} data - DataPostUsersCSV - this is the data that is being passed to the
 * function.
 * @param {Function} callback - Function - this is the function that will be called when the request is
 * complete.
 */
export const PostUsersCSV = async (data: DataPostUsersCSV, callback: Function) => {
    const { token, isNotify, file, lang = "fr", customMessage } = data;
    var formData = new FormData();
    formData.append("file", file);
    formData.append("isNotify", `${isNotify}`);
    formData.append("lang", lang);
    formData.append("customMessage", `${customMessage}`);
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .post("/api/admin/upload", formData)
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};

interface DataPostNewUser {
    token: string;
    firstName: string | false;
    lastName: string | false;
    email: string | false;
    age: string | false;
    // nationality: string | false;
    studies: string | false;
    grade: string | false;
    service: string | false;
    company: string | false;
    secteur: string | false;
    country: string | false;
    district: string | false;
    gender: string | false;
    companySize: string | false;
    seniority: string | false;
    role: string | false;
    version: string | false;
}
/**
 * It takes in a data object and a callback function, and then it makes a POST request to the server
 * with the data object, and then it calls the callback function with the response from the server
 * @param {DataPostNewUser} data - DataPostNewUser: This is the data that we are sending to the server.
 * @param {Function} callback - Function - This is the function that will be called when the request is
 * complete.
 */
export const PostNewUser = async (data: DataPostNewUser, callback: Function) => {
    const { token, ...user } = data;
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .post("/api/admin/user", user)
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};
interface DataRemoveUser {
    token: string;
    id: number;
}

/**
 * It sends a DELETE request to the server with the id of the user to delete and the token of the admin
 * @param {DataRemoveUser} data - DataRemoveUser: This is the data that will be sent to the server.
 * @param {Function} callback - Function - this is the function that will be called after the request
 * is made.
 */
export const RemoveUser = (data: DataRemoveUser, callback: Function) => {
    const { id, token } = data;
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .delete("/api/admin/user", { data: { idUser: id } })
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};
interface DataPostUpdateUser {
    token: string;
    firstName: string | false;
    lastName: string | false;
    email: string | false;
    age: string | false;
    // nationality: string | false;
    studies: string | false;
    grade: string | false;
    service: string | false;
    company: string | false;
    secteur: string | false;
    country: string | false;
    district: string | false;
    gender: string | false;
    companySize: string | false;
    seniority: string | false;
    role: string | false;
}

/**
 * It updates a user's information in the database
 * @param {DataPostUpdateUser} data - DataPostUpdateUser
 * @param {boolean} isNotify - boolean
 * @param {TagObject[]} tagToDelete - TagObject[]
 * @param {Function} callback - Function
 */
export const PostUpdateUser = (
    data: DataPostUpdateUser,
    isNotify: boolean,
    hasANewTest: boolean,
    hasToSendMail: boolean,
    tagToDelete: TagObject[],
    callback: Function
) => {
    const { token, ...user } = data;
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .put("/api/admin/user", {
            user,
            isNotify,
            hasANewTest,
            hasToSendMail,
            tagToDelete
        })
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};

interface DataGetVersions {
    token: string;
}

/**
 * DEPRECIATED
 * It gets the versions of the app from the server
 * @param {DataGetVersions} data - DataGetVersions - this is the data that is passed to the function.
 * @param {Function} callback - This is the function that will be called after the request is made.
 */
export const getVersions = (data: DataGetVersions, callback: Function) => {
    const { token } = data;
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .get("/api/admin/versions")
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};

interface DataGetTemplateImportCSV {
    token: string;
}

/**
 * It gets the template csv file for importing users
 * @param {DataGetTemplateImportCSV} data - DataGetTemplateImportCSV - this is the data that will be
 * sent to the API.
 * @param {Function} callback - Function - This is the function that will be called after the request
 * is made.
 */
export const getTemplateImportCSV = async (
    data: DataGetTemplateImportCSV,
    callback: Function
) => {
    const { token } = data;
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .get("/api/admin/templatecsv", { responseType: "blob" })
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};
interface DataGroupedActionsUsers {
    action: string;
    data: any;
    token: string;
    callback: Function;
}

export const GroupedActionsUsers = ({
    action,
    data,
    token,
    callback
}: DataGroupedActionsUsers) => {
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    switch (action) {
        case "export-results":
            axios
                .get("/api/csv/results", {
                    params: { ids: data.ids },
                    responseType: "blob"
                })
                .then((response) => {
                    callback(response);
                })
                .catch(({ response }) => {
                    callback(response);
                });
            break;
        case "export-users":
            axios
                .get("/api/csv/users", {
                    params: { ids: data.ids },
                    responseType: "blob"
                })
                .then((response) => {
                    callback(response);
                })
                .catch(({ response }) => {
                    callback(response);
                });
            break;
        case "delete":
            axios
                .delete("/api/admin/users", { data: { ids: data.ids } })
                .then((response) => {
                    callback(response);
                })
                .catch(({ response }) => {
                    callback(response);
                });
            break;
        case "tags":
            axios
                .post("/api/tag/grouped", data)
                .then((response) => {
                    callback(response);
                })
                .catch(({ response }) => {
                    callback(response);
                });
            break;
        case "mail-results":
            axios
                .get("/api/admin/mail/results", { params: data })
                .then((response) => {
                    callback(response);
                })
                .catch(({ response }) => {
                    callback(response);
                });
            break;
        case "mail-signin":
            axios
                .post("/api/admin/mail/signin", data)
                .then((response) => {
                    callback(response);
                })
                .catch(({ response }) => {
                    callback(response);
                });
            break;
        case "mail-notify":
            axios
                .post("/api/admin/mail/notify", data)
                .then((response) => {
                    callback(response);
                })
                .catch(({ response }) => {
                    callback(response);
                });
            break;
        case "add-crosseye":
            axios
                .post("/api/crosseye/addUsers", {
                    version: data.version,
                    evaluedUsers: data.selectUsers,
                    testerUsers: data.ids,
                    hasToSendMail: data.hasToSendMail,
                    lang: data.lang,
                    customMessage: data.customMessage,
                    survey: data.survey
                })
                .then((response) => {
                    callback(response);
                })
                .catch(({ response }) => {
                    callback(response);
                });
            break;
        case "add-survey":
            console.log(data);

            axios
                .post("/api/survey/users", {
                    version: data.version,
                    users: data.ids,
                    hasToSendMail: data.hasToSendMail,
                    lang: data.lang,
                    customMessage: data.customMessage
                })
                .then((response) => {
                    callback(response);
                })
                .catch(({ response }) => {
                    callback(response);
                });
            break;

        case "add-admin":
            axios
                .post("/api/admin/addAdminToUsers", {
                    adminId: data.selectUser,
                    userIds: data.ids
                })
                .then((response) => {
                    callback(response);
                })
                .catch(({ response }) => {
                    callback(response);
                });
            break;

        case "export-team":
            axios
                .get("/api/team/pdf", { params: { ids: data.ids }, responseType: "blob" })
                .then((response) => {
                    callback(response);
                })
                .catch(({ response }) => {
                    callback(response);
                });
            break;

        default:
            break;
    }
};
interface DataFindCommunTags {
    token: string;
    ids: string[];
}
export const FindCommunTags = (data: DataFindCommunTags, callback: Function) => {
    const { token } = data;
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .get("/api/tag/commun", {
            params: { ids: data.ids }
        })
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};

interface DataAddSurveyToUser {
    token: string;
    version: string;
    userId: number;
    hasToSendMail: boolean;
    customMessage: string;
}
/**
 * This function is used to add a survey to a user
 * @param {DataAddSurveyToUser} data - DataAddSurveyToUser - This is the data that will be sent to the
 * server.
 * @param {Function} callback - Function - This is the function that will be called when the request is
 * complete.
 */
export const AddSurveyToUser = (data: DataAddSurveyToUser, callback: Function) => {
    const { token } = data;
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
        .post("/api/admin/survey/add", data)
        .then((response) => {
            callback(response);
        })
        .catch(({ response }) => {
            callback(response);
        });
};
