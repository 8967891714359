import { AxiosResponse } from "axios";
import { ReactElement, useCallback, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import Download from "../../../assets/Download";
import FileCSV from "../../../assets/FileCSV";
import FilePDF from "../../../assets/FilePDF";
import { GetCSV, GetPDF } from "../../../services/survey.service";
import Dropdown from "../../Templates/Dropdown";
import Modal from "../../Templates/Modal";
import RoleRestrictZone from "../../Templates/RoleRestrictZone";

type SimplifiedSurvey = {
    id: number;
    version: string;
};

export function DownloadUser(props: any) {
    const [t, i18n] = useTranslation("admin");
    const [cookies] = useCookies(["access_token"]);
    const setModal = props.setModal;
    const surveys: SimplifiedSurvey[] = props.data.surveys
        .filter((survey: any) => survey.end)
        .map((survey: any) => {
            return {
                id: survey.id,
                version: survey.template.version
            };
        })
        .sort((a: any, b: any) => a.end - b.end);

    const [selectedSurvey, setSelectedSurvey] = useState(surveys[0]);
    const version = useRef<ReactElement | false>(false);

    const changeVersion = (versionHash: string) => {
        const [id, version] = versionHash.split(" - ");
        const survey = surveys.find(
            (survey) => survey.id === +id && survey.version === version
        );

        if (survey) {
            setSelectedSurvey(survey);
        }
    };

    const downloadPDF = () => {
        GetPDF(
            { token: cookies.access_token, id: selectedSurvey.id },
            resolveDownloadPDF
        );
    };
    const resolveDownloadPDF = useCallback((response: AxiosResponse) => {
        setModal(
            <>
                <h3>{t("The user survey as file")}</h3>
                <FilePDF color={"#00ACE7"} />
                <h3>{t("has been downloaded successfully !")}</h3>
            </>
        );
        switch (response.status) {
            case 200:
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "soft-skills-test-resultats.pdf");
                document.body.appendChild(link);
                link.click();
                link.remove();
                break;
            default:
                setModal(
                    <>
                        <h3>{t("The user survey as file")}</h3>
                        <FilePDF color={"#b5007f"} />
                        <h3>{t("has not been downloaded !")}</h3>
                    </>
                );
                break;
        }
    }, []);

    const downloadCSV = () => {
        GetCSV(
            { token: cookies.access_token, id: selectedSurvey.id },
            resolveDownloadCSV
        );
    };
    const resolveDownloadCSV = useCallback((response: AxiosResponse) => {
        setModal(
            <>
                <h3>{t("The user survey as file")}</h3>
                <FileCSV color={"#00ACE7"} />
                <h3>{t("has been downloaded successfully !")}</h3>
            </>
        );
        switch (response.status) {
            case 200:
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "soft-skills-test-resultats.csv");
                document.body.appendChild(link);
                link.click();
                link.remove();
                break;
            default:
                setModal(
                    <>
                        <h3>{t("The user survey as file")}</h3>
                        <FileCSV color={"red"} />
                        <h3>{t("has not been downloaded !")}</h3>
                    </>
                );
                break;
        }
    }, []);

    return (
        <div id="DownloadUser">
            <h3>
                {t("You want to download the user survey as a file")}{" "}
                <span>({t("click on the icon")})</span> :
            </h3>
            <div className="selectType">
                <FilePDF onClick={downloadPDF} />
                <RoleRestrictZone namespace="user" zoneId="dl-survey-csv">
                    <FileCSV onClick={downloadCSV} />
                </RoleRestrictZone>
            </div>
            <h5>{t("Version")}</h5>
            <Dropdown
                refInput={version}
                values={surveys.map((survey: any) => `${survey.id} - ${survey.version}`)}
                callback={changeVersion}
                defaultValue={`${selectedSurvey.id} - ${selectedSurvey.version}`}
            />
        </div>
    );
}

export function IconeDownloadUser(props: PropsDownloadUser) {
    const [modal, setModal] = useState<ReactElement | false>(false);
    const user = props.data;
    const endingSurveys =
        user.surveys
            .filter((survey: any) => survey.end)
            .sort((a: any, b: any) => a.end - b.end) || [];
    return (
        <>
            <Modal content={modal} setContent={setModal} />
            <Download
                className={
                    endingSurveys.length >= 1 && endingSurveys[0].end
                        ? "tool active"
                        : "tool inactive"
                }
                onClick={() => {
                    if (endingSurveys.length >= 1 && endingSurveys[0].end) {
                        setModal(<DownloadUser setModal={setModal} {...props} />);
                    }
                }}
            />
        </>
    );
}

interface PropsDownloadUser {
    data: any;
}
