import { PropsSVG } from "./SVG.interface";

export default function Mail({
    className,
    onClick,
    size = 60,
    color = "#00ACE7"
}: PropsSVG) {
    return (
        <svg
            className={className}
            width={size}
            height={size}
            onClick={onClick}
            viewBox="0 0 45 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M41.25 11.25C41.25 9.1875 39.5625 7.5 37.5 7.5H7.5C5.4375 7.5 3.75 9.1875 3.75 11.25V33.75C3.75 35.8125 5.4375 37.5 7.5 37.5H37.5C39.5625 37.5 41.25 35.8125 41.25 33.75V11.25ZM37.5 11.25L22.5 20.625L7.5 11.25H37.5ZM37.5 33.75H7.5V15L22.5 24.375L37.5 15V33.75Z"
                fill={color}
            />
        </svg>
    );
}
