import { AxiosResponse } from "axios";
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useRef,
    useState
} from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { getSurveyTokenPrice, SurveyTokenType } from "../../services/purchase.service";
import { formatPrice } from "../../utils";
import Btn from "../Templates/Btn";

type TokenCalculatorInputProps = {
    /**
     * Warning : this is a i18n key
     */
    label?: string;
    surveyTokenType: SurveyTokenType;
    tokenQuantityState: number;
    setTokenQuantityState: Dispatch<SetStateAction<number>>;
    setTotalPriceState: Dispatch<SetStateAction<number>>;
    minimumQuantityLimit?: number;
};

type busyQuery = "getSurveyTokenPrice";
type busyQueriesState = { [key in busyQuery]: boolean };

export default function TokenCalculatorInput({
    label,
    surveyTokenType,
    tokenQuantityState,
    setTokenQuantityState,
    setTotalPriceState,
    minimumQuantityLimit = 0
}: TokenCalculatorInputProps) {
    const [t] = useTranslation("purchase");
    const [cookies] = useCookies(["access_token"]);
    const [unitPrice, setUnitPrice] = useState(0);
    const [calculetedPrice, setCalculatedPrice] = useState<number>(0);

    const busy = useRef<busyQueriesState>({
        getSurveyTokenPrice: false
    });

    /**
     * Set unit price (from API data)
     */
    const resolveGetUnitPrice = useCallback(async (response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                setUnitPrice(response.data.unitPrice);
                break;
            default:
                console.log("Getting unit price failed", response);
                break;
        }
        busy.current.getSurveyTokenPrice = false;
    }, []);
    useEffect(() => {
        if (!busy.current.getSurveyTokenPrice) {
            getSurveyTokenPrice(
                { token: cookies.access_token, surveyTokenType },
                resolveGetUnitPrice
            );
            busy.current.getSurveyTokenPrice = true;
        }
    }, [cookies.access_token, resolveGetUnitPrice, surveyTokenType]);

    /**
     * Calculate price on change of quantity
     */
    useEffect(() => {
        setCalculatedPrice(tokenQuantityState * unitPrice);
        setTotalPriceState(tokenQuantityState * unitPrice);
    }, [setTotalPriceState, tokenQuantityState, unitPrice]);

    /**
     * Rendering
     */

    return (
        <div className="token-calculator">
            <div className="label">{label && <h4>{t(label)}</h4>}</div>
            <div className="selectCount">
                <Btn
                    id="btn-minus"
                    className={
                        tokenQuantityState > minimumQuantityLimit ? "active" : "disabled"
                    }
                    callback={() => {
                        setTokenQuantityState((currentQuantity) => {
                            if (currentQuantity > minimumQuantityLimit) {
                                return currentQuantity - 1;
                            }
                            return currentQuantity;
                        });
                    }}
                >
                    -
                </Btn>
                <span className="value">{tokenQuantityState}</span>
                <Btn
                    id="btn-plus"
                    className="active"
                    callback={() => {
                        setTokenQuantityState((currentQuantity) => {
                            return currentQuantity + 1;
                        });
                    }}
                >
                    +
                </Btn>
                <span className="operator">×</span>
                <span className="value">{formatPrice(unitPrice)}</span>
                <span className="operator">=</span>
                <span className="value bold">{formatPrice(calculetedPrice)}</span>
                <span className="noTaxe">{t("withoutTax")}</span>
            </div>
        </div>
    );
}
