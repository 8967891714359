export const frCommon = {
    /**
     * Action buttons
     * // TODO CG : move all action button here
     */
    "Logout": "Déconnexion",
    "Cancel": "Annuler",
    "Validate": "Valider",
    "Delete": "Supprimer",
    "Export": "Exporter",
    "Send": "Envoyer",

    "It's coming soon": "Bientôt disponible",
    "For now": "Pour le moment, contactez l'équipe Act4 Talents directement",
    "Individual": "Individuel",
    "CrossEye": "Regard Croisé pour",
    /**
     * Status
     */
    "Pending": "En attente",

    "Started": "Débuté il y a",
    "Just now": "quelque secondes",
    "minutes ago": "minutes",
    "hours ago": "heures",
    "days ago": "jours",
    "weeks ago": "semaines",
    "months ago": "mois",
    "years ago": "années",

    "You": "Vous",

    /**
     * Footer
     * */
    "Contact": "Contact",
    "Legal information": "Mentions légales",

    /**
     * Error messages
     */
    "Action forbidden": "Action interdite"
};
