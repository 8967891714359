import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { AxiosResponse } from "axios";
import { ReactElement, useEffect, useRef, useState, useReducer } from "react";
import { useCookies } from "react-cookie";
import Admin from "../../../assets/Admin";
import Superuser from "../../../assets/Superuser";
import UserAdd from "../../../assets/UserAdd";
import { PostNewUser } from "../../../services/root.service";
import { isDev } from "../../../utils/isDev";

import Btn from "../../Templates/Btn";
import Checkbox from "../../Templates/Checkbox";
import { reloadList } from "../../Templates/Dashboard/List";
import Dropdown from "../../Templates/Dropdown";
import Field, { RowFields } from "../../Templates/Field";
import Modal from "../../Templates/Modal";
import VersionDropDown from "../../Templates/VersionDropdown";
import Waiting from "../../Templates/Waiting";
import { DatasDropdown } from "./DatasDropdown";
import Tag from "../../Templates/Tag";
import { randomInt } from "../../../utils";
import { useTranslation } from "react-i18next";
import RoleRestrictZone from "../../Templates/RoleRestrictZone";
import { Role } from "../../Templates/RoleRestrictZone/restrictRule.types";
import { UserAlreadyExist } from "../../Error/UserAlreadyExist";
import { BadRequest } from "../../Error/BadRequest";

export default function NewUser({ closeModal }: PropsNewUser) {
    const [t] = useTranslation("admin");
    const [cookies] = useCookies(["access_token", "role"]);
    const divMain = useRef<HTMLInputElement | null>(null);

    const [callbackRequest, setCallbackRequest] = useState<ReactElement | false>(false);

    const firstName = useRef<HTMLInputElement | null>(null);
    const lastName = useRef<HTMLInputElement | null>(null);
    const email = useRef<HTMLInputElement | null>(null);
    const gender = useRef<HTMLInputElement | null>(null);
    const age = useRef<HTMLInputElement | null>(null);
    // const nationality = useRef<HTMLInputElement | null>(null);

    const studies = useRef<HTMLInputElement | null>(null);
    const grade = useRef<HTMLInputElement | null>(null);
    const service = useRef<HTMLInputElement | null>(null);
    const company = useRef<HTMLInputElement | null>(null);
    const secteur = useRef<HTMLInputElement | null>(null);
    const country = useRef<HTMLInputElement | null>(null);
    const district = useRef<HTMLInputElement | null>(null);
    const tag = useRef<HTMLInputElement | null>(null);
    const version = useRef<HTMLInputElement | null>(null);

    const [companySize, setCompanySize] = useState<false | string>(isDev ? "10" : false);
    const [seniority, setSeniority] = useState<false | string>(isDev ? "1-3" : false);
    const [role, setRole] = useState<false | string>(
        cookies.role === Role.admin ? "User" : false
    );
    const [isNotify, setNotify] = useState<boolean>(true);

    const [activeBtn, setActiveBtn] = useState<Boolean>(false);
    const [activeDistrict, setActiveDistrict] = useState<Boolean>(false);
    const [error, setError] = useState({});
    const [tags, setTags] = useState<string[]>([]);

    const [reset, setReset] = useState<number>(0);
    const [, render] = useReducer((p) => !p, false);
    // use the dispatch of useReducer to re-render the required values

    useEffect(() => {
        const handleEnter = (e: KeyboardEvent) => {
            if (e.key === "Enter" && document.activeElement === tag.current) {
                handleTag();
            }
        };
        if (tag.current) {
            tag.current.addEventListener("keydown", handleEnter);
        }
        return () => {
            if (tag!.current) {
                tag!.current.removeEventListener("keydown", handleEnter);
            }
        };
    });

    useEffect(() => {
        if (
            email.current?.value &&
            verifForm() &&
            firstName.current?.value &&
            lastName.current?.value &&
            role
        ) {
            setActiveBtn(true);
        } else setActiveBtn(false);
    }, [email.current?.value, lastName.current?.value, firstName.current?.value, role]);

    const verifForm = () => {
        const verifEmail = String(email.current?.value)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        if (verifEmail) return true;
        return false;
    };
    const resetForm = () => {
        setRole(false);
        // Increment 'key' to force the re-render the form (thanks to React "key" property)
        setReset((prevState) => prevState + 1);
        divMain.current?.parentElement?.scrollTo({ top: 0 });
    };

    const handleForm = () => {
        if (!activeBtn || email.current === null) return false;

        if (verifForm()) {
            setCallbackRequest(
                <>
                    <Waiting />
                </>
            );
            const formData = {
                token: cookies.access_token,
                firstName: firstName.current!.value,
                lastName: lastName.current!.value,
                email: email.current!.value,
                age: age.current!.value,
                // nationality: nationality.current!.value,
                studies: studies.current!.value,
                grade: grade.current!.value,
                service: service.current!.value,
                company: company.current!.value,
                secteur: secteur.current!.value,
                country: country.current!.value,
                district: district.current!.value,
                gender: gender.current!.value,
                // gender: gender,
                companySize: companySize,
                seniority: seniority,
                role: role,
                version: version.current!.value,
                isNotify,
                tags
            };
            PostNewUser(formData, resolvePost);
        }
    };
    const resolvePost = (response: AxiosResponse) => {
        switch (response.status) {
            case 201:
                setCallbackRequest(
                    <NextUser
                        close={() => {
                            reloadList();
                            closeModal(false);
                            setCallbackRequest(false);
                        }}
                        other={() => {
                            setCallbackRequest(false);
                        }}
                    />
                );
                resetForm();
                break;
            case 400:
                setCallbackRequest(
                    <BadRequest errorMessage={response.data.message ?? ""} />
                );
                break;
            case 409:
                setCallbackRequest(<UserAlreadyExist />);
                break;
            default:
                break;
        }
    };

    const displayNewTag = (newTag: string) => {
        if (tags.includes(newTag)) return;
        const newList = [...tags, newTag];
        setTags(newList);
    };

    const handleTag = () => {
        if (!tag.current || !tag.current.value) {
            setError({ tag: true });
            setTimeout(() => {
                setError({});
            }, 2000);
            return;
        }
        displayNewTag(tag.current.value);
        tag.current.value = "";
    };

    const handleDeleteTag = (tagValue: string) => {
        const updateList = tags.filter((value) => value !== tagValue);
        setTags(updateList);
    };

    return (
        <div id="NewUser" ref={divMain} key={reset}>
            <Modal content={callbackRequest} setContent={setCallbackRequest} />
            <h3>{t("New user")}</h3>
            <fieldset>
                <h5>{t("Personal data")}</h5>
                <RowFields>
                    <Field
                        type="text"
                        id="firstName"
                        label={t("First name")}
                        placeholder="John"
                        refField={firstName}
                        defaultValue={isDev ? "John" : ""}
                        onChange={render}
                    />
                    <Field
                        type="text"
                        id="lastName"
                        label={t("Last name")}
                        placeholder="Doe"
                        refField={lastName}
                        defaultValue={isDev ? "Doe" : ""}
                        onChange={render}
                    />
                </RowFields>

                <Field
                    type="text"
                    id="email"
                    label={`${t("Email")} *`}
                    placeholder="johnDoe@email.com"
                    defaultValue={
                        isDev ? `${randomInt(10)}-${randomInt(10)}@email.com` : ""
                    }
                    refField={email}
                    onChange={render}
                />
                <h6>
                    {t(
                        "The password is automatically generated when sending the email to the user."
                    )}
                </h6>
                <RowFields>
                    <Dropdown
                        label={t("Age")}
                        refInput={age}
                        values={DatasDropdown.age}
                    />
                    <Dropdown
                        label={t("Gender")}
                        refInput={gender}
                        values={DatasDropdown.gender} // TODO : add translate for gender dropdown values (difficulty : value in static file + enum in DB)
                    />
                </RowFields>
                <RowFields>
                    <Dropdown
                        label={t("Studies")}
                        refInput={studies}
                        values={DatasDropdown.studies} // TODO : add translate for studies dropdown values (difficulty : value in static file + enum in DB)
                    />

                    {/* <Dropdown
            label={"Nationalité"}
            refInput={nationality}
            values={DatasDropdown.nationality}
          /> */}
                </RowFields>
            </fieldset>
            <fieldset>
                <h5>{t("Professional data")}</h5>
                <RowFields>
                    <Dropdown
                        label={t("Grade")}
                        refInput={grade}
                        values={DatasDropdown.grade}
                    />
                    <Dropdown
                        label={t("Service")}
                        refInput={service}
                        values={DatasDropdown.service}
                    />
                </RowFields>

                <RowFields>
                    <Field
                        type="text"
                        id="company"
                        label={t("Company")}
                        placeholder="ACT4 TALENTS"
                        refField={company}
                        defaultValue={isDev ? "ACT4 TALENTS" : ""}
                    />
                    <Dropdown
                        label={t("Sector")}
                        refInput={secteur}
                        values={DatasDropdown.secteur}
                    />
                </RowFields>
                {/* <RowFields label="Taille de l’entreprise">
          <Checkbox
            type={"radio"}
            value={"under10company"}
            name="companysize"
            callback={setCompanySize}
          >
            - de 10
          </Checkbox>
          <Checkbox
            type={"radio"}
            value={"under50company"}
            name="companysize"
            callback={setCompanySize}
          >
            - de 50
          </Checkbox>
          <Checkbox
            type={"radio"}
            value={"under100company"}
            name="companysize"
            callback={setCompanySize}
          >
            - de 100
          </Checkbox>
          <Checkbox
            type={"radio"}
            value={"under250company"}
            name="companysize"
            callback={setCompanySize}
          >
            - de 250
          </Checkbox>
          <Checkbox
            type={"radio"}
            value={"more250company"}
            name="companysize"
            callback={setCompanySize}
          >
            + de 250
          </Checkbox>
        </RowFields> */}

                <RowFields label={t("Seniority")}>
                    <Checkbox
                        type={"radio"}
                        value={"under1seniority"}
                        name="seniority"
                        callback={setSeniority}
                    >
                        {t("under1seniority")}
                    </Checkbox>
                    <Checkbox
                        type={"radio"}
                        value={"under3seniority"}
                        name="seniority"
                        callback={setSeniority}
                    >
                        {t("under3seniority")}
                    </Checkbox>
                    <Checkbox
                        type={"radio"}
                        value={"under6seniority"}
                        name="seniority"
                        callback={setSeniority}
                    >
                        {t("under6seniority")}
                    </Checkbox>
                    <Checkbox
                        type={"radio"}
                        value={"under10seniority"}
                        name="seniority"
                        callback={setSeniority}
                    >
                        {t("under10seniority")}
                    </Checkbox>
                    <Checkbox
                        type={"radio"}
                        value={"more10seniority"}
                        name="seniority"
                        callback={setSeniority}
                    >
                        {t("more10seniority")}
                    </Checkbox>
                </RowFields>

                <RowFields>
                    <Dropdown
                        label={t("Country")}
                        refInput={country}
                        values={DatasDropdown.country}
                        callback={() => {
                            if (country.current && country.current!.value === "France")
                                setActiveDistrict(true);
                            else setActiveDistrict(false);
                        }}
                    />
                    <Field
                        type="text"
                        id="district"
                        label={activeDistrict ? t("Postal code") : t("City")}
                        placeholder={activeDistrict ? "95001" : "Paris"}
                        refField={district}
                    />
                </RowFields>
            </fieldset>
            <fieldset>
                <h5>{t("Add a tag")}</h5>
                <RowFields>
                    <Field
                        type="text"
                        id="tag"
                        placeholder={t("Add a tag")}
                        refField={tag}
                        error={error}
                    />
                    <Btn className="margin-auto" callback={handleTag}>
                        <FontAwesomeIcon icon={faCirclePlus} />
                    </Btn>
                </RowFields>
                <div className="tags">
                    {tags.map((value) => {
                        return (
                            <Tag
                                key={value}
                                value={value}
                                color="primary"
                                active={true}
                                callback={handleDeleteTag}
                            />
                        );
                    })}
                </div>
            </fieldset>
            <RoleRestrictZone namespace="user" zoneId="change-role">
                <fieldset>
                    <h5>{t("Status")}</h5>
                    <RowFields>
                        <Checkbox
                            type={"radio"}
                            value={"Root"}
                            name="role"
                            callback={setRole}
                        >
                            <Superuser /> SuperAdmin
                        </Checkbox>
                        <Checkbox
                            type={"radio"}
                            value={"Admin"}
                            name="role"
                            callback={setRole}
                        >
                            <Admin /> Admin
                        </Checkbox>
                        <Checkbox
                            type={"radio"}
                            value={"User"}
                            name="role"
                            callback={setRole}
                        >
                            User
                        </Checkbox>
                    </RowFields>
                </fieldset>
            </RoleRestrictZone>
            <fieldset>
                <h5>{t("Survey version")}</h5>
                <RowFields>
                    {/* <Dropdown
            refInput={version}
            values={["1.0.0", "1.0.0-Vinci", "1.0.1", "test"]}
          /> */}
                    <VersionDropDown refInput={version} />
                    <Checkbox
                        type={"checkbox"}
                        value={"isNotify"}
                        name="isNotify"
                        callback={() => setNotify(!isNotify)}
                        checked={isNotify}
                    >
                        {t("Send password to user")}
                    </Checkbox>
                </RowFields>
            </fieldset>
            <Btn className={activeBtn ? "active" : "disabled"} callback={handleForm}>
                {t("Add this user")}
            </Btn>
        </div>
    );
}
interface PropsNewUser {
    closeModal: Function;
}

const NextUser = ({ other, close }: PropsNextUser) => {
    const [t, i18n] = useTranslation("admin");
    return (
        <>
            <h3>{t("The user has been successfully created")}</h3>
            <UserAdd size={60} />
            <RowFields>
                <Btn className={"cancel"} callback={other}>
                    {t("Create another")}
                </Btn>
                <Btn className={"active"} callback={close}>
                    {t("Back to the list")}
                </Btn>
            </RowFields>
        </>
    );
};
interface PropsNextUser {
    other: Function;
    close: Function;
}
