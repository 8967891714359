export const enCommon = {
    /**
     * Action buttons
     */
    "Logout": "Log out",
    "Cancel": "Cancel",
    "Validate": "Validate",
    "Delete": "Delete",
    "Export": "Export",
    "Send": "Send",
    "It's coming soon": "It's coming soon",
    "For now": "For now, contact the Act4 Talents team directly",
    "Individual": "Individual",
    "CrossEye": "Cross test for",

    /**
     * Status
     */
    "Pending": "Pending",

    "Started": "Started",
    "Just now": "Just now",
    "minutes ago": "minutes ago",
    "hours ago": "hours ago",
    "days ago": "days ago",
    "weeks ago": "weeks ago",
    "months ago": "months ago",
    "years ago": "years ago",

    "You": "You",

    /**
     * Footer
     * */
    "Contact": "Contact",
    "Legal information": "Legal information",

    /**
     * Error messages
     */
    "Action forbidden": "Action forbidden"
};
