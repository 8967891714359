import { PropsSVG } from "./SVG.interface";

export default function ValidTag({
    className,
    onClick,
    size = 70,
    color = "#00ACE7"
}: PropsSVG) {
    return (
        <svg
            className={className}
            width={size}
            height={size}
            viewBox="0 0 70 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
        >
            <path
                d="M43.5001 39L36.5001 32L34.1667 34.3334L43.5001 43.6667L63.5001 23.6667L61.1667 21.3334L43.5001 39Z"
                fill="#EB864B"
            />
            <path
                d="M36.1833 19.3L21.1833 4.30004C20.5833 3.70004 19.7499 3.33337 18.8333 3.33337H7.16659C5.33325 3.33337 3.83325 4.83337 3.83325 6.66671V18.3334C3.83325 19.25 4.19992 20.0834 4.81659 20.7L19.8166 35.7C20.4166 36.3 21.2499 36.6667 22.1666 36.6667C23.0833 36.6667 23.9166 36.3 24.5166 35.6834L36.1833 24.0167C36.7999 23.4167 37.1666 22.5834 37.1666 21.6667C37.1666 20.75 36.7833 19.9 36.1833 19.3ZM22.1666 33.35L7.16659 18.3334V6.66671H18.8333V6.65004L33.8333 21.65L22.1666 33.35V33.35Z"
                fill={color}
            />
            <path
                d="M11.3333 13.3334C12.714 13.3334 13.8333 12.2141 13.8333 10.8334C13.8333 9.45266 12.714 8.33337 11.3333 8.33337C9.95254 8.33337 8.83325 9.45266 8.83325 10.8334C8.83325 12.2141 9.95254 13.3334 11.3333 13.3334Z"
                fill={color}
            />
        </svg>
    );
}
