export default function ImportCSV() {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="40" height="40" rx="20" fill="#EB864B" />
            <path
                d="M11 21H13V19H11V21ZM11 25H13V23H11V25ZM11 17H13V15H11V17ZM15 21H29V19H15V21ZM15 25H29V23H15V25ZM15 15V17H29V15H15ZM11 21H13V19H11V21ZM11 25H13V23H11V25ZM11 17H13V15H11V17ZM15 21H29V19H15V21ZM15 25H29V23H15V25ZM15 15V17H29V15H15Z"
                fill="white"
            />
        </svg>
    );
}
