import { AxiosResponse } from "axios";
import { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { GetAllTokensByUserId } from "../../../services/token.service";
import Btn from "../../Templates/Btn";
import List from "../../Templates/Dashboard/List";
import { IconeAssignUser } from "./AssignToUser";
import Key from "../../../assets/Key";
import SidePage from "../../Templates/SidePage";
import AdminPurchaseForm from "../../Purchase/AdminPurchaseForm";
import { useSearchParams } from "react-router-dom";
import Modal from "../../Templates/Modal";
import Collapse from "../../Templates/Collapse";
import ShoppingBasket from "../../../assets/ShoppingBasket";
import { useLocalStorage } from "../../../utils/useLocalStorage";
import { getNavigatorLang } from "../../../utils/getNavigatorLang";
import { RowHeader } from "../../Templates/Dashboard/Row";

export default function Token() {
    const [t, i18n] = useTranslation("admin");
    const [tokens, setTokens] = useState<any[] | null>(null);
    const [usedTokens, setUsedTokens] = useState<any[] | null>(null);
    const [modal, setModal] = useState<ReactElement | false>(false);
    const [cookies] = useCookies(["access_token", "id"]);
    const busy = useRef<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [lang, setLang] = useLocalStorage<string>("lang", getNavigatorLang() || "fr");

    const displayPurchaseConfirModal = useCallback(() => {
        switch (searchParams.get("redirect_status")) {
            case "succeeded":
                setModal(
                    <>
                        <h3 className="purchase-confirmation title">
                            {t("purchaseTokenConfirmationTitle", { lng: lang })}
                        </h3>
                        <ShoppingBasket />
                        <p className="purchase-confirmation body">
                            {t("purchaseTokenConfirmationBody1", { lng: lang })}
                            <br />
                            {t("purchaseTokenConfirmationBody2", { lng: lang })}
                            <br />
                            {t("purchaseTokenConfirmationBody3", { lng: lang })}
                        </p>
                    </>
                );
                break;

            default:
                break;
        }
        searchParams.delete("payment_intent");
        searchParams.delete("payment_intent_client_secret");
        searchParams.delete("redirect_status");

        setSearchParams(searchParams);
    }, [searchParams, setSearchParams, t, lang]);

    useEffect(() => {
        displayPurchaseConfirModal();
    }, [displayPurchaseConfirModal, i18n.language]);

    const resolveGetAll = useCallback((response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                setTokens(response.data.filter((token: any) => token.used === false));
                setUsedTokens(response.data.filter((token: any) => token.used === true));
                break;
            case 401:
            default:
                console.log("ERROR", response);
                break;
        }
        busy.current = false;
    }, []);

    useEffect(() => {
        if (!tokens && !busy.current) {
            busy.current = true;
            GetAllTokensByUserId(
                { token: cookies.access_token, userId: cookies.id },
                resolveGetAll
            );
        }
    }, [cookies.access_token, cookies.id, tokens, resolveGetAll]);

    useEffect(() => {});

    return (
        <div className="table" id="Token">
            <Modal content={modal} setContent={setModal} />
            {tokens && (
                <Collapse label={<h5>{t("AvailableTokens")}</h5>} active={true}>
                    <div className="table">
                        {tokens.length === 0 ? (
                            <h5>{t("NoTokens")}</h5>
                        ) : (
                            <>
                                <RowHeader
                                    callback={() => {}}
                                    filters={false}
                                    {...{
                                        cells: {
                                            "value": {
                                                text: t("Key"),
                                                active: false
                                            },
                                            "creation": {
                                                text: t("Creation date"),
                                                active: false
                                            },
                                            "token-survey-version": {
                                                text: t("Version"),
                                                active: false
                                            },
                                            "token-survey-user": {
                                                text: t("User"),
                                                active: false
                                            },
                                            "reset": true
                                        }
                                    }}
                                />
                                <List
                                    datas={tokens}
                                    rowOptions={rowOptions}
                                    getDatas={() => {
                                        GetAllTokensByUserId(
                                            {
                                                token: cookies.access_token,
                                                userId: cookies.id
                                            },
                                            resolveGetAll
                                        );
                                    }}
                                />
                            </>
                        )}
                    </div>
                </Collapse>
            )}

            {usedTokens && (
                <Collapse label={<h5>{t("UsedTokens")}</h5>} active={true}>
                    <div className="table">
                        {usedTokens.length === 0 ? (
                            <h5>{t("NoTokens")}</h5>
                        ) : (
                            <>
                                <RowHeader
                                    callback={() => {}}
                                    filters={false}
                                    {...{
                                        cells: {
                                            "value": {
                                                text: t("Key"),
                                                active: false
                                            },
                                            "creation": {
                                                text: t("Creation date"),
                                                active: false
                                            },
                                            "token-survey-version": {
                                                text: t("Version"),
                                                active: false
                                            },
                                            "token-survey-user": {
                                                text: t("User"),
                                                active: false
                                            },
                                            "reset": true
                                        }
                                    }}
                                />
                                <List
                                    datas={usedTokens}
                                    rowOptions={rowOptions}
                                    getDatas={() => {
                                        GetAllTokensByUserId(
                                            {
                                                token: cookies.access_token,
                                                userId: cookies.id
                                            },
                                            resolveGetAll
                                        );
                                    }}
                                />
                            </>
                        )}
                    </div>
                </Collapse>
            )}
        </div>
    );
}
export type GroupedAction = {
    type: string;
    /**
     * i18n key for the name of the action
     */
    name: string;
    content: React.ElementType;
};
export type TokenAdminRowOptions = {
    "value": boolean;
    "creation": boolean;
    // "expiration": boolean;
    "token-survey-version": boolean;
    "token-survey-user": boolean;
    "tools": {
        add: (props: any) => JSX.Element;
    };

    "groupedAction": GroupedAction[];
};
export const rowOptions: TokenAdminRowOptions = {
    "value": true,
    "creation": true,
    // "expiration": true,
    "token-survey-version": true,
    "token-survey-user": true,
    "tools": {
        add: (props: any) => <IconeAssignUser {...props} />
    },
    "groupedAction": []
};

export const Tools = (props: any) => {
    const [t] = useTranslation("admin");
    useEffect(() => {
        setSidePage(false);
    }, [props.update]);
    const [sidePage, setSidePage] = useState<ReactElement | false>(false);

    return (
        <>
            <SidePage content={sidePage} setContent={setSidePage} />
            <Btn
                className="admin"
                callback={() => {
                    setSidePage(<AdminPurchaseForm />);
                }}
            >
                <Key background={true} size={40} />
                {t("BuyTokenAction")}
            </Btn>
        </>
    );
};
