import { useTranslation } from "react-i18next";
import { ClearTitlePsychology } from ".";
import CapacityBar from "./CapacityBar";
import { LegendBar } from "./LegendBar";

interface PropsCapacityDetail {
    results: Array<{
        capacity: Array<{
            capacity: { id: number; name: string; description: string };
            psychology: { id: number; name: string; description: string };
            max: number;
            purcent: number;
            score: number;
        }>;
        psychology: { id: number; name: string; description: string };
        max: number;
        purcent: number;
        score: number;
    }>;
    crosseyes: Array<{
        capacity: Array<{
            capacity: { id: number; name: string; description: string };
            psychology: { id: number; name: string; description: string };
            max: number;
            purcent: number;
            score: number;
        }>;
        psychology: { id: number; name: string; description: string };
        max: number;
        purcent: number;
        score: number;
    }>;
    survey?: any;
}

const ColorPsycho = {
    1: "self",
    2: "situational",
    3: "relational",
    4: "creative"
};

export default function CapacityDetail({
    results,
    crosseyes,
    survey = false
}: PropsCapacityDetail) {
    const [t] = useTranslation("survey");
    let capacities = results
        .map((x) => x.capacity)
        .flat()
        .sort((a, b) => {
            if (a.capacity.id < b.capacity.id) return -1;
            if (a.capacity.id > b.capacity.id) return 1;
            return 0;
        })
        .sort((a, b) => b.purcent - a.purcent);
    let capacitiesCross: any = false;
    if (crosseyes) {
        capacitiesCross = crosseyes
            .map((x) => x.capacity)
            .flat()
            .sort((a, b) => {
                if (a.capacity.id < b.capacity.id) return -1;
                if (a.capacity.id > b.capacity.id) return 1;
                return 0;
            })
            .sort((a, b) => b.purcent - a.purcent);
    }

    return (
        <>
            <h3>{t("Your capacities")}</h3>
            <h4>{t("in detail")}</h4>
            <div className="intels">
                <div className="intel">
                    <div className="bubble relational"></div>
                    {ClearTitlePsychology("Monde de l'Autre", false)}
                </div>
                <div className="intel">
                    <div className="bubble creative"></div>
                    {ClearTitlePsychology("Nouveau Monde", false)}
                </div>
                <div className="intel">
                    <div className="bubble self"></div>
                    {ClearTitlePsychology("Monde de Soi", false)}
                </div>
                <div className="intel">
                    <div className="bubble situational"></div>
                    {ClearTitlePsychology("Monde Inattendu", false)}
                </div>
            </div>
            {crosseyes.length > 0 && (
                <>
                    <div className="separator" />
                    <LegendBar />
                    <div className="separator" />
                </>
            )}

            {crosseyes.length > 0
                ? capacitiesCross.map((data: any, index: number) => {
                      return (
                          <div key={index}>
                              <CapacityBar
                                  survey={survey}
                                  crosseye={true}
                                  key={index}
                                  purcent={data.purcent}
                                  title={t(`capacity:${data.capacity.id}-name`)}
                                  data={{ ...data, userCapacities: capacities }}
                                  color={
                                      Object.getOwnPropertyDescriptor(
                                          ColorPsycho,
                                          data.psychology.id
                                      )?.value
                                  }
                              />
                              {index < capacitiesCross.length - 1 && (
                                  <div className="separator" />
                              )}
                          </div>
                      );
                  })
                : capacities.map((data: any, index: number) => {
                      return (
                          <div key={index}>
                              <CapacityBar
                                  survey={survey}
                                  key={index}
                                  purcent={data.purcent}
                                  title={t(`capacity:${data.capacity.id}-name`)}
                                  data={data}
                                  color={
                                      Object.getOwnPropertyDescriptor(
                                          ColorPsycho,
                                          data.psychology.id
                                      )?.value
                                  }
                              />
                              {index < capacities.length - 1 && (
                                  <div className="separator" />
                              )}
                          </div>
                      );
                  })}
        </>
    );
}
