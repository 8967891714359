import { useTranslation } from "react-i18next";
import UserDecay from "../../assets/UserDecay";

export const UserAlreadyExist = () => {
    const [t, i18n] = useTranslation("error");
    return (
        <>
            <h3>{t("User already exists")}</h3>
            <UserDecay size={60} />
        </>
    );
};
