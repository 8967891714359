import { PropsSVG } from "./SVG.interface";

export default function FilePDF({
    className,
    onClick,
    size = 80,
    color = "#EB864B"
}: PropsSVG) {
    return (
        <svg
            className={className}
            width={size}
            height={size}
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
        >
            <g clipPath="url(#clip0_1239_16831)">
                <path
                    d="M9.11651 78.1643V1.83545H44.1556L62.0871 19.7509V78.1643H9.11651Z"
                    fill="white"
                />
                <path
                    d="M43.3961 3.6711L60.2515 20.5111V76.3271H10.9519V3.66949H43.3961V3.6711ZM44.9158 -0.00170898H7.28137V79.9983H63.9211V18.9876L44.9158 -0.00170898Z"
                    fill={color}
                />
                <path
                    d="M62.0871 78.1644H9.11652V1.83557H44.1556L44.5709 2.25088V19.3518H61.6856L62.0871 19.751V78.1644Z"
                    fill="white"
                />
                <path
                    d="M42.7353 3.67116V21.1867H60.2508V76.3281H10.9512V3.67047H42.7346L42.7353 3.67116ZM44.9144 -0.000732422H7.28V79.9993H63.9197V18.9886L62.4444 17.5146H46.4042V1.48932L44.9144 -0.000732422Z"
                    fill={color}
                />
                <path
                    d="M56.5023 16.4653H47.6295V7.49768L56.5023 16.1509V16.4653Z"
                    fill={color}
                />
                <path
                    d="M49.4651 11.8524L52.3125 14.6297H49.4651V11.8524ZM48.3174 5.60474H45.7939V18.3009H58.337V15.3772L48.3174 5.60474Z"
                    fill={color}
                />
                <path
                    d="M68.7962 61.6072H2.40579V34.8052L5.78949 31.4231V35.6794H65.4141V31.4231L68.7962 34.8052V61.6072Z"
                    fill={color}
                />
                <path
                    d="M66.9056 35.0232L67.3064 35.424V60.1175H3.89791V35.424L4.29875 35.0232V37.1713H66.9042V35.0232H66.9056ZM63.9216 27.8219V34.1885H7.27954V27.8219L0.912991 34.1885V63.0992H70.2872V34.1885L63.9216 27.8219Z"
                    fill={color}
                />
                <path
                    d="M11.301 58.6649V39.5747H18.291C19.3287 39.5747 20.3175 39.6824 21.2573 39.8978C22.1972 40.0936 23.0195 40.446 23.7244 40.9551C24.4292 41.4446 24.9873 42.1005 25.3984 42.9228C25.8292 43.7256 26.0446 44.7242 26.0446 45.9185C26.0446 47.0933 25.8292 48.1017 25.3984 48.9436C24.9873 49.7855 24.4292 50.4806 23.7244 51.0288C23.0195 51.5575 22.2069 51.9589 21.2867 52.233C20.3664 52.4875 19.407 52.6148 18.4085 52.6148H16.3526V58.6649H11.301ZM16.3526 48.6205H18.1735C19.1721 48.6205 19.9063 48.3856 20.3762 47.9157C20.8657 47.4458 21.1105 46.78 21.1105 45.9185C21.1105 45.057 20.8462 44.4501 20.3175 44.0976C19.7888 43.7452 19.035 43.569 18.056 43.569H16.3526V48.6205Z"
                    fill="white"
                />
                <path
                    d="M29.1577 58.6649V39.5747H34.7967C36.2456 39.5747 37.5672 39.7509 38.7616 40.1034C39.9755 40.4558 41.0133 41.0138 41.8748 41.7774C42.7363 42.541 43.402 43.52 43.8719 44.7144C44.3614 45.9087 44.6062 47.3479 44.6062 49.0317C44.6062 50.7156 44.3712 52.1645 43.9013 53.3784C43.4314 54.5924 42.7657 55.5909 41.9042 56.3741C41.0622 57.1573 40.0539 57.7349 38.8791 58.1069C37.7239 58.4789 36.461 58.6649 35.0904 58.6649H29.1577ZM34.2093 54.6119H34.503C35.1883 54.6119 35.8344 54.5336 36.4414 54.377C37.0484 54.2008 37.5672 53.9071 37.998 53.4959C38.4483 53.0847 38.8008 52.5169 39.0553 51.7925C39.3098 51.068 39.4371 50.1478 39.4371 49.0317C39.4371 47.9157 39.3098 47.0052 39.0553 46.3003C38.8008 45.5955 38.4483 45.0472 37.998 44.6556C37.5672 44.2641 37.0484 43.9997 36.4414 43.8627C35.8344 43.706 35.1883 43.6277 34.503 43.6277H34.2093V54.6119Z"
                    fill="white"
                />
                <path
                    d="M48.014 58.6649V39.5747H60.4667V43.8039H53.0655V47.4458H59.4094V51.675H53.0655V58.6649H48.014Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_1239_16831">
                    <rect
                        width="70"
                        height="80"
                        fill="white"
                        transform="translate(0.57692)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
