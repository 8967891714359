import { AxiosResponse } from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Download from "../../assets/Download";
import { GetPDF } from "../../services/survey.service";
import { getSurveys } from "../../services/user.service";
import { formatDate, formatDateSince } from "../../utils";
import Btn from "../Templates/Btn";
import Collapse from "../Templates/Collapse";
import List from "../Templates/Dashboard/List";
import { RowHeader } from "../Templates/Dashboard/Row";
import Waiting from "../Templates/Waiting";
import { IconeViewResult } from "./ViewResult";

import StartSurvey from "../../assets/StartSurvey";
import Eye from "../../assets/Eye";

export default function MySurvey(props: any) {
    const navigate = useNavigate();
    const [t] = useTranslation("user");
    const [cookies, setCookie] = useCookies([
        "access_token",
        "inProgress",
        "id",
        "email"
    ]);
    const [surveys, setSurveys] = useState<Array<any> | false>(false);
    const [lastSurvey, setLastSurvey] = useState<Array<any> | false>(false);
    const busy = useRef<boolean>(false);

    useEffect(() => {
        if (!surveys && !busy.current) {
            getSurveys(cookies.id, cookies.access_token, resolveGet);
            busy.current = true;
        }
    }, [cookies.access_token, cookies.id, surveys]);
    const resolveGet = (response: AxiosResponse) => {
        switch (response.status) {
            case 200:
            case 304:
                setCookie("inProgress", true, { path: "/" });
                const allSurveys = sanitizer(response.data, cookies.id);
                setLastSurvey(allSurveys.filter((survey: any) => !survey.finished));
                setSurveys(allSurveys.filter((survey: any) => survey.finished));
                break;
        }
        busy.current = false;
    };
    if (!surveys) return <Waiting />;
    return (
        <div id="MySurvey">
            <Collapse label={<h5>{t("My last survey")}</h5>} active={true}>
                <div className="table">
                    {lastSurvey && lastSurvey.length === 0 ? (
                        <h5>{t("No test")}</h5>
                    ) : (
                        <>
                            <RowHeader
                                {...{
                                    cells: {
                                        "mysurvey-version": {
                                            text: t("Version"),
                                            active: false
                                        },
                                        "type-survey": { text: t("Type"), active: false },
                                        "progress": {
                                            text: t("Started"),
                                            active: false
                                        },
                                        "part": {
                                            text: t("Progress"),
                                            active: false
                                        },
                                        "reset": true
                                    },
                                    callback: () => {},
                                    filters: {},
                                    group: false
                                }}
                            />
                            <List
                                datas={lastSurvey}
                                rowOptions={rowOptions}
                                getDatas={() => {
                                    if (!busy.current) {
                                        getSurveys(
                                            cookies.id,
                                            cookies.access_token,
                                            resolveGet
                                        );
                                    }
                                }}
                                group={false}
                            />
                        </>
                    )}
                </div>
            </Collapse>
            <Collapse label={<h5>{t("My history")}</h5>} active={true}>
                <div className="table">
                    {surveys.length === 0 ? (
                        <h5>{t("No test")}</h5>
                    ) : (
                        <>
                            <RowHeader
                                {...{
                                    cells: {
                                        "mysurvey-version": {
                                            text: t("Version"),
                                            active: false
                                        },
                                        "type-survey": {
                                            text: t("Type"),
                                            active: false
                                        },
                                        "user-scores": {
                                            text: t("Scores"),
                                            active: false
                                        },
                                        "end": {
                                            text: t("Finished"),
                                            active: false
                                        },
                                        "reset": true
                                    },
                                    callback: () => {},
                                    filters: {},
                                    group: false
                                }}
                            />
                            <List
                                datas={surveys}
                                rowOptions={rowOptionsHistory}
                                getDatas={() => {
                                    if (!busy.current) {
                                        getSurveys(
                                            cookies.id,
                                            cookies.access_token,
                                            resolveGet
                                        );
                                    }
                                }}
                                group={false}
                            />
                        </>
                    )}
                </div>
            </Collapse>
        </div>
    );
}
export const rowOptions = {
    "mysurvey-version": true,
    "type-survey": true,
    "progress": true,
    "part": true,
    "tools": {
        view: (props: any) => <IconeViewResult {...props} icone={"start"} />,
        download: (props: any) => <DownloadResult {...props} />
    }
};
export const rowOptionsHistory = {
    "mysurvey-version": true,
    "type-survey": true,
    "user-scores": true,
    "end": true,
    "tools": {
        view: (props: any) => <IconeViewResult {...props} icone={"eye"} />,
        download: (props: any) => <DownloadResult {...props} />
    }
};
const DownloadResult = ({ data }: any) => {
    const [cookies] = useCookies(["firstName", "access_token", "id"]);
    const surveyId = data.id;
    const downloadPDF = () => {
        GetPDF({ token: cookies.access_token, id: surveyId }, resolveDownload);
    };
    const resolveDownload = useCallback((response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "soft-skills-test-resultats.pdf");
                document.body.appendChild(link);
                link.click();
                link.remove();
                break;
        }
    }, []);
    const verifActive = () => {
        if (data.type === "crosseye" && data.finished) {
            return data.user.id === +cookies.id;
        }
        if (data.type === "normal" && data.finished) {
            return true;
        }
        return false;
    };

    return (
        <>
            {verifActive() && (
                <Download className={`tool`} size={24} onClick={downloadPDF} />
            )}
        </>
    );
};

const sanitizer = (datas: any[], userId: string) => {
    return datas.map(({ survey, scores }) => {
        const user = { ...survey.user, ...survey.user.profile };
        let finished = () => {
            console.log("userId", userId);

            if (survey.type === "normal") {
                if (survey.end !== null) return true;
            }
            if (survey.type === "crosseye") {
                if (survey.userId === parseInt(userId) && survey.end) return true;
                const isMySurveyEnded = survey.crosseyes.filter(
                    (crosseye: any) =>
                        crosseye.end && crosseye.userId === parseInt(userId)
                ).length;
                console.log("isMySurveyEnded", isMySurveyEnded);
                if (isMySurveyEnded) return true;
            }
            return false;
        };
        return {
            id: survey.id,
            type: survey.type,
            version: survey.template.version,
            end: survey.end && formatDate(new Date(survey.end), true),
            start: survey.start && formatDateSince(survey.start),
            scores: scores && scores,
            countAnswers: survey.results.length,
            crosseyes: survey.crosseyes && survey.crosseyes,
            user,
            finished: finished()
        };
    });
};
