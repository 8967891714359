import { CookieSetOptions } from "universal-cookie"; // CookieSetOptions is a type used inside react-cookie
import { isDev } from "./isDev";

/**
 * Object containing all the user data in cookies
 */
export type UserDataInCookie = {
    access_token?: string;
    role?: string;
    id?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    inProgress?: boolean;
    userId?: string;
};

/**
 * Union of all possible user data in cookies
 */
export type UserDataInCookieAttributs = keyof UserDataInCookie;

/**
 * Function to set a cookie
 * (provided by react-cookie with useCookies())
 */
type CookieSetterFunction = (
    name: UserDataInCookieAttributs,
    value: any,
    options?: CookieSetOptions | undefined
) => void;

/**
 * Set user data in cookies
 *
 * Security : if values are "undefined" or missing they are not modified in cookies
 *
 * @param userData user data to store in cookie
 * @param cookieSetter function to set cookie (provided by react-cookie with useCookies())
 */
export function setAllUserDataCookie(
    userData: UserDataInCookie,
    cookieSetter: CookieSetterFunction
): void {
    const maxAge = (isDev ? 360000 : 3600) * 1000;

    if (userData.access_token !== undefined)
        cookieSetter("access_token", userData.access_token, { path: "/", maxAge });

    if (userData.role !== undefined)
        cookieSetter("role", userData.role, { path: "/", maxAge });

    if (userData.id !== undefined) cookieSetter("id", userData.userId ?? userData.id, { path: "/", maxAge });

    if (userData.email !== undefined)
        cookieSetter("email", userData.email, { path: "/", maxAge });

    if (userData.firstName !== undefined)
        cookieSetter("firstName", userData.firstName, { path: "/", maxAge });

    if (userData.lastName !== undefined)
        cookieSetter("lastName", userData.lastName, { path: "/", maxAge });

    if (userData.inProgress !== undefined)
        cookieSetter("inProgress", userData.inProgress, { path: "/", maxAge });
}
