import { AxiosResponse } from "axios";
import { ReactElement, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import UserDecay from "../../assets/UserDecay";
import { postDeleteUser } from "../../services/user.service";
import Btn from "../Templates/Btn";
import Modal from "../Templates/Modal";

export default function DeleteAccount() {
    const [t, i18n] = useTranslation("user");
    const [cookies, setCookie, removeCookie] = useCookies([
        "access_token",
        "id",
        "email"
    ]);
    const [modal, setModal] = useState<ReactElement | false>(false);
    const navigate = useNavigate();

    const logout = () => {
        removeCookie("access_token");
        removeCookie("id");
        removeCookie("email");
        navigate(`/`);
    };

    const handleDelete = async () => {
        postDeleteUser(cookies.id, cookies.access_token, resolveDelete);
    };
    const resolveDelete = (response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                setModal(
                    <>
                        <h3>{t("Your account has been successfully deleted.")}</h3>
                        <UserDecay size={60} />
                    </>
                );
                break;
            case 404 | 500:
                setModal(
                    <>
                        <UserDecay size={60} />
                        <div>{t("We could not delete your account.")}</div>
                        <div>
                            {t("Contact our support")}{" "}
                            <a href={`mailto:${process.env.MAIL_ACCOUNT_SUPPORT}`}>
                                {process.env.MAIL_ACCOUNT_SUPPORT}
                            </a>
                        </div>
                    </>
                );
                break;
            default:
                break;
        }
    };

    return (
        <div className="DeleteAccount">
            <Modal content={modal} setContent={setModal} callback={logout} />
            <p>
                {t(
                    "A your sure ? Personnal data will be deleted and only surveys will be kept anonymized."
                )}
            </p>
            <em>
                {t("Account deleted later")} <br />
                {t("If you want to reactivate account")}
            </em>
            <Btn className="active" callback={handleDelete}>
                {t("Yes I want it")}
            </Btn>
        </div>
    );
}
