import { useTranslation } from "react-i18next";
import { PSYCHOLOGY } from ".";

export const TeamZone = ({ Quarter }: any) => {
    const [t] = useTranslation("survey");
    return (
        <div className="team-zone">
            {Object.entries(PSYCHOLOGY.def).map(([key, psychology]) => {
                return (
                    <div className="quarter" key={key}>
                        <div
                            className="circle"
                            style={{
                                background: psychology.color
                            }}
                        >
                            <span>{t(`${psychology.trad}`)}</span>
                        </div>
                        <div className="cloud">
                            <div className="circleborder"></div>
                            {Quarter[psychology.tag]}
                        </div>
                    </div>
                );
            })}
            <div className="landmark">
                <div className="horizontal"></div>
                <div className="vertical"></div>
            </div>
        </div>
    );
};
