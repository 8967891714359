import { AxiosResponse } from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

import { GetAllUsers } from "../../../services/profil.service";
import { GroupedActionsUsers } from "../../../services/root.service";
import { useLocalStorage } from "../../../utils/useLocalStorage";

import Btn from "../../Templates/Btn";
import Checkbox from "../../Templates/Checkbox";
import List, { reloadList } from "../../Templates/Dashboard/List";
import { RowHeader } from "../../Templates/Dashboard/Row";
import { RowFields } from "../../Templates/Field";
import LangDropdown from "../../Templates/LangDropdown";
import TextField from "../../Templates/TextField";
import VersionDropdown from "../../Templates/VersionDropdown";
import Waiting from "../../Templates/Waiting";

interface PropsNewCrosseye {
    closeModal: Function;
}
export default function NewCrosseye({ closeModal }: PropsNewCrosseye) {
    const [t, i18n] = useTranslation("admin");
    const [cookies] = useCookies(["access_token"]);
    const [users, setUsers] = useState<Array<any> | false>(false);
    const [hasToSendMail, setHasToSendMail] = useState(false);
    const chooseLang = useRef(null);
    const [lang, setLang] = useState(i18n.language);
    const [hasCustomMessage, setHasCustomMessage] = useState(false);
    const [customMessage, setCustomMessage] = useState("");
    const refCustomMessage = useRef<HTMLInputElement | null>(null);
    const [currentVersion, setCurrentVersion] = useLocalStorage("version", "");
    const refVersion = useRef<HTMLInputElement | null>(null);

    const resolveGetAll = useCallback((response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                setUsers(
                    response.data?.map((user: any) => {
                        return { ...user, ...user.profile };
                    })
                );
                break;
            case 401:
            default:
                console.log("ERROR", response);
                break;
        }
    }, []);

    const handleSubmit = useCallback(async () => {
        const evaluedUsers: any[] = [];
        const testerUsers: any[] = [];

        document.querySelectorAll(`[name="select-valued"]`).forEach((elem: any) => {
            if (elem.checked) evaluedUsers.push(elem.value.split("-").pop());
        });
        document.querySelectorAll(`[name="select-tester"]`).forEach((elem: any) => {
            if (elem.checked) testerUsers.push(elem.value.split("-").pop());
        });
        GroupedActionsUsers({
            action: "add-crosseye",
            data: {
                version: refVersion.current!.value,
                ids: evaluedUsers,
                selectUsers: testerUsers,
                lang,
                hasToSendMail,
                hasCustomMessage,
                customMessage
            },
            token: cookies.access_token,
            callback: () => {
                reloadList();
                closeModal(false);
            }
        });
    }, [
        closeModal,
        cookies.access_token,
        customMessage,
        hasCustomMessage,
        hasToSendMail,
        lang
    ]);

    useEffect(() => {
        if (!users) {
            GetAllUsers({ token: cookies.access_token, filters: false }, resolveGetAll);
        }
    }, [cookies.access_token, users, resolveGetAll]);
    return (
        <div id="NewCrosseye">
                <h3>{t("New cross eye")}</h3>
                <h5>{t("Survey version")}</h5>
                <VersionDropdown
                    callback={setCurrentVersion}
                    refInput={refVersion}
                    defaultValue={currentVersion}
                />
            <div className="table">
                <RowHeader
                    {...{
                        cells: {
                            "crosseye-select-valued": { text: t("Rated"), active: false },
                            "crosseye-select-tester": {
                                text: t("Evaluator"),
                                active: false
                            },
                            "lastName": {
                                text: t("Last name"),
                                active: false
                            },
                            "firstName": { text: t("First name"), active: false },
                            "email": { text: t("Email"), active: false },
                            "company": { text: t("Company"), active: false }
                        },
                        callback: console.log,
                        filters: false
                    }}
                />
                {typeof users === "object" && (
                    <List datas={users} rowOptions={rowOptions} getDatas={() => {}} />
                )}
                <RowFields>
                    <Checkbox
                        type={"checkbox"}
                        value={"sendMail"}
                        name="sendMail"
                        callback={() => {
                            setHasToSendMail(!hasToSendMail);
                            if (hasToSendMail) {
                                setCustomMessage("");
                                setHasCustomMessage(false);
                            }
                        }}
                        checked={hasToSendMail}
                    >
                        {t("Send an email to notify test access")}
                    </Checkbox>
                    {/* 
                </RowFields>
                <RowFields> */}
                    {hasToSendMail && (
                        <>
                            <Checkbox
                                type={"checkbox"}
                                value={"customMessage"}
                                name="customMessage"
                                callback={() => {
                                    setHasCustomMessage(!hasCustomMessage);
                                    if (hasCustomMessage) {
                                        setCustomMessage("");
                                    }
                                }}
                                checked={hasCustomMessage}
                            >
                                {t("Custom message")}
                            </Checkbox>
                            <LangDropdown
                                refInput={chooseLang}
                                defaultValue={lang}
                                callback={setLang}
                            />
                        </>
                    )}
                </RowFields>
                {hasCustomMessage && (
                    <TextField
                        defaultValue={customMessage}
                        callback={() => {
                            if (refCustomMessage.current) {
                                setCustomMessage(refCustomMessage.current.value);
                            }
                        }}
                        refField={refCustomMessage}
                        placeholder={t("Custom message")}
                    />
                )}
                <RowFields>
                    <Btn
                        children={t("Cancel")}
                        className={"cancel"}
                        callback={() => closeModal(false)}
                    />
                    <Btn
                        children={t("Active")}
                        className={"active"}
                        callback={() => {
                            closeModal(<Waiting width={800} height={800} />);
                            handleSubmit();
                        }}
                    />
                </RowFields>
            </div>
        </div>
    );
}

export const rowOptions = {
    "crosseye-select-tester": true,
    "crosseye-select-valued": true,
    "lastName": true,
    "firstName": true,
    "email": true,
    "company": true,
    "tools": {}
};
