import { Elements } from "@stripe/react-stripe-js";
import { Appearance, loadStripe, Stripe, StripeElementLocale } from "@stripe/stripe-js";
import { AxiosResponse } from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import {
    createSurveyTokenPurchaseIntent,
    getStripePublicKey
} from "../../services/purchase.service";
import {
    calculatedPriceWithoutTax,
    calculatePriceWithTax,
    formatPrice
} from "../../utils";
import { useLocalStorage } from "../../utils/useLocalStorage";
import Btn from "../Templates/Btn";
import { RowFields } from "../Templates/Field";
import VersionDropdown from "../Templates/VersionDropdown";
import CheckoutForm from "./CheckoutForm";
import TokenCalculatorInput from "./TokenCalculatorInput";

type busyQuery =
    | "getStripePublicKey"
    | "createSurveyTokenPurchaseIntent"
    | "getSurveyTokenPrice";
type busyQueriesState = { [key in busyQuery]: boolean };

export default function AdminPurchaseForm() {
    const [t, i18n] = useTranslation("purchase");
    const [cookies] = useCookies(["access_token"]);
    const [clientSecret, setClientSecret] = useState("");
    const [stripeClient, setStripeClient] = useState<Stripe | null>(null);
    const [calculetedPrice, setCalculatedPrice] = useState<number>(0);

    const [individualQuantity, setIndividualQuantity] = useState<number>(0);
    const [individualTotalPrice, setIndividualTotalPrice] = useState<number>(0);
    const [crosseyeQuantity, setCrosseyeQuantity] = useState<number>(0);
    const [crosseyeTotalPrice, setCrosseyeTotalPrice] = useState<number>(0);

    const InputVersion = useRef<HTMLInputElement>(null);
    const [currentVersion] = useLocalStorage("version", "");

    const busy = useRef<busyQueriesState>({
        getStripePublicKey: false,
        createSurveyTokenPurchaseIntent: false,
        getSurveyTokenPrice: false
    });

    /**
     * Setup stripe client (from API data)
     */
    const resolveGetClientSecret = useCallback(async (response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                if (response.data.stripePublicKey) {
                    const sCLient: Stripe | null = await loadStripe(
                        response.data.stripePublicKey
                    );
                    setStripeClient(sCLient);
                }
                break;
            default:
                console.log("Getting client secret failed", response);
                break;
        }
        busy.current.getStripePublicKey = false;
    }, []);
    useEffect(() => {
        if (!busy.current.getStripePublicKey && !stripeClient) {
            getStripePublicKey({ token: cookies.access_token }, resolveGetClientSecret);
            busy.current.getStripePublicKey = true;
        }
    }, [cookies.access_token, resolveGetClientSecret, stripeClient]);

    /**
     * Create payment intent (with API)
     */
    const resolveCreateSurveyTokenPaymentIntent = useCallback(
        (response: AxiosResponse) => {
            switch (response.status) {
                case 200:
                    setClientSecret(response.data.clientSecret);
                    break;
                default:
                    console.log("Creating survey token payment intent failed", response);
                    break;
            }
            busy.current.createSurveyTokenPurchaseIntent = false;
        },
        []
    );

    /**
     * Calculate total price
     */
    useEffect(() => {
        setCalculatedPrice(individualTotalPrice + crosseyeTotalPrice);
    }, [individualTotalPrice, crosseyeTotalPrice]);

    const appearance: Appearance = {
        theme: "stripe"
    };

    return (
        <div id="AdminPurchase">
            <div className="form">
                {!clientSecret ? (
                    <>
                        <h3>{t("HowManyQuestion")}</h3>

                        <TokenCalculatorInput
                            label="Individual"
                            surveyTokenType={"individual"}
                            tokenQuantityState={individualQuantity}
                            setTokenQuantityState={setIndividualQuantity}
                            setTotalPriceState={setIndividualTotalPrice}
                        />

                        <TokenCalculatorInput
                            label="Crosseye"
                            surveyTokenType={"crosseye"}
                            tokenQuantityState={crosseyeQuantity}
                            setTokenQuantityState={setCrosseyeQuantity}
                            setTotalPriceState={setCrosseyeTotalPrice}
                        />
                        {/* <RowFields> */}
                        <VersionDropdown
                            callback={console.log}
                            refInput={InputVersion}
                            defaultValue={currentVersion}
                            label={t("Version")}
                        />
                        {/* </RowFields> */}

                        <div className="total-price-without-tax">
                            <div className="label">{t("totalPriceWithoutTax")}</div>
                            <div className="price">
                                {formatPrice(calculatedPriceWithoutTax(calculetedPrice))}
                            </div>
                        </div>
                        <div className="total-price-with-tax">
                            <div className="label">{t("totalPriceWithTax")}</div>
                            <div className="price">
                                {formatPrice(calculatePriceWithTax(calculetedPrice))}
                            </div>
                        </div>

                        <Btn
                            className={
                                individualQuantity + crosseyeQuantity > 0
                                    ? "active"
                                    : "disabled"
                            }
                            callback={() => {
                                if (
                                    !busy.current.createSurveyTokenPurchaseIntent &&
                                    !clientSecret
                                ) {
                                    createSurveyTokenPurchaseIntent(
                                        {
                                            token: cookies.access_token,
                                            individualQuantity,
                                            crosseyeQuantity,
                                            version: InputVersion.current
                                                ? InputVersion.current.value
                                                : "1.0.0"
                                        },
                                        resolveCreateSurveyTokenPaymentIntent
                                    );
                                    busy.current.createSurveyTokenPurchaseIntent = true;
                                }
                            }}
                        >
                            {t("Validate", { ns: "common" })}
                        </Btn>
                    </>
                ) : (
                    <Elements
                        options={{
                            clientSecret,
                            appearance,
                            locale: (i18n.language || "fr") as StripeElementLocale
                        }}
                        stripe={stripeClient}
                    >
                        <CheckoutForm onSucessRedirection={window.location.href} />
                    </Elements>
                )}
            </div>
        </div>
    );
}
