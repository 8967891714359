export default function Superuser() {
    return (
        <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1176_12578)">
                <path
                    d="M21.3379 16.9121C23.0504 18.0746 24.2504 19.6496 24.2504 21.7496V25.4996H29.2504V21.7496C29.2504 19.0246 24.7879 17.4121 21.3379 16.9121Z"
                    fill="#295380"
                />
                <path
                    d="M19.2504 15.5C22.0129 15.5 24.2504 13.2625 24.2504 10.5C24.2504 7.7375 22.0129 5.5 19.2504 5.5C18.6629 5.5 18.1129 5.625 17.5879 5.8C18.6254 7.0875 19.2504 8.725 19.2504 10.5C19.2504 12.275 18.6254 13.9125 17.5879 15.2C18.1129 15.375 18.6629 15.5 19.2504 15.5Z"
                    fill="#295380"
                />
                <path
                    d="M11.75 15.5C14.5125 15.5 16.75 13.2625 16.75 10.5C16.75 7.7375 14.5125 5.5 11.75 5.5C8.9875 5.5 6.75 7.7375 6.75 10.5C6.75 13.2625 8.9875 15.5 11.75 15.5ZM11.75 8C13.125 8 14.25 9.125 14.25 10.5C14.25 11.875 13.125 13 11.75 13C10.375 13 9.25 11.875 9.25 10.5C9.25 9.125 10.375 8 11.75 8Z"
                    fill="#295380"
                />
                <path
                    d="M11.75 16.75C8.4125 16.75 1.75 18.425 1.75 21.75V25.5H21.75V21.75C21.75 18.425 15.0875 16.75 11.75 16.75ZM19.25 23H4.25V21.7625C4.5 20.8625 8.375 19.25 11.75 19.25C15.125 19.25 19 20.8625 19.25 21.75V23Z"
                    fill="#295380"
                />
            </g>
            <defs>
                <clipPath id="clip0_1176_12578">
                    <rect width="30" height="30" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
