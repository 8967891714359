import { randomInt } from "../../utils";

export default function Waiting({ count = 10, width = 300, height = 150 }: PropsWaiting) {
    return (
        <svg
            className="Waiting"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
        >
            {Array(count)
                .fill(false)
                .map((value, index) => {
                    return <Circle key={index} width={width} height={height} />;
                })}
        </svg>
    );
}

const Circle = ({ width, height }: PropsCircle) => {
    const nameColors = Object.keys(Colors);
    const randomColor = Object.getOwnPropertyDescriptor(
        Colors,
        nameColors[randomInt(0, nameColors.length)]
    );
    const randomTimer = randomInt(2000, 6000);
    const randomRayon = randomInt(5, 30);
    const randomCx = randomInt(0 + randomRayon * 2, width - randomRayon * 4);
    const randomCy = height + randomInt(0 + randomRayon * 2, 300 + randomRayon * 4);
    return (
        <circle
            cx={randomCx}
            cy={randomCy}
            r={randomRayon}
            fill={randomColor && randomColor.value(1)}
        >
            <animate
                attributeType="XML"
                attributeName="cy"
                from={randomCy}
                to="-200"
                dur={`${randomTimer}ms`}
                repeatCount={400}
            />
            <animate
                attributeType="XML"
                attributeName="fill"
                from={randomColor && randomColor.value(1)}
                to={randomColor && randomColor.value(0)}
                dur={`${randomTimer}ms`}
                repeatCount={400}
            />
        </circle>
    );
};
const Colors = {
    Flamenco: (alpha = 1) => `rgba(235, 133, 74, ${alpha})`,
    Zambia: (alpha = 1) => ` rgba(255, 153, 12, ${alpha})`,
    EyePoppingCherry: (alpha = 1) => ` rgba(181, 0, 127, ${alpha})`,
    VividCerulean: (alpha = 1) => ` rgba(148, 186, 224, ${alpha})`,
    TranquilBay: (alpha = 1) => ` rgba(0, 172, 231, ${alpha})`,
    PassionFlower: (alpha = 1) => ` rgba(107, 79, 151, ${alpha})`
};

interface PropsWaiting {
    count?: number;
    width?: number;
    height?: number;
}
interface PropsCircle {
    width: number;
    height: number;
}
