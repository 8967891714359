import { PropsSVG } from "./SVG.interface";

export default function UserDecay({ size = 45 }: PropsSVG) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 45 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M26.25 15C26.25 10.8562 22.8938 7.5 18.75 7.5C14.6062 7.5 11.25 10.8562 11.25 15C11.25 19.1438 14.6062 22.5 18.75 22.5C22.8938 22.5 26.25 19.1438 26.25 15ZM22.5 15C22.5 17.0625 20.8125 18.75 18.75 18.75C16.6875 18.75 15 17.0625 15 15C15 12.9375 16.6875 11.25 18.75 11.25C20.8125 11.25 22.5 12.9375 22.5 15Z"
                fill="#00ACE7"
            />
            <path
                d="M3.75 33.75V37.5H33.75V33.75C33.75 28.7625 23.7563 26.25 18.75 26.25C13.7437 26.25 3.75 28.7625 3.75 33.75ZM7.5 33.75C7.875 32.4188 13.6875 30 18.75 30C23.7938 30 29.5687 32.4 30 33.75H7.5Z"
                fill="#00ACE7"
            />
            <path d="M43.125 18.75H31.875V22.5H43.125V18.75Z" fill="#00ACE7" />
        </svg>
    );
}
