import { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import IconeImportCSV from "../../../assets/ImportCSV";
import Btn from "../../Templates/Btn";
import List from "../../Templates/Dashboard/List";
import { RowHeader } from "../../Templates/Dashboard/Row";
import SidePage from "../../Templates/SidePage";
import NewWhiteLabel from "./NewWhiteLabel";
import { WhiteLabelModel } from "../models";
import { AxiosResponse } from "axios";
import { GetAllWhiteLabels } from "../../../services/whiteLabel.service";
import { IconeDeleteWhiteLabel } from "./DeleteWhiteLabel";
import ToolsWhiteLabel from "./ToolsWhiteLabel";
import Psychology from "../../../assets/Psychology";
import Capacity from "../../../assets/Capacity";
import Toc from "../../../assets/Toc";
import { IconeEditWhiteLabel } from "./EditWhiteLabel";

export default function WhiteLabel() {
    const [t, i18n] = useTranslation("admin");
    const [cookies] = useCookies(["access_token"]);
    const [whiteLabels, setWhiteLabels] = useState<WhiteLabelModel[] | boolean>(false);
    const busy = useRef(false);

    const resolveGetAll = useCallback((response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                const whiteLabelsResponse = response.data;
                setWhiteLabels(whiteLabelsResponse);
                break;
            case 401:
            default:
                console.log("ERROR", response);
                <>ERROR</>;
                break;
        }
        busy.current = false;
    }, []);
    useEffect(() => {
        if (!whiteLabels && !busy.current) {
            GetAllWhiteLabels({ token: cookies.access_token }, resolveGetAll);
            busy.current = true;
        }
    }, [cookies.access_token, whiteLabels, resolveGetAll]);

    const rowOptions = {
        "white-label-name": true,
        "white-label-admin": true,
        "white-label-url": true,
        "tools": {
            psychology: (props: any) => <ToolsWhiteLabel page='psychology' Icone={Psychology} name={props.data.name} />,
            capacity: (props: any) => <ToolsWhiteLabel page='capacity' Icone={Capacity} name={props.data.name} />,
            question: (props: any) => <ToolsWhiteLabel page='questions' Icone={Toc} name={props.data.name} />,
            edit: (props: any) => <IconeEditWhiteLabel {...props} />,
            delete: (props: any) => <IconeDeleteWhiteLabel {...props.data} />
        }
    };
    return (
        <div className="table" id="WhiteLabel">
            <RowHeader
                {...{
                    cells: {
                        // id: { text: "Id", active: false },
                        "white-label-name": {
                            text: t("White label name"),
                            active: false
                        },
                        "white-label-admin": {
                            text: t("Administrator"),
                            active: false
                        },
                        "white-label-url": { text: t("White label url"), active: false }
                    },
                    callback: console.log,
                    filters: false
                }}
            />
            {typeof whiteLabels === "object" && (
                <List
                    datas={whiteLabels}
                    rowOptions={rowOptions}
                    getDatas={() => setWhiteLabels(false)}
                />
            )}
        </div>
    );
}

type PropsTools = {
    update: number;
};

export const Tools = (props: PropsTools) => {
    const [t, i18n] = useTranslation("admin");
    const [sidePage, setSidePage] = useState<ReactElement | false>(false);
    useEffect(() => {
        setSidePage(false);
    }, [props.update]);
    return (
        <>
            <SidePage content={sidePage} setContent={setSidePage} />
            <Btn
                className="admin"
                callback={() => {
                    setSidePage(<NewWhiteLabel closeModal={setSidePage} />);
                }}
            >
                <IconeImportCSV />
                {t("New white label")}
            </Btn>
        </>
    );
};
