import { AxiosResponse } from "axios";
import { ReactElement, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import Edit from "../../../assets/Edit";
import Toc from "../../../assets/Toc";
import { putCapacity } from "../../../services/capacity.service";
import { getTradFromDB } from "../../../services/translate.service";
import Btn from "../../Templates/Btn";
import { reloadList } from "../../Templates/Dashboard/List";
import Dropdown from "../../Templates/Dropdown";
import Field from "../../Templates/Field";
import Modal from "../../Templates/Modal";
import PsychologyDropDown from "../../Templates/PsychologyDropdown";
import SidePage from "../../Templates/SidePage";
import TextField from "../../Templates/TextField";
import Waiting from "../../Templates/Waiting";

export const EditCapacity = ({ capacity }: any) => {
    const [t, i18n] = useTranslation("admin");
    const [cookies] = useCookies(["access_token"]);
    const [modal, setModal] = useState<ReactElement | false>(false);
    const [activeBtn, setActiveBtn] = useState<boolean>(false);

    const [langTrad, setLangTrad] = useState<string | false>(false);

    const id = useRef<HTMLInputElement | null>(null);
    const psychology = useRef<HTMLInputElement | null>(null);
    const name = useRef<HTMLInputElement | null>(null);
    const description = useRef<HTMLInputElement | null>(null);

    const selectLang = useRef<HTMLInputElement | null>(null);
    const tradName = useRef<HTMLInputElement | null>(null);
    const tradDesc = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (!langTrad) {
            setLangTrad("en");
        }
        if (langTrad) {
            if (tradName.current) {
                tradName.current.value = t(capacity.capacity, { lng: langTrad });
            }
            if (tradDesc.current) {
                tradDesc.current.value = t(capacity.description, { lng: langTrad });
            }
        }
    }, [langTrad, capacity, t]);
    useEffect(() => {
        setActiveBtn(verifForm());
    }, []);

    const changeForm = () => {
        return setActiveBtn(verifForm());
    };

    const verifForm = () => {
        if (id.current && id.current.value === "") return false;
        if (psychology.current && psychology.current.value === "") return false;
        if (name.current && name.current.value === "") return false;
        if (description.current && description.current.value === "") return false;

        return true;
    };
    const resolvePut = async (response: AxiosResponse) => {
        const { status } = response;
        switch (status) {
            case 200:
                await getTradFromDB();
                reloadList();
                setModal(
                    <>
                        <h4>{t("Modification of the capacity performed")}</h4>
                        <Toc size={80} />
                    </>
                );
                break;
            default:
                setModal(
                    <>
                        <h4>{t("An error has occurred please try again")}</h4>
                    </>
                );
                break;
        }
    };
    const handleForm = () => {
        if (!activeBtn) return false;
        setModal(<Waiting />);
        if (verifForm()) {
            const formData = {
                token: cookies.access_token,
                capacity: {
                    id: id.current?.value || "",
                    name: name.current?.value || "",
                    description: description.current?.value || "",
                    psychology: psychology.current?.value || "",
                    trad: {
                        lng: langTrad || "",
                        name: tradName.current?.value || "",
                        description: tradDesc.current?.value || ""
                    }
                }
            };
            putCapacity(formData, resolvePut);
        }
    };
    return (
        <div id="EditCapacity">
            <Modal content={modal} setContent={setModal} />
            <h3>
                {t("Capacity")} n°{capacity.id}
            </h3>
            <div className="content">
                <div className="default">
                    <PsychologyDropDown
                        label={t("Psychology")}
                        refInput={psychology}
                        defaultValue={t(capacity.world, { lng: "fr" })}
                        callback={changeForm}
                    />

                    <Field
                        defaultValue={t(capacity.capacity, { lng: "fr" })}
                        label={t("Name")}
                        refField={name}
                        callback={changeForm}
                        type={"text"}
                        id={capacity.name}
                    />
                    <TextField
                        refField={description}
                        label={t("Description")}
                        defaultValue={t(capacity.description, { lng: "fr" })}
                        size={10}
                        callback={changeForm}
                    />
                </div>
                <div className="translate">
                    <h4>{t("Edit translation")}</h4>

                    <Dropdown
                        label={t("Choose language")}
                        refInput={selectLang}
                        callback={() => {
                            if (selectLang.current) {
                                setLangTrad(selectLang.current?.value);
                            }
                        }}
                        values={i18n.languages.filter((x) => x !== "fr") as string[]}
                        defaultValue={"en"}
                    />
                    {langTrad && (
                        <>
                            <Field
                                defaultValue={t(capacity.capacity, { lng: langTrad })}
                                label={t("Name")}
                                refField={tradName}
                                type={"text"}
                                id={capacity.capacity + "trad"}
                            />
                            <TextField
                                defaultValue={t(capacity.description, {
                                    lng: langTrad
                                })}
                                label={t("Description")}
                                refField={tradDesc}
                                size={10}
                            />
                        </>
                    )}
                </div>
            </div>
            <Btn
                className={activeBtn ? "active" : "disabled"}
                callback={() => {
                    if (verifForm()) {
                        handleForm();
                    }
                }}
            >
                {t("Save my changes")}
            </Btn>
            <Field
                type="hidden"
                id="id"
                refField={id}
                placeholder={"999"}
                callback={undefined}
                defaultValue={String(capacity.id)}
            />
        </div>
    );
};

interface PropsIconeEditCapacity {
    update: { date: number };
    data: any;
}
export function IconeEditCapacity(props: PropsIconeEditCapacity) {
    const [sidepage, setSidepage] = useState<ReactElement | false>(false);
    return (
        <>
            <SidePage content={sidepage} setContent={setSidepage} />
            <Edit
                className="tool"
                onClick={() => {
                    setSidepage(<EditCapacity capacity={props.data} />);
                }}
            />
        </>
    );
}
