import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { isDev } from "../../utils/isDev";
import Btn from "../Templates/Btn";

interface PropsProgress {
    value: number;
    max: number;
    currentPart: number;
    start: Function;
    end: Function;
}

export default function Progress({
    value = 10,
    max,
    currentPart,
    start,
    end
}: PropsProgress) {
    const [t] = useTranslation("survey");

    const [started, setStarted] = useState<boolean>(false);

    const purcent = (currentPart * 100) / max;
    if (!started) {
        return (
            <>
                <Btn
                    dataset={isDev && "start-survey"}
                    callback={() => {
                        start();
                        setStarted(true);
                    }}
                    className="active"
                >
                    {t("Start")}
                </Btn>
            </>
        );
    }
    if (value > max) {
        return (
            <>
                <Btn callback={end} className="active">
                    {t("See my results")}
                </Btn>
            </>
        );
    }
    return (
        <div className="progress">
            <div className="text">
                {currentPart}/{max} {t("pages")}
            </div>
            <div className="bar" style={{ width: `${purcent}%` }}></div>
        </div>
    );
}
