import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { accessibleLanguages, Languages } from "../langs";
import Dropdown from "./Templates/Dropdown";
import { useLocalStorage } from "../utils/useLocalStorage";
import { useCookies } from "react-cookie";

export default function SwitchLangDropdown() {
    const [t, i18n] = useTranslation();
    const [langLocal, setLangLocal] = useLocalStorage<string>("lang", "fr");
    const [cookies, setCookies] = useCookies(["lang"]);
    const refInput = React.useRef<HTMLInputElement | null>(null);

    const handleChangeLang = (lang: string) => {
        const [internationalLang] = Object.entries(Languages).find(
            ([internationnalLang, native]: [string, string]) => {
                return native === lang;
            }
        ) || [Object.keys(Languages)[0]];

        setLangLocal(internationalLang);
        setCookies("lang", internationalLang, { path: "/" });
        i18n.changeLanguage(internationalLang);
    };

    useEffect(() => {
        if (!cookies.lang) {
            setCookies("lang", "fr", { path: "/" });
        }
    });

    const currentLang = Object.getOwnPropertyDescriptor(Languages, langLocal)?.value;

    return (
        <>
            <Dropdown
                defaultValue={currentLang}
                values={accessibleLanguages as string[]}
                callback={handleChangeLang}
                refInput={refInput}
            />
        </>
    );
}
