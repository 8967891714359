import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import FileCSV from "../../../../assets/FileCSV";
import Trash from "../../../../assets/Trash";
import ValidCSV from "../../../../assets/ValidCSV";
import { GroupedActionsUsers } from "../../../../services/root.service";
import Btn from "../../../Templates/Btn";
import { reloadList } from "../../../Templates/Dashboard/List";
import { RowFields } from "../../../Templates/Field";
import Waiting from "../../../Templates/Waiting";

export const ExportResults = ({ action, ids, token, setModal }: any) => {
    const [t] = useTranslation("admin");
    const callback = (response: AxiosResponse) => {
        switch (response.status) {
            case 200:
                setModal(
                    <>
                        <h4>{t("The CSV file has been exported successfully !")}</h4>
                        <ValidCSV />
                    </>
                );
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "export-results.csv");
                document.body.appendChild(link);
                link.click();
                link.remove();
                reloadList();
                break;
            default:
                setModal(<>
                    <h4>{t("CSV bad request")}</h4>
                    <Trash />
                </>);
                reloadList();
                break;
        }
    };
    return (
        <>
            <h4>
                {t("Confirm list export")}
                <br /> {t("selected users in CSV file ?")}
            </h4>
            <FileCSV color={"#00ACE7"} />
            <RowFields>
                <Btn
                    children={t("Cancel", { ns: "common" })}
                    className={"cancel"}
                    callback={() => {
                        setModal(false);
                    }}
                />
                <Btn
                    children={t("Export", { ns: "common" })}
                    className={"active"}
                    callback={() => {
                        setModal(<Waiting />);
                        GroupedActionsUsers({
                            action,
                            data: { ids },
                            token,
                            callback
                        });
                    }}
                />
            </RowFields>
        </>
    );
};
