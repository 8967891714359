import { ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Eye from "../../assets/Eye";
import StartSurvey from "../../assets/StartSurvey";
import SurveyTabs from "../Survey/Results/SurveyTabs";

export function ViewResult({ result }: any) {
    const { id } = result.data;
    return (
        <div id="ViewResult">
            <SurveyTabs id={id} activeDL={true} />
        </div>
    );
}
export const IconeViewResult = (props: any) => {
    const { type } = props.data;
    const navigate = useNavigate();
    if (type && type === 1) {
        return <></>;
    }
    return (
        <>
            {props.icone === "eye" ? (
                <Eye
                    className={`tool`}
                    size={24}
                    onClick={() => {
                        navigate(`/survey/${props.data.id}`);
                    }}
                />
            ) : (
                <StartSurvey
                    className={`tool`}
                    size={24}
                    onClick={() => {
                        navigate(`/survey/${props.data.id}`);
                    }}
                />
            )}
        </>
    );
};
