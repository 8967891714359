export const frDropdown = {
    "Man": "Homme",
    "Woman": "Femme",
    "Do not wish to answer": "Ne souhaite pas répondre",
    //Reverse translate gender
    "Homme": "Homme",
    "Femme": "Femme",
    "Ne souhaite pas répondre": "Ne souhaite pas répondre",

    "Self-Taught": "Autodidacte",
    "High school diploma": "Bac",
    "Bac +2": "Bac +2",
    "Bachelor's degree": "Bac +3",
    "Master's degree": "Bac +5",

    "Autodidacte": "Autodidacte",
    "Bac": "Bac",
    "Bac +3": "Bac +3",
    "Bac +5": "Bac +5",

    "- 25 years old": "- de 25 ans",
    "25 - 34 years": "25 - 34 ans",
    "35 - 50 years": "35 - 50 ans",
    "51 - 65 years": "51 - 65 ans",
    "65 and over": "65 et plus",
    //Reverse translate age
    "- de 25 ans": "- de 25 ans",
    "25 - 34 ans": "25 - 34 ans",
    "35 - 50 ans": "35 - 50 ans",
    "51 - 65 ans": "51 - 65 ans",
    "65 et plus": "65 et plus",

    "Collaborator": "Collaborateur",
    "Manager": "Manager",
    "Director": "Directeur",
    "Executive": "Dirigeant",
    "Freelancer": "Indépendant",
    "Other": "Autre",

    "Collaborateur": "Collaborateur",
    // Manager: "Manager",
    "Directeur": "Directeur",
    "Dirigeant": "Dirigeant",
    "Indépendant": "Indépendant",
    "Autre": "Autre",

    "Administrative": "Administratif",
    "Purchasing": "Achat",
    "Commercial": "Commercial",
    "Finance": "Finance",
    "Legal": "Juridique",
    "Industrial": "Industriel",
    "Marketing": "Marketing",
    "HR": "RH",
    "R&D": "R&D",
    // Other: "Autre",
    "Administratif": "Administratif",
    "Achat": "Achat",
    // Commercial: "Commercial",
    // Finance: "Finance",
    "Juridique": "Juridique",
    "Industriel": "Industriel",
    // Marketing: "Marketing",
    "RH": "RH",
    // "R&D": "R&D",

    "Company in the industry": "Entreprise dans l'industrie",
    "Company in the service": "Entreprise dans le service",
    "Public organization": "Organisation publique",

    "Entreprise dans l'industrie": "Entreprise dans l'industrie",
    "Entreprise dans le service": "Entreprise dans le service",
    "Organisation publique": "Organisation publique"
};
