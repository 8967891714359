import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { getCapacities } from "../../services/capacity.service";
import { useLocalStorage } from "../../utils/useLocalStorage";
import Dropdown from "./Dropdown";
interface PropsCapacityDropDown {
    refInput: React.RefObject<HTMLInputElement>;
    callback?: Function;
    defaultValue?: string | false;
    label?: string;
}

export default function CapacityDropDown({
    refInput,
    callback,
    defaultValue,
    label
}: PropsCapacityDropDown) {
    const [cookie] = useCookies(["access_token"]);
    const [capacity, setCapacity] = useState<false | Array<any>>(false);
    const [currentVersion, setCurrentVersion] = useLocalStorage('version', "");
    useEffect(() => {
        const resolveGet = (response: AxiosResponse) => {
            switch (response.status) {
                case 200:
                    setCapacity(response.data);
                    break;

                default:
                    break;
            }
        };
        if (!capacity) {
            getCapacities({token: cookie.access_token, version : currentVersion }, resolveGet);
        }
    });
    console.log('capacities', capacity);
    
    if (!capacity) return null;
    return (
        <Dropdown
            label={label}
            refInput={refInput}
            callback={callback}
            values={capacity.map((capa: any) => capa.name)}
            defaultValue={defaultValue}
        />
    );
}
